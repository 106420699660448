import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Cookies from 'universal-cookie';

import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Meal from './classes/MealClass.js';
import Food from './classes/FoodClass.js';
import MealList from './classes/MealListClass.js';

import { jsonFoodList } from './Data.js';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import ScaleIcon from '@mui/icons-material/Scale';

import Slider from '@mui/material/Slider';
import { popoverClasses } from '@mui/material';

//import InputDialog from './InputDialog.js';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import Footer from './lib/Footer.js';

import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';

import { useNavigate } from "react-router-dom";


//ICONS
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SaveIcon from '@mui/icons-material/Save';
import ImageIcon from '@mui/icons-material/Image';


const defaultTheme = createTheme();

let meal = new Meal();
var globali = 1;

//███████  ██████   ██████  ██████      ██      ██ ███    ██ ███████ 
//██      ██    ██ ██    ██ ██   ██     ██      ██ ████   ██ ██      
//█████   ██    ██ ██    ██ ██   ██     ██      ██ ██ ██  ██ █████   
//██      ██    ██ ██    ██ ██   ██     ██      ██ ██  ██ ██ ██      
//██       ██████   ██████  ██████      ███████ ██ ██   ████ ███████ 
class FoodLine extends React.Component {
  food;
  name = "";
  value = 1;
  test = 200;
  mydisplay='none'
  

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
  //  this.state = { counter: 0 };
  //  this.handleClick = this.handleClick.bind(this);
    this.food = props.food;
    this.deleteFoodSuper = props.deleteFood;
    this.changeFoodSuper = props.changeFood;
    this.meal = props.meal;
    this.state = {
      state: false,
      superstate: props.state,
      numericValue: 0,
      opendValue: false
    }
    this.planStatus = props.status;
    console.log("Sattus" + props.status);
    this.min_weight = 0;
    this.max_weight = 200;
    this.step_weight = 1;
    this.mark_portion = false;
    this.marks_portion = [];
    
    if (this.food && this.food.min) this.min_weight = this.food.min;
    if (this.food && this.food.max) this.max_weight = this.food.max; 
    if (this.food && this.food.step) this.step_weight = this.food.step;
    

    this.inputRef = React.createRef(); // Create ref using React.createRef

//slide step or portion
    if ((this.food.wpart) ){
      this.mark_portion = true;
      this.step_portion = this.food.wpart;
      if (this.step_weight == 1) this.step_weight = this.step_portion;
      
      for (let iPortion=this.min_weight; iPortion<=this.max_weight; iPortion+=this.step_weight){
        let label_part = '' + (Math.round(iPortion / this.step_portion*10)/10);
        if ((iPortion / this.step_portion == 1)
          || (iPortion == 0))
         label_part += ' ' +this.food.part
        
        
        this.marks_portion.push({ value: iPortion, label: label_part});
      }

    }

  }
  
  handleShowVlaue=()=>{
    this.setState({numericValue: this.food.weight});
    this.setState({opendValue: true});
    setTimeout(() => {
      this.inputRef.current && this.inputRef.current.focus();
    }, 100);
  }

  handleClosed = () => {
    this.setState({opendValue: false});
  }

  handleSubmitValue=()=> {
    
    //console.log("Submitted Numeric Value:", this.state.numericValue);
    // Add your submission logic here
    this.food.weight = this.state.numericValue;
    this.handleClosed();

  };

  handleChangeValue=(event)=>{
    //debugger
    this.setState({numericValue: event.target.value});
    this.state.numericValue = event.target.value;
    //setNumericValue(event.target.value);
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmitValue();
      event.preventDefault(); // Prevent the default action to avoid form submission/reload
    }
  };

  deleteFood(){
    this.deleteFoodSuper(this.food);
    //this.setState({state: !this.state.state});
    console.log("STATE1:" + this.state.state)
  }

  change(e, v, planned_actual){
    if (planned_actual === 'A'){
      this.food.actual_weight = v;
      
    }

    if (planned_actual === 'P'){
      this.food.actual_weight = v;
      this.food.weight = v;
      console.log(v)
    }

      this.setState({state: !this.state.state});
      this.meal.setFoodWeight(this.food, v);
      this.changeFoodSuper(this.food);
//      init = 0;
    
  }

  //██████  ███████ ███    ██ ██████  ███████ ██████  
  //██   ██ ██      ████   ██ ██   ██ ██      ██   ██ 
  //██████  █████   ██ ██  ██ ██   ██ █████   ██████  
  //██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██ 
  //██   ██ ███████ ██   ████ ██████  ███████ ██   ██ 
  render() {
    this.mydisplay = 'none'
    if (!this.props.status) this.mydisplay = 'block'
    return(
      <Box>
        <Grid container spacing={0} key={globali++} direction="row"  justifyContent="flex-end"  alignItems="center">
        {/*/<Grid item xs={1}><LocalDiningIcon /></Grid> */}
        <Grid item xs={1}>{this.food.cho}</Grid>
        <Grid item xs={9}>[{this.food.id}] {this.food.name}</Grid>
        <Grid item xs={1}>&nbsp;</Grid>
        <Grid item xs={1}><IconButton onClick={() => {this.deleteFood()}}><Box display="flex" justifyContent="flex-end"><DeleteForeverIcon /></Box></IconButton></Grid>
      </Grid>  
        
      <Grid container spacing={0} key={0} direction="row"  justifyContent="flex-end"  alignItems="center">
      
        <Grid item xs={8}>
          <Slider 
            className={this.props.status?"":"hidden-thumb"}
            disabled={!this.props.status}
            value={this.food.weight} 
            aria-label="Default" 
            valueLabelDisplay="auto" 
            onChange={(e, v)=>this.change(e, v, "P")}
            step={this.step_weight}
            marks={this.props.status?this.marks_portion:""}
            min={this.min_weight}
            max={this.max_weight}

            />
        </Grid>
        <Grid item xs={2} >
          <Box 
            display="flex" 
            justifyContent="flex-end" 
            onClick={()=>{this.handleShowVlaue()}}
            sx={{'&:hover': { cursor: 'pointer'}}}
            >

            {this.state.superState} {this.food.weight}
          </Box>
        </Grid>
        <Grid item xs={2} >
          <Box display="flex" justifyContent="flex-end" className={this.props.status?"textbold":""}>
          {this.food.getCHO()}
          </Box>
        </Grid>
        
        
        <Grid item xs={8} sx={{ display: this.mydisplay }}>
          
          <Slider 
            
            value={this.food.actual_weight?this.food.actual_weight:0} 
            aria-label="Default" 
            valueLabelDisplay="auto" 
            onChange={(e, v)=>this.change(e, v, "A")}
            step={this.step_weight}
            marks={this.marks_portion}
            min={this.min_weight}
            max={this.max_weight}
            />
        
        </Grid>
        <Grid item xs={2} sx={{ display: this.mydisplay }}>
          <Box display="flex" justifyContent="flex-end">
            {this.state.superState} {this.food.actual_weight}
          </Box>
        </Grid>
        
        <Grid item xs={2} sx={{ display: this.mydisplay }}>
          <Box display="flex" justifyContent="flex-end" className={"textbold"}>
          {this.food.getActualCHO()}
          </Box>
        </Grid>
        
      </Grid>  
      <hr/>
{/*
██    ██  █████  ██      ██    ██ ███████     ██████  ██  █████  ██       ██████   ██████  
██    ██ ██   ██ ██      ██    ██ ██          ██   ██ ██ ██   ██ ██      ██    ██ ██       
██    ██ ███████ ██      ██    ██ █████       ██   ██ ██ ███████ ██      ██    ██ ██   ███ 
 ██  ██  ██   ██ ██      ██    ██ ██          ██   ██ ██ ██   ██ ██      ██    ██ ██    ██ 
  ████   ██   ██ ███████  ██████  ███████     ██████  ██ ██   ██ ███████  ██████   ██████  
*/}      
      <Dialog open={this.state.opendValue} onClose={this.handleClosed}>
        <DialogTitle>Immetti il peso</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="numericValue"
            label="Peso"
            type="number"
            inputProps={{step: 0.1 }}
            fullWidth
            variant="standard"
            value={this.state.numericValue}
            onChange={(e)=>{this.setState({numericValue: e.target.value})}}

            variant="outlined" 
            onFocus={this.handleFocus} // Use the onFocus handler here
            inputRef={this.inputRef} // Attach the ref to the input element
            onKeyPress={this.handleKeyPress} // Handle the key press event
            size="big"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClosed}>Cancel</Button>
          <Button onClick={this.handleSubmitValue}>Submit</Button>
        </DialogActions>
      </Dialog>

    </Box>
    );
  }
}





var init = 1;

//███████ ████████  █████  ██████  ████████ 
//██         ██    ██   ██ ██   ██    ██    
//███████    ██    ███████ ██████     ██    
//     ██    ██    ██   ██ ██   ██    ██    
//███████    ██    ██   ██ ██   ██    ██    
export default function Current(props) {
  const navigate = useNavigate()
  //init = 0;
//███████ ████████  █████  ████████ ███████ 
//██         ██    ██   ██    ██    ██      
//███████    ██    ███████    ██    █████   
//     ██    ██    ██   ██    ██    ██      
//███████    ██    ██   ██    ██    ███████ 
  const[opendValue, setOpendValue] = React.useState(false);
  const [numericValue, setNumericValue] = React.useState('');

  const[opend, setOpend] = React.useState(false);
  const[stateIN, setINState] = React.useState(1);
  const[valGUI, setValGUI] = React.useState(1);
  const[valWeight, setValWeight] = React.useState(0);
  const[imageUrl, setImageUrl] = React.useState("");
  const[planStatus, setPlan] =  React.useState(true);
  
  let openDialog = false;
  

  let totWeight = 0;
  let myVal = 10;
  const fields = [];


  meal.loadFromMemory();


  const toggleChange = () =>{
    setPlan(!planStatus);
    
    
    
    console.log(stateIN);
  }

  const TogglePlan = ()=>{
    return (
      <ToggleButtonGroup
        color="primary"
        value={planStatus}
        exclusive
        fullWidth
        aria-label="Platform"
        onChange={toggleChange}
      >
        <ToggleButton value={true}>PIANIFICATO</ToggleButton>
        <ToggleButton value={false}>DOPO-PASTO</ToggleButton>
        
      </ToggleButtonGroup>
    )
  }

  const DeleteFood = (food) => {

    meal.delFood(food);
    meal.saveToMemory();
    setINState(Math.random());
  }

  const ChangeFood = (food) => {
    //alert("change food")
    //console.log("CHANGHE FOOD:" + food.id + " W:" + food.weight);
    
    /* setValWeight(food.weight); */
    
    
    
    meal.saveToMemory();
    setINState(Math.random());
  }

  const slideChange = (e, v, food) =>{
    
    
    food.weight = v;
    //setINState(!stateIN);
    setINState(Math.random());
    console.log("SLIDECHANGE:" + food.id + " ->" + stateIN);
  }
//*** SAVE MEAL DIALOG MANAGEMENT
  const handleOpend = () => {
    setOpend(true);
    
  };

  const handleClosed = (e, action) => {
    //alert(action)
    setOpend(false);
    
    if (action === 'SAVE'){
      e.preventDefault();
      let newMealName = "";
      let newMealNote = "";

      if (e && e.target && e.target['mealname'] && e.target['mealname'].value)
        newMealName = e.target['mealname'].value;
      if (e && e.target && e.target['mealnote'] && e.target['mealnote'].value)
        newMealNote = e.target['mealnote'].value;

      meal.id = newMealName;
      meal.note = newMealNote;
  
      let mealList = new MealList();
      mealList.loadFromMemory();
      
      mealList.addMeal(meal);
      mealList.saveToMemory();

    }

  };



  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  
  const imgUpload = (e, d) => {
    meal.setImage(e.target.files[0]);
    meal.saveToMemory();
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    
    //setINState(Math.random());

  };

  const saveMeal = (e, d) =>{
    //alert("save meal");
    var currentdate = new Date(); 
    var datetime = "pasto-" + currentdate.getFullYear() +  "." 
                + ((currentdate.getMonth()+1)<10?"0":"") + (currentdate.getMonth()+1) + "." 
                + (currentdate.getDate()<10?"0":"") + currentdate.getDate() + "-"
                + (currentdate.getHours()<10?"0":"") + currentdate.getHours() + "."  
                + (currentdate.getMinutes() < 10 ? "0":"") + currentdate.getMinutes()
                
    meal.name=datetime
    meal.note="";
    for (let i=0; i<meal.foodList.length; i++){
      if (meal.note.trim() !== '') meal.note += ' - '
      meal.note +=  meal.foodList[i].name.substring(0, 9).trim();
    }

    setOpend(true);

  }



//**************************************** MAIN 
  if (meal && meal.foodList && meal.foodList.length){
    for(let i=0; i<meal.foodList.length; i++){
      let food = new Food();
      food = food.getFromJson(meal.foodList[i]);

      fields.push(
        <FoodLine 
          meal={meal} 
          food={food} 
          deleteFood={DeleteFood}  
          changeFood={ChangeFood} 
          key={"LINE" + food.id} 
          status={planStatus} 
        />
      )
    }
  }


//██████  ███████ ████████ ██    ██ ██████  ███    ██ 
//██   ██ ██         ██    ██    ██ ██   ██ ████   ██ 
//██████  █████      ██    ██    ██ ██████  ██ ██  ██ 
//██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██ 
//██   ██ ███████    ██     ██████  ██   ██ ██   ████ 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <TogglePlan />
        <Box
        
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            
            flexGrow: 1,
            
          }}
        >
          
          <Grid container spacing={0} key={globali++} direction="row"  justifyContent="flex-end"  alignItems="center">
          <Grid item xs={12}><Button component="label" variant="contained" startIcon={<ImageIcon />} onClick={(e)=>{navigate("/foods")}} fullWidth sx={{ m: 1 }}>AGGIUNGI PIATTO</Button></Grid>
          <Grid item xs={12}>          {fields}          </Grid>
          
{/*          
            <img src={meal.getUrlImage} />
            <Grid item xs={12}><Button component="label" variant="contained" startIcon={<ImageIcon />} fullWidth sx={{ m: 1 }}>IMMAGINE<VisuallyHiddenInput type="file" onChange={imgUpload}/></Button></Grid>
        */}            

            <Grid item xs={12}><Button onClick={saveMeal}   variant="contained" startIcon={<SaveIcon />} fullWidth sx={{ m: 1 }}>SALVA</Button></Grid>
            
            <Grid item xs={8}><h3>TOTAL:</h3></Grid>
            

            <Grid item xs={2}>{meal.getWeightTot()}</Grid>
            <Grid item xs={2}  style={{textAlign: 'right'}}><h3>{meal.getCHOTot()}</h3></Grid>
          </Grid>  
        
        </Box>
          
      <Dialog open={opend} onClose={handleClosed}>
      <form onSubmit={(e) => {  handleClosed(e, 'SAVE'); } }>
       <DialogTitle>PIATTO</DialogTitle>
        <DialogContent>
        <Grid container spacing={0} key={globali++} direction="col"  justifyContent="flex-end"  alignItems="center">
        <Grid item xs={12}><TextField id="mealname" label="Nome" variant="outlined" fullWidth sx={{ m: 1 }} value={meal.name}/></Grid>
        <Grid item xs={12}><TextField id="mealnote" label="Note" variant="outlined" multiline rows={4} fullWidth sx={{ m: 1 }} value={meal.note}/></Grid>
        



      </Grid>  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosed}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </form>
    </Dialog>


      </Container>
      <Footer />      
    </ThemeProvider>
  );
}




