import * as React from 'react';
import { Container, Grid, Paper, useMediaQuery, useTheme, Button, Link } from '@mui/material';

import DocMenu from './DocMenu';

export default function Doc() {
    return (
       
            <Container component="main" maxWidth="xl">
                    
                      
            
                <Grid container spacing={2} className='homeGrid'>
                    
                    
                    
                        
                            <Grid item xs={2}>
<DocMenu current="doch1e2" />
                            </Grid>
                            <Grid item xs={10}>
                            <Paper elevation={1} >
                            <div className='docs'>


    <h1>Calcolo dei Carboidrati per il Pasto</h1>
    <img src="/meal_h1e2.png" alt="Pasto per il calcolo dei carboidrati" height={300} />

    <div class="meal-component">
        <div class="component-title">Quinoa:</div>
        <p>Supponiamo che la porzione di quinoa sia di circa 100 grammi (peso cotto). In media, 100 grammi di quinoa cotta contengono circa 21 grammi di carboidrati.</p>
    </div>
    <div class="meal-component">
        <div class="component-title">Petto di pollo arrosto:</div>
        <p>Il pollo non contiene carboidrati.</p>
    </div>
    <div class="meal-component">
        <div class="component-title">Fagiolini verdi:</div>
        <p>Per una piccola porzione di fagiolini verdi, possiamo stimare circa 5 grammi di carboidrati.</p>
    </div>
    <div class="meal-component">
        <div class="component-title">Frutti di bosco misti:</div>
        <p>Supponiamo una ciotola piccola di circa 100 grammi. I frutti di bosco misti contengono in media circa 12 grammi di carboidrati per 100 grammi.</p>
    </div>
    <div class="meal-component">
    <p>Sommando i carboidrati di ogni componente, otteniamo:</p>
    <ul>
        <li>Quinoa: 21 g di carboidrati</li>
        <li>Petto di pollo: 0 g di carboidrati</li>
        <li>Fagiolini verdi: 5 g di carboidrati</li>
        <li>Frutti di bosco: 12 g di carboidrati</li>
    </ul>
    </div>
    
    <div class="total-carbs">
        <h2>Totale Carboidrati nel Pasto:</h2>
        <p>38g di carboidrati (21g + 0g + 5g + 12g)</p>
    </div>

    <p>Questi valori sono stime basate su medie e dimensioni delle porzioni standard. Il contenuto effettivo di carboidrati può variare a seconda della marca specifica degli alimenti, del metodo di cottura, e delle dimensioni esatte delle porzioni.</p>


    <h1>Calcolo dei Macronutrienti per il Pasto</h1>

    <p>Per calcolare i macronutrienti totali del pasto, consideriamo i valori medi dei macronutrienti (carboidrati, proteine e grassi) per ciascun componente del pasto. Ecco il procedimento dettagliato:</p>

    <div class="component">
        <strong>Quinoa:</strong>
        <ul>
            <li>Carboidrati: Circa 21 grammi di carboidrati per 100 grammi.</li>
            <li>Proteine: Circa 4 grammi di proteine per 100 grammi.</li>
            <li>Grassi: Circa 1.5 grammi di grassi per 100 grammi.</li>
        </ul>
    </div>

    <div class="component">
        <strong>Petto di pollo arrosto:</strong>
        <ul>
            <li>Carboidrati: Il pollo non contiene carboidrati.</li>
            <li>Proteine: Circa 31 grammi di proteine per 100 grammi.</li>
            <li>Grassi: Circa 3.5 grammi di grassi per 100 grammi.</li>
        </ul>
    </div>

    <div class="component">
        <strong>Fagiolini verdi:</strong>
        <ul>
            <li>Carboidrati: Circa 5 grammi di carboidrati per una piccola porzione.</li>
            <li>Proteine: Piccola quantità di proteine.</li>
            <li>Grassi: Quantità trascurabile di grassi.</li>
        </ul>
    </div>

    <div class="component">
        <strong>Frutti di bosco misti:</strong>
        <ul>
            <li>Carboidrati: Circa 12 grammi di carboidrati per 100 grammi.</li>
            <li>Proteine: Piccola quantità di proteine.</li>
            <li>Grassi: Quantità trascurabile di grassi.</li>
        </ul>
    </div>

    <p class="total">Sommando i macronutrienti di ogni componente, otteniamo:</p>
    <ul>
        <li>Carboidrati: 21 g (quinoa) + 0 g (pollo) + 5 g (fagiolini) + 12 g (frutti di bosco) = 38 g</li>
        <li>Proteine: 4 g (quinoa) + 31 g (pollo) + piccole quantità dai fagiolini e frutti di bosco ≈ 35 g</li>
        <li>Grassi: 1.5 g (quinoa) + 3.5 g (pollo) + piccole quantità dai fagiolini ≈ 5 g</li>
        
    </ul>
    <p class="total">Totale carboidrati nel pasto: 21 g + 0 g + 5 g + 12 g = 38 g di carboidrati</p>
    <img src="/macro_h1e2.png" alt="Stima macronutrienti" height={300} />
    <p>Questi valori sono stime basate su medie e dimensioni delle porzioni standard. Il contenuto effettivo di macronutrienti può variare a seconda della marca specifica degli alimenti, del metodo di cottura, e delle dimensioni esatte delle porzioni.</p>




                            
                            </div>
                            </Paper>
                            </Grid>
                        
                        
                    
                </Grid>
            </Container>

    )
}