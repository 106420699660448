import * as React from 'react';
import { Container, Grid, Paper, useMediaQuery, useTheme, Button, Link } from '@mui/material';

import DocMenu from './DocMenu';

export default function Doc() {
    return (
       
            <Container component="main" maxWidth="xl">
                    
                      
            
                <Grid container spacing={2} className='homeGrid'>
                    
                    
                    
                        
                            <Grid item xs={2}>
                            <DocMenu current="doca01" />
                            </Grid>
                            <Grid item xs={10}>
                            <Paper elevation={1} >
                            <div className='docs'>
                            <h1>Rivoluziona il Calcolo dei Carboidrati con l'Assistente Vocale </h1>
                                <img src="/cuoco_alexa.png" alt="Assistente vocale" width={300}></img>
                    

   

    <h2>Preparare Pasti e Gestire il Diario Alimentare Non È Mai Stato Così Semplice</h2>

    <p><strong>Introduzione:</strong> Nell'era della tecnologia digitale, ContaDeiCarboidrati si posiziona come un innovativo alleato in cucina, particolarmente prezioso per coloro che seguono una dieta controllata o necessitano di un monitoraggio costante dell'assunzione di carboidrati. La piattaforma ContaDeiCarboidrati introduce una funzionalità di assistente vocale che rende il calcolo dei carboidrati e la gestione del diario alimentare più semplici e interattivi che mai.</p>

    <p><strong>La Cucina Incontra la Tecnologia:</strong> Con ContaDeiCarboidrati, dire addio alla tediosa attività di inserire manualmente i dati nel diario alimentare è un sogno che diventa realtà. Mentre sei impegnato a mescolare, friggere o condire, il nostro assistente vocale è lì con te, pronto a rispondere a comandi vocali per registrare ogni ingrediente e calcolare i carboidrati in tempo reale. In questo modo, puoi concentrarti sulla preparazione del pasto senza perdere il filo del tuo conteggio.</p>

    <p><strong>Funzionalità Principali:</strong> 
    <ul>
        <li><strong>Calcolo dei Carboidrati:</strong> Chiedi all'assistente vocale il contenuto di carboidrati di ogni ingrediente, e ricevi risposte immediate basate su un ampio database di alimenti.</li>
        <li><strong>Registrazione Vocale del Pasto:</strong> Pronuncia semplicemente cosa stai mangiando, e l'assistente aggiungerà automaticamente le voci al tuo diario alimentare.</li>
        <li><strong>Consigli Nutrizionali:</strong> Ottieni consigli su alternative alimentari a basso contenuto di carboidrati o su porzioni consigliate per i tuoi obiettivi nutrizionali.</li>
        <li><strong>Sincronizzazione con App e Dispositivi:</strong> Tutte le informazioni vengono sincronizzate in tempo reale con la tua app ContaDeiCarboidrati, così puoi accedere al tuo diario alimentare da qualsiasi dispositivo.</li>
    </ul>
    </p>

    <p><strong>Interazione Intuitiva:</strong> Usare l'assistente vocale di ContaDeiCarboidrati è così semplice come chiedere "Quanti carboidrati ci sono in 100 grammi di riso?" o dire "Aggiungi una mela al mio diario alimentare". La piattaforma è progettata per riconoscere comandi naturali e fornire feedback precisi, rendendo l'esperienza utente fluida e piacevole.</p>

    <p><strong>Sicurezza e Privacy:</strong> La tua privacy è la nostra priorità. ContaDeiCarboidrati garantisce che tutte le interazioni vocali e i dati del diario alimentare siano trattati con i più alti standard di sicurezza, proteggendo le tue informazioni personali e le tue abitudini alimentari.</p>

    <p><strong>Conclusione:</strong> L'integrazione dell'assistente vocale nella piattaforma ContaDeiCarboidrati non solo facilita il monitoraggio dei carboidrati ma eleva anche l'intera esperienza di cucina a un livello superiore. Che tu stia cercando di gestire il diabete, seguire una dieta chetogenica o semplicemente mantenere uno stile di vita sano, ContaDeiCarboidrati e il suo assistente vocale sono gli alleati perfetti in cucina.</p>
                            </div>
                            </Paper>
                            </Grid>
                        
                        
                    
                </Grid>
            </Container>

    )
}