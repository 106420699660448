import * as React from 'react';
import { Container, Grid, Paper, useMediaQuery, useTheme, Button, Link } from '@mui/material';

import DocMenu from './DocMenu';

export default function Doc() {
    return (
       
            <Container component="main" maxWidth="xl">
                    
                      
            
                <Grid container spacing={2} className='homeGrid'>
                    
                    
                    
                        
                            <Grid item xs={2}>
<DocMenu />
                            </Grid>
                            <Grid item xs={10}>
                            <Paper elevation={1} >
                            <div className='docs'>
                                <h1>Counting dei carboidrati: cos'è e come funziona</h1>
                                <p>Il counting dei carboidrati, o carbohydrate counting o CHO counting, è un nuovo approccio alla cura del diabete che prevede la somministrazione di insulina a dosi variabili, decise dal paziente in base alla quantità di carboidrati che ha ingerito nel pasto precedente.</p>
                                <p>Questo metodo si basa sul principio che i carboidrati sono la principale fonte di energia per l'organismo e che la loro assunzione deve essere bilanciata con la dose di insulina necessaria per metabolizzarli.</p>
                                <h2>Come funziona</h2>
                                <p>Il counting dei carboidrati prevede tre fasi:</p>
                                <ol>
                                <li><strong>Conoscenza del contenuto di carboidrati dei cibi</strong></li>
                                <p>Il primo passo è conoscere il contenuto di carboidrati dei cibi più comunemente utilizzati. Questo può essere fatto consultando le tabelle nutrizionali dei prodotti alimentari o utilizzando un'app per il counting dei carboidrati.</p>
                                <li><strong>Stima del peso della razione di cibo</strong></li>
                                <p>Una volta che si conosce il contenuto di carboidrati di un alimento, è necessario stimare il peso della razione che si desidera introdurre. Questo può essere fatto utilizzando un bilancino da cucina o un'app per il counting dei carboidrati.</p>
                                <li><strong>Calcolo dell'adeguato bolo insulinico pre-pasto</strong></li>
                                <p>In base al peso della razione di cibo e al rapporto Insulina/Carboidrati calcolato su base individuale, è possibile calcolare l'adeguato bolo insulinico pre-pasto.</p>
                                </ol>
                                <h2>Vantaggi</h2>
                                <p>Il counting dei carboidrati presenta numerosi vantaggi, tra cui:</p>
                                <ul>
                                <li><strong>Migliore controllo della glicemia</strong></li>
                                <p>Il counting dei carboidrati permette di controllare la glicemia in modo più preciso, riducendo il rischio di iperglicemia e ipoglicemia.</p>
                                <li><strong>Maggiore flessibilità alimentare</strong></li>
                                <p>Il counting dei carboidrati permette di seguire una dieta più flessibile, senza dover rinunciare ai propri cibi preferiti.</p>
                                <li><strong>Migliore qualità della vita</strong></li>
                                <p>Il counting dei carboidrati permette di migliorare la qualità della vita dei pazienti diabetici, riducendo il tempo necessario per le iniezioni di insulina e aumentando la libertà di movimento.</p>
                                </ul>
                                <h2>Svantaggi</h2>
                                <p>Il counting dei carboidrati presenta anche alcuni svantaggi, tra cui:</p>
                                <ul>
                                <li><strong>Richiede impegno</strong></li>
                                <p>Il counting dei carboidrati richiede un certo impegno da parte del paziente, che deve imparare a conoscere il contenuto di carboidrati dei cibi e a calcolare la dose di insulina necessaria.</p>
                                <li><strong>Può essere complicato</strong></li>
                                <p>Il counting dei carboidrati può essere complicato per i pazienti che non sono abituati a calcolare le quantità di cibo.</p>
                                </ul>
                                <h2>Conclusione</h2>
                                <p>Il counting dei carboidrati è un metodo efficace per il controllo della glicemia nei pazienti diabetici. Tuttavia, richiede un certo impegno e può essere complicato per i pazienti che non sono abituati a calcolare le quantità di cibo.</p>
                            </div>
                            </Paper>
                            </Grid>
                        
                        
                    
                </Grid>
            </Container>

    )
}