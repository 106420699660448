
import { SocialDistanceOutlined } from '@mui/icons-material';
import Food from './FoodClass.js';

export default class Meal{
  MEMORY_TAG = "currentmeal"
  MEMORY_TAG_IMG = "currentmeal_img"
    name = "";
    foodIDList = [];
    foodList = [];
    test = 10;
    nome = "";

    constructor(){
      this.name = "";
      this.foodList = [];
      this.image = "";
    }
  
    getFromJson(json){
      return Object.assign(new Meal(), json);
    }
    

    setImage(image){
      this.image = image;
    }

    getUrlImage(){
      return URL.createObjectURL(this.image);
    }

    addFood(food){
      if (this.foodList.findIndex((fooditem) => fooditem.id == food.id) >=0) return;
      this.foodList.push(food);
    }

    setFoodWeight(food, weight){
      let foodIndex =  this.foodList.findIndex((fooditem) => fooditem.id == food.id);
      if (foodIndex < 0) return;
      this.foodList[foodIndex].weight = weight;
    }


    delFood(food){
      const objWithIdIndex = this.foodList.findIndex((fooditem) => fooditem.id == food.id)
      if (objWithIdIndex > -1) {
        this.foodList.splice(objWithIdIndex, 1);
      }
      
    }

    getTest(){
      let result = 0;
      for(let i=0; i<this.foodList.length; i++){
        result += this.foodList[i].weight;
//        console.log("WTOT " + i + " W;"+this.foodList[i].weight);
      }
      
      return result;
    }

    getWeightTot(){
      let result = 0;
      for(let i=0; i<this.foodList.length; i++){
        result += this.foodList[i].weight;
//        console.log("WTOT " + i + " W;"+this.foodList[i].weight);
      }
      
      return result;

    }

    getCHOTot(){
      let result = 0;
      for(let i=0; i<this.foodList.length; i++){
        let food = new Food();
        food = food.getFromJson(this.foodList[i]);
        result += food.getCHO(3);
         
//        console.log("WTOT " + i + " W;"+this.foodList[i].weight);
      }

      return Math.round(result *10) / 10;

    }

    toString(){
      return JSON.stringify(this);
    }

    

    saveToMemory() {
      console.log("MEAL SAVE TO MEMORY")
      
      if (this.foodList)
        localStorage.setItem(this.MEMORY_TAG, JSON.stringify(this.foodList));
      if (this.image) 
        localStorage.setItem(this.MEMORY_TAG_IMG, JSON.stringify(this.image));
      
      
   
    }

    loadFromMemory(){
      console.log("MEAL LOAD FROM MEMORY")
      let localFoodList_json = localStorage.getItem(this.MEMORY_TAG);
      let localImg_json = localStorage.getItem(this.MEMORY_TAG_IMG);
      
      if (localFoodList_json){
          this.foodList = JSON.parse(localFoodList_json);
      }

      if (localImg_json){
        this.image = JSON.parse(localImg_json);
    }


      
      if (this.foodList && this.foodList.length > 0) return true;
      return false;

    }

    getFood(id, data){
        return data.filter((item)=>item.id===id);
    }
  }

