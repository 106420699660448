import * as React from 'react';
import { Container, Grid, Paper, useMediaQuery, useTheme, Button, Link } from '@mui/material';

import DocMenu from './DocMenu';

export default function Doc() {
    return (
       
            <Container component="main" maxWidth="xl">
                    
                      
            
                <Grid container spacing={2} className='homeGrid'>
                    
                    
                    
                        
                            <Grid item xs={2}>
<DocMenu current="doch12" />
                            </Grid>
                            <Grid item xs={10}>
                            <Paper elevation={1} >
                            <div className='docs'>
                            <h1>Guida all'Utilizzo delle Tabelle Nutrizionali per il Calcolo dei Carboidrati Totali di un Pasto</h1>

<p>Le tabelle nutrizionali sono strumenti preziosi per chi segue diete controllate in carboidrati, come i diabetici o coloro che desiderano gestire l'apporto nutritivo dei pasti. Imparare a utilizzare queste tabelle in modo efficace è fondamentale per calcolare con precisione l'assunzione di carboidrati e mantenere uno stile di vita sano.</p>

<h2>Passo 1: Comprende la Tabella Nutrizionale</h2>

<p>Prima di iniziare il calcolo dei carboidrati di un pasto, è essenziale comprendere i diversi elementi presenti in una tabella nutrizionale. Le informazioni chiave includono la dimensione della porzione, le calorie totali, i carboidrati, le proteine, i grassi, le fibre e gli zuccheri.</p>

<h2>Passo 2: Identifica la Dimensione della Porzione</h2>

<p>Guarda la parte superiore della tabella per individuare la dimensione della porzione standard. Assicurati di confrontare questa dimensione con la quantità effettiva di cibo che stai per consumare. Se la tua porzione è diversa, dovrai regolare i calcoli di conseguenza.</p>

<h2>Passo 3: Concentrati sui Carboidrati Totali</h2>

<p>La sezione più critica per chi segue una dieta controllata in carboidrati è la quantità totale di carboidrati. I carboidrati sono spesso suddivisi in zuccheri, fibre e altri carboidrati. Concentrati sui carboidrati totali, poiché rappresentano l'apporto complessivo di carboidrati nel cibo.</p>

<h2>Passo 4: Confronta le Dimensioni delle Porzioni</h2>

<p>Assicurati che la dimensione della porzione elencata sulla tabella nutrizionale corrisponda alla quantità effettiva che stai per consumare. Questo passo è cruciale per calcolare con precisione l'apporto di carboidrati nel tuo pasto.</p>

<h2>Passo 5: Calcola i Carboidrati Totali</h2>

<p>Sommare la quantità di carboidrati per la tua porzione ti darà l'apporto di carboidrati totali. Assicurati di effettuare questo calcolo per ogni alimento nel tuo pasto. Puoi utilizzare gli strumenti di misurazione, come una bilancia da cucina, per ottenere risultati più precisi.</p>

<h2>Passo 6: Fai Attenzione agli Altri Nutrienti</h2>

<p>Oltre ai carboidrati, osserva anche le quantità di proteine e grassi. Questi nutrienti sono importanti per il bilancio nutrizionale generale e possono influenzare la sazietà. Tuttavia, se stai seguendo una dieta specifica, concentrati principalmente sui carboidrati.</p>

<h2>Passo 7: Tieni un Diario Alimentare</h2>

<p>Mantieni un diario alimentare registrando l'apporto di carboidrati di ogni pasto. Questo ti aiuterà a monitorare l'assunzione giornaliera complessiva e a fare eventuali aggiustamenti nella dieta in base ai tuoi obiettivi personali.</p>

<h2>Conclusioni</h2>

<p>Utilizzare le tabelle nutrizionali per calcolare i carboidrati di un pasto richiede pratica, ma diventerà più facile con il tempo. Questa abilità è fondamentale per coloro che devono gestire l'apporto di carboidrati per ragioni di salute. Imparare a leggere e interpretare le informazioni presenti nelle tabelle nutrizionali è un passo significativo verso uno stile di vita sano e consapevole.</p>


<h1>Esempio Dettagliato di Calcolo dei Carboidrati di un Pasto</h1>

<p>Immaginiamo di preparare un pasto composto da pollo arrosto, riso integrale e verdure miste. Utilizzeremo le tabelle nutrizionali degli imballaggi per calcolare l'apporto di carboidrati totali del nostro pasto.</p>

<h2>Tabelle Nutrizionali degli Alimenti</h2>

<table>
    <tr>
        <th>Alimento</th>
        <th>Dimensione della Porzione (grammi)</th>
        <th>Carboidrati per Porzione (grammi)</th>
    </tr>
    <tr>
        <td>Pollo Arrosto</td>
        <td>150g</td>
        <td>0g</td>
    </tr>
    <tr>
        <td>Riso Integrale</td>
        <td>195g (1 tazza cotta)</td>
        <td>45g</td>
    </tr>
    <tr>
        <td>Verdure Miste</td>
        <td>150g</td>
        <td>10g</td>
    </tr>
</table>

<h2>Calcolo dell'Apporto di Carboidrati Totale</h2>

<p>Per calcolare l'apporto di carboidrati totali del pasto, eseguiamo i seguenti passaggi:</p>

<ol>
    <li><strong>Pollo Arrosto:</strong> La tabella nutrizionale indica 0g di carboidrati per 150g di pollo arrosto. Non contribuisce ai carboidrati totali del pasto.</li>

    <li><strong>Riso Integrale:</strong> La tabella nutrizionale indica 45g di carboidrati per 195g di riso integrale cotto. Convertiamo la quantità in grammi della porzione che stiamo consumando (ad esempio, 200g) e calcoliamo i carboidrati proporzionali.</li>

    <li><strong>Verdure Miste:</strong> La tabella nutrizionale indica 10g di carboidrati per 150g di verdure miste. Non dimentichiamo di considerare la quantità di verdure nella nostra porzione e calcolare i carboidrati corrispondenti.</li>
</ol>

<h2>Risultato del Calcolo</h2>

<p>Sommiamo i carboidrati calcolati da ciascun alimento:</p>

<table>
    <tr>
        <th>Alimento</th>
        <th>Carboidrati per Porzione (grammi)</th>
    </tr>
    <tr>
        <td>Pollo Arrosto</td>
        <td>0g</td>
    </tr>
    <tr>
        <td>Riso Integrale</td>
        <td>41.54g</td>
    </tr>
    <tr>
        <td>Verdure Miste</td>
        <td>10g</td>
    </tr>
    <tr>
        <th>Totale</th>
        <th>51.54g</th>
    </tr>
</table>

<p>Quindi, il pasto complessivo con pollo arrosto, riso integrale e verdure miste contiene circa 51.54 grammi di carboidrati. Questo calcolo preciso è essenziale per coloro che devono monitorare l'assunzione di carboidrati, come nel caso di persone con diabete.</p>

                            
                            </div>
                            </Paper>
                            </Grid>
                        
                        
                    
                </Grid>
            </Container>

    )
}