import * as React from 'react';
import { Container, Grid, Paper, useMediaQuery, useTheme, Button, Link } from '@mui/material';

//ICONS
import FoodBankIcon from '@mui/icons-material/FoodBank';

export default function DocMenu(props) {
    let al = "";
    if (props && props.current)
        al = props.current;
    
    return (
       
            
            <Paper elevation={1}  >
            <ul style={{ paddingLeft:30, paddingTop:20, paddingBottom:25, lineHeight:2 }} >
                <li><Link href="/" underline='none'>Home</Link></li>
                <li><Link href="/doc01" underline= {al==='doc01'?'always':'none'}>Introduzione</Link></li>
                <strong>Tabelle nutrizionali</strong>
                {/*<ul style={{ paddingLeft:20, paddingTop:0, paddingBottom:25, lineHeight:2 }} >*/}
                <li><Link href="/doch10" underline= {al==='doch10'?'always':'none'}>Cosa sono</Link></li>
                <li><Link href="/doch11" underline= {al==='doch11'?'always':'none'}>Come vengono create</Link></li>
                <li><Link href="/doch12" underline= {al==='doch12'?'always':'none'}>Come si utilizzano</Link></li>
                <li><Link href="/doch1e1" underline= {al==='doch1e1'?'always':'none'}>Esempio 1</Link></li>
                <li><Link href="/doch1e2" underline= {al==='doch1e2'?'always':'none'}>Esempio 2</Link></li>
                <li><Link href="/foods" color="#552F8b" underline='none'><FoodBankIcon/> Vai alle tabelle CdC</Link></li>
                <li><Link href="/doch13" underline= {al==='doch13'?'always':'none'}>Guida alle tabelle CdC</Link></li>
                <li><Link href="/doca01" underline= {al==='doca01'?'always':'none'}>Assitente vocale</Link></li>
                {/*</ul>*/}
                
            </ul>
            </Paper>
    )
}