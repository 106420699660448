import * as React from 'react';
import { Container, Grid, Paper, useMediaQuery, useTheme, Button, Link } from '@mui/material';

import DocMenu from './DocMenu';

export default function Doc() {
    return (
       
            <Container component="main" maxWidth="xl">
                    
                      
            
                <Grid container spacing={2} className='homeGrid'>
                    
                    
                    
                        
                            <Grid item xs={2}>
<DocMenu current="doch1e1" />
                            </Grid>
                            <Grid item xs={10}>
                            <Paper elevation={1} >
                            <div className='docs'>

    <h1>Calcolo dei Carboidrati per il Pasto</h1>
    <img src="/meal_h1e1.png" alt="Pasto per il calcolo dei carboidrati" width={300}></img>

    <div class="calculation-description">
        <p>Il calcolo dei carboidrati si basa sulle porzioni e sul contenuto medio di carboidrati di ciascun alimento. Per ogni componente del pasto, si stima il peso della porzione e si moltiplica per il contenuto di carboidrati per 100 grammi, ottenendo il totale di carboidrati per quella porzione. Sommando i valori di tutti gli alimenti, si ottiene il totale dei carboidrati del pasto.</p>
    </div>
    <div class="meal-component">
        <div class="component-title">Riso integrale:</div>
        <p>Supponiamo una porzione di 150g. Carboidrati: 34.5g (23g per 100g)</p>
    </div>
    <div class="meal-component">
        <div class="component-title">Salmone alla griglia:</div>
        <p>Porzione di 100g. Carboidrati: 0g</p>
    </div>
    <div class="meal-component">
        <div class="component-title">Carote al vapore:</div>
        <p>Porzione di 75g. Carboidrati: 7.5g (10g per 100g)</p>
    </div>
    <div class="meal-component">
        <div class="component-title">Banana:</div>
        <p>Porzione di 120g. Carboidrati: 26.4g (22g per 100g)</p>
    </div>
    <div class="total-carbs">
        <h2>Totale Carboidrati nel Pasto:</h2>
        <p>68.4g di carboidrati (34.5g + 0g + 7.5g + 26.4g)</p>
    </div>


                            
                            </div>
                            </Paper>
                            </Grid>
                        
                        
                    
                </Grid>
            </Container>

    )
}