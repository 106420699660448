import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

import { jsonFoodList, jsonFoodList2 } from './Data.js';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { useMediaQuery, useTheme } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';





//ICONS
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';


import Switch from '@mui/material/Switch';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Cookies from 'universal-cookie';


import Snackbar from '@mui/material/Snackbar';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Fab from '@mui/material/Fab';

import Tooltip from '@mui/material/Tooltip';
import Rating from '@mui/material/Rating';

// TODO remove, this demo shouldn't need to reset the theme.

import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';

import CloudDownloadTwoToneIcon from '@mui/icons-material/CloudDownloadTwoTone';

import Meal from './classes/MealClass.js';
import Food from './classes/FoodClass.js';
import FoodList from './classes/FoodListClass.js';
import Image from './classes/ImageClass.js';

import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress  from '@mui/material/LinearProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { ExportExcel } from './lib/importexport.js';
import { AuthContext } from "./Auth/AuthContext"
import { IntegrationInstructionsRounded } from '@mui/icons-material';

import Chip from "@mui/material/Chip";
import Resizer from "react-image-file-resizer";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

let msglastID = "---";
//var ismobile = false;
var init = 0;




function bcodeIcon(props) {
	return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1024 1024" {...props}><path fill="currentColor" d="M120 160H72c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8m833 0h-48c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8M200 736h112c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8H200c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8m321 0h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8m126 0h178c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8H647c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8m-255 0h48c4.4 0 8-3.6 8-8V168c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v560c0 4.4 3.6 8 8 8m-79 64H201c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h112c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8m257 0h-48c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8m256 0H648c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h178c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8m-385 0h-48c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8"></path></svg>);
}



function SimpleSnackbar(props) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      
      <Snackbar open={props.open} autoHideDuration={500} onClose={handleClose}>
        <Alert  severity="success" sx={{ width: '100%' }}> {msglastID} </Alert>
      </Snackbar>
    </div>
  );
}



const defaultTheme = createTheme();



let foodList;
/////////////////////////////////////////////////////////////////////////////
//███████ ████████  █████  ██████  ████████ 
//██         ██    ██   ██ ██   ██    ██    
//███████    ██    ███████ ██████     ██    
//     ██    ██    ██   ██ ██   ██    ██    
//███████    ██    ██   ██ ██   ██    ██    
export default function Foods(props) {
  const navigate = useNavigate()  
  const location = useLocation();
  const { product } = location.state || {}; // Defaulting to an empty object if state is undefined

  const {user,
    isLoading,
    signIn,
    signOut,
    getCurrentUser,
    getValue, setValue,
    getSession } = React.useContext(AuthContext)

  React.useEffect(() => {
    init = 1;
    if (product){
      let newFood = new Food();
      newFood.name = product.product_name;
      newFood.cho = product.nutriments.carbohydrates_100g;
      newFood.fat = product.nutriments.fat_100g;
      newFood.pro = product.nutriments.proteins_100g;
      newFood.ENc = product.nutriments["energy-kcal_100g"]
      newFood.sug = product.nutriments.sugars_100g;
      newFood.fib = product.nutriments.fiber_100g;
      newFood.col = product.nutriments.cholesterol_100g;

      if (product.image_url)
        handleImgFromUrl(product.image_url, 'img')

      setCurrentFood(newFood);    
      setOpend(true)
      console.log("***START", product)
    }
    
 },[])

//*** INIT 

  foodList = new FoodList();
  foodList.initFoodList(jsonFoodList);

  
  const theme = useTheme();
  const ismobileXS = useMediaQuery(theme.breakpoints.down('xs'));
  const ismobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  //if (!rows) rows = localFoodList;
/*
  if (props  && props.ismobile){
    ismobile = props.ismobile;
  }
*/
//*** PROCS
//██    ██ ███████ ███████       ███████ ████████  █████  ████████ ███████ 
//██    ██ ██      ██            ██         ██    ██   ██    ██    ██      
//██    ██ ███████ █████   █████ ███████    ██    ███████    ██    █████   
//██    ██      ██ ██                 ██    ██    ██   ██    ██    ██      
// ██████  ███████ ███████       ███████    ██    ██   ██    ██    ███████ 
  const[state, setState] = React.useState(true);
  const[openMSG, setMSGOpen] = React.useState(false);
  const[opend, setOpend] = React.useState(false); //dialog
  const[openConfirm, setOpenConfirm] = React.useState(false); //dialog confirm
  const[showConfirm, setShowConfirm ] = React.useState(false); //dialog
  const[currentFood, setCurrentFood] = React.useState(new Food()); //dialog
//  const[currentImage, setCurrentImage] = React.useState(null); //dialog
  //const[currentB64Image, setCurrentB64Image] = React.useState(""); //dialog
  const[currentObjImage, setCurrentObjImage] = React.useState(null); //dialog
  const[showWait, setShowWait] = React.useState(false); 
  const [selectedID, setSelectedID] = React.useState(0);
  const [tabValue, setTabValue] = React.useState(0);


  const manageFavourite = (e, v, id)=>{
    foodList.setFavouriteVal(id, v);
    
  }




  const handleRowClick = ( id, e) => {
    
    if (id && id.id)
      setSelectedID(id.id);
    setOpenConfirm(true);
    //AddFoodToCurrent(id.id);

  }
  const HandleAddFoodToCurrent = (e, v) => {
    AddFoodToCurrent(selectedID);
    setOpenConfirm(false);
    navigate("/current");
  }

  const AddFoodToCurrent = (id) => {


    const cookies = new Cookies();

    let meal = new Meal();
    meal.loadFromMemory();
    let food = new Food();
    food = foodList.getFoodByID(id);
    

    meal.addFood(food);
    meal.saveToMemory();    
    

    
    msglastID = "Aggiunto " + id;
    setMSGOpen(true);
 
  }





  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //███████ ██████  ██ ████████ 
  //██      ██   ██ ██    ██    
  //█████   ██   ██ ██    ██    
  //██      ██   ██ ██    ██    
  //███████ ██████  ██    ██    
  // handle the modification food button
  const EditFood = (food, type) => {
    //alert("*** EDIT FOOD")
    setEditImage(food);


    //let img = foodList.getImageFromMemory(food);
    //if (img) setCurrentB64Image(img);

    if (type == "COPY"){
    
      let newFoodId = foodList.copyFood(food);
      foodList.saveToMemory();
      let newfood = foodList.getFoodByID(newFoodId);
      setCurrentFood(newfood);
      setOpend(true);
      return;
    }
    setCurrentFood(food);
    setOpend(true);
  }

  const setEditImage = (food) => {
    //console.log("*** SET IMAGE")
    //console.log(food)
    //setCurrentB64Image("");
    setCurrentObjImage(null);     
    
    let oimg = new Image();
    try{
      oimg.getImageFromMemory(food.id, "food");
      console.log("*** SET IMAGE")
      console.log(oimg)
    }
    catch(e){
      
    }
    
    if (oimg.getIsNew(food.imageTime)){
      //** download from cloud into local memory*/
      console.log("*** DOWNLOAD IMAGE")
      new Promise(async(resolve, reject) => {
        let cimg = await getValue("foodimage", food.id );
        console.log("*** DOWNLOAD IMAGE")
        console.log(cimg)
        oimg.getImageFromJSON(cimg);
        setCurrentObjImage(oimg);  
        oimg.saveToMemory();
        //setCurrentB64Image(oimg.B64Image);
      })
    }
    else{
      setCurrentObjImage(oimg);
      //setCurrentB64Image(oimg.B64Image);
    }
    

    
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ██████  ██████  ██ ██████  
  //██       ██   ██ ██ ██   ██ 
  //██   ███ ██████  ██ ██   ██ 
  //██    ██ ██   ██ ██ ██   ██ 
  // ██████  ██   ██ ██ ██████  
  const columns = [
    { field: 'id', headerName: 'ID', width: 60 , align:'right'},
//    { field: 'name', headerName: 'Name', width: ismobile?100:400,
    { field: 'name', headerName: 'Name',  flex: 1,
    
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.note === "" ? params.row.name : params.row.note} >
        <span>{params.row.name}</span>
        
        </Tooltip>
        
      );
    }
    },
    
    
    {field: 'cho',    headerName: 'CHO',    type: 'number',   width: 50,  align:'right'},
    /*{field: 'category',    headerName: 'Category',      width: 100,  },*/
    /*{      field: 'portion',    headerName: 'Porzione [gr]',    sortable: false,    width: 80,
      valueGetter: (params) => ` ${params.row.part || ''} ${params.row.wpart?'[':''}${params.row.wpart || ''}${params.row.wpart?']':''}`,  },
*/      
    { field: "add",
      
      width: 10,
      headerName: '+',
      valueGetter: (params) => `${params.row.id}`,
      renderCell: (cellValues) => {
          return (
            <IconButton  color="primary" onClick={()=>{AddFoodToCurrent(cellValues.value)}}> <AddCircleOutlineIcon /> </IconButton>
          );
        }
    },
    { field: "favourite",
      
      width: 80,
      headerName: '*',
      valueGetter: (params) => `${params.row.id}`,
      renderCell: (params) => {


          return ( 
            <div style={{  padding: 0, spacing:0 }}>
            <Rating name="no-value" value={params.row.fav} onChange={(e, v) => manageFavourite(e, v, params.row.id)} max={3} min={0}/>              
          </div>
          );
        }
    },
    { field: "modify",
      
      width: 10,
      headerName: 'M',
      valueGetter: (params) => `${params.row.id}`,
      renderCell: (params) => {
          

          
          return (
            <IconButton  color="primary" onClick={()=>{EditFood(params.row)}}> <EditIcon/> </IconButton>
            
          );
        }
    },
    /*{ field: "copy",
      
      width: 10,
      headerName: 'M',
      valueGetter: (params) => `${params.row.id}`,
      renderCell: (params) => {
          

          
          return (
            <IconButton  color="primary" onClick={()=>{EditFood(params.row, 'COPY')}}> <ContentCopyIcon /> </IconButton>
            
          );
        }
    },*/
  ];

  //██████  ██    ██ ████████ ████████  ██████  ███    ██ ███████ 
  //██   ██ ██    ██    ██       ██    ██    ██ ████   ██ ██      
  //██████  ██    ██    ██       ██    ██    ██ ██ ██  ██ ███████ 
  //██   ██ ██    ██    ██       ██    ██    ██ ██  ██ ██      ██ 
  //██████   ██████     ██       ██     ██████  ██   ████ ███████ 
  const DataGridTitle = ()=> {
    //const waiting = <CircularProgress  sx={{ color: 'primary' }} />
    const waiting = <LinearProgress sx={{ color: 'primary' }} />
    //const waiting = <Box sx={{ width: '100%' }}><LinearProgress  sx={{ color: 'primary' }} /></Box>
    
    return(
        <Box component="div" display="inline">
            

            <Grid container spacing={0}  direction="row"  justifyContent="flex-end"  alignItems="center">
              <Grid item xs={3} md={1.2}>
                <IconButton color="primary" onClick={getFood}> 
                  <Tooltip id="button1" title="Download from cloud">
                    <Chip icon={<CloudDownloadIcon />} label="Down" color="primary"/>
                  </Tooltip>
                </IconButton>
              </Grid>
              <Grid item xs={3} md={1.2}>
                <IconButton color="primary" onClick={saveFood} > 
                  <Tooltip id="button2" title="Save into the cloud">
                    <Chip icon={<CloudUploadIcon />} label="Save" color="primary"/>
                  </Tooltip>
                </IconButton>
                </Grid>
              <Grid item xs={3} md={1.2}>

              <IconButton color="primary" onClick={addFood} variant="outlined"> 
                <Tooltip id="button3" title="Insert new food">
                  <Chip icon={<AddCircleOutlineIcon />} label="Add &nbsp;" color="primary"/>
                </Tooltip>
              </IconButton>
              </Grid>
              <Grid item xs={3} md={1.3}>
                <IconButton color="primary" onClick={addFoodBarcode} variant="outlined"> 
                  <Tooltip id="button4" title="Insert new food from barcode">
                    <Chip icon={bcodeIcon()} label="Scan" color="primary" 
                    sx={{    "& .MuiChip-icon": {        fontSize: "24px"    }  }}/>
                  </Tooltip>
                </IconButton>               
              </Grid>
            </Grid>
            {showWait ? waiting:<></>}
{/*                          
            <Grid container justifyContent="flex-start">
            <IconButton color="primary" onClick={getFood}> 
              <Tooltip id="button1" title="Download from cloud">
                <Chip icon={<CloudDownloadIcon />} label="Down" color="primary"/>
              </Tooltip>
            </IconButton>

            <IconButton color="primary" onClick={saveFood} > 
              <Tooltip id="button2" title="Save into the cloud">
                <Chip icon={<CloudUploadIcon />} label="Save" color="primary"/>
              </Tooltip>
            </IconButton>
            <IconButton color="primary" onClick={addFood} variant="outlined"> 
              <Tooltip id="button3" title="Insert new food">
                <Chip icon={<AddCircleOutlineIcon />} label="Add" color="primary"/>
              </Tooltip>
            </IconButton>
            <IconButton color="primary" onClick={addFoodBarcode} variant="outlined"> 
              <Tooltip id="button4" title="Insert new food from barcode">
                <Chip icon={<QrCodeScannerIcon />} label="Scan" color="primary"/>
              </Tooltip>
            </IconButton> 
            {showWait ? waiting:<></>}
            
           </Grid>
*/}           
        </Box>
        
    )
  }

  const datafilter = (e, data, ID) =>{
    
    if (!e || !e.target || !e.target.value === undefined) return;

    if (ID === "SEL"){
      if (e.target.value === "ALL") foodList.filterByCategory("");
      else foodList.filterByCategory(e.target.value);
    }

    if (ID === "TXT"){
      foodList.filterByText(e.target.value.toLowerCase());

    }

    if (ID === "SWT"){
      
      foodList.filterByFavourite(data);
      

    }
    
    

    setState(!state)
    
  }

 ///////////////////////////////////////////////////////////////////////////////
// ██    ██ ██████  ██       ██████   █████  ██████  
// ██    ██ ██   ██ ██      ██    ██ ██   ██ ██   ██ 
// ██    ██ ██████  ██      ██    ██ ███████ ██   ██ 
// ██    ██ ██      ██      ██    ██ ██   ██ ██   ██ 
//  ██████  ██      ███████  ██████  ██   ██ ██████  
  const saveFood = async(e, d) => {
    console.log("*** UPLOAD  FOOD")
    setShowWait(true);
    //alert("save foods")
    await setValue("ufoods", foodList.getAllUserFoodList())  //save the custom user foods
    await setValue("sfoods", foodList.getAllStandardFoodList())  //save the standard foods
    //console.log(foodList.getAllStandardFoodList())
    let oimg = new Image();
    //oimg.test();
    //return;
    
      while (oimg.getNextImage_lc("food")){
        console.log("*** UPLOAD  IMAGE " +  oimg.id)  
        
        
        await setValue("foodimage", oimg, oimg.id);
        //console.log("*** IMAGE LIST" + oimg.id)
        //console.log(oimg)
      }
      setShowWait(false);
    
    /*
    let newImageList = foodList.getNewImageList();
    if (newImageList && newImageList.length){
      for (let i=0; i<newImageList.length; i++){
        //console.log("*** IMAGE LIST" + i)
        //console.log(newImageList[i]);
        setValue("foodimage", foodList.getImageFromMemory(null, newImageList[i]))
      }
      foodList.clearNewImageList();
      
    }
    */
  }

//██████   ██████  ██     ██ ███    ██ ██       ██████   █████  ██████  
//██   ██ ██    ██ ██     ██ ████   ██ ██      ██    ██ ██   ██ ██   ██ 
//██   ██ ██    ██ ██  █  ██ ██ ██  ██ ██      ██    ██ ███████ ██   ██ 
//██   ██ ██    ██ ██ ███ ██ ██  ██ ██ ██      ██    ██ ██   ██ ██   ██ 
//██████   ██████   ███ ███  ██   ████ ███████  ██████  ██   ██ ██████  
  const getFood = (e, d) => {
    setShowWait(true);
    let myPromise = new Promise(async(resolve, reject)=> {
      //console.log('getFood init');
      let foods = await getValue("foods", "" );
      //console.log('getFood after foods:', foods);
      //debugger;
      if (foods){
        foods = JSON.parse(foods);
        foodList.setFromStandard(foods);
        foodList.saveToMemory();
      }
      else{
        setMSGOpen(true);
      }
      //console.log("***FOODS");
      //console.log(foods);
      setShowWait(false);

      resolve();
    });

  }

  const addFood = (e, d) => {
    setCurrentFood(new Food());    
    setOpend(true);
  }

  const addFoodBarcode = (e, d) => {
    navigate("/scan")
  }



  const handleDeleteShow= (e, d, show) => {
    //foodList.deleteFood(currentFood);
    setShowConfirm(show);
    //setOpend(false);
  }
  
  const handleDeleteConfirm = (e, d) => {
    foodList.deleteFood(currentFood);
    setShowConfirm(false);
    setOpend(false);
  }

  const handleCopy= (e, d) => {
    //setOpend(false);
      //currentFood.name = 'pippo';
      let newFood = new Food();
      newFood.id = 100000;
      newFood.name = "pok"
      setCurrentFood(newFood);
      //setMSGOpen("pippo");
      setOpend(false);
      
      setOpend(true);
      return;
      let newFoodId = foodList.copyFood(currentFood);
      foodList.saveToMemory();
      let newfood = foodList.getFoodByID(1);
      setCurrentFood(newfood);
      setOpend(false);
      
      setOpend(true);
      //return;
  };


  //███████ ██ ███████ ██      ██████       ██████ ██   ██  █████  ███    ██  ██████  ███████ 
  //██      ██ ██      ██      ██   ██     ██      ██   ██ ██   ██ ████   ██ ██       ██      
  //█████   ██ █████   ██      ██   ██     ██      ███████ ███████ ██ ██  ██ ██   ███ █████   
  //██      ██ ██      ██      ██   ██     ██      ██   ██ ██   ██ ██  ██ ██ ██    ██ ██      
  //██      ██ ███████ ███████ ██████       ██████ ██   ██ ██   ██ ██   ████  ██████  ███████ 
  const  handleOnChange = (e, v, special) =>  {
    
    //console.log("** ON CHANGE FIELD")
    //console.log(e.target.value)
    //console.log(v)
    if (special && special === "TAB"){
      setTabValue(v);
      return;
    }
    let newFood = Object.assign({}, currentFood);
    newFood[v] = e.target.value
    setCurrentFood(newFood);

  }

 
  const handleOnChangeImg_old = (e, v) =>  {
    
    //console.log("** ON CHANGE IMG")
    //console.log(e.target.value)
    //console.log(v)
    let oimg = new Image();
    oimg = Object.assign({}, currentObjImage);                      //initilize from curent memory


    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);                          //get image from form
      new Promise(resolve => {
        reader.onloadend = () => {
          if (v) oimg[v]= reader.result;                                  //assign the right image 1/2/3
          //oimg.resize(v);
          setCurrentObjImage(oimg);
          resolve(oimg);
        };
      });
    }
    else{
      oimg[v] = e.target.value;
      setCurrentObjImage(oimg);
      
    }


  }

  const fetchFileFromURL = async(url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      // Now you have the file as a Blob, and you can use it as needed
      // For example, to create a URL for it and display it in an img element:
      return blob
      //const objectURL = URL.createObjectURL(blob);
      //return objectURL;
      //document.getElementById('myImage').src = objectURL;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  }

  const handleImgFromUrl = async(url, v) =>  {
    console.log("URL:",url);
    let oimg = new Image();
    oimg = Object.assign({}, currentObjImage);                      //initilize from curent memory
    let file = await fetchFileFromURL(url);
    console.log("FILE:",file);
    if (file) {
      try {
        //const file = e.target.files[0];
        const image = await resizeFile(file);
        console.log("image:", image);
        console.log(image);
        if (v) oimg[v]= image
          //oimg.resize(v);
          setCurrentObjImage(oimg);
          //resolve(oimg);
      } catch (err) {
        console.log(err);
      }
      
      
      
    }
/*    
    else{
      oimg[v] = e.target.value;
      setCurrentObjImage(oimg);
    
    }
*/    
  }

  const handleOnChangeImg = async(e, v) =>  {
    
    //console.log("** ON CHANGE IMG")
    //console.log(e.target.value)
    //console.log(v)
    let oimg = new Image();
    oimg = Object.assign({}, currentObjImage);                      //initilize from curent memory
   

    if (e.target.files && e.target.files[0]) {
      try {
        const file = e.target.files[0];
        const image = await resizeFile(file);
        console.log(image);
        if (v) oimg[v]= image
          //oimg.resize(v);
          setCurrentObjImage(oimg);
          //resolve(oimg);
      } catch (err) {
        console.log(err);
      }
      
      
      
    }
    else{
      oimg[v] = e.target.value;
      setCurrentObjImage(oimg);
    
    }


  }



  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });


// ███████  █████  ██    ██ ███████ 
// ██      ██   ██ ██    ██ ██      
// ███████ ███████ ██    ██ █████   
//      ██ ██   ██  ██  ██  ██      
// ███████ ██   ██   ████   ███████ 
  const handleClosed = (e, d) => {
    setShowConfirm(false);
    setOpend(false);
    setOpenConfirm(false);
    
  };

  const handleClosed2 = (e, d) => {
    //debugger;
    e.preventDefault();
    setOpend(false);
    
    
    //console.log("*** IMAGE")
    //console.log(currentB64Image);
    //console.log("TARGET", e.target);
    //*** create new food based on form
    let newFood = new Food();
    newFood.name      = e.target.food_name.value;
    newFood.cho    = parseFloat(e.target.food_cho.value);
    newFood.weight    = parseFloat(e.target.food_weight.value);
    newFood.cat  = e.target.food_category.value;
    //newFood.part      = e.target.food_part.value;
    newFood.sug    = parseFloat(e.target.food_sug.value);
    newFood.col    = parseFloat(e.target.food_col.value);
    newFood.fib    = parseFloat(e.target.food_fib.value);

    newFood.step      = parseFloat(e.target.food_step.value);
    newFood.min       = parseFloat(e.target.food_min.value);
    newFood.max       = parseFloat(e.target.food_max.value);
    newFood.note      = e.target.food_note.value;
    newFood.tag      = e.target.food_tag.value;    
    newFood.mdate     = Date.now();

//    let p1 = 

    newFood.wpart1     = parseFloat(e.target.food_wpart1.value);  //Fetta slice22
    newFood.wpart2     = parseFloat(e.target.food_wpart2.value);  //Spicchio clove
    newFood.wpart3     = parseFloat(e.target.food_wpart3.value);  //chicco Grain
    newFood.wpart4     = parseFloat(e.target.food_wpart4.value);  //Pezzo Piece
    newFood.wpart5     = parseFloat(e.target.food_wpart5.value);  //Intero  whole
    newFood.wpart6     = parseFloat(e.target.food_wpart6.value);  //Porzione Serving



    //*** id new/modified
    if (e.target.food_id.value > 0){
      newFood.id = e.target.food_id.value;
     
    }
    else {
      newFood.id = foodList.getNextID();
     
    }

    //*** image
    let oimg = new Image();

    oimg.saveToMemoryObj(newFood.id,  "food", currentObjImage)
    newFood.imageTime = oimg.getTime();   //TODO:check if change always time even if not selected picture
    
    //*** id new/modified
    if (e.target.food_id.value > 0){
     
      foodList.modifyFood(newFood);
    }
    else {
     
      foodList.addFood(newFood);
    }

    foodList.saveToMemory();
    
  };



  let mobileColumnsVisibility = {
    // Hide columns status and traderName, the other columns will remain visible
    portion : false, id:false, add:false
    
  }

  let category_field = [];

//*** ███    ███  █████  ██ ███    ██         
//*** ████  ████ ██   ██ ██ ████   ██         
//*** ██ ████ ██ ███████ ██ ██ ██  ██         
//*** ██  ██  ██ ██   ██ ██ ██  ██ ██         
//*** ██      ██ ██   ██ ██ ██   ████         
  
//  if ((localFoodList)  && localFoodList.length) {
  if ((foodList)  && foodList.hasFood()) {  
    const categoryList = foodList.getCategoryList();
     
    category_field.push(<MenuItem value={"ALL"} key={"ALL"}>{"*** TUTTO ***"}</MenuItem>);
    
    for (let i=0; i<categoryList.length; i++){
      let category =  categoryList[i];
      category_field.push(<MenuItem value={category} key={category}>{category}</MenuItem>);
    }
  }

  
  if (!ismobile)  mobileColumnsVisibility = {}


  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const styleAddIcon = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
};
  //██████  ███████ ████████ ██    ██ ██████  ███    ██ 
  //██   ██ ██         ██    ██    ██ ██   ██ ████   ██ 
  //██████  █████      ██    ██    ██ ██████  ██ ██  ██ 
  //██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██ 
  //██   ██ ███████    ██     ██████  ██   ██ ██   ████ 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
         
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/*<Button onClick={()=>{const cookies = new Cookies(); cookies.set('CURRENT_MEAL_J2', "{}", { path: '/' });}}> TEST</Button> */}
          <Snackbar open={openMSG} autoHideDuration={500} onClose={() => setMSGOpen(false)}>
            <Alert  severity="success" sx={{ width: '100%' }}> {msglastID} </Alert>
          </Snackbar>
        
          <Grid container spacing={0}  direction="row"  justifyContent="flex-end"  alignItems="center">
            <Grid item xs={9}>
              <TextField
                required
                id="food_name_filter"
                label="Filter"
                defaultValue={foodList.getTextFilter()}
                sx={{ height: '4rem', width:'100%'}}
                //onChange={(e, d)=>datafilter(e, d, 'TXT')}
                onKeyUp={(e, d) => {
                  if (e.key === 'Enter'){
                    
                    datafilter(e, d, 'TXT')}
                }}
              />
            </Grid>

            <Grid item xs={2}>
              <FormControlLabel 
                control={
                  <Switch onChange={(e, d) => datafilter(e, d, 'SWT')} />} label="Favoriti" labelPlacement="top" checked={foodList.getFavouriteFilter()}/>
            </Grid>
            <Grid item xs={1}>
              <IconButton tooltip="Description here" onClick={() => navigate('/doch13')}>
                <HelpIcon color="primary" fontSize='large' />
              </IconButton>
            </Grid>
          
          </Grid>  

          
          
          <FormControl variant="outlined"    style={{ width: "100%", marginBottom: 32 }}    >

            <InputLabel id="label-category">Categoria</InputLabel> 
            <Select
              onChange={(e, d)=>datafilter(e, d, 'SEL')}
              id="select-category"
              value={foodList.getCategoryFilter()}
              label="Categoria"
              sx={{ height: '4rem', width:'100%'}}
              style={{ width: "100%" }}
              variant="outlined"
              >
              { category_field }
            </Select>
          </FormControl>
          
          <Box sx={{ display: 'flex' }}>
          </Box>
          

          
          <DataGrid
          style={{  width: '100%' }}
            rows={foodList.getFoodList()}
            columns={columns}
            components={{Toolbar: DataGridTitle}}
            columnVisibilityModel={mobileColumnsVisibility}
            rowHeight={40}
            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 100 },    },          }}
            pageSizeOptions={[50, 100, 200]}
            onRowDoubleClick = { handleRowClick}
          /> 
          
      </Box>
{
  //███████  ██████  ██████  ███    ███     ██████  ██  █████  ██       ██████   ██████  
  //██      ██    ██ ██   ██ ████  ████     ██   ██ ██ ██   ██ ██      ██    ██ ██       
  //█████   ██    ██ ██████  ██ ████ ██     ██   ██ ██ ███████ ██      ██    ██ ██   ███ 
  //██      ██    ██ ██   ██ ██  ██  ██     ██   ██ ██ ██   ██ ██      ██    ██ ██    ██ 
  //██       ██████  ██   ██ ██      ██     ██████  ██ ██   ██ ███████  ██████   ██████  
}     
        <Dialog open={opend} onClose={handleClosed}>

          <form action="/" method="POST" onSubmit={handleClosed2}>
            <DialogContent>
{/*              
              <TextField  id={"" + currentFood.id}  label="test" value={currentFood.id}  size="small"/>
*/}                
        
            {Food.getForm(currentFood, handleOnChange, handleOnChangeImg, currentObjImage, tabValue)}

        
              <div style={{ display: showConfirm ? "block" : "none" }}>
                <Button onClick={(e, d) => handleDeleteShow(e, d, false)}>Cancel</Button>
                <Button onClick={handleDeleteConfirm}>Confirm Delete</Button>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e, d) => handleDeleteShow(e, d, true)}>Delete</Button>
              <Button onClick={handleCopy}>Copy</Button>
              <Button onClick={handleClosed}>Cancel</Button>
            
              <Button  type="submit" >Save</Button>
            </DialogActions>
          </form>
        </Dialog>
{        
// ██████  ██████  ███    ██ ███████ ██ ██████  ███    ███ 
//██      ██    ██ ████   ██ ██      ██ ██   ██ ████  ████ 
//██      ██    ██ ██ ██  ██ █████   ██ ██████  ██ ████ ██ 
//██      ██    ██ ██  ██ ██ ██      ██ ██   ██ ██  ██  ██ 
// ██████  ██████  ██   ████ ██      ██ ██   ██ ██      ██ 
}
        <Dialog open={openConfirm} onClose={handleClosed}>
        <DialogTitle>Conferma</DialogTitle>
          <DialogContent>
            <div>
              <strong>Aggiungere al pasto attuale?</strong>
            </div>
          </DialogContent>
          <DialogActions>
              
              <Button onClick={HandleAddFoodToCurrent}>OK</Button>
              <Button onClick={handleClosed}>Cancel</Button>
            
              
            </DialogActions>
        </Dialog>
        { /*<Box sx={{ '& > :not(style)': { m: 1 } }}> <Fab color="primary" aria-label="add" style={styleAddIcon} onClick={addFood}> <AddIcon />  </Fab> </Box>        */}
      </Container>
    </ThemeProvider>

    
  );
}