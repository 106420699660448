import * as React from 'react';
import { Container, Grid, Paper, useMediaQuery, useTheme, Button, Link } from '@mui/material';

import DocMenu from './DocMenu';

export default function Doc() {
    return (
       
            <Container component="main" maxWidth="xl">
                    
                      
            
                <Grid container spacing={2} className='homeGrid'>
                    
                    
                    
                        
                            <Grid item xs={2}>
<DocMenu current="doch13" />
                            </Grid>
                            <Grid item xs={10}>
                            <Paper elevation={1} >
                            <div className='docs'>
                            <h1>Tabelle nutrizionali di ContaDeiCarboidrati</h1>
<img src="/foods_h1.png" alt="Pasto per il calcolo dei carboidrati" ></img>
<ol>
        <li><strong>Filtro di ricerca: </strong>Inserire il nome del piatto da ricercare e avviare. 
        <br/>- Attenzione il filtro verrà applicato solo dopo aver confermato la ricerca.
        <br/>- I nomi dei piatti possono essere plurali o singolati. Si consiglia di non inseire l'ultima vocale (Ex. "mel" al posto di "mela" o "mele")
        </li>
        <li><strong>Visulizza solo i preferiti:</strong> in modo da ripolire la lista dai piatti non di interesse</li>
        <li>Selezione della categoria di ricerca</li>
        <li>Pulsante per scaricare i dati</li>
        <li>Pulsante per salvare la configurazione attuale</li>
        <li>Pulsante per aggiungere una nuova voce</li>
        <li>Pulsante per scansionare un codice QR</li>
        <li>Voce di menu selezionata</li>
        <li>Icona per aggiungere il piatto al menù corrente</li>
        <li>Icona per aggiungere la voce ai preferiti</li>
        <li>Icona per modificare il piatto</li>
    </ol>
                            
                            </div>
                            </Paper>
                            </Grid>
                        
                        
                    
                </Grid>
            </Container>

    )
}