import React from "react";
import { useState } from "react"
import { confirmPassword } from "./auth"

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import Avatar from '@mui/material/Avatar';
import Login from '@mui/icons-material/Key';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();
export default function ResetPassword() {
  const [username, setUsername] = useState("")
  const [confirmationCode, setConfirmationCode] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")

    try {
      await confirmPassword(username, confirmationCode, newPassword)
      setSuccess(true)
    } catch (err) {
      setError(err.message)
    }
  }

  if (success) {
    return (
      <div>
        <h2>Reset password</h2>
        <p>Your password has been reset successfully!</p>
      </div>
    )
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

  
  

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <Login/>

          </Avatar>
          <Typography component="h1" variant="h5">
          Reset Password
          </Typography>
          
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

<TextField
              margin="normal"
              required
              fullWidth
              id="code"
              label="Confirmation code"
              name="Confirmation code"
              autoComplete="code"
              autoFocus
              
              value={username}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
<TextField
              margin="normal"
              required
              fullWidth
              type="password"
              id="passwrod"
              label="New password"
              name="New password"
              autoComplete="passwrod"
              autoFocus
              
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />

            

        <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
      </Box>
      <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" to="/sign-forgot">
                  Forgot password?
                </Link>


  
              </Grid>
              <Grid item>
                
                <Link to="/sign-up" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
      {error && <p>{error}</p>}
    
    </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}