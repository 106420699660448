import logo from './logo.svg';

import './App.css';
import {jsonMenu, jsonMenuType, jsonNewMenu} from './Data.js';
import {WeekMenu, helloWorld, SelectMenuType, SelectMenuWeek, SelectMenuDay} from './Utility.js'
import globalVal from './globalVar';
import Toolbar from '@mui/material/Toolbar';
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputLabel from '@mui/material/InputLabel';
import Slider from '@mui/material/Slider';


import CssBaseline from '@mui/material/CssBaseline';
//radio group
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Switch from '@mui/material/Switch';

import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import ScaleIcon from '@mui/icons-material/Scale';

/*menu item */
import MenuItem from '@mui/material/MenuItem';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import Select from '@mui/material/Select';
import TonalityIcon from '@mui/icons-material/Tonality';

import ButtonAppBar from './menu.js';

import {isMobile} from 'react-device-detect';
import SimpleBottomNavigation from './bottommenu.js';

import { BrowserRouter as Router, Routes, Route, Navigate }
    from 'react-router-dom';


    import {Link, withRouter} from 'react-router-dom';
    
    //import BottomNavigationAction from '@mui/material/BottomNavigationAction';
    //import { BottomNavigation } from 'react-native-material-ui';
    import BottomNavigation from '@mui/material/BottomNavigation';
    //import BottomNavigationAction from '@mui/material/BottomNavigationAction';
    import LanguageIcon from '@mui/icons-material/Language';
    import GroupIcon from '@mui/icons-material/Group';
    import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
    import HelpIcon from '@mui/icons-material/Help';
    import EmailIcon from '@mui/icons-material/Email';
    import { useNavigate } from "react-router-dom";


/////////////////////////////// ROUTES
import UserProfile from './Auth/UserProfile.js';
import SignUp from './Auth/SignUp.js';
import SignIn from './Auth/SignIn';
import SignOut from './Auth/SignOut.js';
import SignConfirm from './Auth/SignConfirm.js';

import { ProtectedRoute } from "./Auth/Protect";
import User from './classes/UserDataClass.js'
import { useLocalStorage } from "./lib/useLocalStorage.js";

import Meals from './Meals.js';
import Foods from './Foods.js';
import Current from './Current.js';
import Home from './Home.js';
//import Home1 from './Home1.js';
//import Home2 from './Home2.js';
import Test from './Test.js';

import Export from './Export.js';
import Cgm from './Cgm.js';
import ScanBarcode from './ScanBarcode.js';
//import ScanBarcode2 from './ScanBarcode2.js'; //test
import Calendar from './Calendar.js';
import Calendar2 from './Calendar2.js';

///// AUTH
import { AuthProvider } from "./Auth/AuthContext";
//import Login from "./Auth/Login";
//import SignIn2 from "./Auth/SignIn2";
import SignUp2 from "./Auth/SignUp";
import ConfirmSignUp from "./Auth/ConfirmSignUp";
import Profile from "./Auth/UserProfile";
import ForgotPassword from "./Auth/ForgotPassword";
import ResetPassword from "./Auth/ResetPassword";
import RouteGuard from "./RouteGuard";

import  HomeTEST from "./HomeTEST";
import ContactTEST from "./ContactTEST";
import  ProfileTEST from "./ProfileTEST.jsx";


//DOC
import Doc01 from "./doc/doc1.js";
import Doch10 from "./doc/doch10.js";
import Doch11 from "./doc/doch11.js";
import Doch12 from "./doc/doch12.js";
import Doch13 from "./doc/doch13.js";
import Doch1e1 from "./doc/doch1e1.js";
import Doch1e2 from "./doc/doch1e2.js";

import Doca01 from "./doc/doca01.js";

import Doch20 from "./doc/doch20.js";

import Doch30 from "./doc/doch30.js";
import Doch40 from "./doc/doch40.js";

import PrivacyAlexa from "./doc/Privacyalexa.js";
import TermsAlexa from "./doc/Termsofusealexa.js";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#282c34',
  textAlign: 'Left',
  color: '#ffffff',
  paddingLeft: 10,
}));

const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: '#282c34',
  textAlign: 'Right',
  color: '#909090',
  paddingRight: 10,

}));

const ItemR = styled(Paper)(({ theme }) => ({
  backgroundColor: '#282c34',
  textAlign: 'Right',
  color: '#FFFFFF',
  paddingRight: 10,
}));

const ItemRR = styled(Paper)(({ theme }) => ({
  backgroundColor: '#282c34',
  textAlign: 'Right',
  color: '#FFFFFF',
  paddingRight: 20,
}));



let value = 'manuele';
let cho = [0,0,0,0,0,0,0,0];
let peso = [0,0,0,0,0,0,0,0];
let init = 1;
let initiatedData = 0;
let totCHO=0;





class Foo extends React.Component {

  constructor(props) {
    super(props);
    
    this.handleClick = this.handleClick.bind(this);
    
  }
  handleClick() {
    console.log('Click happened');
    
  }


  render() {
    return <button onClick={this.handleClick()}>Click Me</button>;
  }
}




function dateselected(date){
    console.log("DATA:" + date +jsonMenu);
    value = "DATA:" + date ;
  
}



function MenuGiorno(props, callback){
  let reload = props.option;
  value = value + "-" + jsonMenu.week1.d1[0].name;
  const fields= [];
  let fieldSwitch = [];
  //let menuday = jsonMenu['week' + globalVal.currentWeek]['d' + globalVal.currentDay];
  const [seed, setSeed] = useState(1);  
    if (globalVal.currentSubMenu!="" && globalVal.currentMenu!="" && globalVal.currentMenuType !==""){
    
    let menuday = jsonNewMenu[globalVal.currentMenuType]
      .filter(item => item.submenu===globalVal.currentSubMenu)
      .filter(item => item.menu===globalVal.currentMenu);
    
    const setChoTot = (value, idx, cho100) => {    
      
      init = 0;
      peso[idx]= value;
      cho[idx]= value * cho100 / 100;
      setSeed(Math.random()); 
      
    }
    let minValue = 0;
    let maxValue = 100;

    


    totCHO=0;
    for (let i = 0; i < menuday.length; i++) {
      minValue = 0;
      maxValue = 100;
      let sliderstep = 1;
      let udmLabel = "";
      let udmPeso = 1;
  
      if (menuday[i].min)
      if ((init == 1) ) peso[i] = menuday[i].peso;
      cho[i] = Math.round(peso[i] * menuday[i].cho100 / 100 *100)/100;
      
      const marks = [];
      if (Object.hasOwn(menuday[i], 'min')){minValue = menuday[i].min}
      if (Object.hasOwn(menuday[i], 'max')){maxValue = menuday[i].max}

      if (Object.hasOwn(menuday[i], 'pesoUdm')){sliderstep = menuday[i].pesoUdm;udmPeso = sliderstep}
      if (Object.hasOwn(menuday[i], 'step')){sliderstep = menuday[i].step}
      if (Object.hasOwn(menuday[i], 'pesoUdm') && Object.hasOwn(menuday[i], 'udm')){
        udmLabel = menuday[i].udm;
        for (let i=minValue; i<=maxValue;i+=sliderstep){
          let posValue = sliderstep * i;
          if (i == minValue)
            marks.push({"value":i , "label": ""+Math.round(i / udmPeso * 10)/10 +" "+ udmLabel});
          else
            marks.push({"value":i , "label": ""+Math.round(i / udmPeso * 10)/10 });
          
        }
        
      }
      
      
      
      fieldSwitch = [];
      fieldSwitch.push(
        <Grid item xs={4} ><ItemRR><div><FormControlLabel control={<Switch />} label="Incompl." labelPlacement="start"/></div></ItemRR></Grid>
      );
      if (menuday[i].cho100 <= 0){
        fieldSwitch = [];
        fieldSwitch.push(
          <Grid item xs={4} ><ItemRR><div>&nbsp;</div></ItemRR></Grid>
        );
      }

      fields.push(
      <div >
      <Grid container spacing={0} key={i+1000} direction="row"  justifyContent="flex-end"  alignItems="center">
        <Grid item xs={1}><Item><LocalDiningIcon /></Item></Grid>
        <Grid item xs={1}><Item2>{menuday[i].cho100}</Item2></Grid>
        <Grid item xs={6} ><Item >{menuday[i].name}</Item></Grid>
        

        {fieldSwitch}
        

      </Grid>  
      <Grid container spacing={0} key={i+2000} direction="row"  justifyContent="flex-end"  alignItems="center">
      <Grid item xs={1} ><Item ><ScaleIcon /></Item></Grid>
        <Grid item xs={7}>
          <Item>
          <Slider 
            defaultValue={peso[i]} 
            value={peso[i]} 
            aria-label="Default" valueLabelDisplay="auto" 
            onChange={(event, value)=>setChoTot(value, i, menuday[i].cho100)}         
            color = {menuday[i].cho100==0?'success':menuday[i].cho100<=10?'warning':'error'} 
            step={sliderstep} 
            min={minValue}
            max={maxValue}
            marks={marks} 
            sx={{"& .MuiSlider-markLabel": {color:"#f0f0f0" }}}
            />
          </Item>
          </Grid>  
        <Grid item xs={2}><ItemR className='right'>{peso[i]}</ItemR></Grid>
        <Grid item xs={2}><ItemR className='right'>{cho[i]}</ItemR></Grid>
        
      </Grid> 
      <Box      sx={{ width: '100%', height: 3,        backgroundColor: '#999999'  , alignContent:'center'    }}    />
      </div>
      );
      totCHO +=cho[i];
    }
    totCHO = Math.round(totCHO);

    //init = 0;
    }
    
    
    if (globalVal.currentSubMenu!="" && globalVal.currentMenu!="" && globalVal.currentMenuType !==""){
    return (
      
      <form key="{999}">
        <div className="container">

          
          <Box sx={{ flexGrow: 1 }}>

          
          {fields}
          <Grid container spacing={0}>
            <Grid item xs={8}><Item ><h3>TOTALE CHO:</h3></Item></Grid>
            <Grid item xs={4}><ItemR ><h3>{totCHO}</h3></ItemR></Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={8}><Item ><h3>Unità:</h3></Item></Grid>
            <Grid item xs={4}><ItemR ><h3>{Math.round(totCHO *10 / 28)/10}</h3></ItemR></Grid>
          </Grid>

          </Box>
        </div>

  </form>

    );
  }
  else return (<div>Seleziona un pasto dal menù</div>);
}




function initData(){
  if (initiatedData == 1) return;
  let currentDate = new Date();
  globalVal.currentDay  = currentDate.getDay();
  if ((globalVal.currentDay > 5) || (globalVal.currentDay < 1)) globalVal.currentDay = 1 

  
  let startDate = new Date(currentDate.getFullYear(), 0, 1);
  var days = Math.floor((currentDate - startDate) /    (24 * 60 * 60 * 1000));
 
  globalVal.currentWeek = Math.ceil(days / 7) % 4;
  initiatedData = 1;
  
  
  console.log("week" + globalVal.currentWeek);
  console.log("day" + globalVal.currentDay);
  
  
}










function App() {
  
  const [user, setUser] = useLocalStorage("users", null);
  
  return (

    <AuthProvider>
    
      <Router>
          <ButtonAppBar user={user}/>
          
          
          <Routes>
              <Route exact path='/' exact element={<Home />} />

              
              <Route path='/foods' element={<Foods ismobile={isMobile} />} />
              <Route path='/meals' element={<Meals />} />
              <Route path='/export' element={<Export />} />

              <Route path='/cgm' element={<Cgm />} />

              <Route path='/test' element={<RouteGuard><Test /></RouteGuard>} />
              
              <Route path='/scan' element={<ScanBarcode />} />
              {/*<Route path='/scan2' element={<ScanBarcode2 />} />*/}
              <Route path='/calendar' element={<Calendar2 ismobile={isMobile}/>} />
              <Route path='/calendar2' element={<Calendar2 ismobile={isMobile}/>} />
              <Route path='/current' element={<Current test={"PIPPO"}/>} />
              
              
              <Route path='/sign-up' element={<SignUp />} />
              <Route path='/sign-out' element={<SignOut setUser={setUser}/>} />
              <Route path='/sign-in' element={<SignIn/>} />
              <Route path='/sign-forgot' element={<ForgotPassword/>} />
              <Route path='/sign-reset' element={<ResetPassword/>} />
              {/*
              <Route path='/sign-in2' element={<SignIn2/>} />
              <Route path='/login' element={<Login />} />
              */}
              <Route path='/sign-confirm' element={<SignConfirm />} /> 
              <Route path='/user-profile' element={<UserProfile user={user} setUser={setUser}/>} /> 
              <Route
                path="/profile"
                element={
                  <RouteGuard>
                    <Profile />
                  </RouteGuard>
                }
                />
              <Route path='/doch10' element={<Doch10/>} /> 
              <Route path='/doch11' element={<Doch11/>} /> 
              <Route path='/doch12' element={<Doch12/>} /> 
              <Route path='/doch13' element={<Doch13/>} /> 
              <Route path='/doch1e1' element={<Doch1e1/>} /> 
              <Route path='/doch1e2' element={<Doch1e2/>} /> 
              <Route path='/doch20' element={<Doch20/>} /> 
              <Route path='/doca01' element={<Doca01/>} /> 

              <Route path='/doch30' element={<Doch30/>} /> 
              <Route path='/doch40' element={<Doch40/>} /> 
              <Route path='/doc01' element={<Doc01/>} /> 

              <Route path='/privacyalexa' element={<PrivacyAlexa />} /> 
              <Route path='/termsofusealexa' element={<TermsAlexa />} /> 

          </Routes>
          {/*
          <Toolbar />
           <SimpleBottomNavigation />    
          */}
      </Router>
    
      </AuthProvider>
      
  );
}

export default App;
