import * as React from 'react';
//import Grid from '@mui/material/Grid';
//import Paper from '@mui/material/Paper';
import { Grid, Paper, useMediaQuery, useTheme, Button } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

//*** ICONS
import FoodBankIcon from '@mui/icons-material/FoodBank';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CircleIcon from '@mui/icons-material/Circle';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

//*** SPECIAL
import { AuthContext } from "./Auth/AuthContext"
import { Trans, useTranslation } from 'react-i18next';

//***LOCAL
import Config from './classes/ConfigClass';
import { Padding } from '@mui/icons-material';

export default function Home1() {
  const navigate = useNavigate()  
  const { t, i18n } = useTranslation();
  const { user, signOut } = React.useContext(AuthContext)
  console.log("HOME", user)
  const iconStyle =  { width:100, height:100 };
  const bulletStyle =  { width:40, height:40, color:"#1976D2" };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let isLogin = false;
  let search = 'CognitoIdentityServiceProvider';
  console.log("THEME", theme)
  
  const readMore = (id) => {
    //alert(id)
    navigate("/doch"+id+'0')
  }

  const readMoreBtn = (id)  =>{
    return(
      <div style={{spacing:10, Padding:10}}>
      <Button variant="contained" onClick={()=>readMore(id)} className='home-read-more-btn' ><ReadMoreIcon /> &nbsp; Read more   </Button>

      </ div>
    )
  }



  const home_title = ()  =>{
    return(
      
      <div className='wrapper01'>
        <img src="/logo.png" width="500" className='logo-home1' align="center"/>
        
        
        {t('home.bullet.txt')}<br/>
        <Trans i18nKey='home.bullet.txtx' components={{ul:<ul className='homelist'/>, li:<li/>}}></Trans>
        <br/>
        <div className='wrapperInit'>Inizia a conoscere il metodo della conta dei carboidrati. Accedi alla documentazione <Link to="/doc01">Scopri di più...</Link></div>                 
      </div>
      
    )
  }

  const home_grid = (id, img)  =>{
    return(

      <div className='wrapper'>
        <h3>{t('home.bullet.title' + id)}</h3>
        <img src={img} width="180" className='wrapper' align="right"/>
        {t('home.bullet.txt' + id)}<br/>
        <Trans i18nKey={'home.bullet.txt' + id + 'x'} components={{ul:<ul className='homelist'/>, li:<li/>}}></Trans>
        <br/> {readMoreBtn(id)} 
        <br/> 
      </div>

      
    )
  }


  
  
  let values = Object.keys(localStorage)
                   .filter( (key)=> key.startsWith(search) )
                   .map( (key)=> localStorage[key] );
  if (values.length > 0) isLogin = true;




    if (isLogin) {      
      let count = 12;
      return (
        <Container component="main" maxWidth="sm">
        <CssBaseline />
          
          <Box >
<Trans i18nKey='userMessagesUnread' count={count}>
    dasa <strong title={t('nameTitle')}>{{name: 'Jan'}}</strong>,      you have {{count}} unread message. <Link to="/msgs">Go to   messages</Link>.
</Trans>
<img src="/logo.png" width="300" className='topright'/>
          <Grid container spacing={1} key={1}   >
            <Grid item xs={1}>&nbsp;</Grid>
            <Grid item xs={4}><IconButton component={Link} to="/foods"><FoodBankIcon style={iconStyle}/></IconButton></Grid>
            <Grid item xs={2}>&nbsp;</Grid>
            <Grid item xs={4}><IconButton component={Link} to="/current"><DinnerDiningIcon style={iconStyle}/></IconButton></Grid>
            <Grid item xs={1}>&nbsp;</Grid>
            <Grid item xs={6} >{t('home.plate_list')}</Grid>
            <Grid item xs={6} >{t('home.current_meal')}</Grid>
            <Grid item xs={1} backgro>&nbsp;</Grid>
            <Grid item xs={4}><IconButton component={Link} to="/meals"><LocalDiningIcon style={iconStyle}/></IconButton></Grid>
            <Grid item xs={2}>&nbsp;</Grid>
            <Grid item xs={4}><IconButton component={Link} to="/calendar2"><CalendarMonthIcon style={iconStyle}/></IconButton></Grid>          
            <Grid item xs={1}>&nbsp;</Grid>
            <Grid item xs={6} >{t('home.meal_archive')}</Grid>
            <Grid item xs={6} >{t('home.calendar')}</Grid>          
          </Grid>  
        
        </Box>    
          </Container>
      );
    }
    else {
/*
            <img src="/logo.png" width="500"/>

            
             
            <Grid container spacing={1} key={1}   >
            
            <Grid item xs={1}><CircleIcon style={bulletStyle}/></Grid>
            <Grid item xs={5}><strong>{t('home.bullet.title1')}</strong>
              <div className='wrapper'>
                <img src="/food_table_it.png" width="150" className='wrapper'/>{t('home.bullet.txt1') }
              </div>
            </Grid>

            <Grid item xs={1}><CircleIcon style={bulletStyle}/></Grid>
            <Grid item xs={5}><strong>{t('home.bullet.title1')}</strong>
              <div className='wrapper'>
                <img src="/food_table_it.png" width="150" className='wrapper'/><Trans i18nKey="home.bullet.txt1x" components={{ ul:<ul/>, li: <li /> }} />
              </div>
            </Grid>
            <Grid item xs={1}><CircleIcon style={bulletStyle}/></Grid>
            <Grid item xs={5}><strong>{t('home.bullet.title3')}</strong><p className='homeBullet'>{t('home.bullet.txt1')}</p></Grid>
            <Grid item xs={1}><CircleIcon style={bulletStyle}/></Grid>
            <Grid item xs={5}><strong>{t('home.bullet.title4')}</strong><p className='homeBullet'>{t('home.bullet.txt1')}</p></Grid>


          </Grid>  
*/
/*
      return (
<Container component="main" maxWidth="lg">
        
          

        <Grid container spacing={2} className='homeGrid'>
        
          <>
          <Grid item xs={0}></Grid>
          <Grid item xs={12}>
          <Paper elevation={3} style={{ height: '100%' }}>
           
            {home_title()}
            </Paper>
          </Grid>
          <Grid item xs={0}></Grid>
            <Grid item xs={12}>
              <Paper elevation={3} style={{ height: '100%' }}>
              {home_grid1()}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={3} style={{ height: '100%' }}>
              {home_grid2()}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={3} style={{ height: '100%' }}>
              {home_grid3()}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={3} style={{ height: '100%' }}>
              {home_grid4()}
              </Paper>
            </Grid>
          </>
        
      </Grid>
            
          
    </Container>
      );
*/
      return (
        <Container component="main" maxWidth="xl">
                
                  
        
                <Grid container spacing={2} className='homeGrid'>
                
                  <>
                    
                    <Grid item xs={12}>
                      <Paper elevation={1} className='homePaper' >
                        {home_title()}
                      </Paper>
                    </Grid>
{/*                    
                    <Grid item xs={12} >
                      <Paper elevation={1} className='homePaper'  >
                      <div className='wrapperInit'>
        
        
                      Inizia a conoscere il metodo della conta dei carboidrati. Accedi alla sezione che documenta il metodo <Link to="/foods">Scopri di più...</Link>         
                        
                        </div>
      
                      </Paper>
                    </Grid>
*/}                    
                    <Grid item xs={12} md={6} lg={4}>
                    <Paper elevation={1} className='homePaper' >
                      {home_grid(1, "/food_table_it.png")}
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                    <Paper elevation={1} className='homePaper' >
                      {home_grid(2, "/meal_it.png")}
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                    <Paper elevation={1} className='homePaper' >
                      {home_grid(3, "/gcm_it.png")}
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={12}>
                      <Paper elevation={1} className='homePaper' >
                      {home_grid(4, "/calendar_it.png")}
                      </Paper>
                    </Grid>
                  </>
                
              </Grid>
                    
                  
            </Container>
              );
    }
  };
