import JSZip from 'jszip';
import moment from 'moment'
import Papa from 'papaparse';

export default class Glokoo{
    CGM_TAG = "CGM_DATA";
    BOLUS_TAG = "BOLUS_DATA";
    BASAL_TAG = "BASAL_DATA";
    min_date;
    max_data;

    constructor(){
        this.cgmData = [];
    }

    getFromJson(json){
        return Object.assign(new Glokoo(), json);
    }

    getTest(){
        return "GLOKOO CLASS TEST";
    }

    test(){
        alert("Test2")
        let csvString = '11/10/2023 19:33,Normale,0.0,"0,0",,"0,6",,,CamAPS mylife YpsoPump';
        let obj = Papa.parse(csvString)
        console.log(obj.data)
    }

    dayHasData(day){
        
        if (!day) return false;
        if (day instanceof Date){
            let sday = moment(day).format("YYYYMMDD");
            
            if (localStorage.getItem(sday)) return true
            sday = this.CGM_TAG + "_" + sday;
            
            if (localStorage.getItem(sday)) return true
        }

        let day_EC = this.CGM_TAG + "_" + day;
        if ((localStorage.getItem(day)  === null)
            && (localStorage.getItem(day_EC) === null)){
            return false;
            }
        return true;
    }

    async loadFile(file){
        const zip = new JSZip();
        const extractedFiles = await zip.loadAsync(file);
        extractedFiles.forEach(async (relativePath, file) => {
            //alert(file.name)
            //console.log(file.name);
            const content = await file.async("string");
            if (file && file.name && file.name === "cgm_data.csv"){
                this.cgmData = this.convertCGMCSVToJson(content);
                this.saveToLocalMemory();
            }
            if (file && file.name && file.name === "Insulin data/bolus_data.csv"){
                this.bolusData = this.convertBolusCSVToJson(content);
                this.saveToLocalMemory();
            }
            if (file && file.name && file.name === "Insulin data/basal_data.csv"){
                this.basalData = this.convertBasalCSVToJson(content);
                this.saveToLocalMemory();
            }
            
        });

    }

    convertCGMCSVToJson(csvData) {
        const lines = csvData.split("\n");
        const headers = lines[1].split(",");
        const result = [];
        for (let i = 2; i < lines.length; i++) {
            const obj = {};
            const currentLine = lines[i].split(",");
            //console.log(currentLine);

            try {
                obj.d = currentLine[0] ? currentLine[0].trim() : "";
                obj.t = this.convertData(currentLine[0], 'DD/MM/YYYY HH:mm', '')
                obj.v = currentLine[1] ? currentLine[1].trim() : "";
            }
            catch (e) {
                console.log(e);
            }
            result.push(obj);
        }
        return result;
    }

    convertBolusCSVToJson(csvString) {
        const csvData = Papa.parse(csvString,{delimiter: ",", skipEmptyLines: true, skipFirstNLines: 0, header: false, newline: '\n'});
        if (!csvData || !csvData.data) return undefined;
        const result = [];
        for (let i = 2; i < csvData.data.length; i++) {
            const obj = {};
            const currentLine = csvData.data[i];
            //console.log(currentLine);
            try {
                obj.d = currentLine[0] ? currentLine[0].trim() : "";
                obj.t = this.convertData(currentLine[0], 'DD/MM/YYYY HH:mm', '')
                obj.c = currentLine[3] ? currentLine[3].trim() : "";
                obj.i = currentLine[5] ? currentLine[5].trim() : "";
            }
            catch (e) {
                console.log(e);
            }
            result.push(obj);
        }
        console.log(result)
        return result;
    }

    convertBasalCSVToJson(csvString) {
        const csvData = Papa.parse(csvString,{delimiter: ",", skipEmptyLines: true, skipFirstNLines: 0, header: false, newline: '\n'});
        if (!csvData || !csvData.data) return undefined;
        const result = [];
        for (let i = 2; i < csvData.data.length; i++) {
            const obj = {};
            const currentLine = csvData.data[i];
            //console.log(currentLine);
            try {
                obj.d = currentLine[0] ? currentLine[0].trim() : "";
                obj.t = this.convertData(currentLine[0], 'DD/MM/YYYY HH:mm', '')
                obj.p = currentLine[2] ? currentLine[2].trim() : "";
                obj.i = currentLine[4] ? currentLine[4].trim() : "";
            }
            catch (e) {
                console.log(e);
            }
            result.push(obj);
        }
        console.log(result)
        return result;
    }


    getFromLocalMemory(day){
        console.log("get from local memory");
        //console.log(day);
        //let cgmData = localStorage.getItem(this.CGM_TAG + "_" + date)
        let cgmData = localStorage.getItem(day)
        return JSON.parse(cgmData);
    }

    getBolusFromLocalMemory(day){
        
        console.log("get from local memory");
        //console.log(day);
        //let cgmData = localStorage.getItem(this.CGM_TAG + "_" + date)
        let bolusName = day.replaceAll(this.CGM_TAG, this.BOLUS_TAG);
        let data = localStorage.getItem(bolusName)
        return JSON.parse(data);
    }



    getChartDataFromLocalMemory_EC(day){
        let cgmData = this.getFromLocalMemory(day);
        let chart = [];
        if (cgmData && cgmData.length > 0){
            for (let i = 0; i < cgmData.length; i++){
                let chartISODate = cgmData[i].t
                let time_value=[chartISODate, parseFloat(cgmData[i].v)];
                chart.push(time_value);
            }
        }
        return chart;
    }

    getBolusChartDataFromLocalMemory_EC(day){
        
        let fileName = day.replaceAll(this.CGM_TAG, this.BOLUS_TAG);
        let data = JSON.parse(localStorage.getItem(fileName));
        let chart = [];
        if (data && data.length > 0){
            for (let i = 0; i < data.length; i++){
                let chartISODate = data[i].t
                let value = parseFloat(("" + data[i].i).replaceAll(",", "."));
                let time_value=[chartISODate, value];
                chart.push(time_value);
            }
        }
        return chart;
    }

    //BASAL CHART
    getBasalChartDataFromLocalMemory_EC(day){
        let fileName = day.replaceAll(this.CGM_TAG, this.BASAL_TAG);
        let data = JSON.parse(localStorage.getItem(fileName));
        let chart = [];
        if (data && data.length > 0){
            for (let i = 0; i < data.length; i++){
                let baseDate = data[i].t;
                let chartISODate = baseDate;
                let value = parseFloat(("" + data[i].i).replaceAll(",", "."));
                //pre
                chartISODate = this.getDate1sec(baseDate, -1)
                chart.push([chartISODate, 0]);
                //start                
                chart.push([chartISODate, value]);

                //end
                chartISODate = this.getDate1sec(baseDate, data[i].p * 60)
                chart.push([chartISODate, value]);
                //post
                chartISODate = this.getDate1sec(baseDate, (data[i].p * 60) +1)
                chart.push([chartISODate, 0]);


            }
        }
        return chart;
    }

/*
    getChartDataFromLocalMemory(day){
        //console.log("get chart data from local memory");
        let cgmData = this.getFromLocalMemory(day);
        let chartData = [];
        if (cgmData && cgmData.length > 0){
            for (let i = 0; i < cgmData.length; i++){
                let obj = {};
                let chartISODate = cgmData[i].t
                //chartISODate = this.convertData( cgmData[i].t, '', '2000-01-01THH:mm:00+02:00')
                //chartISODate = this.convertData( cgmData[i].t, '', '2000-01-01THH:mm:00+00:00')
                let chartDate = new Date(chartISODate);//cgmData[i].t
                
                obj.x = chartDate
                obj.y = parseFloat(cgmData[i].v);
                if (obj.x && obj.y) chartData.push(obj);

                if (!this.min_date || this.min_date > chartDate) this.min_date = chartDate;
                
                if (!this.max_date || this.max_date < chartDate) this.max_date = chartDate;

                
            }
        }
        console.log(chartData)
        return chartData;
    }

    getBolusChartDataFromLocalMemory(day){
     
        console.log("get BOLUS chart data from local memory");
        let cgmData = this.getBolusFromLocalMemory(day);
        let chartData = [];
        if (cgmData && cgmData.length > 0){
            for (let i = 0; i < cgmData.length; i++){
                let obj = {};
                let chartISODate = cgmData[i].t
                //chartISODate = this.convertData( cgmData[i].t, '', '2000-01-01THH:mm:00+02:00')
                //chartISODate = this.convertData( cgmData[i].t, '', '2000-01-01THH:mm:00+00:00')
                let chartDate = new Date(chartISODate);//cgmData[i].t
                
                obj.x = chartDate
                obj.y = parseFloat(("" + cgmData[i].i).replaceAll(",", "."));
                obj.y = obj.y * 100;
                if (obj.x ) chartData.push(obj);

                if (!this.min_date || this.min_date > chartDate) this.min_date = chartDate;
                
                if (!this.max_date || this.max_date < chartDate) this.max_date = chartDate;

                
            }
        }
        console.log(chartData)
        return chartData;
    }
*/

    saveToLocalMemory(){
        //console.log("save to local memory");
        //*** CGM
        console.log("*** save to local memory")   
        if (this.cgmData && this.cgmData.length > 0){
            let current_date = "";
            let current_data = [];
            for (let i = 0; i < this.cgmData.length; i++){

                current_data.push(this.cgmData[i]);

                let yyyymmdd = this.convertData(this.cgmData[i].d, "DD/MM/YYYY HH:mm", "YYYYMMDD");
               
                if (current_date === "") current_date = yyyymmdd;
                if ((current_date !== yyyymmdd) || (i == this.cgmData.length - 1)){
                    
                    localStorage.setItem(this.CGM_TAG + "_" +current_date, JSON.stringify(current_data));
                    current_date = yyyymmdd;
                    current_data = [];
                    
                }
            }
        }
        //*** BOLUS
        if (this.bolusData && this.bolusData.length > 0){
            let current_date = "";
            let current_data = [];
            for (let i = 0; i < this.bolusData.length; i++){

                current_data.push(this.bolusData[i]);
                let yyyymmdd = this.convertData(this.bolusData[i].d, "DD/MM/YYYY HH:mm", "YYYYMMDD");
               
                if (current_date === "") current_date = yyyymmdd;
                if ((current_date !== yyyymmdd) || (i == this.bolusData.length - 1)){
                    
                    localStorage.setItem(this.BOLUS_TAG + "_" +current_date, JSON.stringify(current_data));
                    current_date = yyyymmdd;
                    current_data = [];
                    
                }
            }
            
        }
        //*** BASAL
        if (this.basalData && this.basalData.length > 0){
            let current_date = "";
            let current_data = [];
            for (let i = 0; i < this.basalData.length; i++){

                current_data.push(this.basalData[i]);
                let yyyymmdd = this.convertData(this.basalData[i].d, "DD/MM/YYYY HH:mm", "YYYYMMDD");
               
                if (current_date === "") current_date = yyyymmdd;
                if ((current_date !== yyyymmdd) || (i == this.basalData.length - 1)){
                    
                    localStorage.setItem(this.BASAL_TAG + "_" +current_date, JSON.stringify(current_data));
                    current_date = yyyymmdd;
                    current_data = [];
                    
                }
            }
            
        }


    }

    convertData(inputData, formatInput, formatOutput) {
        //const startDate = moment('17/07/2023', 'DD/MM/YYYY')
        let mdate = moment(inputData, formatInput);
        //mdate.add(2, 'hours')
        return mdate.format(formatOutput)

    }

    getAllLocalMemoryEntry(){
        let keys = Object.keys(localStorage);
        let result = [];
        //debugger
        for (let i = 0; i < keys.length; i++){
            if (keys[i].indexOf(this.CGM_TAG) > -1){
                result.push(keys[i]);
                
            }
        }
        return result.sort();
    }
      
    resetMinMaxDate(){
        this.min_date = undefined;
        this.max_data = undefined;

    }

    getMaxDate1d(){
        let mdate = moment(this.min_date);
        mdate.add(1, 'day')
        return new Date(mdate);
    }

    getDate1sec(date, sec){
        let mdate = moment(date);
        mdate.add(sec,'second')
        return new Date(mdate);

    }

}