import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

import {AuthenticationDetails, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';


import userpool from './userpool';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignConfirm() {
  const[msg, setMSG] = React.useState("");
  const[msgOK, setMSGOK] = React.useState("");
  const[showLoginLink, setShowLoginLink] = React.useState(false);
  const[waitSec, setWaitSec] = React.useState(5);
  

  const handleWaitSec = (w) => {
    console.log("waitSec: " + waitSec + " w:" + w);
    if (w > 0) {
      //setTimeout(function() { setWaitSec(waitSec - 1); }, 1000);
      setWaitSec(w)
      
      setTimeout(function() {handleWaitSec(w - 1);}, 1000);
    } else {
      window.location.replace('/sign-in');
    }
  }

  const handleSubmit = (event) => {
    //alert("submit" );
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      code: data.get('code'),
    });

    let Email = data.get('email');
    let Code = data.get('code');

    

    //alert("LOGIN:" + Email + " COED:" + Code)
    return new Promise((resolve,reject)=>{
      const user=new CognitoUser({
          Username:Email,
          Pool:userpool
      });



      user.confirmRegistration(Code, true,(err, data) => {
        if (err) {
          setMSG(err.message);
          
          
        } else {
          
          setShowLoginLink(true);
          setMSGOK("Success: " + user.getUsername());
          handleWaitSec(5)
        }
      });
    }); 

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            User confirm
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="code"
              label="Code"
              type="number"
              id="code"
              autoComplete="code"
            />

              {
                showLoginLink == true &&
                <div>
                  
                  <p>{msgOK} &nbsp; <a href="/sign-in" > go to Sign In </a> </p>
                  <h5>Automatic redirect in {waitSec} seconds</h5>
                </div>
              }
              
                <p>{msg}</p>
              

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Confirm
            </Button>
            
          </Box>
        </Box>
        
      </Container>
    </ThemeProvider>
  );
}