import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  return (
    <Box component="footer" sx={{ backgroundColor: '#f5f5f5', padding: '20px 0' }}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Organization Name
            </Typography>
            <Typography>
              <Link href="/mission" color="inherit">Our Mission</Link>
            </Typography>
            <Typography>
              <Link href="/get-involved" color="inherit">Get Involved</Link>
            </Typography>
            <Typography>
              <Link href="/donate" color="inherit">Donate</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Learn More
            </Typography>
            <Typography>
              <Link href="/events" color="inherit">Upcoming Events</Link>
            </Typography>
            <Typography>
              <Link href="/news" color="inherit">Latest News</Link>
            </Typography>
            <Typography>
              <Link href="/contact" color="inherit">Contact Us</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Connect With Us
            </Typography>
            <Link href="https://facebook.com" color="inherit">
              <FacebookIcon />
            </Link>
            <Link href="https://instagram.com" sx={{ marginLeft: '10px' }} color="inherit">
              <InstagramIcon />
            </Link>
            <Link href="https://twitter.com" sx={{ marginLeft: '10px' }} color="inherit">
              <TwitterIcon />
            </Link>
          </Grid>
        </Grid>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ marginTop: '20px' }}>
          © {new Date().getFullYear()} Organization Name. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
