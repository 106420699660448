import * as React from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';


import  {Event, Events}  from './classes/EventsClass.js';


import { AuthContext } from "./Auth/AuthContext"
//import { useZxing } from "react-zxing";


import { Calendar, momentLocalizer } from "react-big-calendar";
import Toolbar from 'react-big-calendar/lib/Toolbar';
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Cal.css";

import IconButton from '@mui/material/IconButton';
//*** ICONS
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import EventShow from "./components/EventShow.js"



const defaultTheme = createTheme();



  




export default function MyCalendar(props) {
  const { user, signOut, getValue, getSession } = React.useContext(AuthContext)
  let ismobile = false;
  if (props  && props.ismobile){
    ismobile = props.ismobile;
  }
  moment.locale("en-GB");
  let events = new Events();
  events.loadFromMemory();

  //███████ ████████  █████  ████████ ███████ 
  //██         ██    ██   ██    ██    ██      
  //███████    ██    ███████    ██    █████   
  //     ██    ██    ██   ██    ██    ██      
  //███████    ██    ██   ██    ██    ███████ 
  //const [events, setEvents] = React.useState(myEventsList);
  const [state, setState] = React.useState({ openEvent: false, openSlot: false });
  const [refresh, setRefresh] = React.useState(0);
  //console.log(user)

  let value = "value1"








const localizer = momentLocalizer(moment);


const handleSelectCal = ({ start, end }) => {
  console.log(start);
  console.log(end);
  const title = window.prompt("New Event name");
  
  if (title){
    events.addEvent(new Event(0, title, start, end));
    setRefresh(Math.random());
    
  }
  
  };
  



const iconStyle =  { width:32, height:32 };

class CalendarToolbar extends Toolbar {

	componentDidMount() {
		const view = this.props.view;
		
	}

	render() {
		return (
			<div className="rbc-toolbar">
				<div className="rbc-btn-group">
        &nbsp; &nbsp;
				</div>
				<div className="rbc-toolbar-label">
          <IconButton onClick={() => this.navigate('PREV')}><ArrowCircleLeftIcon style={iconStyle}/></IconButton>
          {this.props.label}
          <IconButton onClick={() => this.navigate('NEXT')}><ArrowCircleRightIcon style={iconStyle}/></IconButton>          
          </div>
				<div className="rbc-btn-group">


				</div>
				<div className="rbc-btn-group2">
          <IconButton onClick={this.view.bind(null, 'week')}><CalendarViewWeekIcon style={iconStyle}/></IconButton>
          <IconButton onClick={this.view.bind(null, 'month')}><CalendarViewMonthIcon style={iconStyle}/></IconButton>
				</div>

			</div>
		);
	}
}

//██████  ██  █████  ██       ██████   ██████      ███    ███  █████  ███    ██  █████   ██████  ███████ 
//██   ██ ██ ██   ██ ██      ██    ██ ██           ████  ████ ██   ██ ████   ██ ██   ██ ██       ██      
//██   ██ ██ ███████ ██      ██    ██ ██   ███     ██ ████ ██ ███████ ██ ██  ██ ███████ ██   ███ █████   
//██   ██ ██ ██   ██ ██      ██    ██ ██    ██     ██  ██  ██ ██   ██ ██  ██ ██ ██   ██ ██    ██ ██      
//██████  ██ ██   ██ ███████  ██████   ██████      ██      ██ ██   ██ ██   ████ ██   ██  ██████  ███████ 
const setTitle=(e)=> {
  state.title = e;
  setState(state);
  
}

const setDescription=(e)=> {
  state.desc = e;
  setState(state);
}

const handleStartTime = (event, date) => {
  setState({ start: date });
};

const handleEndTime = (event, date) => {
  setState({ end: date });
};

 //  Allows user to click on calendar slot and handles if appointment exists
 const cal_handleSlotSelected=(slotInfo)=> {
  console.log("Real slotInfo", slotInfo);
  setState({
    title: "",
    desc: "",
    slotInfo: slotInfo,
    start: slotInfo.start,
    end: slotInfo.end,
    openSlot: true,
    
  });
}

const cal_handleEventSelected = (e) => {
  console.log("event", e);
  setState({
    init:true,
    clickedEvent: e,
    start: e.start,
    end: e.end,
    title: e.title,
    desc: e.desc,
    openEvent: true,
  });
}

const handleClose = () => {
  setState({ openEvent: false, openSlot: false });
}

const appointmentActions = [
  <Button label="Cancel" secondary={true} onClick={handleClose} >Calcell</Button>,
  <Button
    
    primary={true}
    keyboardFocused={true}
    onClick={() => {
      //alert("rwazr");
      setNewAppointment();
      handleClose();
    }}
  >Submit</Button>,
];
  
const setNewAppointment=()=> {
  const { start, end, title, desc } = state;
  let appointment = { title, start, end, desc };
  let events = state.events.slice();
  events.push(appointment);
  // localStorage.setItem("cachedEvents", JSON.stringify(events));
  setState({ events });
}



  //██████  ███████ ████████ ██    ██ ██████  ███    ██ 
  //██   ██ ██         ██    ██    ██ ██   ██ ████   ██ 
  //██████  █████      ██    ██    ██ ██████  ██ ██  ██ 
  //██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██ 
  //██   ██ ███████    ██     ██████  ██   ██ ██   ████ 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
         
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
      
        <Calendar
          localizer={localizer}
          events={events.getAllEvents()}
          views={["week", "month"]}
          selectable
          startAccessor="start"
          endAccessor="end"
          style={{  width: ismobile?'100%':'800px' }}
          onSelectEvent={event => cal_handleEventSelected(event)}
          onSelectSlot={slotInfo => cal_handleSlotSelected(slotInfo)}

          
          components={{
            
            toolbar: CalendarToolbar,
            
          }}
        />

        
    </Box>
    <EventShow open={state.openEvent} init={state} event={state.clickedEvent} onClose={handleClose} events={events} />
    <EventShow open={state.openSlot} slot={state.slotInfo} onClose={handleClose} events={events} />
    
    
    
      
     
      </Container>
    </ThemeProvider>
  );
}

