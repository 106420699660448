import * as React from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

//EXCEL TEST
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import * as Cognito from "amazon-cognito-identity-js";
//DATA
import { jsonFoodList } from './Data.js';
// TODO remove, this demo shouldn't need to reset the theme.


import { AuthContext } from "./Auth/AuthContext"
//import { useZxing } from "react-zxing";
//import { OpenFoodFactsApi } from 'openfoodfac-ts';
import User from './classes/UserDataClass.js'

//import * as echarts from 'echarts/core';
//import ReactEcharts from "echarts-for-react"

//import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

//import "react-big-calendar/lib/css/react-big-calendar.css";
//import "./Cal.css";

//import EventShow from "./components/EventShow.js"
import { useTranslation } from 'react-i18next';

import {
   LineChart,
  // BarChart,
  // PieChart,
  // ScatterChart,
  // RadarChart,
  // MapChart,
  // TreeChart,
  // TreemapChart,
  // GraphChart,
  // GaugeChart,
  // FunnelChart,
  // ParallelChart,
  // SankeyChart,
  // BoxplotChart,
  // CandlestickChart,
  // EffectScatterChart,
  // LinesChart,
  // HeatmapChart,
  // PictorialBarChart,
  // ThemeRiverChart,
  // SunburstChart,
  // CustomChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
  // GridSimpleComponent,
  GridComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  // GraphicComponent,
  // ToolboxComponent,
  TooltipComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  // TimelineComponent,
  // MarkPointComponent,
  // MarkLineComponent,
  // MarkAreaComponent,
  // LegendComponent,
  // LegendScrollComponent,
  // LegendPlainComponent,
  // DataZoomComponent,
  // DataZoomInsideComponent,
  // DataZoomSliderComponent,
  // VisualMapComponent,
  // VisualMapContinuousComponent,
  // VisualMapPiecewiseComponent,
  // AriaComponent,
  // TransformComponent,
  DatasetComponent,
} from 'echarts/components';

import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';


const defaultTheme = createTheme();



const ExportExcel =  () => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xmlx';
  var excelData=jsonFoodList;
  const fileName = 'pippo';

  const exportToExcel = async () => {
    var workbook = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, ws, "Results");
    XLSX.writeFile(workbook, 'out2.xlsx', {type: 'file'});
  }
  return (
    <>
      <Tooltip titel = "Excel export">
        <Button onClick={(e) => exportToExcel(fileName)}> Excel Export</Button>
      </Tooltip>
    
    </>
  )
}
  




export default function Test(props) {
  const { user, signOut, getValue, getSession } = React.useContext(AuthContext)
  const { t, i18n } = useTranslation();
  console.log(props)



  //console.log('*** TEST ***')
  //console.log('*** USER ***')
  //console.log(user)
  //let myUser = new User(user);
  //console.log(myUser.test());
  //if (myUser.getAccessExpired())

  //let cus = myUser.getCognitoSession();
  //let ia = cus.getAccessToken()
  //console.log(ia);
  //let cu = myUser.getCognitoUser();
  //console.log(cu.getUsername());
  //console.log(cus.isValid());
  //let user = new User(userData);
  //console.log(user.cognitoUserSession.isValid());
  //███████ ████████  █████  ████████ ███████ 
  //██         ██    ██   ██    ██    ██      
  //███████    ██    ███████    ██    █████   
  //     ██    ██    ██   ██    ██    ██      
  //███████    ██    ██   ██    ██    ███████ 
  
  const [state, setState] = React.useState("init");
  //console.log(user)

  let value = "value1"
  /*
  let my_atoken = userData.atoken.jwtToken  ;
  console.log(my_atoken);
  
  const URL = "https://ypcdl7dr4e.execute-api.eu-west-1.amazonaws.com/v1/"
  const restapi = (e) => {
    const LIMIT = 20;
    let r_id = Math.round(Math.random() * LIMIT);
    setState("Manuele " + r_id);
    //fetch(URL + "testjson")
    const headers = { 'Authorization': 'Bearer '  +  my_atoken };
    fetch(URL + "user" , { headers })
    .then(response => response.json())
    .then(data =>{
       console.log(data)
       
       
       
    }
       );
    
  }
  */
  
  const test = async () => {
    //alert(user.email);
    let val = await getValue('user');
    setState (JSON.stringify(val) + Math.random())

    

    
    //signOut();
    
  }

  const excel = (e, d) => {
    alert("Export Excel")
}

const handleScan = (data) => {
  setState(data)
}
const handleError = (err)=> {
  setState("ERROR:" + err)
  console.error(err)
}

/*
const { ref } = useZxing({
  onDecodeResult(result) {
    setState(result.getText());
  },
});
*/

const options = {
  grid: { top: 20, right: 40, bottom: 20, left: 40 },
  xAxis: {
    type: "category",
    data: ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5"]
  },
  yAxis: {
    type: "value"
  },
  series: [
    {
      data: [400, 300, 350, 200, 280],
      type: "line",
      smooth: true
    }
  ],
  tooltip: {
    trigger: "axis"
  }
}

const getFood = async () => {
  /*
  const openFoodFactsApi = new OpenFoodFactsApi();
  
  const product = await openFoodFactsApi.findProductByBarcode('8076809512107');
  
  console.log(product);

  const countries = await openFoodFactsApi.findCountries();
  */
}




//moment.locale("en-GB");
//const localizer = momentLocalizer(moment);
const myEventsList = [
  {    id: 0,    title: "All Day Event very long title",    allDay: true,    start: new Date(2023, 11, 1),    end: new Date(2023, 11, 1)  },
  {    id: 1,    title: "Long Event",   allDay: false, start: new Date(2023, 11, 7),    end: new Date(2023, 11, 11)  },
  {    id: 2,    title: "DTS STARTS",    start: new Date(2023, 11, 13, 12, 0, 0),    end: new Date(2023, 11, 13, 13, 0, 0)  }]
  const [events, setEvents] = React.useState(myEventsList);
  const [bstate, setBState] = React.useState(false);

const handleSelectCal = ({ start, end }) => {
  console.log(start);
  console.log(end);
  const title = window.prompt("New Event name");
  if (title)
  setEvents   ([
      ...events,
      {
        start,
        end,
        title
      }
    ]);
};
  const langs = {
    en: {
      code: 'en',
      label: 'English',
    },
    it: {
      code: 'it',
      label: 'Italiano',
    }

  }

  return (
    <div className="container">
      <header>
        <h1>Welcome to Our Dietetics Services</h1>
        <p>Your Path to a Healthier Lifestyle</p>
      </header>
      <section className="services">
        <h2>Our Services</h2>
        <div className="service">
          <h3>Nutritional Counseling</h3>
          <p>Personalized guidance for your nutritional needs.</p>
        </div>
        <div className="service">
          <h3>Meal Planning</h3>
          <p>Customized meal plans tailored to your goals.</p>
        </div>
        <div className="service">
          <h3>Weight Management</h3>
          <p>Support for achieving and maintaining a healthy weight.</p>
        </div>
      </section>
      <section className="about">
        <h2>About Us</h2>
        <p>
          We are a team of experienced dietitians dedicated to helping you achieve
          your health and wellness goals through personalized nutrition plans and
          expert guidance.
        </p>
      </section>
      <section className="contact">
        <h2>Contact Us</h2>
        <p>
          Ready to start your journey towards a healthier lifestyle? Contact us
          today to schedule a consultation.
        </p>
        {/* Add a contact form or contact information here */}
      </section>
    </div>
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          {Object.keys(langs).map((lng) => (
            <Button color="primary" onClick={()=>i18n.changeLanguage(lng)}>{lng}</Button>
          )

          )}
          <h1>{t('Welcome to React')}</h1>
          <h1>test</h1>
          <h1>{state}</h1>
          </Box>
{/*          
          <Button  onClick={excel}> EXCEL</Button>
          <Button  onClick={test}> TEST</Button>
          <Button  onClick={getFood}> GET OPEN FD</Button>
           <Button  onClick={restapi}> REST API </Button> 
          <ExportExcel  />

          STATE: {state}
        
        <EventShow open={bstate} onClose={() => setBState(false)}/>
        <Button onClick={()=>{setBState(true)}}> TEST EXTERNAL</Button>

 ██████  █████  ██      ███████ ███    ██ ██████   █████  ██████  
██      ██   ██ ██      ██      ████   ██ ██   ██ ██   ██ ██   ██ 
██      ███████ ██      █████   ██ ██  ██ ██   ██ ███████ ██████  
██      ██   ██ ██      ██      ██  ██ ██ ██   ██ ██   ██ ██   ██ 
 ██████ ██   ██ ███████ ███████ ██   ████ ██████  ██   ██ ██   ██ 

        <Calendar
          localizer={localizer}
          events={events}
          views={["week", "month"]}
          selectable
          startAccessor="start"
          endAccessor="end"
          style={{ height: 900, width: 800 }}
          onSelectEvent={(event) => alert(event.title)}
          onSelectSlot={handleSelectCal}
        />
*/}
        <>
      {/* <video ref={ref} paused={true}  />*/}
      <p>
        <span>Last result:</span>
        
      </p>
    </>
     
      </Container>
    </ThemeProvider>
  );
}

