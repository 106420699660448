import React from 'react';
import { Container, Grid, Paper, useMediaQuery, useTheme, Button, Link } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container component="main" maxWidth="lg">
<div>
       <h1>Informativa sulla privacy per Alexa Skill</h1>
       <p>
        La presente informativa sulla privacy descrive come Conta dei carboidrati ("noi") protegge la tua privacy ("tu", "l'utente") e i tuoi dati. 
        Prima di utilizzare una qualsiasi delle nostre Skill Alexa ("le nostre Skill"), ti invitiamo a leggere la presente 
        policy e politica sulla privacy di utilizzo delle Skill Alexa, nonché  
        i <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201809740">Termini di utilizzo di Alexa</a> e
         le <a href="https://www.amazon.com/privacy">Informative sulla privacy</a> di Amazon Digital Services LLC (con le sue affiliate, " Amazon”).
        </p>
      
       <p>Per quanto riguarda Amazon, "Alexa" indica il servizio vocale Alexa che include servizi di terze parti (come le nostre competenze) e altri software correlati.</p>
      
       <p>Se utilizzi una delle nostre competenze, accetti pienamente la presente informativa sulla privacy.</p>
      
       <h2>Generale</h2>
       <p>Quando usi le nostre competenze devi parlare con Alexa. Questo input vocale viene inviato ad Amazon e a noi dove lo utilizziamo per capire cosa dovrebbe fare per te la nostra competenza. Ciò è assolutamente necessario affinché il nostro servizio possa darti una risposta adeguata.</p>
      
       <h2>Dati</h2>
       <p>Non raccogliamo né condividiamo mai dati personali con le nostre competenze.</p>
      
       <p>Per migliorare i nostri servizi, analizziamo automaticamente la frequenza con cui vengono pronunciate le espressioni e altri dati analitici. Questa operazione viene eseguita automaticamente da Amazon nell'Amazon Developer Portal.</p>
      
       <p>Per alcune delle nostre competenze è necessario salvare i dati per te nei nostri database. Questi dati sono collegati solo al tuo Alexa-UserId ma mai al tuo nome o ad altre informazioni personali. Nel caso della nostra abilità “sittmacher” salviamo quanti millilitri hai bevuto al giorno. Altrimenti non potremmo fornirti questo servizio.</p>
      
       <h2>Modifiche</h2>
       <p>Le nostre competenze o parti di esse potrebbero cambiare o essere aggiornate in qualsiasi momento. Inoltre, la presente Informativa sulla privacy potrebbe cambiare. Puoi trovare un collegamento a questa politica nella pagina di descrizione della competenza nell'App Alexa o nell'Alexa Store. L'uso continuato della nostra competenza dopo le modifiche all'Informativa sulla privacy o alla competenza stessa sarà visto come la tua accettazione di entrambi.</p>
     </div>
     </Container>
  );
};

export default PrivacyPolicy;
