import React, { Component } from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Icon } from "@mui/material";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from "moment";
import  {Event, Events}  from '../classes/EventsClass.js';


//*** ICONS */
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';

export default class EventShow extends Component {
    
    constructor(props) {
        super();
        //debugger

        this.state = {
            events: {},
            title: "",
            start: "",
            end: "",
            desc: "",
            note: "",
            openSlot: false,
            openEvent: false,
            clickedEvent: {},
            slotInfo: {},
            update: undefined,
            showConfirm: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.setState = this.setState.bind(this);
        
        //this.handleClose = this.handleClose.bind(this);
        
    }


    //███████  █████  ██    ██ ███████ 
    //██      ██   ██ ██    ██ ██      
    //███████ ███████ ██    ██ █████   
    //     ██ ██   ██  ██  ██  ██      
    //███████ ██   ██   ████   ███████ 
    handleSave = (e, d) => {
    
        e.preventDefault();
        this.setState({ openEvent: false, openSlot: false });
        if (this.state.openSlot) {

            this.state.openEvent = false;
            this.state.openSlot = false;
            
            this.props.setState(this.state);
            this.props.onSave(this.state, 0);

        }

        if (this.state.openEvent) {
            
            this.state.openEvent = false;
            this.state.openSlot = false;
            
            this.state.clickedEvent.title = this.state.title;
            this.state.clickedEvent.start = this.state.start;
            this.state.clickedEvent.end = this.state.end;
            this.state.clickedEvent.desc = this.state.desc;
            this.state.clickedEvent.note = this.state.note;
            
            this.props.setState(this.state);
            this.props.onSave(this.state, 1);
        }
    }

    //██████  ███████ ██      ███████ ████████ ███████ 
    //██   ██ ██      ██      ██         ██    ██      
    //██   ██ █████   ██      █████      ██    █████   
    //██   ██ ██      ██      ██         ██    ██      
    //██████  ███████ ███████ ███████    ██    ███████ 
    handleDelete = () => {
        this.state.openEvent = false;
        this.state.openSlot = false;
        this.state.showConfirm = false;
        this.props.setState(this.state);
        this.props.onDelete(this.state.clickedEvent);
        this.setState({showConfirm: false});
    }
       
    handleDeleteShow = (show) => {
        this.setState({ showConfirm: show });
        //this.state.showConfirm = show;
    }

    handleClosed = () => {
        this.state.openEvent = false;
        this.state.openSlot = false;

        this.props.setState(this.state);

        //this.props.onClose();
    }

    handleChange = (event) => {
        console.log("event2", event.target.id);
        this.setState({ [event.target.id]: event.target.value });
    }

    deleteButton ()  {
        if (this.props.event)
        return (
            <Button onClick={() => this.handleDeleteShow(true)} variant="contained" color="error">
                <DeleteIcon />
                Delete
                </Button>
        )
        return (<div></div>)
    }

    assignEvent() {
        
        if (this.props && this.props.event) {
            if (this.props.event.title) this.state.title = this.props.event.title;
            if ( this.props.event.desc) this.state.desc = this.props.event.desc;
            if (this.props.event.start) this.state.start =  moment(this.props.event.start).format("YYYY-MM-DDTHH:mm:ss");
            if (this.props.event.end) this.state.end = moment(this.props.event.end).format("YYYY-MM-DDTHH:mm:ss");
        }

        if (this.props && this.props.slotInfo){
            
            if (this.props.slotInfo.start) this.state.start =  moment(this.props.slotInfo.start).format("YYYY-MM-DDTHH:mm:ss");
            if (this.props.slotInfo.end) this.state.end = moment(this.props.slotInfo.end).format("YYYY-MM-DDTHH:mm:ss");

        }
        


    }

    //███████  ██████  ██████  ███    ███ 
    //██      ██    ██ ██   ██ ████  ████ 
    //█████   ██    ██ ██████  ██ ████ ██ 
    //██      ██    ██ ██   ██ ██  ██  ██ 
    //██       ██████  ██   ██ ██      ██ 
    eventForm = () => {
        //debugger;
        if (this.props && this.props.init && this.props.init.init){
            this.assignEvent();
            this.props.init.state = false;
        }
        return (
            <DialogContent>

                <TextField
                    autoFocus
                    margin="dense"
                    className="rbc-txt-show"
                    id="title"
                    label="Title"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={this.state.title}
                    onChange={this.handleChange}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    className="rbc-txt-show"
                    id="desc"
                    label="Description"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={this.state.desc}
                    onChange={this.handleChange}
                />

                <TextField
                    autoFocus
                    margin="dense"
                    className="rbc-txt-show"
                    id="start"
                    label="Start"
                    type="datetime-local"
                    fullWidth
                    variant="standard"
                    value={this.state.start}
                    onChange={this.handleChange}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    className="rbc-txt-show"
                    id="end"
                    label="End"
                    type="datetime-local"
                    fullWidth
                    variant="standard"
                    value={this.state.end}
                    onChange={this.handleChange}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    className="rbc-txt-show"
                    id="note"
                    label="Note"
                    type="text"
                    multiline
                    rows={4}
                    fullWidth
                    variant="standard"
                    value={this.state.note}
                    onChange={this.handleChange}
                />                
            </DialogContent>
        )
    }

    render() {
        //if (!this.state.update) this.setState(this.props.state);
        if (!this.state.update || 
            this.state.update < this.props.state.update){
                 this.setState(this.props.state);
                 this.setState({showConfirm: false});
                 //debugger;
            }
        
        return (
            <div>
                
                {/*<Button onClick={() => this.setState({ openEvent: true })} variant="contained" color="primary"> Add Event </Button>*/}
                <Dialog open={this.props.open} onClose={this.handleClosed} aria-labelledby="form-dialog-title" >
                <form action="/" method="POST" onSubmit={this.handleSave}>
                    
                    
                        <DialogContent>
                        <div className="title"><EditCalendarIcon fontSize="large" /> Event { this.state.openEvent?" ["+(this.state.clickedEvent.id)+"]":""}</div>
                        {this.eventForm()}
                        </DialogContent>
                        <DialogContent >
                            <div style={{ display: this.state.showConfirm ? "block" : "none" }}>
                                <Button onClick={(e, d) => this.handleDeleteShow( false)}>Cancel</Button>
                                <Button onClick={this.handleDelete}>Confirm Delete</Button>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {this.deleteButton()}
                            <Button type="submit" variant="contained" startIcon={<SaveIcon  />}>Save</Button>
                            <Button onClick={this.handleClosed} variant="contained" startIcon={<CancelIcon  />}>Cancel</Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    }

}