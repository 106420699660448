import React, { Component, Children } from "react";
//import BigCalendar from "react-big-calendar";

import { Calendar, momentLocalizer } from "react-big-calendar";
import Toolbar from 'react-big-calendar/lib/Toolbar';


import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Cal.css";
import moment from "moment";
import 'moment/locale/it';
import Dialog from '@mui/material/Dialog';

//import FlatButton from "@mui/material/FlatButton";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
//import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TimePicker } from '@mui/x-date-pickers';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
//*** ICONS
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import EventShow from "./EventShow.js"
import  {Event, Events}  from '../classes/EventsClass.js';
import { Link } from "react-router-dom";
import { PowerInputSharp } from "@mui/icons-material";
import Glokoo from '../classes/GlokooClass';

//require("react-big-calendar/lib/css/react-big-calendar.css");
const localizer = momentLocalizer(moment)
//Calendar.momentLocalizer(localizer);





//████████  ██████   ██████  ██            ██████   █████  ██████  
//   ██    ██    ██ ██    ██ ██            ██   ██ ██   ██ ██   ██ 
//   ██    ██    ██ ██    ██ ██      █████ ██████  ███████ ██████  
//   ██    ██    ██ ██    ██ ██            ██   ██ ██   ██ ██   ██ 
//   ██     ██████   ██████  ███████       ██████  ██   ██ ██   ██ 
class CalendarToolbar extends Toolbar {

  iconStyle =  { width:32, height:32 };

	componentDidMount() {
		const view = this.props.view;
		
	}

	render() {
		return (
			<div className="rbc-toolbar">
				<div className="rbc-toolbar-label">
          <IconButton onClick={() => this.navigate('PREV')}><ArrowCircleLeftIcon style={this.iconStyle}/></IconButton>
          {this.props.label}
          <IconButton onClick={() => this.navigate('NEXT')}><ArrowCircleRightIcon style={this.iconStyle}/></IconButton>          
          </div>
				<div className="rbc-btn-group">


				</div>
				<div className="rbc-btn-group2">
          <IconButton onClick={this.view.bind(null, 'week')}><CalendarViewWeekIcon style={this.iconStyle}/></IconButton>
          <IconButton onClick={this.view.bind(null, 'month')}><CalendarViewMonthIcon style={this.iconStyle}/></IconButton>
          <Tooltip title="Agenda"><IconButton onClick={this.view.bind(null, 'agenda')}><ViewAgendaIcon style={this.iconStyle}/></IconButton></Tooltip>
				</div>

			</div>
		);
	}
}

// ██████  ██████  ███    ███ ██████   ██████  ███    ██ ███████ ███    ██ ████████ 
//██      ██    ██ ████  ████ ██   ██ ██    ██ ████   ██ ██      ████   ██    ██    
//██      ██    ██ ██ ████ ██ ██████  ██    ██ ██ ██  ██ █████   ██ ██  ██    ██    
//██      ██    ██ ██  ██  ██ ██      ██    ██ ██  ██ ██ ██      ██  ██ ██    ██    
// ██████  ██████  ██      ██ ██       ██████  ██   ████ ███████ ██   ████    ██    
class EventCalendar extends Component {
  constructor(props) {
    super();


    this.state = {
      events: {},
      title: "",
      start: "",
      end: "",
      desc: "",
      note: "",
      openSlot: false,
      openEvent: false,
      clickedEvent: {},
      clicckestedSlot: {},
      update: Date.now()
    };
    //debugger
    if (props.events){
       this.state.events = props.events;
       
    }
    //if (this.props.events) this.state.events = props.events;
    this.handleClose = this.handleClose.bind(this);
    this.glooko =  new Glokoo();
  }

  // componentDidMount(){
  //     this.getCachedEvents();
  // }

  // getCachedEvents(){
  //   const cachedEvents = localStorage.getItem("cachedEvents");
  //   console.log("Cached Events", JSON.parse(cachedEvents));
  //   if(cachedEvents){
  //       this.setState({events: JSON.parse(cachedEvents)})
  //   }
  //   return;
  // }

  //closes modals
  handleClose() {
    this.setState({ openEvent: false, openSlot: false });
  }

  //  Allows user to click on calendar slot and handles if appointment exists
  handleSlotSelected(slotInfo) {
    console.log("handleSlotSelected", slotInfo);
    if (this.stop){
      this.stop = false;
      return;
    }
    this.setState({
      title: "",
      desc: "",
      note: "",
      start: moment(slotInfo.start).format("YYYY-MM-DDTHH:mm"),
      end: moment(slotInfo.end).format("YYYY-MM-DDTHH:mm"),
      openSlot: true,
      update: Date.now()
    });
  }

  handleEventSelected(event) {
    
    this.setState({
      openEvent: true,
      clickedEvent: event,
      start: moment(event.start).format("YYYY-MM-DDTHH:mm"),
      end: moment(event.end).format("YYYY-MM-DDTHH:mm"),
      title: event.title,
      desc: event.desc,
      note: event.note,
      update: Date.now()
    });
  }

  handleNavigate(newDate, view, action) {
    console.log("handleNavigate", newDate, view, action);
   
  }

  handleRangeChange(range) {
    console.log("handleRangeChange", range);

  }

  //███████  █████  ██    ██ ███████ 
  //██      ██   ██ ██    ██ ██      
  //███████ ███████ ██    ██ █████   
  //     ██ ██   ██  ██  ██  ██      
  //███████ ██   ██   ████   ███████ 
  handleEventSave(cstate, update_new) {
    console.log("EventCalendar.handleEventSave", cstate);
    
    
    
    if (update_new) {
      this.state.events.saveToMemory();
    }
    else{
      let newEvent = {
        title: cstate.title,
        start: cstate.start,
        end: cstate.end,
        desc: cstate.desc,
        note: cstate.note,
        id: 0,
      };
      this.state.events.addEvent(newEvent);
    }
    
    

  }

  //  filters out specific event that is to be deleted and set that variable to state
  deleteEvent(event) {
    //debugger
    this.state.events.deleteEvent(event);
    /*
    let updatedEvents = this.state.events.filter(
      event => event["start"] !== this.state.start
    );
    // localStorage.setItem("cachedEvents", JSON.stringify(updatedEvents));
    this.setState({ events: updatedEvents });
    */
  }


  

  ColoredDateCellWrapper2 = ({children, value}) =>
  React.cloneElement(Children.only(children), {
      style: {
          ...children.style,
          backgroundColor: '#0ff',
      },
  });


ColoredDateCellWrapper3 = ({ children, value }) => {
    return (

      <div className={children.props.className} >
        {/*<Link onClick={(e)=>{e.stopPropagation(); this.stop=true; console.log("test" + value)}} color="primary"><QueryStatsIcon /></IconButton>*/}
        { children}
      <button onClick={(e)=>{e.stopPropagation(); this.stop=true; console.log("test" + value)}} color="primary"><QueryStatsIcon /></button>
      </div>
      

    );
  };

  ColoredDateCellWrapper4({ event }) {
    return (
        <div className="rbc-day-bg">
            <button>Book Class</button>
        </div>
    )
}


btn_click_test = (event, p) => {
  this.stop = true;
  console.log("clock", event, p);
  //alert("test");
}


iconChart (props){
  if (!props || props.isOffRange) return(null);
  if (this.glooko.dayHasData(props.date)){
    return(
      <QueryStatsIcon color="action" sx={{ "&:hover": { color: "blue" } }} fontSize="small" onClick={(e, d) => this.btn_click_test(e, props)} />
    )
  }
  return (null);
}

  render() {
    //console.log("EventCalendar", this.state.events);
    
    return (
      <div id="Calendar">
        {/* react-big-calendar library utilized to render calendar*/}
        <Calendar
          localizer={localizer}
          events={this.state.events.eventList}
          views={["month", "week", "day", "agenda"]}
          timeslots={2}
          defaultView="month"
          defaultDate={new Date()}
          selectable={true}
          
          onSelectEvent={event => this.handleEventSelected(event)}
          onSelectSlot={slotInfo => this.handleSlotSelected(slotInfo)}
          onNavigate={(newDate, view, action) => this.handleNavigate(newDate, view, action)}     
          onRangeChange={(range) => this.handleRangeChange(range)}
          style={{  width: this.props.ismobile ? '400px' : '800px'}}
          components={{ toolbar: CalendarToolbar,
            
            month: {
              dateHeader: (props) => (
                
              <div className="rbc-date-cell3">
                {/*<div><QueryStatsIcon color="action" sx={{ "&:hover": { color: "blue" } }} fontSize="small" onClick={(e, d) => this.btn_click_test(e, props)} /></div>*/}
                <div>{this.iconChart(props)}</div>
                <div>{props.label}</div>
                
              </div>
              )
            }
          }}
          tooltipAccessor={(e) => 
            ((e.title) 
          + (e.desc && e.desc.length ? "\n" + e.desc : "")
          + (e.note && e.note.length ? "\n" + e.note : "")
          )
        }
          
        />
        
        {/* Material-ui Modal for booking new appointment */}
        <EventShow open={this.state.openEvent}  event={this.state.clickedEvent} onSave={this.handleEventSave} state={this.state} setState={this.setState.bind(this)} onDelete={this.deleteEvent}/> 
        <EventShow open={this.state.openSlot}  onSave={this.handleEventSave} state={this.state} setState={this.setState.bind(this)} /> 

      </div>
    );
  }
}

export default EventCalendar;
