import React from "react";
import moment from "moment";
import { forEach } from "jszip";
moment.locale("en-GB");

/*
const myEventsList = [
    {    id: 0,    title: "All Day Event very long title",    allDay: true,    start: new Date(2023, 11, 1),    end: new Date(2023, 11, 1)  },
    {    id: 1,    title: "Long Event",   allDay: false, start: new Date(2023, 11, 7),    end: new Date(2023, 11, 11)  },
    {    id: 2,    title: "DTS STARTS",    start: new Date(2023, 11, 13, 12, 0, 0),    end: new Date(2023, 11, 13, 13, 0, 0)  }]
 
*/

export  class Event{
    constructor(id, title, start, end, desc, note){
        this.id = id;
        this.title = title;
        this.start = start;
        this.end = end;
        this.desc = desc;
        this.note = note?note:"";
        
    }
    
    test(){
        return "Event Class Test"
    } 
}

export  class Events{
    MEM_TAG = "EVENTS"
    eventList = [];


    constructor(){
        this.eventList = [];
        //this.addEvent(new Event(1, "Test Event", new Date(2023, 11, 18, 10, 0), new Date(2023, 11, 18, 11, 0)));
        //console.log(this.eventList)
        //this.addEvent(new Event(2, "Test Event 2", new Date(2023, 11, 18, 12, 0), new Date(2023, 11, 18, 13, 0)));
    }
    
    test2(){
        
        console.log("**** TEST ****")
        console.log("Events Class Test")
        alert("Events Class Test")
        return "Events Class Test"
    }

    addEvent(event){
        if (event.id == 0){
            event.id = this.eventList.length + 1;
            
        } 
        
        this.eventList.push(event);
        this.saveToMemory();
    }
    
    deleteEvent(event){
        console.log("Deleting event " + event.id)
        console.log(event)
        //console.log(this.eventList)
        let index = this.eventList.findIndex(x => x.id === event.id);
        //alert(index)
        if (index > -1){
            this.eventList.splice(index, 1);
        }
        this.saveToMemory();
        //console.log(this.eventList)
        //console.log("Deleted event")
        //console.log(event)
    }

    getAllEvents(){
        //console.log("Getting all events")   
        //console.log(this.eventList)
        
        return this.eventList;
    }

    setEventList(eventList){
        this.eventList = eventList;
        //this.saveToMemory();
    }

    getFromJson(json){
        return Object.assign(new Events(), json);
    }

    saveToMemory(){
        localStorage.setItem(this.MEM_TAG, JSON.stringify(this.eventList));
    }

    loadFromMemory(){
        let savedEvents = localStorage.getItem(this.MEM_TAG);
        //console.log(savedEvents)
        
        if (savedEvents){
            this.eventList = JSON.parse(savedEvents);
            for (let i = 0; i < this.eventList.length; i++){
                
                this.eventList[i].start = moment(this.eventList[i].start).toDate();
                this.eventList[i].end = moment(this.eventList[i].end).toDate();
                
            }

        }
    }


    updateEvent(event){
        //console.log("Updating event")
        //console.log(event)
        //console.log(this.eventList)
        for (let i = 0; i < this.eventList.length; i++){
            if (this.eventList[i].id == event.id){
                this.eventList[i] = event;
                this.saveToMemory();
                return;
            }
        }
    }

    toString(){
        return JSON.stringify(this);
    }










}