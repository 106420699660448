import Meal from './MealClass.js';

export default class MealList{
    MEMORY_TAG = "localmeallist"
    MEMORY_FILTER_TAG = "localmeallistfilter"
    
    constructor(){

      this.mealList = [];
      this.idxCurrentMeal = 0;
      this.filter = {}
      this.filter.text = "";
      this.filter.category = "";
      this.filter.favourite = false;
      
    }

    addMeal(meal){
        this.mealList = Object.assign([], this.mealList);
        this.mealList.push(meal);
    }

    modifyMeal(meal){
        let modMeal = this.getMealByID(meal.id);
        Object.assign(modMeal, meal);



    }

    deleteMeal(id){
        
        let idxMeal =  this.mealList.findIndex(x => x.id === id)
        
        this.mealList = Object.assign([], this.mealList);
        
        this.mealList.splice(idxMeal, 1)
        this.saveToMemory();



    }

    setCurrentMeal(id){
        let currentMeal = new Meal();
        let jsonMeal = this.getMealByID(id);
        console.log(jsonMeal)
        currentMeal = currentMeal.getFromJson(jsonMeal);
        console.log(currentMeal)
        
        currentMeal.saveToMemory();
    }

    getNextID(){
        const max = Math.max(...this.mealList.map(o => o.id), 0);
        return max+1
    }

    moveFirst(){
        this.idfCurrentMeal = 0;
    }

    moveNext(){
        this.idfCurrentMeal++;
    }

    getCurrentMeal(increment){
        if (this.mealList && this.idxCurrentMeal < this.mealList.length){
            let iMem = this.idfCurrentMeal;
            if (increment) this.idfCurrentMeal += increment;
            return this.mealList[iMem];
        }
        return undefined;
    }

    getMealByID(id){
        let idxMeal =  this.mealList.findIndex(x => x.id === id)
        return this.mealList[idxMeal];
    }

    getMealList(){
        
        let result =  this.mealList;
        if (this.filter && this.filter.text ){
            result = result.filter((item) => item.name.toLowerCase().includes(this.filter.text));
        }

        if (this.filter && this.filter.category ){
            result = result.filter((item) => item.category===this.filter.category);
        }

        if (this.filter && this.filter.favourite){
            
            result = result.filter((item) => item.fav==true);
        }
        
        return result;    
        
        
        
    }

    setFromStandard(standardList){
        this.mealList = standardList;
    }

    loadFromMemory(){
        let localMealList_json = localStorage.getItem(this.MEMORY_TAG);
        let localMealFilter_json = localStorage.getItem(this.MEMORY_FILTER_TAG);
        if (localMealList_json){
            this.mealList = JSON.parse(localMealList_json);
        }
        
        if (localMealFilter_json){
            this.filter = JSON.parse(localMealFilter_json);
        }


        if (this.mealList && this.mealList.length > 0) return true;
        return false;

    }
    
    saveToMemory() {
        if (!this.mealList) return;
        localStorage.setItem(this.MEMORY_TAG, JSON.stringify(this.mealList));
        localStorage.setItem(this.MEMORY_FILTER_TAG, JSON.stringify(this.filter));
    }

    static resetMemory(){
        localStorage.removeItem(this.MEMORY_TAG);
        localStorage.removeItem(this.MEMORY_FILTER_TAG);
        localStorage.clear();
    }

    initMealList(standardList){
        if (this.loadFromMemory()) return true;
        if (standardList)
            this.setFromStandard(standardList);
        
    }

    hasMeal(){
        if (this.mealList && this.mealList.length > 0) return true;
        return false;

    }

    getCategoryList(){
        if (! this.mealList || !this.mealList.length) return undefined;
        let categoryList = this.mealList
        .map(item=>item.category)
        .filter((value, index, current_value) => current_value.indexOf(value) === index);

        return categoryList;
    }

    setFavourite(id){
        let idxMeal =  this.mealList.findIndex(x => x.id === id)
        if (this.mealList[idxMeal].fav) this.mealList[idxMeal].fav = false;
        else this.mealList[idxMeal].fav = true;
        this.saveToMemory();
    }


    unfilter(){
        this.filter.text = "";
        this.filter.category = "";
        this.filter.favourite = false;
  
    }

    filterByCategory(category){
        this.filter.category = category;
        this.saveToMemory();
    }

    filterByText(text){
        this.filter.text = text;
        this.saveToMemory();
        
    }

    filterByFavourite(onlyFavourite){
        this.filter.favourite = onlyFavourite;
        this.saveToMemory();
    }

    getTextFilter(){
        return this.filter.text;
    }
    
    getCategoryFilter(){
        return this.filter.category;
    }

    getFavouriteFilter(){
        return this.filter.favourite;
    }

}