import React from "react";
import Cookies from 'universal-cookie';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export default class Food{
    id = 0;
    name = "";
    weight = 0;
    cho = 0;
    note = "";
    par = "";
    wpar = 0;
    actual_weight = 0;
    fav=2;
    state = {tab: "0"};
    /*
    wpart1  = 0;
    wpart2  = 0;
    wpart3  = 0;
    wpart4  = 0;
    wpart5  = 0;
    wpart6  = 0;
   */ 
    constructor(){
      this.name = "";
      this.id = 0;
      this.state = {tab: "0"};
    }
  
    getFromJson(json){
      
      return Object.assign(new Food(), json);
    }
    
    getTest(){
      return "FOOD CLASS TEST";
    }

  getCHO(decimal){
    if (!decimal) decimal = 1;
    decimal *= 10;
      return Math.round(this.weight * this.cho / 100 * decimal) / decimal;
   }

   getActualCHO(decimal){
    if (!decimal) decimal = 1;
    decimal *= 10;
      return Math.round(this.actual_weight * this.cho / 100 * decimal) / decimal;
   }

    toString(){
      return JSON.stringify(this);
    }

    loadFromCookies(){
        const cookies = new Cookies();
        let savedFoodList = cookies.get('CURRENT_MEAL_J2');
    
    
        if (savedFoodList){
            return this.getFromJson(savedFoodList);
        }
    }


    saveToCookies(){
        const cookies = new Cookies();
        cookies.set('CURRENT_MEAL_J2', this.toString(), { path: '/' , expires: new Date(Date.now()+2592000)});
    }

    getFood(id, data){
      let foodList =  data.filter((item)=>item.id == id);
      if (foodList.length > 0)
        return Object.assign(new Food(), foodList[0]);
      return this;
      
       
    }
           
    static getFormLabelGrid(dimension, id, label){

    

      return (
        <Grid item xs={dimension}>
          &nbsp;&nbsp;{label}
            
        </Grid>  
      )
    }


    static getFormCheckGrid(dimension, id, label, obj_value, onChangeFun, property, disabled, numeric, multiline){

      

      return (
        <Grid item xs={dimension}>
          <Checkbox 
            id={id}  
            label={label} 
            variant="outlined" fullWidth sx={{ m: 1 }} 
            value={obj_value}  
            
            
            
            
            
            size="small"
            onChange={(e)=>{onChangeFun(e, property)}}
          />
        </Grid>  
      )
    }

    static getFormGrid(dimension, id, label, obj_value, onChangeFun, property, disabled, numeric, multiline){

      let type = "text";
      let rows = 1;
      let value = "";
      

      if (numeric){ type="number"; value=0}
      if (multiline) rows = 2;
      if (obj_value && obj_value[property]) value = obj_value[property];

      return (
        <Grid item xs={dimension}>
          <TextField 
            id={id}  
            label={label} 
            variant="outlined" fullWidth sx={{ m: 1 }} 
            value={value}  
            disabled={disabled}
            type={type}
            inputProps={{step: 0.1 }}
            multiline={multiline}
            rows={rows}
            size="small"
            onChange={(e)=>{onChangeFun(e, property)}}
          />
        </Grid>  
      )
    }

    static getFormImageGrid(dimension, id, label, value, onChangeFun, property, disabled, numeric, multiline){
      const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });

      let type = "text";
      let rows = 1;
      if (numeric) type="number";
      if (multiline) rows = 2;
      return (
        <Grid item xs={dimension}>
         

          
        <Button component="label" variant="contained" startIcon={<AddAPhotoIcon />} fullWidth sx={{ m: 1 }} disabled={disabled}>
        {label}
                <VisuallyHiddenInput type="file" onChange={(e)=>{onChangeFun(e, property)}} id={id} />
          </Button>
        </Grid>  
      )
    }

    static getImage(ImageObj, property, gridSize, imgSize){
      
      
      let imageB64 = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";              //transparent pixel
      let imgWidth = 0
      if (ImageObj && ImageObj[property]){
        imageB64 = ImageObj[property];
        imgWidth = imgSize;
      }
      if (!gridSize) gridSize = 12;
      if (!imgSize) imgSize = 100;
      return (
        <Grid item xs={gridSize} >
          <center><img alt="preview image" style={{ width: imgWidth }} src={ imageB64} /></center>
        </Grid>
      );

    }

    // ██████  ███████ ████████     ███████  ██████  ██████  ███    ███ 
    //██       ██         ██        ██      ██    ██ ██   ██ ████  ████ 
    //██   ███ █████      ██        █████   ██    ██ ██████  ██ ████ ██ 
    //██    ██ ██         ██        ██      ██    ██ ██   ██ ██  ██  ██ 
    // ██████  ███████    ██        ██       ██████  ██   ██ ██      ██ 
    /**
     * Renders a button to upload an image for the food item, 
     * and displays a preview of the uploaded image.
     * 
     * The VisuallyHiddenInput allows selecting a file for upload without 
     * showing the default file input.
     * 
     * The image preview displays the base64-encoded image data from the state.
     */
  //static getForm(food, handleOnchange, handleOnchangeImg, setImage, imageB64, imgB64_w1, imgB64_w2, imgB64_w3) {
    static getForm(food, handleOnchange, handleOnchangeImg,  imageObj, tab) {
    //alert("get form " + food.name)
    //let tab = "0";
    const VisuallyHiddenInput = styled('input')({
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
      bottom: 0,
      left: 0,
      whiteSpace: 'nowrap',
      width: 1,
    });

    let id_label = "";
    if (food && food.id >= 0) {
      id_label = "[" + food.id + "]";
      let f = new Food();

    }

    //1x1 transparent pixel
    const noImage = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="; 
    return (
      <div>
        <FormControl>
          <Grid container spacing={0} direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item xs={10}><h2>PIATTO</h2></Grid>

            {Food.getFormGrid(2, "food_id", "ID", food, handleOnchange, 'id', true)}
            {Food.getFormGrid(7, "food_name", "Nome", food, handleOnchange, 'name')}

            {Food.getFormGrid(5, "food_category", "Categoria", food, handleOnchange, 'cat')}

            {Food.getFormGrid(7, "food_cho", "CHO100", food, handleOnchange, 'cho', false, true)}
            {Food.getFormGrid(5, "food_weight", "Peso suggerito", food, handleOnchange, 'weight', false, true)}

            <Box sx={{ width: '100%' }}>
              <Tabs
                value={tab}
                onChange={(e, newValue)=>{handleOnchange(e, newValue, 'TAB')}}
                aria-label="wrapped label tabs example"
                variant="fullWidth"
              >
                <Tab value={0} label="Dettagli"  />
                <Tab value={1} label="Porzioni" id="tab1"/>
                <Tab value={2} label="Foto" />
                
              
              </Tabs>
              
              <div role="tabpanel" hidden={tab !== 0} id="tab0"> 
              
                <Grid container spacing={0} direction="row" justifyContent="flex-end" alignItems="center">
                {Food.getFormGrid(4, "food_ENc", "KCal.", food, handleOnchange, 'ENc', false, true)}
                {Food.getFormGrid(4, "food_pro", "Pro.", food, handleOnchange, 'pro', false, true)}
                {Food.getFormGrid(4, "food_fat", "Fat.", food, handleOnchange, 'fat', false, true)}

                {Food.getFormGrid(4, "food_sug", "Zucc.", food, handleOnchange, 'sug', false, true)}
                {Food.getFormGrid(4, "food_col", "Col.", food, handleOnchange, 'col', false, true)}
                {Food.getFormGrid(4, "food_fib", "Fib.", food, handleOnchange, 'fib', false, true)}


                {Food.getFormGrid(4, "food_min", "P.Min", food, handleOnchange, 'min', false, true)}
                {Food.getFormGrid(4, "food_max", "P.Max", food, handleOnchange, 'mix', false, true)}
                {Food.getFormGrid(4, "food_step", "Peso Steps", food, handleOnchange, 'step', false, true)}

                {Food.getFormGrid(12, "food_note", "Note", food, handleOnchange, 'note', false, false, true)}
                {Food.getFormGrid(12, "food_tag", "Nomi alternativi (per ricerca)", food, handleOnchange, 'tag', false, false, true)}            



                </Grid>
              </div>

{/****************  PORZIONI ***************************/ }              
              <div role="tabpanel" hidden={tab !== 1} id="tab1">  
                <Grid container spacing={0} direction="row" justifyContent="flex-end" alignItems="center">
                {/*Food.getFormGrid(7, "food_part", "Nome Porzione", food, handleOnchange, 'part',)*/}
                {Food.getFormGrid(4, "food_wpart1", "Peso fetta", food, handleOnchange, 'wpart1', false, true)}
                {Food.getFormGrid(4, "food_wpart2", "Peso spicchio", food, handleOnchange, 'wpart2', false, true)}
                {Food.getFormGrid(4, "food_wpart4", "Peso pezzo", food, handleOnchange, 'wpart4', false, true)}                

                {Food.getFormImageGrid(4, "img_p1", "Fetta", food, handleOnchangeImg, 'img_p1', food.wpart1?false:true, true)}
                {Food.getFormImageGrid(4, "img_p2", "Spicchio", food, handleOnchangeImg, 'img_p2', food.wpart2?false:true, true)}
                {Food.getFormImageGrid(4, "img_p4", "Pezzo", food, handleOnchangeImg, 'img_p4', food.wpart4?false:true, true)}

                {this.getImage(imageObj, "img_p1", 4, 100)}
                {this.getImage(imageObj, "img_p2", 4, 100)}
                {this.getImage(imageObj, "img_p4", 4, 100)}

                {Food.getFormGrid(4, "food_wpart3", "Peso chicco", food, handleOnchange, 'wpart3', false, true)}
                {Food.getFormGrid(4, "food_wpart5", "Peso intero", food, handleOnchange, 'wpart5', false, true)}
                {Food.getFormGrid(4, "food_wpart6", "Peso porzione", food, handleOnchange, 'wpart6', false, true)}

                {Food.getFormImageGrid(4, "img_p3", "Chicco", food, handleOnchangeImg, 'img_p3', food.wpart3?false:true, true)}
                {Food.getFormImageGrid(4, "img_p5", "Intero", food, handleOnchangeImg, 'img_p5', food.wpart4?false:true, true)}
                {Food.getFormImageGrid(4, "img_p6", "Porzione", food, handleOnchangeImg, 'img_p6', food.wpart6?false:true, true)}

                {this.getImage(imageObj, "img_p3", 4, 100)}
                {this.getImage(imageObj, "img_p5", 4, 100)}
                {this.getImage(imageObj, "img_p6", 4, 100)}

                </Grid>
              </div>
{/**************** NOTE  ***************************/ }                            
              <div role="tabpanel" hidden={tab !== 2} id="tab2"> 
                <Grid container spacing={0} direction="row" justifyContent="flex-end" alignItems="center">
{/****************  FOTO ***************************/ }                              
<Grid item xs={12}>
              <Button component="label" variant="contained" startIcon={<AddAPhotoIcon />} fullWidth sx={{ m: 1 }}>
                FOTO PIATTO
                <VisuallyHiddenInput type="file" onChange={(e)=>{handleOnchangeImg(e, "img")}}  id={"food_image"} />
              </Button>
              {this.getImage(imageObj, "img", 12, 200)}
            </Grid>

            <Grid item xs={12} > &nbsp; &nbsp;Foto  Grammatura (non obbligatorie):</Grid>

            {Food.getFormGrid(4, "img1_tag", "Peso 1", imageObj, handleOnchangeImg, 'img1_tag', false, true)}
            {Food.getFormGrid(4, "img1_tag", "Peso 2", imageObj, handleOnchangeImg, 'img2_tag', false, true)}
            {Food.getFormGrid(4, "img1_tag", "Peso 3", imageObj, handleOnchangeImg, 'img3_tag', false, true)}
            
            {Food.getFormImageGrid(4, "img_x1", "Foto", food, handleOnchangeImg, 'img_x1', false, true)}
            {Food.getFormImageGrid(4, "img_x2", "Foto", food, handleOnchangeImg, 'img_x2', false, true)}
            {Food.getFormImageGrid(4, "img_x3", "Foto", food, handleOnchangeImg, 'img_x3', false, true)}

            <></>
            {this.getImage(imageObj, "img_x1", 4, 100)}
            {this.getImage(imageObj, "img_x2", 4, 100)}
            {this.getImage(imageObj, "img_x3", 4, 100)}                  
                </Grid>
              </div>
            </Box>

            {/*Food.getFormGrid(12, "food_time", "image time", food, handleOnchange, 'imageTime', false, true)*/}

                                                                            




          </Grid>
        </FormControl>

      </div>

    );
  }


  }

