import * as React from 'react';
import { Container, Grid, Paper, useMediaQuery, useTheme, Button, Link } from '@mui/material';

import DocMenu from './DocMenu';

export default function Doc() {
    return (
       
            <Container component="main" maxWidth="xl">
                    
                      
            
                <Grid container spacing={2} className='homeGrid'>
                    
                    
                    
                        
                            <Grid item xs={2}>
                            <DocMenu current="doch11" />
                            </Grid>
                            <Grid item xs={10}>
                            <Paper elevation={1} >
                            <div className='docs'>
                            <h1>Come Vengono Create le Tabelle Nutrizionali</h1>

<p>Le tabelle nutrizionali sono strumenti informativi essenziali per comprendere la composizione nutrizionale degli alimenti che consumiamo. La creazione di queste tabelle è un processo accurato e regolamentato che coinvolge l'analisi dettagliata dei nutrienti presenti negli alimenti. Ecco un'analisi dettagliata su come vengono create le tabelle nutrizionali.</p>

<h2>1. Analisi Nutrizionale degli Alimenti</h2>

<p>La creazione di una tabella nutrizionale inizia con un'analisi nutrizionale approfondita degli alimenti. Gli alimenti vengono scomposti nei loro componenti fondamentali, tra cui carboidrati, proteine, grassi, fibre, zuccheri, vitamine e minerali. Questo processo coinvolge tecniche di laboratorio avanzate, come la spettroscopia e la cromatografia, che consentono di determinare la quantità precisa di ciascun nutriente.</p>

<h2>2. Standardizzazione delle Dimensioni delle Porzioni</h2>

<p>Per garantire la comparabilità tra alimenti, le dimensioni delle porzioni devono essere standardizzate. Le autorità normative stabiliscono le linee guida per le dimensioni delle porzioni, e queste sono utilizzate per calcolare il contenuto nutritivo per unità di misura standard, come 100 grammi o una tazza.</p>

<h2>3. Calcolo delle Calorie e dei Nutrienti</h2>

<p>Una volta ottenuti i dati sull'analisi nutrizionale e standardizzate le dimensioni delle porzioni, vengono calcolate le calorie e i nutrienti per ogni porzione. Questo coinvolge la moltiplicazione della quantità di ciascun nutriente per il numero di porzioni standard nella dimensione della porzione specificata.</p>

<h2>4. Inclusione delle Informazioni Obbligatorie</h2>

<p>Le tabelle nutrizionali devono contenere informazioni obbligatorie come la dimensione della porzione, le calorie totali, i carboidrati, le proteine, i grassi, le fibre, gli zuccheri e, quando richiesto, le vitamine e i minerali. Queste informazioni sono organizzate in una forma standardizzata per facilitare la comprensione da parte dei consumatori.</p>

<h2>5. Dichiarazioni Aggiuntive e Opzionali</h2>

<p>Alcune tabelle nutrizionali includono dichiarazioni aggiuntive, come le percentuali del fabbisogno giornaliero basate su una dieta media di 2000 calorie. Inoltre, possono essere fornite informazioni aggiuntive sugli zuccheri aggiunti, allergeni, o le percentuali giornaliere raccomandate di vitamine e minerali.</p>

<h2>6. Adempimento alle Normative Locali e Globali</h2>

<p>Le tabelle nutrizionali devono conformarsi alle normative locali e globali sulla dichiarazione nutrizionale degli alimenti. Organizzazioni come il Codex Alimentarius, l'Unione Europea e la Food and Drug Administration (FDA) negli Stati Uniti stabiliscono regole specifiche che devono essere seguite.</p>

<h2>7. Revisione e Approvazione</h2>

<p>Prima di essere stampate sugli imballaggi degli alimenti, le tabelle nutrizionali sono soggette a revisione e approvazione. Gli esperti in nutrizione, scienziati alimentari e professionisti del settore assicurano l'accuratezza e la conformità alle normative vigenti.</p>

<h2>Conclusioni</h2>

<p>La creazione delle tabelle nutrizionali è un processo rigoroso che coinvolge analisi scientifiche dettagliate e la conformità a normative specifiche. Queste tabelle forniscono informazioni chiare e dettagliate sulla composizione nutrizionale degli alimenti, consentendo ai consumatori di prendere decisioni informate sulla propria alimentazione e promuovendo uno stile di vita sano e consapevole.</p>

                            
                            </div>
                            </Paper>
                            </Grid>
                        
                        
                    
                </Grid>
            </Container>

    )
}