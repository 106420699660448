import React from "react";
import { createContext, useState, useEffect } from "react";
import * as auth from "./auth";
import { cognitoConfig } from "./cognitoConfig";

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getCurrentUser = async () => {
//    console.log("get current user");
    try {
      const user = await auth.getCurrentUser();
      
      
      
      setUser(user);
    } catch (err) {
      // not logged in
      console.log(err);
      setUser(null);
    }
  };

  useEffect(() => {
    //console.log("**********************************************")
    getCurrentUser()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  const signIn = async (username, password) => {
    //debugger;
    let data = await auth.signIn(username, password);
    
    

    
  };
  const signOut = async () => {
    
    await auth.signOut();
    setUser(null);
  };

  const getSession = async()=>{
    return await auth.getSession();
  }

  // ██████  ███████ ████████     ██    ██  █████  ██      ██    ██ ███████ 
  //██       ██         ██        ██    ██ ██   ██ ██      ██    ██ ██      
  //██   ███ █████      ██        ██    ██ ███████ ██      ██    ██ █████   
  //██    ██ ██         ██         ██  ██  ██   ██ ██      ██    ██ ██      
  // ██████  ███████    ██          ████   ██   ██ ███████  ██████  ███████ 
  const getValue = async (key, subKey) => {
    let jvalue;
    try{
      let session = await getSession();
      let my_atoken = session.getAccessToken().getJwtToken();

      const URL = "https://ypcdl7dr4e.execute-api.eu-west-1.amazonaws.com/v1/"
      const headers = { 'Authorization': 'Bearer '  +  my_atoken };
      let response = await fetch(URL + "user?q=" + key+"&d="+subKey, { headers })
      let jvalue = await response.json();
          //console.log('getvalue->**RESPONSE');
          //console.log(response);
          //console.log('getvalue->', jvalue);
          return jvalue;
          //console.log('getvalue->returned');
    }
    catch (err) {console.log('getvalue->ERROR', err);}
    return jvalue;
  }
                                                                                                                                   

//███████ ███████ ████████     ██    ██  █████  ██      ██    ██ ███████ 
//██      ██         ██        ██    ██ ██   ██ ██      ██    ██ ██      
//███████ █████      ██        ██    ██ ███████ ██      ██    ██ █████   
//     ██ ██         ██         ██  ██  ██   ██ ██      ██    ██ ██      
//███████ ███████    ██          ████   ██   ██ ███████  ██████  ███████ 
  const setValue = async (key, userdata, subKey) =>{
    console.log("*** set value 1 " + key + " " + subKey)  
    console.log(userdata)
    let session = await getSession();
    let my_atoken = session.getAccessToken().getJwtToken();

    const URL = "https://ypcdl7dr4e.execute-api.eu-west-1.amazonaws.com/v1/"
    
    //user = user.getFromJson(userdata);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '  +  my_atoken },
      body: JSON.stringify(userdata)
    };
    console.log("*** set value 2 " + key + " " + subKey)
    console.log(requestOptions)
    console.log (userdata)
    let response = await fetch(URL + "user?q=" + key+"&d=" + subKey, requestOptions)
    console.log("*** set value 3 " + key + " " + subKey)    
    //alert("wait");
    
  }

  const authValue = {
    user,
    isLoading,
    signIn,
    signOut,
    getCurrentUser,
    getValue, setValue,
    getSession
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
