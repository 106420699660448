import Food from './FoodClass.js';

export default class FoodList{
    MEMORY_TAG = "localfoodlist"
    MEMORY_FILTER_TAG = "localfoodlistfilter"
    MEMORY_IMAGE_TAG = "fimg"
    MEMORY_NEWIMAGE_TAG = "fimg_new"
    
    constructor(){

      this.foodList = [];
      this.idxCurrentFood = 0;
      this.filter = {}
      this.filter.text = "";
      this.filter.category = "";
      this.filter.favourite = false;
      
    }

    addFood(food){
        this.foodList = Object.assign([], this.foodList);
        this.foodList.push(food);
    }

    modifyFood(food){
        let modFood = this.getFoodByID(food.id);
        Object.assign(modFood, food);



    }

    deleteFood(food){
        let idxFood =  this.foodList.findIndex(x => x.id === food.id)
        
        this.foodList = Object.assign([], this.foodList);
        
        this.foodList.splice(idxFood, 1)
        this.saveToMemory();
    }

    copyFood(food){
        let modFood = new Food();
        Object.assign(modFood, food);
        modFood.id = this.getNextID();
        this.addFood(modFood);
        return modFood.id;
    }

    getNextID(){
        let max = Math.max(...this.foodList.map(o => o.id), 0);
        if (max < 1000) max= 1000;


        return max+1
    }

    moveFirst(){
        this.idfCurrentFood = 0;
    }

    moveNext(){
        this.idfCurrentFood++;
    }

    getCurrentFood(increment){
        if (this.foodList && this.idxCurrentFood < this.foodList.length){
            let iMem = this.idfCurrentFood;
            if (increment) this.idfCurrentFood += increment;
            return this.foodList[iMem];
        }
        return undefined;
    }

    getFoodByID(id){
        let idxFood =  this.foodList.findIndex(x => x.id == id)
        return this.foodList[idxFood];
    }


    getAllFoodList(){
        let result =  this.foodList;
        //result.forEach(e => e.id= 'S'+ e.id);
        //console.log("*** ALL FOOD")
        //console.log(result)
        return result;
    }

    getAllStandardFoodList(){
        let result =  this.foodList;
        result = result.filter((item) => item.id < 1000);
        return result;
    }

    getAllUserFoodList(){
        let result =  this.foodList;
        result = result.filter((item) => item.id > 1000);
        return result;
    }

    getFoodList(){
        
        let result =  this.foodList;
        if (this.filter && this.filter.text ){
            result = result.filter((item) => item.name.toLowerCase().includes(this.filter.text));
        }

        if (this.filter && this.filter.category ){
            result = result.filter((item) => item.cat===this.filter.category);
        }

        if (this.filter && this.filter.favourite){
            
            result = result.filter((item) => item.fav > 0);
        }
        
        return result;    
        
        
        
    }

    setFromStandard(standardList){
        this.foodList = standardList;
    }

    loadFromMemory(){
        let localFoodList_json = localStorage.getItem(this.MEMORY_TAG);
        let localFoodFilter_json = localStorage.getItem(this.MEMORY_FILTER_TAG);
        if (localFoodList_json){
            this.foodList = JSON.parse(localFoodList_json);
        }
        
        if (localFoodFilter_json){
            this.filter = JSON.parse(localFoodFilter_json);
        }


        if (this.foodList && this.foodList.length > 0) return true;
        return false;

    }
    
    refreshImageTime(food){
        this.getFoodByID(food.id).imageTime =  Date.now();
    }

    saveImageToMemory(food, B64Image){

        this.refreshImageTime(food)                                                                                     //refresh in food list
        
        localStorage.setItem(this.MEMORY_IMAGE_TAG  + "_" + food.id, B64Image); 
        let newImageList = localStorage.getItem(this.MEMORY_NEWIMAGE_TAG);
        if (!newImageList) newImageList = [];
        else newImageList = JSON.parse(newImageList);
        newImageList.push(food.id);
        //debugger;
        localStorage.setItem(this.MEMORY_NEWIMAGE_TAG, JSON.stringify(newImageList));
    }

    getImageFromMemory(food, food_id){
        if (food)
            return localStorage.getItem(this.MEMORY_IMAGE_TAG  + "_" + food.id);
        if (food_id)
            return localStorage.getItem(this.MEMORY_IMAGE_TAG  + "_" + food_id);
    }

    clearNewImageList(){
        let newImageList = [];
        localStorage.setItem(this.MEMORY_NEWIMAGE_TAG, JSON.stringify(newImageList));

    }

    getNewImageList(){
        let newImageList = localStorage.getItem(this.MEMORY_NEWIMAGE_TAG);
        if (!newImageList) return null
        else newImageList = JSON.parse(newImageList);
        return newImageList;
    }

    saveToMemory() {
        if (!this.foodList) return;
        localStorage.setItem(this.MEMORY_TAG, JSON.stringify(this.foodList));
        localStorage.setItem(this.MEMORY_FILTER_TAG, JSON.stringify(this.filter));
    }

    static resetMemory(){
        localStorage.removeItem(this.MEMORY_TAG);
        localStorage.removeItem(this.MEMORY_FILTER_TAG);
        localStorage.clear();
    }

    initFoodList(standardList){
        //console.log("initFoodList");
        if (this.loadFromMemory()) return true;
        else this.setFromStandard(standardList);
    }

    hasFood(){
        if (this.foodList && this.foodList.length > 0) return true;
        return false;

    }

    getCategoryList(){
        if (! this.foodList || !this.foodList.length) return undefined;
        //console.log(this.foodList);
        let categoryList = this.foodList
        .map(item=>item.cat)
        .filter((value, index, current_value) => current_value.indexOf(value) === index);

        return categoryList;
    }


    setFavouriteVal(id, val){
        let idxFood =  this.foodList.findIndex(x => x.id == id)
        
        this.foodList[idxFood].fav = val;
        this.saveToMemory();
    }


    unfilter(){
        this.filter.text = "";
        this.filter.category = "";
        this.filter.favourite = false;
  
    }

    filterByCategory(category){
        this.filter.category = category;
        this.saveToMemory();
    }

    filterByText(text){
        this.filter.text = text;
        this.saveToMemory();
        
    }

    filterByFavourite(onlyFavourite){
        this.filter.favourite = onlyFavourite;
        this.saveToMemory();
    }

    getTextFilter(){
        return this.filter.text;
    }
    
    getCategoryFilter(){
        return this.filter.category;
    }

    getFavouriteFilter(){
        return this.filter.favourite;
    }

}