import React from 'react';
import { Container, Grid, Paper, useMediaQuery, useTheme, Button, Link } from '@mui/material';

const TermsOfUse = () => {
  return (
    <Container component="main" maxWidth="lg">

<div>
       <h2>Termini di utilizzo per Alexa Skill</h2>
       <h3>Generale</h3>
       <p>Le presenti Condizioni d'uso costituiscono un accordo tra te (l'"utente") e Conta dei carboidrati ("noi"). 
        Prima di utilizzare una qualsiasi delle nostre Skill Alexa ("le nostre Skill"), leggi il presente accordo e la nostra <a href="/privacyalexa">Informativa sulla privacy di Alexa Skills</a>, 
        nonché i <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201809740">Termini di utilizzo </a>di Alexa da parte di Amazon Digital Services LLC (con le sue affiliate, "Amazon").</ p>
       <p>Per quanto riguarda Amazon, "Alexa" indica il servizio vocale Alexa che include servizi di terze parti (come le nostre competenze) e altri software correlati.</p>
       <p>Se utilizzi una delle nostre competenze, accetti pienamente i presenti Termini di utilizzo.</p>
       <h3>Esonero di responsabilità</h3>
       <p>Le nostre competenze o parti di esse potrebbero essere interrotte in qualsiasi momento o potrebbero contenere errori. Utilizzi le nostre competenze sotto la tua responsabilità. Qualsiasi informazione non deve essere vista come un consiglio.</p>
       <h3>Modifiche</h3>
       <p>Le nostre competenze o parti di esse potrebbero cambiare o essere aggiornate in qualsiasi momento. Inoltre, i nostri Termini di utilizzo potrebbero cambiare. Puoi trovare un collegamento a questo accordo nella pagina di descrizione della competenza nell'App Alexa o nell'Alexa Store. L'uso continuato della nostra competenza dopo le modifiche ai Termini di utilizzo o alla competenza stessa sarà visto come la tua accettazione di entrambi.</p>
       <p>Ci piace sviluppare le Skill Alexa per te. E amiamo migliorare le cose sviluppando prodotti e servizi digitali.</p>
     </div>
     </Container>
  );
};

export default TermsOfUse;
