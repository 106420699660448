import Resizer from "react-image-file-resizer";


export default class Image{
    MEMORY_IMAGE_TAG = "img";
    MEMORY_IMAGE_LC_TAG = "imglc";

    category = "";
    id = "";
    img = "";
    img_x1 = "";
    img_x2 = "";
    img_x3 = "";
    img1_tag = 0;
    img2_tag = 0;
    img3_tag = 0;

    lc_newImageList = [];   //local cache of new images
    REV = 0;

    constructor(){
        
        this.id = "";
        this.category = "";
        this.imageTime =  0;
        this.img = "";
    }

    test(){
        localStorage.setItem(this.MEMORY_IMAGE_LC_TAG +"_food", '["1001","1002","1005"]');
    }

    setCategory(category){
        this.category= category;
    }

    refreshImageTime(){
        this.imageTime =  Date.now();
    }

    getTime(){
        return this.imageTime;
    }

    //save list of new image as local cache
    addNewImage_lc(id,  category){
        //console.log("*** add new image to local cache ***" + id);
        this.lc_newImageList = localStorage.getItem(this.MEMORY_IMAGE_LC_TAG +"_"+ category); 
        if (this.lc_newImageList === "") this.lc_newImageList  = [];
        else this.lc_newImageList = JSON.parse(this.lc_newImageList);
        //debugger
        if (!this.lc_newImageList) this.lc_newImageList  = [];
        if ( this.lc_newImageList.includes(id)) return;
        this.lc_newImageList.push(id);
        //console.log(this.lc_newImageList);
        localStorage.setItem(this.MEMORY_IMAGE_LC_TAG +"_"+ category, JSON.stringify(this.lc_newImageList)); 
    }

    getNextImage_lc(category){
        
        this.lc_newImageList = localStorage.getItem(this.MEMORY_IMAGE_LC_TAG +"_"+ category); 
        this.lc_newImageList = JSON.parse(this.lc_newImageList);
        
        let result = this.lc_newImageList.pop();
        localStorage.setItem(this.MEMORY_IMAGE_LC_TAG +"_"+ category, JSON.stringify(this.lc_newImageList)); 
        //console.log("*** getNextImage_lc " + result )
        this.getImageFromMemory(result, category);
        return result;
        
    }

    saveToMemoryObj(id,  category, oimg){
        
        if (id) this.id = id;
        if (category) this.category = category;
        if ((oimg) && (oimg.img))  this.img = oimg.img
        if ((oimg) && (oimg.img_x1)) this.img_x1 = oimg.img_x1
        if ((oimg) && (oimg.img_x2))  this.img_x2 = oimg.img_x2
        if ((oimg) && (oimg.img_x3))  this.img_x3 = oimg.img_x3

        if ((oimg) && (oimg.img1_tag))  this.img1_tag = oimg.img1_tag
        if ((oimg) && (oimg.img2_tag))  this.img2_tag = oimg.img2_tag        
        if ((oimg) && (oimg.img3_tag))  this.img3_tag = oimg.img3_tag
        
    
    
        this.refreshImageTime();
        localStorage.setItem(this.MEMORY_IMAGE_TAG +"_"+ this.category + "_" + this.id, JSON.stringify(this)); 
        this.addNewImage_lc(this.id, this.category)
    }

    saveToMemory(id,  category, img){
        
        if (id) this.id = id;
        if (category) this.category = category;
        if (img){
             this.img = img;
             this.refreshImageTime()   
        }
        
        
        localStorage.setItem(this.MEMORY_IMAGE_TAG +"_"+ this.category + "_" + this.id, JSON.stringify(this)); 
        this.addNewImage_lc(this.id, this.category)
    }

    getImageFromMemory(id, category){
        console.log("*** get image from memory ***" + id);
        let jobj = ""
        if (category) this.category = category;
        
        if (id)
            jobj = localStorage.getItem(this.MEMORY_IMAGE_TAG +"_"+ category + "_" + id);
        console.log(jobj)
            //jobj = JSON.parse(jobj)
        
        if (jobj){
            this.img = JSON.parse(jobj).img;
            this.img_x1 = JSON.parse(jobj).img_x1;
            this.img_x2 = JSON.parse(jobj).img_x2;
            this.img_x3 = JSON.parse(jobj).img_x3;

            this.img1_tag = JSON.parse(jobj).img1_tag;
            this.img2_tag = JSON.parse(jobj).img2_tag;
            this.img3_tag = JSON.parse(jobj).img3_tag

            this.imageTime = JSON.parse(jobj).imageTime;
            this.id = JSON.parse(jobj).id;
            this.category = JSON.parse(jobj).category;  
            return this;
        }
        console.log("*** get image from memory: undefined***");
        return undefined
    }

    getImageFromJSON(jobj){
        
        if (jobj){
            jobj = JSON.parse(jobj);
            this.img = jobj.img;
            this.img_x1 = jobj.img_x1;
            this.img_x2 = jobj.img_x2;
            this.img_x3 = jobj.img_x3;

            this.img1_tag = jobj.img1_tag;
            this.img2_tag = jobj.img2_tag;
            this.img3_tag = jobj.img3_tag


            

            this.imageTime = jobj.imageTime;
            this.id = jobj.id;
            this.category = jobj.category;
            return this;
        }
        return undefined
    }

    /**
     * 
     * @param {*} externalTime 
     * @return true if extrernalTime is newer than imageTime
     */
    getIsNew(externalTime){
        console.log("*** getIsNew ***" + externalTime + " " + this.imageTime);
        return (externalTime > this.imageTime);
    }


    resize(imgtype, width, height){
      

        if (!width) width = 100;
        if (!height) height = 100;


    }

}