import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import { Link, useNavigate } from "react-router-dom";


import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

import Tooltip from '@mui/material/Tooltip';

//ICONS
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Login from '@mui/icons-material/Login';
import HomeIcon from '@mui/icons-material/Home';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { PropaneSharp } from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';


import Icon from '@mui/material/Icon';
import SvgIcon from '@mui/material/SvgIcon';

//DRAWER MENU
import PropTypes from 'prop-types';


import CssBaseline from '@mui/material/CssBaseline';

import Drawer from '@mui/material/Drawer';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';





import {ImportExcelMenu, ExportExcelMenu} from './lib/importexport.js'

import FoodList from './classes/FoodListClass.js';

import { AuthContext } from "./Auth/AuthContext"
import { Calendar } from 'react-big-calendar';


import { useTranslation } from 'react-i18next';


const drawerWidth = 240;

let menustate = false;

function TemporaryDrawer(props) {
  const { t, i18n } = useTranslation();
  
  //if (props.mystate ){setState(true);}
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    console.log(event)
    props.setDrawerState(false);
    
  };

  const resetMemory =   (event) => {
    FoodList.resetMemory();
  };

  const menuEventExportFood =   (event) => {
    console.log(event)
  };

  const menuEventImportFood =   (event) => {
    console.log(event)
  };

  const RouteMenuItem = (props) => {
    
    
    return (

      <ListItem key={props.key} disablePadding>
      <ListItemButton component={Link} to={props.link}>
        <ListItemIcon>
        
        {props.icon}
        </ListItemIcon>
        <ListItemText primary={props.label} />
      </ListItemButton>
    </ListItem> 
    );
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <RouteMenuItem label="Home" key="home" link="" icon={<HomeIcon />   }/>      
        <RouteMenuItem label="Documentation" key="doc" link="doc01" icon={<ArticleIcon />   }/>      
      </List>
      <Divider />
      <List>
        <RouteMenuItem label={t("menu.disces")} key="piatti" link="foods" icon={<FoodBankIcon />   }/>      
        <RouteMenuItem label={t("menu.menu")} key="menu" link="meals" icon={<LocalDiningIcon />   }/>      
        <RouteMenuItem label={t("menu.current")} key="current" link="current" icon={<DinnerDiningIcon />  }/>
        <RouteMenuItem label={t("menu.calendar")} key="calendar" link="calendar" icon={<CalendarMonthIcon />   }/>
      </List>
      <Divider />
      <List>
        <RouteMenuItem label={t("values")} key="cgm" link="cgm" icon={<ShowChartIcon />   }/>      
      </List>

      <Divider />
      <List>
      <RouteMenuItem label="Import & Export" key="Export" link="export" icon={<ImportExportIcon /> }/>
        <ListItem key={'reset'} disablePadding>
          <ListItemButton onClick={resetMemory}>
            <ListItemIcon>
              <AutoDeleteIcon  /> 
            </ListItemIcon>
            <ListItemText primary={'Reset memory'} />
          </ListItemButton>
        </ListItem>

      </List>
    </Box>
  );

  return (
    <div>
      
        <React.Fragment key={'left'}>
          
          <Drawer
            anchor={'left'}
            open={props.mystate}
            onClose={toggleDrawer('left', false)}
          >
            {list('left')}
          </Drawer>
        </React.Fragment>
      
    </div>
  );
}



function AccountMenu({userData}) {
  const navigate = useNavigate();
  const { user, signOut } = React.useContext(AuthContext)
  const { t, i18n } = useTranslation();
//  console.log("*** USER ***");
//  console.log(user);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    
    setAnchorEl(event.currentTarget);

  };
  const handleClose = (e, d) => {
    //console.log("**** MENU ***")
    
    //console.log(d);
    setAnchorEl(null);


  };

  const getMenuItem = (label, icon, onEvent) => {
    return (
      <MenuItem onClick={(e)=>onEvent(e)}>
        <ListItemIcon>{icon}</ListItemIcon>
        {label}
      </MenuItem>

    )

  }

  const fields = [];
  //console.log(user)
  
  if (user != null  )
  
    return (
      <React.Fragment>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>

          <Button  onClick={()=>i18n.changeLanguage('it')}>
          <SvgIcon >
            <svg width="512" height="336" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg">
              <g fill="none">
                <path d="M170.667 335.724H8.828A8.829 8.829 0 0 1 0 326.896V9.103A8.829 8.829 0 0 1 8.828.275h161.839v335.449z" fill="#73AF00"/>
                <path fill="#F5F5F5" d="M170.67.276h170.67v335.448H170.67z"/>
                <path d="M503.172 335.724H341.333V.276h161.839A8.829 8.829 0 0 1 512 9.104v317.793a8.828 8.828 0 0 1-8.828 8.827z" fill="#FF4B55"/>
              </g>
            </svg>
          </SvgIcon>
          </Button>

          <Button  onClick={()=>i18n.changeLanguage('en')}>
          <SvgIcon >
            <svg width="512" height="336" viewBox="0 0 512 336" xmlns="http://www.w3.org/2000/svg">
              <g fill="none">
                <path d="M503.172 335.725H8.828A8.829 8.829 0 0 1 0 326.897V9.104A8.829 8.829 0 0 1 8.828.276h494.345a8.829 8.829 0 0 1 8.828 8.828v317.793a8.83 8.83 0 0 1-8.829 8.828z" fill="#41479B"/>
                <path d="M512 9.104a8.829 8.829 0 0 0-8.828-8.828h-39.495l-163.54 107.147V.276h-88.276v107.147L48.322.276H8.828A8.829 8.829 0 0 0 0 9.104v22.831l140.309 91.927H0v88.276h140.309L0 304.066v22.831a8.829 8.829 0 0 0 8.828 8.828h39.495l163.54-107.147v107.147h88.276V228.578l163.54 107.147h39.495a8.829 8.829 0 0 0 8.828-8.828v-22.831l-140.309-91.927H512v-88.276H371.691L512 31.935V9.104z" fill="#F5F5F5"/>
                <g fill="#FF4B55">
                  <path d="M512 141.518H282.483V.276h-52.966v141.242H0v52.965h229.517v141.242h52.966V194.483H512z"/>
                  <path d="M178.948 212.138L.25 328.135c.625 4.263 4.14 7.59 8.577 7.59h12.159l190.39-123.587-32.428.001v-.001zm167.44 0H313.96l190.113 123.404c4.431-.472 7.928-4.09 7.928-8.646v-7.258l-165.613-107.5zM0 18.849l161.779 105.014h32.428L5.143 1.137C2.123 2.54 0 5.555 0 9.104v9.745zm332.566 105.014L511.693 7.586c-.744-4.122-4.184-7.309-8.521-7.309h-12.647L300.138 123.863h32.428z"/>
                </g>
              </g>
            </svg>
          </SvgIcon>
          </Button>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ width: 32, height: 32, bgcolor: userData != null ? '#ff0000' : '#909090' }}>{user.email[0].toLocaleUpperCase()}</Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >

          {getMenuItem(t("profile"), <Avatar />, (e) => { navigate("/user-profile") })}
          {getMenuItem(t("myaccount"), <Avatar />, handleClose)}
          <Divider />
          {/*getMenuItem(t("Add another account"), <PersonAdd fontSize="small" />, handleClose)*/}
          {getMenuItem(t("settings"), <Settings fontSize="small" />, handleClose)}
          {getMenuItem(t("logout"), <Logout fontSize="small" />, (e) => { navigate("/sign-out") })}


        </Menu>
      </React.Fragment>
    );


  else 
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Typography sx={{ minWidth: 100 }}>Contact</Typography>
        <Typography sx={{ minWidth: 100 }}>Profile</Typography>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >


        {getMenuItem("Register", <Avatar fontSize="small" />, (e)=>{navigate("/sign-up")})}
        {getMenuItem("Login", <Login fontSize="small" />, (e)=>{navigate("/sign-in")})}
      </Menu>
    </React.Fragment>
  );
}



export default function ButtonAppBar({userData}) {
    const [drawerState, setDrawerState] = React.useState(false);
    const myalert = (txt) => { setDrawerState(!drawerState);};
    const navigate = useNavigate();
    console.log("MENU", {userData});

  return (
    <Box sx={{ flexGrow: 1 }}>
      
      <TemporaryDrawer mystate={drawerState} setDrawerState={myalert}/>
      
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={(myalert)}
          >
            <MenuIcon />
          </IconButton>
          
          
          
          
          
          <Tooltip title="Back to home page" placement="top-end">
          
          
          
          
          <Typography
            noWrap
            component="a"
            href="/"
            sx={{mr: 2, display: { xs: 'flex', md: 'flex' }, fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem',color: 'inherit',textDecoration: 'none'}}
          >
{/*
            <HomeIcon sx={{ display: { xs: 'flex', md: 'flex' }, mr: 1 }}/> 
            HOME
  */}
  <img src="/logo2.png" width="200" className='topleft'/>
          </Typography>
          </Tooltip>
          <Typography  component="div" sx={{ flexGrow: 1 }}>
          </Typography>
          
          
          
          <AccountMenu user={userData}/>
          
          
        </Toolbar>
        
      </AppBar>
      
      <Toolbar />
      
    </Box>
  );
}