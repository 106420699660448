import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import React, { useState } from "react";

import globalVal from './globalVar';


import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
/*menu item */
import MenuItem from '@mui/material/MenuItem';

import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';

import {jsonMenu, jsonMenutype} from './Data.js';

let currentWeek =-1;
let currentDay = -1;


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#282c34',
    textAlign: 'Left',
    color: '#ffffff',
    paddingLeft: 10,
  }));

function WeekMenu(props){
    currentWeek = props.week;
    currentDay = props.day;
    let init = props.state

    //console.log("MENU WEEK:" + currentWeek + " "  +props.option);
  
    const [seedW, setSeedW] = useState(1);  
    
    const handleChange = (value, type)=> {
      init=1;
      console.log("CHANGE MENU TYPE" + type + value)
    
      if (type === "week"){
        currentWeek = value;
        globalVal.currentWeek = value;
      }
    
      if (type === "day"){
        currentDay = value;
        globalVal.currentDay = value;
      }
      //event => setSeedW(Math.random());
      setSeedW(Math.random()); 
      props.updateEvent();
    
    }
  
    return(
    <FormControl>
        
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="WeekNumber"
          value={"week" + currentWeek}
          className="radio-menu"
        >
          {/*<FormLabel id="demo-row-radio-buttons-group-label" style={{color: 'white'}}><br/>Settimana {currentWeek}</FormLabel>*/}
          
          <FormControlLabel onChange={(e, v)=>handleChange(1, 'week')} value="week1" control={<Radio sx={{color: 'white'}}/>} label="Sett.na 1" labelPlacement="top"/>
          <FormControlLabel onChange={(e, v)=>handleChange(2, 'week')} value="week2" control={<Radio sx={{color: 'white'}}/>} label="Sett.na 2" labelPlacement="top"/>
          <FormControlLabel onChange={(e, v)=>handleChange(3, 'week')} value="week3" control={<Radio sx={{color: 'white'}}/>} label="Sett.na 3" labelPlacement="top"/>
          <FormControlLabel onChange={(e, v)=>handleChange(4, 'week')} value="week4" control={<Radio sx={{color: 'white'}}/>} label="Sett.na 4" labelPlacement="top"/>
          
        </RadioGroup>
  
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="weekDay"
          value={"weekDay" + currentDay}
          className="radio-menu"
        >
          {/*< FormLabel id="demo-row-radio-buttons-group-label" style={{color: 'white'}}><br/>Giorno</FormLabel > */}
          
          <FormControlLabel onChange={(e, v)=>handleChange(1, 'day')} value="weekDay1" control={<Radio sx={{color: 'white'}}/>} label="Lun." labelPlacement="top"/>
          <FormControlLabel onChange={(e, v)=>handleChange(2, 'day')} value="weekDay2" control={<Radio sx={{color: 'white'}}/>} label="Mar." labelPlacement="top"/>
          <FormControlLabel onChange={(e, v)=>handleChange(3, 'day')} value="weekDay3" control={<Radio sx={{color: 'white'}}/>} label="Mer." labelPlacement="top"/>
          <FormControlLabel onChange={(e, v)=>handleChange(4, 'day')} value="weekDay4" control={<Radio sx={{color: 'white'}}/>} label="Gio." labelPlacement="top"/>
          <FormControlLabel onChange={(e, v)=>handleChange(5, 'day')} value="weekDay5" control={<Radio sx={{color: 'white'}}/>} label="Ven." labelPlacement="top"/>
          
        </RadioGroup>
        
      </FormControl>
  
    );
  
  }


function SelectMenuType(props){
    let handleChangeMenu = ""
    if (props.hasOwnProperty('onChangeEvent')){
        handleChangeMenu = props.onChangeEvent;
    }
    const field = [];
    if ((props) && (props.data) && props.data.menuType.length) {
        for (let i=0; i<props.data.menuType.length; i++){
            let menuName =  props.data.menuType[i];
            field.push(<MenuItem value={menuName}>{menuName}</MenuItem>);
        }
    }
    let defaultMenu = props.data.menuType[0];

    return(

    <FormControl fullWidth>
    <InputLabel id="demo-simple-select-label1" sx={{color:'white'}}>Tipo menu</InputLabel>
      <Select
        labelId="demo-simple-select-label1"
        onChange={handleChangeMenu}
        id="demo-simple-select"
        value={globalVal.currentMenuType}
        
        label="Tipo menu"
        sx={{ height: '2.5rem', width:'100%', 
        color: 'white','& .MuiOutlinedInput-notchedOutline': { borderColor: 'white'},  '& .MuiSvgIcon-root': { color: 'white'}  }}
      >
        {field}
      </Select>
      </FormControl>
    
    );

}

function SelectMenuWeek(props){
  

  /*EVENT HANDLE*/  
  let handleChangeMenu = ""
    if (props.hasOwnProperty('onChangeEvent')){
        handleChangeMenu = props.onChangeEvent;
    }

    /*MENU LIST*/  
    const field = [];
    if ((props) && (props.data) && props.menuType.length && props.data[props.menuType] )  {
      let menuList = ['uio', 'due']//desired_output(props.data[props.menuType]);
      let fullMenu = props.data[props.menuType];
      let subMenuList = fullMenu.map(item=>item.submenu)
      .filter((value, index, current_value) => current_value.indexOf(value) === index);
  
        for (let i=0; i<subMenuList.length; i++){
            let menuName =  subMenuList[i];
            field.push(<MenuItem value={menuName}>{menuName}</MenuItem>);
        }
        let defaultMenu = menuList[0];
    }
    
    

    return(

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label2" sx={{color:'white'}}>Settimana/Sotto menu</InputLabel>

            <Select
              labelId="Settimana/Sotto Menu"
              onChange={handleChangeMenu}
              
              id="demo-simple-select2"
              value={globalVal.currentSubMenu}
              label="Settimana/Sotto Menu"
              sx={{ height: '2.5rem', width:'100%', 
              color: 'white','& .MuiOutlinedInput-notchedOutline': { borderColor: 'white'},  '& .MuiSvgIcon-root': { color: 'white'}  }}
            >
              {/*
              <MenuItem value={'W1'}>Settimana 1</MenuItem>
              <MenuItem value={'W2'}>Settimana 2</MenuItem>
              <MenuItem value={'W3'}>Settimana 3</MenuItem>
              <MenuItem value={'W4'}>Settimana 4</MenuItem>
    */}
              {field}
            </Select>
            </FormControl>


    );
}

function SelectMenuDay(props){
  
  /*EVENT HANDLE*/  
    let handleChangeMenu = ""
    if (props.hasOwnProperty('onChangeEvent'))
        handleChangeMenu = props.onChangeEvent;

    /*MENU LIST*/  
    const field = [];
    if ((props) && (props.data) && props.menuType.length && props.data[props.menuType] )  {
      let menuList = ['uio', 'due']//desired_output(props.data[props.menuType]);
      let fullMenu = props.data[props.menuType];
      let subMenuList = fullMenu
      .filter(item=>item.submenu===props.submenu)
      .map(item=>item.menu)
      .filter((value, index, current_value) => current_value.indexOf(value) === index);
  
        for (let i=0; i<subMenuList.length; i++){
            let menuName =  subMenuList[i];
            field.push(<MenuItem value={menuName}>{menuName}</MenuItem>);
        }
        let defaultMenu = menuList[0];
    }


    return(

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" sx={{color:'white'}}>Giorno/menu</InputLabel>

            <Select
              labelId="Giorno"
              onChange={handleChangeMenu}
              id="demo-simple-select"
              value={globalVal.currentMenu}
              label="Giorno/Menu"
              sx={{ height: '2.5rem', width:'100%', 
              color: 'white','& .MuiOutlinedInput-notchedOutline': { borderColor: 'white'},  '& .MuiSvgIcon-root': { color: 'white'}  }}
            >
              {/*
              <MenuItem value={'D1'}>Lunedi'</MenuItem>
              <MenuItem value={'D2'}>Martedi'</MenuItem>
              <MenuItem value={'D3'}>Mercoledi'</MenuItem>
              <MenuItem value={'D4'}>Giovedi'</MenuItem>
              <MenuItem value={'D5'}>Venerdi'</MenuItem>
    */}
    {field}

            </Select>
            </FormControl>
          

    );
}




function helloWorld(){
    console.log("Hello MF")
}

  export {WeekMenu, helloWorld, SelectMenuType, SelectMenuWeek, SelectMenuDay};