import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

import {AuthenticationDetails, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { unstable_HistoryRouter, useNavigate } from "react-router-dom";
import { useJwt } from "react-jwt";
import userpool from './userpool.js';
import UserData from '../classes/UserDataClass.js'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { DataGrid } from '@mui/x-data-grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { AuthContext } from "./AuthContext"





function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}







// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function UserProfile() {
  const { user, signOut , getValue, setValue, getSession} = React.useContext(AuthContext)
  const navigate = useNavigate();
  const[msg, setMSG] = React.useState("");
  const[state, setState] = React.useState(0);
  const[opend, setOpend] = React.useState(false); //dialog
  const[currentRatio, setCurrentRatio] = React.useState({}); //dialog
  const[userData, setUserData] = React.useState({}); //dialog
  

  React.useEffect( () => {

    async function fetchUserData(){

      let udata = await getValue("user");  
      console.log(udata);
      let _userdata = new UserData(udata);
      //_userdata.nick = "Manuele"
      setUserData(_userdata);
      
  
    }
    
    async function fetchSession(){

      let session = await getSession();
      console.log("***SESSION");
      console.log(session);
      let at = await session.getAccessToken();
      let atexp = at.getExpiration();
      console.log("***EXPIRATION");
      console.log(atexp);
      console.log(new Date(atexp * 1000));
      


      
  
    }
    fetchUserData();
    fetchSession();
    


    //_userdata.nick = "Manuele";
    


    //setUserData(_userdata);

    
  }, []);

  //*************************************** MAIN */
  let _userData = new UserData(userData);
  
  
  //////////////////////////////////////////////
  /// User profile save into cloud
  const handleSubmit = (event) => {
    
    
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
//    console.log(userData);
//    let user_nick = data.get("user-nick");
    setValue("user", userData);

  };
  
  const handleClosed = (e, d) => {
    setOpend(false);
  };

  const handleClosed2 = (e, d) => {
    e.preventDefault();
    setOpend(false);
    
    //alert("note:" + e.target.food_note.value)
    
    let time  = e.target.time.value;
    let ratio = parseFloat(e.target.ratio.value);
    let id = parseFloat(e.target.id.value);

    if (!id) _userData.addRatio(time, ratio)
    else _userData.modRatio(time, ratio, id)
    setUserData(_userData);
//    setMSG("user changed" +  Math.random())
    
  };

  const handleChange = (event, element) => {
    if (element === "NICK"){ _userData.nick = event.target.value;   }
    if (element === "WEIGHT"){ _userData.weight = event.target.value;   }
    setUserData(_userData);
//    setMSG("user changed" +  Math.random())
    
  };

  const EditCHORatio = (id, action) => {
    if (action === "DEL"){
    
        _userData.delRatio(id);
      setUserData(_userData);
    }
    if (action === "MOD"){
      let ratio = _userData.getRatio(id);
      setCurrentRatio(ratio);
      setOpend(true);
    }

  }

  const columns = [
    { field: 't', headerName: 'TIME', width: 70 },
    { field: 'r', headerName: 'VALUE', width: 130 },
    { field: "modify", headerName: 'M',    width: 10,
      valueGetter: (params) => `${params.row.id}`,
      renderCell: (params) => { return ( <IconButton  color="primary" onClick={()=>{EditCHORatio(params.row.id, "MOD")}}> <EditIcon/> </IconButton>); }
    },
    { field: "delete", headerName: 'D',    width: 10,
      valueGetter: (params) => `${params.row.id}`,
      renderCell: (params) => { return ( <IconButton  color="primary" onClick={()=>{EditCHORatio(params.row.id, "DEL")}}> <DeleteIcon/> </IconButton>); }
    },

  ];
  
  const rows = [
    { id:1, time: "00.00", value: 300 },
    { id:2, time: "01.00", value: 300 },
    { id:3, time: "02.00", value: 300 },

  ];

  const addRatio = () => {
    //let myUser = new User(user);

    _userData.addRatio("11:00", 300);
    console.log(_userData.name)
    setUserData(_userData);
    setMSG("user changed" +  Math.random())

    console.log(_userData.getRatioList())
    console.log(userData)
  }

const DataGridTitle = ()=> {
  return(
      <Box component="div" display="inline">
          
          <Grid container justifyContent="flex-end">
          <IconButton aria-label="delete" onClick={addRatio}>Carbo ratio <AddCircleOutlineIcon /></IconButton>
         </Grid>
      </Box>
      
  )
}



  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <Typography component="h1" variant="h5">
            Profile <Typography component="subtitle1" variant="subtitle1">{_userData.id}</Typography>
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          
          

        <TextField  id="user-nick"   label="Nick name"   name="user-nick"
          value={_userData.nick} InputLabelProps={{ shrink: true }}   margin="normal" fullWidth   onChange={(e)=>handleChange(e, 'NICK')} />
        <TextField  id="user-weight"   label="Weight"   name="user-weight"
          value={_userData.weight} InputLabelProps={{ shrink: true }}   margin="normal" fullWidth   onChange={(e)=>handleChange(e, 'WEIGHT')} />


    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
      
      components={{Toolbar: DataGridTitle}}
        rows={_userData.getRatioList()}
        columns={columns}
      />
    </div>

            
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save
            </Button>
            
            
            
          </Box>
        </Box>
        <Dialog open={opend} onClose={handleClosed}>
        <form action="/" method="POST" onSubmit={handleClosed2}>
       
        <DialogContent>
        
        {UserData.getForm(currentRatio)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosed}>Cancel</Button>
          
          <Button  type="submit" >Save</Button>
        </DialogActions>
        </form>
        </Dialog> 

        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}