import * as React from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CancelIcon from "@mui/icons-material/Cancel";

import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../node_modules/react-vis/dist/style.css';
import {XYPlot, LineSeries, VerticalBarSeries, VerticalBarSeriesCanvas, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, Crosshair} from 'react-vis';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';



import Alert from '@mui/material/Alert';
import Glokoo from './classes/GlokooClass';

import {  OutlinedInput,  InputLabel,  MenuItem,  Select,  FormControl,  Stack,  Chip,  } from "@mui/material";

import * as echarts from 'echarts/core';
import ReactEcharts from "echarts-for-react"

import {  LineChart, BarChart } from 'echarts/charts';
// import components, all suffixed with Component
import { GridComponent, TooltipComponent, TitleComponent, DatasetComponent} from 'echarts/components';
import { CanvasRenderer} from 'echarts/renderers';


// TODO remove, this demo shouldn't need to reset the theme.



const defaultTheme = createTheme();



let foodList;
/////////////////////////////////////////////////////////////////////////////

export default function Cgm(props) {
  

  React.useEffect(() => {
   
 },[])

//*** INIT 

  


//*** PROCS
//██    ██ ███████ ███████       ███████ ████████  █████  ████████ ███████ 
//██    ██ ██      ██            ██         ██    ██   ██    ██    ██      
//██    ██ ███████ █████   █████ ███████    ██    ███████    ██    █████   
//██    ██      ██ ██                 ██    ██    ██   ██    ██    ██      
// ██████  ███████ ███████       ███████    ██    ██   ██    ██    ███████ 
  const[state, setState] = React.useState(true);
  const[chartMulti, setChartMulti] = React.useState(false);
  //const[openMSG, setMSGOpen] = React.useState(false);
  //const[opend, setOpend] = React.useState(false); //dialog
  //const[showConfirm, setShowConfirm ] = React.useState(false); //dialog
  
//  const[currentImage, setCurrentImage] = React.useState(null); //dialog
  //const[currentB64Image, setCurrentB64Image] = React.useState(""); //dialog
  //const[currentObjImage, setCurrentObjImage] = React.useState(null); //dialog
  //const[showWait, setShowWait] = React.useState(false); 
  const [selectedNames, setSelectedNames] = React.useState([]);


//*** ███    ███  █████  ██ ███    ██         
//*** ████  ████ ██   ██ ██ ████   ██         
//*** ██ ████ ██ ███████ ██ ██ ██  ██         
//*** ██  ██  ██ ██   ██ ██ ██  ██ ██         
//*** ██      ██ ██   ██ ██ ██   ████         
 


  let glokoo = new Glokoo();
  let data = [];
  const chartslist = [];


  if (selectedNames && selectedNames.length > 0){
    for (let i=0; i<selectedNames.length;i++){
      //data.push(glokoo.getChartDataFromLocalMemory_EC(selectedNames[i]));
      let CGM_dataEc = glokoo.getChartDataFromLocalMemory_EC(selectedNames[i]);
      let BOL_dataEc = glokoo.getBolusChartDataFromLocalMemory_EC(selectedNames[i]);
      let BAS_dataEc = glokoo.getBasalChartDataFromLocalMemory_EC(selectedNames[i]);
      const series_graph = [];

      // ██████  ██████  ███    ███     ███████ ███████ ██████  ██ ███████ 
      //██      ██       ████  ████     ██      ██      ██   ██ ██ ██      
      //██      ██   ███ ██ ████ ██     ███████ █████   ██████  ██ █████   
      //██      ██    ██ ██  ██  ██          ██ ██      ██   ██ ██ ██      
      // ██████  ██████  ██      ██     ███████ ███████ ██   ██ ██ ███████ 
      if (CGM_dataEc && CGM_dataEc.length > 0){
        series_graph.push(
          {
            data: CGM_dataEc,
            type: "line",
            smooth: true,
            markLine: {
              data: [
                { yAxis: 180,
                label: {normal: { show: false}},
                lineStyle: { normal: { type:'dashed', color: 'red'} },
                },
                { yAxis: 70,
                  label: {normal: { show: false}},
                  lineStyle: { normal: { type:'dashed', color: 'red'} },
                }
              ],
            }
          }
        );
      }


      //      console.log(BOL_dataEc);
//BOLUS
if (BOL_dataEc && BOL_dataEc.length > 0){
  series_graph.push(
    {
      data: BOL_dataEc,
      type: "bar",
      color: "#FFA500",
      yAxisIndex: 1,
    }
  );
}
      //BASAL
      if (BAS_dataEc && BAS_dataEc.length > 0){
        series_graph.push(
          {
            data: BAS_dataEc,
            type: "line",
            
            color: "#87CEFA",
            lineStyle: { normal: { type:'solid', color: "#87CEFA", opacity: 0.5, width: 1} },
            yAxisIndex: 1,
            showSymbol: false,
            areaStyle: { color: '#87CEFA',
            opacity: 0.6},
          }
        );
      }





      const options = {
        title: {      text: selectedNames[i]    },
        grid: { top: 50, right: 40, bottom: 20, left: 40 },
        xAxis: { type: "time" },
        yAxis: [{  type: "value" ,name: "GCM"  }, 
              { type:"value"      ,name: "Bolus"    }, 

        ],
        series: series_graph,
        tooltip: {
          trigger: "axis"
        }
      }
      data.push(options);
      chartslist.push(
        <ReactEcharts option={options} style={{ width: "1000px", height: "300px" }}></ReactEcharts>
      );
    }
  }

  
/*
  let series = [];
  for (let i=0; i<data.length; i++){
    series.push(<LineSeries data={data[i]}/>)
  }

  if (glokoo.min_date && glokoo.max_date){
    let data180 = [ {x:glokoo.min_date, y:180}, {x:glokoo.max_date, y:180}]
    let data70 = [ {x:glokoo.min_date, y:70}, {x:glokoo.max_date, y:70}]
    series.push(<LineSeries data={data180} color="red"/>)
    series.push(<LineSeries data={data70} color="red"/>)
  }
  */
  

  
  
  //███████  ██████ ██   ██  █████  ██████  ████████ 
  //██      ██      ██   ██ ██   ██ ██   ██    ██    
  //█████   ██      ███████ ███████ ██████     ██    
  //██      ██      ██   ██ ██   ██ ██   ██    ██    
  //███████  ██████ ██   ██ ██   ██ ██   ██    ██    
  //let dataEc = glokoo.getChartDataFromLocalMemory_EC(selectedNames[i]);
  let dataEc = data[0];
  let bolusDataEc = glokoo.getBolusChartDataFromLocalMemory_EC('CGM_DATA_20231011');
  

/*
  
  const options = {
    
    grid: { top: 50, right: 40, bottom: 20, left: 40 },
    xAxis: { type: "time" },
    yAxis: [{  type: "value" ,name: "GCM"  }, 
          { type:"value"      ,name: "Bolus"    }, 
    ],
    series: [
      {
        data: dataEc,
        type: "line",
        smooth: true,
        markLine: {
          data: [
            { yAxis: 180,
            label: {normal: { show: false}},
            lineStyle: { normal: { type:'dashed', color: 'red'} },
            },
            { yAxis: 70,
              label: {normal: { show: false}},
              lineStyle: { normal: { type:'dashed', color: 'red'} },
            }
          ],
        }
      }

    ],
    tooltip: {
      trigger: "axis"
    }
  }
*/
  //list of dates
  let allEntry = glokoo.getAllLocalMemoryEntry();

  const DataSelect = (dateList) =>{
    return (
      <FormControl sx={{ m: 1, width: 500 }}>
        <InputLabel>Multiple Select</InputLabel>
        <Select
          multiple
          value={selectedNames}
          onChange={(e) => setSelectedNames(e.target.value)}
          input={<OutlinedInput label="Multiple Select" />}
          renderValue={(selected) => (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  onDelete={() =>
                    setSelectedNames(
                      selectedNames.filter((item) => item !== value)
                    )
                  }
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                />
              ))}
            </Stack>
          )}
        >
          {dateList.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }


  //██████  ███████ ████████ ██    ██ ██████  ███    ██ 
  //██   ██ ██         ██    ██    ██ ██   ██ ████   ██ 
  //██████  █████      ██    ██    ██ ██████  ██ ██  ██ 
  //██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██ 
  //██   ██ ███████    ██     ██████  ██   ██ ██   ████ 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box

          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >


 {DataSelect(allEntry) }
  
 <FormControlLabel 
              control={
                <Switch onChange={(e, d) =>{setChartMulti(!chartMulti)} } />} label="Sovrapposto" labelPlacement="top" checked={chartMulti}/>

  


{chartslist}



          </Box>



      </Container>
    </ThemeProvider>

    
  );
}