
const jsonMenuType={"menuType":['Materna Autunnale', 'Materna Invernale', 'Colazione']}


const jsonMenu = 
    {"week1":
        {"d1":[
            {"name":"Minestra Invernale", "cho100":21, "peso":100},
            {"name":"Petto Di Pollo Alla Salvia", "cho100":0, "peso":120},
            {"name":"Patate Lessate", "cho100":15, "peso":30}
        ]
        ,"d2":[
            {"name":"Crema Di Ceci E Zucca", "cho100":8, "peso":100},
            {"name":"+ Crostini", "cho100":67, "peso":12},
            {"name":"Frittata", "cho100":0, "peso":120},
            {"name":"Carote", "cho100":9.5, "peso":20}
        ]
        ,"d3":[
            {"name":"Spezzatino Di Manzo","cho100":0, "peso":0},
            {"name":"+ Polenta","cho100":22, "peso":70},
            {"name":"+ Patate","cho100":15, "peso":30},
            {"name":"Insalata","cho100":3, "peso":10},
            {"name":"+ Finocchio Gratinato Al Forno","cho100":11, "peso":0}

        ]
        ,"d4":[
            {"name":"Pasta Al Pomodoro","cho100":68, "peso":50, "step":5},
            {"name":"Polpette Di Pesce","cho100":0, "peso":0},
            {"name":"Spinaci Cotti All'olio","cho100":7, "peso":5}



        ]
        ,"d5":[
            {"name":"Risotto Alla Zucca","cho100":70, "peso":50},
            {"name":"Asiago","cho100":0, "peso":0},
            {"name":"Cappuccio","cho100":6, "peso":20}

        ]
    
        },

        "week2":{ 
            "d1":[
                {"name":"Pasta Burro e Salvia","cho100":70, "peso":50},
                {"name":"Asiago","cho100":0, "peso":0},
                {"name":"Finocchio","cho100":7, "peso":0}]
            ,"d2":[
                {"name":"Passato di Verdure Invernali","cho100":8, "peso":70},
                {"name":"+ Crostini","cho100":67, "peso":12},
                {"name":"Polpette Di Carne Bianca Al Sugo","cho100":0, "peso":0},
                {"name":"+ Patate E Piselli","cho100":14, "peso":20},
                {"name":"Insalata","cho100":3, "peso":10}]

            ,"d3":[
                {"name":"Pasta All'ortolana","cho100":70, "peso":50},
                {"name":"Fagioli All'uccelletto","cho100":10, "peso":10},
                {"name":"Carote","cho100":9.5, "peso":20}]
            ,"d4":[
                {"name":"Pastina","cho100":70, "peso":30},
                {"name":"+ Brodo Vegetale","cho100":0, "peso":0},
                {"name":"Arista Di Maiale Agli Agrumi","cho100":0, "peso":0},
                {"name":"Insalata","cho100":3, "peso":10}]
            ,"d5":[
                {"name":"Risotto Alla Parmigiana","cho100":50, "peso":60},
                {"name":"Filetto Di Merluzzo","cho100":0, "peso":0},
                {"name":"Cappuccio","cho100":6, "peso":20}]

        }
        ,"week3":{ 
            "d1":[
                {"name":"Pasta Al Ragù di Pesce","cho100":65, "peso":0},
                {"name":"Fagiolini Cotti All'olio","cho100":4.6, "peso":0}]
            ,"d2":[
                {"name":"Pasta Al Pomodoro","cho100":70, "peso":0},
                {"name":"Rotolo Di Coniglio Arrosto","cho100":0, "peso":0},
                {"name":"Spinaci Cotti All'olio","cho100":7, "peso":0}]
            ,"d3":[
                {"name":"Crema di Zucca Con Riso","cho100":-1, "peso":0},
                {"name":"Filetti Di Merluzzo Al Pomodoro","cho100":0, "peso":0},
                {"name":"Verza Cotta All'olio","cho100":3.8, "peso":0}]
            ,"d4":[
                {"name":"Pasta All'olio Evo E Grana","cho100":70, "peso":-1},
                {"name":"Caciottina Fresca","cho100":2, "peso":-1},
                {"name":"Cappuccio","cho100":6, "peso":-1},]
            ,"d5":[
                {"name":"Risotto Ai Carciofi","cho100":70, "peso":-1},
                {"name":"Frittata Al Forno","cho100":0, "peso":-1},
                {"name":"Carote Cotte All'olio","cho100":9.5, "peso":-1},
                {"name":"+ Verdure Invernali","cho100":3, "peso":-1}]

        }
        ,"week4":{ 
            "d1":[
                {"name":"Pasta Alla Crema Di Zucchine","cho100":68, "peso":-1},
                {"name":"Filetto Di Platessa Al Limone","cho100":0, "peso":-1},
                {"name":"Insalata","cho100":3, "peso":-1}]
            ,"d2":[
                {"name":"Passato Di Verdure Invernali Con Pastina","cho100":-1, "peso":-1},
                {"name":"Polpette Di Manzo In Umido","cho100":-1, "peso":-1},
                {"name":"Patate Al Forno","cho100":20, "peso":-1}]
            ,"d3":[
                {"name":"Pasta Alle Zucchine E Zafferano","cho100":-1, "peso":-1},
                {"name":"Burger Di Lenticchie E Verdure","cho100":-1, "peso":-1},
                {"name":"Carote","cho100":9.5, "peso":-1}]
            ,"d4":[
                {"name":"Minestra D'orzo","cho100":-1, "peso":-1},
                {"name":"Bocconcini Di Pollo","cho100":-1, "peso":-1},
                {"name":"Bieta Cotta All'olio","cho100":6, "peso":-1}]
            ,"d5":[
                {"name":"Pasta Pasticciata","cho100":-1, "peso":-1},
                {"name":"Piselli Prezzemolo/Finocchio","cho100":14, "peso":-1}]

        }
    };


   const jsonNewMenu= {
    'Materna Autunnale':[
        {"submenu":"week1",     "menu":"lunedi",    "name":"Minestra Invernale",           "cho100":21,    "peso":100},
        {"submenu":"week1",     "menu":"lunedi",    "name":"Petto Di Pollo Alla Salvia",   "cho100":0,     "peso":120},
        {"submenu":"week1",     "menu":"lunedi",    "name":"Patate Lessate",               "cho100":15,    "peso":30},

        
        {"submenu":"week1",	    "menu":"martedi",	"name":"Crema Di Ceci E Zucca",	        "cho100":8,	    "peso":100},
        {"submenu":"week1",	    "menu":"martedi",	"name":"+ Crostini",	                "cho100":67,	"peso":12},
        {"submenu":"week1",	    "menu":"martedi",	"name":"Frittata",	                    "cho100":0,	    "peso":120},
        {"submenu":"week1",	    "menu":"martedi",	"name":"Carote",	                    "cho100":9.5,	"peso":20},
        {"submenu":"week1",	    "menu":"mercoledi",	"name":"Spezzatino Di Manzo",	        "cho100":0,	    "peso":0},
        {"submenu":"week1",	    "menu":"mercoledi",	"name":"+ Polenta",	                    "cho100":22,	"peso":70},
        {"submenu":"week1",	    "menu":"mercoledi",	"name":"+ Patate",	                    "cho100":15,	"peso":30},
        {"submenu":"week1",	    "menu":"mercoledi",	"name":"Insalata",	                    "cho100":3,	    "peso":10},
        {"submenu":"week1",	    "menu":"mercoledi",	"name":"+ Finocchio Gratinato Al Forno","cho100":11,	"peso":0},
        {"submenu":"week1",	    "menu":"giovedi",	"name":"Pasta Al Pomodoro",	            "cho100":68,    "peso":50,	"step":5},
        {"submenu":"week1",	    "menu":"giovedi",	"name":"Polpette Di Pesce",	            "cho100":0,	    "peso":0},
        {"submenu":"week1",	    "menu":"giovedi",	"name":"Spinaci Cotti All'olio",	    "cho100":7,	    "peso":5},
        {"submenu":"week1",	    "menu":"venerdi",	"name":"Risotto Alla Zucca",	        "cho100":70,	"peso":50},
        {"submenu":"week1",	    "menu":"venerdi",	"name":"Asiago",	                    "cho100":0,	    "peso":0},
        {"submenu":"week1",	    "menu":"venerdi",	"name":"Cappuccio",	                    "cho100":6,	    "peso":20},

        {"submenu":"week2",	    "menu":"lunedi",	"name":"Pasta Burro e Salvia",	        "cho100":70,	"peso":50},
        {"submenu":"week2",	    "menu":"lunedi",	"name":"Asiago",	                    "cho100":0,	    "peso":0},
        {"submenu":"week2",	    "menu":"lunedi",	"name":"Finocchio",	                    "cho100":7,	    "peso":0},

        {"submenu":"week2",	    "menu":"martedi",	"name":"Passato di Verdure Invernali",	"cho100":8,	    "peso":70},
        {"submenu":"week2",	    "menu":"martedi",	"name":"+ Crostini",	                "cho100":67,	"peso":12},
        {"submenu":"week2",	    "menu":"martedi",	"name":"Polpette Di Carne Bianca Al Sugo","cho100":0,	"peso":0},
        {"submenu":"week2",	    "menu":"martedi",	"name":"+ Patate E Piselli",	        "cho100":14,	"peso":20},
        {"submenu":"week2",	    "menu":"martedi",	"name":"Insalata",	                    "cho100":3,	    "peso":10},


        {"submenu":"week2",	    "menu":"mercoledi",	"name":"Pasta All'ortolana",	        "cho100":70,	"peso":50},
        {"submenu":"week2",	    "menu":"mercoledi",	"name":"Fagioli All'uccelletto",	    "cho100":10,	"peso":10},
        {"submenu":"week2",	    "menu":"mercoledi",	"name":"Carote",	                    "cho100":9.5,	"peso":20},

        {"submenu":"week2",	    "menu":"giovedi",	"name":"Pastina",	                    "cho100":70,	"peso":30},
        {"submenu":"week2",	    "menu":"giovedi",	"name":"+ Brodo Vegetale",	            "cho100":0,	"peso":0},
        {"submenu":"week2",	    "menu":"giovedi",	"name":"Arista Di Maiale Agli Agrumi",	"cho100":0,	"peso":0},
        {"submenu":"week2",	    "menu":"giovedi",	"name":"Insalata",	                    "cho100":3,	"peso":10},

        {"submenu":"week2",	    "menu":"venerdi",	"name":"Risotto Alla Parmigiana",	    "cho100":50,	"peso":60},
        {"submenu":"week2",	    "menu":"venerdi",	"name":"Filetto Di Merluzzo",	        "cho100":0,	"peso":0},
        {"submenu":"week2",	    "menu":"venerdi",	"name":"Cappuccio",	                    "cho100":6,	"peso":20},



        {"submenu":"week3",	    "menu":"lunedi",	"name":"Pasta Al Ragù di Pesce",	    "cho100":65,	"peso":0},
        {"submenu":"week3",	    "menu":"lunedi",	"name":"Fagiolini Cotti All'olio",	    "cho100":4.6,	"peso":0},

        {"submenu":"week3",	    "menu":"martedi",	"name":"Pasta Al Pomodoro",	            "cho100":70,	"peso":0},
        {"submenu":"week3",	    "menu":"martedi",	"name":"Rotolo Di Coniglio Arrosto",	"cho100":0,	"peso":0},
        {"submenu":"week3",	    "menu":"martedi",	"name":"Spinaci Cotti All'olio",	    "cho100":7,	"peso":0},

        {"submenu":"week3",	    "menu":"mercoledi",	"name":"Crema di Zucca Con Riso",	    "cho100":-1,	"peso":0},
        {"submenu":"week3",	    "menu":"mercoledi",	"name":"Filetti Di Merluzzo Al Pomodoro","cho100":0,	"peso":0},
        {"submenu":"week3",	    "menu":"mercoledi",	"name":"Verza Cotta All'olio",	        "cho100":3.8,	"peso":0},

        {"submenu":"week3",	    "menu":"giovedi",	"name":"Pasta All'olio Evo E Grana",	"cho100":70,	"peso":-1},
        {"submenu":"week3",	    "menu":"giovedi",	"name":"Caciottina Fresca",	            "cho100":2,	"peso":-1},
        {"submenu":"week3",	    "menu":"giovedi",	"name":"Cappuccio",	                    "cho100":6,	"peso":-1},

        {"submenu":"week3",	    "menu":"venerdi",	"name":"Risotto Ai Carciofi",	        "cho100":70,	"peso":-1},
        {"submenu":"week3",	    "menu":"venerdi",	"name":"Frittata Al Forno",	            "cho100":0,	"peso":-1},
        {"submenu":"week3",	    "menu":"venerdi",	"name":"Carote Cotte All'olio",	        "cho100":9.5,	"peso":-1},
        {"submenu":"week3",	    "menu":"venerdi",	"name":"+ Verdure Invernali",	        "cho100":3,	"peso":-1},



        {"submenu":"week4",	    "menu":"lunedi",	"name":"Pasta Alla Crema Di Zucchine",	"cho100":68,	"peso":-1},
        {"submenu":"week4",	    "menu":"lunedi",	"name":"Filetto Di Platessa Al Limone",	"cho100":0,	"peso":-1},
        {"submenu":"week4",	    "menu":"lunedi",	"name":"Insalata",	                    "cho100":3,	"peso":-1},

        {"submenu":"week4",	    "menu":"martedi",	"name":"Passato Di Verdure Invernali Con Pastina",	"cho100":-1,	"peso":-1},
        {"submenu":"week4",	    "menu":"martedi",	"name":"Polpette Di Manzo In Umido",	"cho100":-1,	"peso":-1},
        {"submenu":"week4",	    "menu":"martedi",	"name":"Patate Al Forno",	            "cho100":20,	"peso":-1},

        {"submenu":"week4",	    "menu":"mercoledi",	"name":"Pasta Alle Zucchine E Zafferano","cho100":-1,	"peso":-1},
        {"submenu":"week4",	    "menu":"mercoledi",	"name":"Burger Di Lenticchie E Verdure","cho100":-1,	"peso":-1},
        {"submenu":"week4",	    "menu":"mercoledi",	"name":"Carote",	                    "cho100":9.5,	"peso":-1},

        {"submenu":"week4",	    "menu":"giovedi",	"name":"Minestra D'orzo",	            "cho100":-1,	"peso":-1},
        {"submenu":"week4",	    "menu":"giovedi",	"name":"Bocconcini Di Pollo",	        "cho100":-1,	"peso":-1},
        {"submenu":"week4",	    "menu":"giovedi",	"name":"Bieta Cotta All'olio",	        "cho100":6,	"peso":-1},

        {"submenu":"week4",	    "menu":"venerdi",	"name":"Pasta Pasticciata",	            "cho100":-1,	"peso":-1},
        {"submenu":"week4",	    "menu":"venerdi",	"name":"Piselli Prezzemolo/Finocchio",	"cho100":14,	"peso":-1},
    ],
   

   'Materna Invernale':[
    {"submenu":"week1",     "menu":"lunedi",    "name":"Minestra Invernale",           "cho100":21,    "peso":100},
    {"submenu":"week1",     "menu":"lunedi",    "name":"Petto Di Pollo Alla Salvia",   "cho100":0,     "peso":120},
    {"submenu":"week1",     "menu":"lunedi",    "name":"Patate Lessate",               "cho100":15,    "peso":30},

    ],

    'Colazione':[
    {"submenu":"latte",     "menu":"latte cereali",    "name":"Latte",           "cho100":5,    "peso":100},
    {"submenu":"latte",     "menu":"latte cereali",    "name":"cereali",   "cho100":66,     "peso": 20},

    {"submenu":"latte",     "menu":"latte fette frutta",    "name":"Latte",           "cho100":5,    "peso":100, "min":0, "max":200, "step":50}, 
    {"submenu":"latte",     "menu":"latte fette frutta",    "name":"Fette Mol Bianco",           "cho100":72,     "peso":17.6, "udm":"fette", "min":0, "max":36, "pesoUdm":8.8},
    {"submenu":"latte",     "menu":"latte fette frutta",    "name":"Fette Integrali Mol Bianco ",           "cho100":63,     "peso":17.6, "udm":"fette", "min":0, "max":36, "pesoUdm":8.8, "step":4.4},
    {"submenu":"latte",     "menu":"latte fette frutta",    "name":"mirtilli ",           "cho100":10,     "peso":25, "step":5},
    {"submenu":"latte",     "menu":"latte fette frutta",    "name":"kiwi ",           "cho100":9,     "peso":25, "step":5},
    {"submenu":"latte",     "menu":"latte fette frutta",    "name":"mela ",           "cho100":13,     "peso":25, "step":1},
    {"submenu":"latte",     "menu":"latte fette frutta",    "name":"banana",           "cho100":17.4,     "peso":25, "step":1},
    

    {"submenu":"ovetto",    "menu":"solo ovetto",    "name":"Ovetto",               "cho100":15,    "peso":30},
    ]
}

const jsonFoodList2=
[

{'id':2, 'name':'Acciuga o alice, sotto sale', 'category': 'PESCE', 'cho100':2.3},
{'id':3, 'name':'Acciuga o alice, sott\'olio', 'category': 'PESCE', 'cho100':0.2},
{'id':4, 'name':'Aglio', 'category': 'VERDURA', 'cho100':1},
{'id':5, 'name':'Aglio Bianco Piacentino', 'category': 'VERDURA', 'cho100':0.9},
{'id':6, 'name':'Aglio Rosso di Castelliri', 'category': 'VERDURA', 'cho100':1.02},

]

const jsonFoodList=
[
    {"id":1,"name":"Acciuga o alice","cat":"Prodotti della pesca","h2o":76.50,"alc":0.00,"ami":0.00,"cho":1.50,"col":61.00,"ENc":96.00,"ENj":403.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.60,"pro":16.80,"sug":1.50},
    {"id":2,"name":"Acciuga o alice, sotto sale","cat":"Prodotti della pesca","h2o":53.80,"alc":0.00,"ami":0.00,"cho":2.30,"col":119.00,"ENc":137.00,"ENj":571.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.10,"pro":25.00,"sug":2.30},
    {"id":3,"name":"Acciuga o alice, sott'olio","cat":"Prodotti della pesca","h2o":58.00,"alc":0.00,"ami":0.00,"cho":0.20,"col":114.00,"ENc":206.00,"ENj":862.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":11.30,"pro":25.90,"sug":0.20},
    {"id":4,"name":"Aglio","cat":"Verdure e ortaggi","h2o":62.80,"alc":0.00,"ami":0.00,"cho":1.00,"col":0.00,"ENc":53.00,"ENj":222.00,"fin":0.00,"fso":0.00,"fib":4.30,"fat":0.80,"pro":8.40,"sug":1.00},
    {"id":5,"name":"Aglio Bianco Piacentino","cat":"Verdure e ortaggi","h2o":62.50,"alc":0.00,"ami":0.00,"cho":0.90,"col":0.00,"ENc":47.00,"ENj":197.00,"fin":0.00,"fso":0.00,"fib":4.70,"fat":0.60,"pro":7.20,"sug":0.94},
    {"id":6,"name":"Aglio Rosso di Castelliri","cat":"Verdure e ortaggi","h2o":63.00,"alc":0.00,"ami":0.00,"cho":1.02,"col":0.00,"ENc":52.00,"ENj":216.00,"fin":0.00,"fso":0.00,"fib":3.80,"fat":0.50,"pro":8.90,"sug":1.02},
    {"id":7,"name":"Aglio Rosso di Procerno","cat":"Verdure e ortaggi","h2o":63.00,"alc":0.00,"ami":0.00,"cho":1.00,"col":0.00,"ENc":57.00,"ENj":237.00,"fin":0.00,"fso":0.00,"fib":4.60,"fat":0.90,"pro":8.90,"sug":1.02},
    {"id":8,"name":"Aglio Rosso di Sulmona","cat":"Verdure e ortaggi","h2o":62.50,"alc":0.00,"ami":0.00,"cho":1.00,"col":0.00,"ENc":58.00,"ENj":244.00,"fin":0.00,"fso":0.00,"fib":4.00,"fat":1.30,"pro":8.70,"sug":0.97},
    {"id":9,"name":"Agnello, coscio, cotto, al forno","cat":"Carni fresche","h2o":71.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":116.00,"ENc":118.00,"ENj":494.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.90,"pro":23.00,"sug":0.00},
    {"id":10,"name":"Agnello, coscio, crudo","cat":"Carni fresche","h2o":76.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":71.00,"ENc":102.00,"ENj":429.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.50,"pro":20.00,"sug":0.00},
    {"id":11,"name":"Agnello, costoletta, cotto, al forno","cat":"Carni fresche","h2o":73.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":104.00,"ENc":118.00,"ENj":493.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.00,"pro":22.70,"sug":0.00},
    {"id":12,"name":"Agnello, costoletta, crudo","cat":"Carni fresche","h2o":76.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":75.00,"ENc":106.00,"ENj":443.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.70,"pro":20.40,"sug":0.00},
    {"id":13,"name":"Agnello, cotto, al forno","cat":"Carni fresche","h2o":62.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":110.00,"ENc":199.00,"ENj":833.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":11.00,"pro":25.00,"sug":0.00},
    {"id":14,"name":"Agnello, crudo","cat":"Carni fresche","h2o":70.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":70.00,"ENc":159.00,"ENj":666.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.80,"pro":20.00,"sug":0.00},
    {"id":15,"name":"Agretti, cotti, bolliti","cat":"Verdure e ortaggi","h2o":91.00,"alc":0.00,"ami":0.00,"cho":2.60,"col":0.00,"ENc":25.00,"ENj":106.00,"fin":2.22,"fso":2.22,"fib":2.70,"fat":0.20,"pro":2.10,"sug":2.60},
    {"id":16,"name":"Agretti, crudi","cat":"Verdure e ortaggi","h2o":92.30,"alc":0.00,"ami":0.00,"cho":2.20,"col":0.00,"ENc":22.00,"ENj":91.00,"fin":1.85,"fso":1.85,"fib":2.30,"fat":0.20,"pro":1.80,"sug":2.20},
    {"id":17,"name":"Albicocche, disidratate","cat":"Frutta","h2o":3.50,"alc":0.00,"ami":0.00,"cho":80.60,"col":0.00,"ENc":356.00,"ENj":1488.00,"fin":0.00,"fso":0.00,"fib":11.00,"fat":1.00,"pro":5.60,"sug":80.60},
    {"id":18,"name":"Albicocche, fresche","cat":"Frutta","h2o":86.30,"alc":0.00,"ami":0.00,"cho":9.80,"col":0.00,"ENc":42.00,"ENj":177.00,"fin":0.83,"fso":0.83,"fib":1.50,"fat":0.10,"pro":0.40,"sug":9.80},
    {"id":19,"name":"Albicocche, sciroppate","cat":"Frutta","h2o":80.00,"alc":0.00,"ami":0.00,"cho":16.10,"col":0.00,"ENc":65.00,"ENj":271.00,"fin":0.00,"fso":0.00,"fib":0.90,"fat":0.10,"pro":0.40,"sug":16.10},
    {"id":20,"name":"Albicocche, secche","cat":"Frutta","h2o":25.00,"alc":0.00,"ami":0.00,"cho":66.50,"col":0.00,"ENc":286.00,"ENj":1196.00,"fin":0.00,"fso":0.00,"fib":6.00,"fat":0.50,"pro":5.00,"sug":66.50},
    {"id":21,"name":"Alici al forno","cat":"Ricette Italiane","h2o":62.20,"alc":0.00,"ami":4.60,"cho":7.10,"col":63.00,"ENc":172.00,"ENj":720.00,"fin":0.00,"fso":0.00,"fib":0.30,"fat":8.00,"pro":18.20,"sug":2.10},
    {"id":22,"name":"Alici marinate","cat":"Ricette Italiane","h2o":75.80,"alc":0.00,"ami":0.00,"cho":1.40,"col":51.00,"ENc":120.00,"ENj":501.00,"fin":0.00,"fso":0.00,"fib":0.10,"fat":6.40,"pro":14.20,"sug":1.40},
    {"id":23,"name":"Amarene, fresche","cat":"Frutta","h2o":85.20,"alc":0.00,"ami":0.00,"cho":10.20,"col":0.00,"ENc":44.00,"ENj":183.00,"fin":0.00,"fso":0.00,"fib":1.10,"fat":0.00,"pro":0.80,"sug":10.20},
    {"id":24,"name":"Anacardi","cat":"Frutta secca a guscio e semi oleaginosi","h2o":3.00,"alc":0.00,"ami":24.90,"cho":33.00,"col":0.00,"ENc":604.00,"ENj":2526.00,"fin":0.00,"fso":0.00,"fib":3.00,"fat":46.00,"pro":15.00,"sug":5.60},
    {"id":25,"name":"Ananas, fresco","cat":"Frutta","h2o":86.40,"alc":0.00,"ami":0.00,"cho":10.00,"col":0.00,"ENc":42.00,"ENj":174.00,"fin":0.83,"fso":0.83,"fib":1.00,"fat":0.00,"pro":0.50,"sug":10.00},
    {"id":26,"name":"Ananas, sciroppato","cat":"Frutta","h2o":82.20,"alc":0.00,"ami":0.00,"cho":16.60,"col":0.00,"ENc":66.00,"ENj":275.00,"fin":0.00,"fso":0.00,"fib":0.70,"fat":0.00,"pro":0.50,"sug":16.60},
    {"id":27,"name":"Anatra domestica, crudo","cat":"Carni fresche","h2o":68.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":38.00,"ENc":159.00,"ENj":667.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.20,"pro":21.40,"sug":0.00},
    {"id":28,"name":"Anguilla d'allevamento, filetti","cat":"Prodotti della pesca","h2o":61.60,"alc":0.00,"ami":0.00,"cho":0.50,"col":87.00,"ENc":237.00,"ENj":991.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":18.90,"pro":16.20,"sug":0.50},
    {"id":29,"name":"Anguilla di fiume","cat":"Prodotti della pesca","h2o":61.50,"alc":0.00,"ami":0.00,"cho":0.10,"col":92.00,"ENc":261.00,"ENj":1092.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":23.70,"pro":11.80,"sug":0.10},
    {"id":30,"name":"Anguilla di mare","cat":"Prodotti della pesca","h2o":62.80,"alc":0.00,"ami":0.00,"cho":0.70,"col":88.00,"ENc":237.00,"ENj":993.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":19.60,"pro":14.60,"sug":0.70},
    {"id":31,"name":"Anguilla, affumicata","cat":"Prodotti della pesca","h2o":62.00,"alc":0.00,"ami":0.00,"cho":0.20,"col":0.00,"ENc":231.00,"ENj":966.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":17.80,"pro":17.50,"sug":0.20},
    {"id":32,"name":"Anguilla, marinata","cat":"Prodotti della pesca","h2o":59.90,"alc":0.00,"ami":0.00,"cho":0.60,"col":0.00,"ENc":259.00,"ENj":1082.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":21.90,"pro":14.80,"sug":0.60},
    {"id":33,"name":"Animelle di bovino","cat":"Frattaglie","h2o":57.70,"alc":0.00,"ami":0.00,"cho":0.10,"col":250.00,"ENc":304.00,"ENj":1271.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":28.60,"pro":11.50,"sug":0.10},
    {"id":34,"name":"Animelle di bovino, cotte","cat":"Frattaglie","h2o":39.60,"alc":0.00,"ami":0.00,"cho":0.20,"col":357.00,"ENc":434.00,"ENj":1818.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":40.90,"pro":16.40,"sug":0.20},
    {"id":35,"name":"Anona, fresca","cat":"Frutta","h2o":78.20,"alc":0.00,"ami":0.00,"cho":15.80,"col":0.00,"ENc":73.00,"ENj":304.00,"fin":0.00,"fso":0.00,"fib":2.20,"fat":0.20,"pro":1.80,"sug":0.00},
    {"id":36,"name":"Aperitivi a base di vino","cat":"Bevande alcoliche","h2o":65.50,"alc":17.40,"ami":0.00,"cho":17.00,"col":0.00,"ENc":186.00,"ENj":777.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":17.00},
    {"id":37,"name":"Arachidi, tostate","cat":"Frutta secca a guscio e semi oleaginosi","h2o":2.30,"alc":0.00,"ami":4.90,"cho":8.50,"col":0.00,"ENc":620.00,"ENj":2593.00,"fin":9.89,"fso":9.89,"fib":10.90,"fat":50.00,"pro":29.00,"sug":3.10},
    {"id":38,"name":"Aragosta","cat":"Prodotti della pesca","h2o":78.10,"alc":0.00,"ami":0.00,"cho":1.00,"col":70.00,"ENc":85.00,"ENj":355.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.90,"pro":16.00,"sug":1.00},
    {"id":39,"name":"Aragosta, bollita","cat":"Prodotti della pesca","h2o":77.10,"alc":0.00,"ami":0.00,"cho":1.00,"col":85.00,"ENc":86.00,"ENj":358.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.90,"pro":16.20,"sug":1.00},
    {"id":40,"name":"Arance bionde succo, fresco","cat":"Frutta","h2o":89.50,"alc":0.00,"ami":0.00,"cho":9.60,"col":0.00,"ENc":38.00,"ENj":159.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.50,"sug":9.60},
    {"id":41,"name":"Arance bionde, fresche","cat":"Frutta","h2o":87.10,"alc":0.00,"ami":0.00,"cho":9.90,"col":0.00,"ENc":45.00,"ENj":188.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":0.20,"pro":0.70,"sug":9.90},
    {"id":42,"name":"Arance Moro, fresche","cat":"Frutta","h2o":88.10,"alc":0.00,"ami":0.00,"cho":9.00,"col":0.00,"ENc":42.00,"ENj":174.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":0.20,"pro":0.70,"sug":9.00},
    {"id":43,"name":"Arance rosse succo, fresco","cat":"Frutta","h2o":89.30,"alc":0.00,"ami":0.00,"cho":9.80,"col":0.00,"ENc":39.00,"ENj":163.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.50,"sug":9.80},
    {"id":44,"name":"Arance rosse, fresche","cat":"Frutta","h2o":87.20,"alc":0.00,"ami":0.00,"cho":9.90,"col":0.00,"ENc":45.00,"ENj":188.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":0.20,"pro":0.70,"sug":9.90},
    {"id":45,"name":"Arance Sanguinello, fresche","cat":"Frutta","h2o":86.50,"alc":0.00,"ami":0.00,"cho":10.60,"col":0.00,"ENc":47.00,"ENj":195.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":0.10,"pro":0.70,"sug":10.60},
    {"id":46,"name":"Arance succo, fresco","cat":"Frutta","h2o":89.30,"alc":0.00,"ami":0.00,"cho":8.20,"col":0.00,"ENc":33.00,"ENj":137.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.50,"sug":8.20},
    {"id":47,"name":"Arance Tarocco, fresche","cat":"Frutta","h2o":87.20,"alc":0.00,"ami":0.00,"cho":10.00,"col":0.00,"ENc":45.00,"ENj":190.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":0.20,"pro":0.70,"sug":10.00},
    {"id":48,"name":"Arance Valencia, fresche","cat":"Frutta","h2o":89.10,"alc":0.00,"ami":0.00,"cho":8.00,"col":0.00,"ENc":39.00,"ENj":162.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":0.30,"pro":0.70,"sug":8.00},
    {"id":49,"name":"Arance Washington Navel, fresche","cat":"Frutta","h2o":87.40,"alc":0.00,"ami":0.00,"cho":9.40,"col":0.00,"ENc":44.00,"ENj":185.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":0.20,"pro":1.00,"sug":9.40},
    {"id":50,"name":"Arance, fresche","cat":"Frutta","h2o":87.20,"alc":0.00,"ami":0.00,"cho":7.80,"col":0.00,"ENc":37.00,"ENj":155.00,"fin":1.00,"fso":1.00,"fib":1.60,"fat":0.20,"pro":0.70,"sug":7.80},
    {"id":51,"name":"Aranciata","cat":"Prodotti vari","h2o":86.00,"alc":0.00,"ami":0.00,"cho":10.00,"col":0.00,"ENc":38.00,"ENj":159.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.10,"sug":10.00},
    {"id":52,"name":"Aringa","cat":"Prodotti della pesca","h2o":65.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":85.00,"ENc":216.00,"ENj":905.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":16.70,"pro":16.50,"sug":0.00},
    {"id":53,"name":"Aringa, affumicata","cat":"Prodotti della pesca","h2o":65.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":82.00,"ENc":194.00,"ENj":811.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":12.70,"pro":19.90,"sug":0.00},
    {"id":54,"name":"Aringa, marinata","cat":"Prodotti della pesca","h2o":63.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":97.00,"ENc":199.00,"ENj":833.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":14.00,"pro":18.30,"sug":0.00},
    {"id":55,"name":"Aringa, sotto sale","cat":"Prodotti della pesca","h2o":48.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":110.00,"ENc":218.00,"ENj":911.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":15.40,"pro":19.80,"sug":0.00},
    {"id":56,"name":"Asparagi di bosco, crudi","cat":"Verdure e ortaggi","h2o":89.30,"alc":0.00,"ami":0.00,"cho":4.00,"col":0.00,"ENc":40.00,"ENj":166.00,"fin":0.00,"fso":0.00,"fib":2.20,"fat":0.20,"pro":4.60,"sug":4.00},
    {"id":57,"name":"Asparagi di campo, cotti, bolliti","cat":"Verdure e ortaggi","h2o":91.00,"alc":0.00,"ami":0.00,"cho":3.40,"col":0.00,"ENc":34.00,"ENj":142.00,"fin":1.57,"fso":1.57,"fib":2.10,"fat":0.20,"pro":3.80,"sug":3.40},
    {"id":58,"name":"Asparagi di campo, crudi","cat":"Verdure e ortaggi","h2o":91.40,"alc":0.00,"ami":0.00,"cho":3.30,"col":0.00,"ENc":33.00,"ENj":136.00,"fin":0.00,"fso":0.00,"fib":2.00,"fat":0.20,"pro":3.60,"sug":3.30},
    {"id":59,"name":"Asparagi di serra, crudi","cat":"Verdure e ortaggi","h2o":92.00,"alc":0.00,"ami":0.00,"cho":3.00,"col":0.00,"ENc":28.00,"ENj":119.00,"fin":0.00,"fso":0.00,"fib":2.10,"fat":0.10,"pro":3.00,"sug":3.00},
    {"id":60,"name":"Avocado, fresco","cat":"Frutta","h2o":66.00,"alc":0.00,"ami":0.00,"cho":1.80,"col":0.00,"ENc":238.00,"ENj":996.00,"fin":0.00,"fso":0.00,"fib":3.30,"fat":23.00,"pro":4.40,"sug":1.80},
    {"id":61,"name":"Baba' al rhum","cat":"Dolci","h2o":49.40,"alc":8.00,"ami":20.60,"cho":41.20,"col":0.00,"ENc":288.00,"ENj":1203.00,"fin":0.00,"fso":0.00,"fib":1.10,"fat":5.70,"pro":5.90,"sug":18.50},
    {"id":62,"name":"Babaco, fresco","cat":"Frutta","h2o":92.50,"alc":0.00,"ami":0.00,"cho":4.50,"col":0.00,"ENc":24.00,"ENj":99.00,"fin":0.70,"fso":0.70,"fib":1.10,"fat":0.20,"pro":0.70,"sug":4.50},
    {"id":63,"name":"Baccalà alla vicentina","cat":"Ricette Italiane","h2o":28.10,"alc":0.00,"ami":0.00,"cho":0.30,"col":80.00,"ENc":520.00,"ENj":2174.00,"fin":0.00,"fso":0.00,"fib":0.70,"fat":47.90,"pro":21.50,"sug":0.20},
    {"id":64,"name":"Banane, fresche","cat":"Frutta","h2o":78.80,"alc":0.00,"ami":2.40,"cho":17.40,"col":0.00,"ENc":76.00,"ENj":319.00,"fin":1.19,"fso":1.19,"fib":1.80,"fat":0.30,"pro":1.20,"sug":14.80},
    {"id":65,"name":"Barbabietole rosse, cotte, bollite","cat":"Verdure e ortaggi","h2o":86.40,"alc":0.00,"ami":0.00,"cho":7.80,"col":0.00,"ENc":43.00,"ENj":180.00,"fin":2.05,"fso":2.05,"fib":2.60,"fat":0.00,"pro":2.10,"sug":7.80},
    {"id":66,"name":"Barbabietole rosse, crude","cat":"Verdure e ortaggi","h2o":91.30,"alc":0.00,"ami":0.00,"cho":4.00,"col":0.00,"ENc":25.00,"ENj":103.00,"fin":0.00,"fso":0.00,"fib":2.60,"fat":0.00,"pro":1.10,"sug":4.00},
    {"id":67,"name":"Barretta al cocco ricoperta di cioccolato","cat":"Dolci","h2o":7.60,"alc":0.00,"ami":4.10,"cho":58.20,"col":10.00,"ENc":472.00,"ENj":1976.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":26.10,"pro":4.80,"sug":53.70},
    {"id":68,"name":"Basilico, fresco","cat":"Verdure e ortaggi","h2o":92.30,"alc":0.00,"ami":0.00,"cho":5.20,"col":0.00,"ENc":42.00,"ENj":177.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":0.80,"pro":3.10,"sug":5.10},
    {"id":69,"name":"Bastoncini di crusca di frumento","cat":"Cereali e derivati","h2o":8.50,"alc":0.00,"ami":25.10,"cho":38.40,"col":0.00,"ENc":301.00,"ENj":1258.00,"fin":0.00,"fso":0.00,"fib":35.90,"fat":3.20,"pro":14.00,"sug":10.80},
    {"id":70,"name":"Besciamella","cat":"Ricette Italiane","h2o":74.00,"alc":0.00,"ami":5.80,"cho":9.50,"col":40.00,"ENc":139.00,"ENj":580.00,"fin":0.00,"fso":0.00,"fib":0.90,"fat":9.50,"pro":3.90,"sug":3.10},
    {"id":71,"name":"Bevanda tipo cola","cat":"Prodotti vari","h2o":89.00,"alc":0.00,"ami":0.00,"cho":10.50,"col":0.00,"ENc":39.00,"ENj":165.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":10.50},
    {"id":72,"name":"Bieta, cotta, al microonde","cat":"Verdure e ortaggi","h2o":93.20,"alc":0.00,"ami":0.00,"cho":1.80,"col":0.00,"ENc":15.00,"ENj":64.00,"fin":0.00,"fso":0.00,"fib":1.20,"fat":0.10,"pro":1.30,"sug":1.80},
    {"id":73,"name":"Bieta, cotta, bollita","cat":"Verdure e ortaggi","h2o":92.20,"alc":0.00,"ami":0.00,"cho":2.10,"col":0.00,"ENc":18.00,"ENj":74.00,"fin":1.20,"fso":1.20,"fib":1.40,"fat":0.10,"pro":1.50,"sug":2.10},
    {"id":74,"name":"Bieta, cruda","cat":"Verdure e ortaggi","h2o":93.30,"alc":0.00,"ami":0.00,"cho":1.80,"col":0.00,"ENc":15.00,"ENj":64.00,"fin":0.00,"fso":0.00,"fib":1.20,"fat":0.10,"pro":1.30,"sug":1.80},
    {"id":75,"name":"Birra chiara","cat":"Bevande alcoliche","h2o":93.50,"alc":2.80,"ami":0.00,"cho":3.50,"col":0.00,"ENc":34.00,"ENj":140.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.20,"sug":3.50},
    {"id":76,"name":"Biscotti, alla soia","cat":"Cereali e derivati","h2o":4.60,"alc":0.00,"ami":58.70,"cho":70.80,"col":0.00,"ENc":375.00,"ENj":1571.00,"fin":0.00,"fso":0.00,"fib":4.50,"fat":6.10,"pro":11.50,"sug":6.20},
    {"id":77,"name":"Biscotti, frollini","cat":"Cereali e derivati","h2o":2.40,"alc":0.00,"ami":45.20,"cho":71.70,"col":0.00,"ENc":426.00,"ENj":1781.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":13.80,"pro":7.20,"sug":22.00},
    {"id":78,"name":"Biscotti, integrali","cat":"Cereali e derivati","h2o":2.40,"alc":0.00,"ami":38.20,"cho":70.80,"col":0.00,"ENc":419.00,"ENj":1755.00,"fin":5.07,"fso":5.07,"fib":6.00,"fat":12.30,"pro":7.80,"sug":28.80},
    {"id":79,"name":"Biscotti, integrali, con soia","cat":"Cereali e derivati","h2o":5.10,"alc":0.00,"ami":53.90,"cho":60.40,"col":0.00,"ENc":464.00,"ENj":1940.00,"fin":0.00,"fso":0.00,"fib":3.80,"fat":20.40,"pro":11.50,"sug":1.10},
    {"id":80,"name":"Biscotti, secchi","cat":"Cereali e derivati","h2o":2.20,"alc":0.00,"ami":56.30,"cho":80.40,"col":0.00,"ENc":404.00,"ENj":1691.00,"fin":1.32,"fso":1.32,"fib":2.60,"fat":7.90,"pro":6.60,"sug":18.50},
    {"id":81,"name":"Biscotti, wafers","cat":"Cereali e derivati","h2o":2.50,"alc":0.00,"ami":45.00,"cho":76.20,"col":0.00,"ENc":452.00,"ENj":1893.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":15.00,"pro":7.10,"sug":26.70},
    {"id":82,"name":"Boga","cat":"Prodotti della pesca","h2o":79.30,"alc":0.00,"ami":0.00,"cho":0.80,"col":25.00,"ENc":87.00,"ENj":364.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.20,"pro":18.30,"sug":0.80},
    {"id":83,"name":"Bottarga, cefalo muggine","cat":"Prodotti della pesca","h2o":27.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":440.00,"ENc":405.00,"ENj":1696.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":25.70,"pro":43.50,"sug":0.00},
    {"id":84,"name":"Bovino adulto o vitellone, copertina di sotto, copertina di spalla, sottospalla e collo, crudo","cat":"Carni fresche","h2o":72.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":132.00,"ENj":554.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.70,"pro":20.30,"sug":0.00},
    {"id":85,"name":"Bovino adulto o vitellone, costata, crudo","cat":"Carni fresche","h2o":71.60,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":140.00,"ENj":586.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.10,"pro":21.30,"sug":0.00},
    {"id":86,"name":"Bovino adulto o vitellone, fesa, crudo","cat":"Carni fresche","h2o":75.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":103.00,"ENj":433.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.80,"pro":21.80,"sug":0.00},
    {"id":87,"name":"Bovino adulto o vitellone, filetto, crudo","cat":"Carni fresche","h2o":72.70,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":127.00,"ENj":531.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.00,"pro":20.50,"sug":0.00},
    {"id":88,"name":"Bovino adulto o vitellone, geretto anteriore e posteriore, crudo","cat":"Carni fresche","h2o":75.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":112.00,"ENj":470.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.20,"pro":20.90,"sug":0.00},
    {"id":89,"name":"Bovino adulto o vitellone, girello, crudo","cat":"Carni fresche","h2o":74.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":110.00,"ENj":462.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.80,"pro":21.30,"sug":0.00},
    {"id":90,"name":"Bovino adulto o vitellone, lombata, crudo","cat":"Carni fresche","h2o":72.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":134.00,"ENj":561.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.20,"pro":21.80,"sug":0.00},
    {"id":91,"name":"Bovino adulto o vitellone, noce, crudo","cat":"Carni fresche","h2o":75.40,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":106.00,"ENj":443.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.30,"pro":21.30,"sug":0.00},
    {"id":92,"name":"Bovino adulto o vitellone, pancia, biancostato e punta di petto, crudo","cat":"Carni fresche","h2o":69.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":171.00,"ENj":714.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":10.20,"pro":19.70,"sug":0.00},
    {"id":93,"name":"Bovino adulto o vitellone, scamone, crudo","cat":"Carni fresche","h2o":73.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":119.00,"ENj":497.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.70,"pro":21.40,"sug":0.00},
    {"id":94,"name":"Bovino adulto o vitellone, sottofesa, crudo","cat":"Carni fresche","h2o":74.60,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":111.00,"ENj":466.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.60,"pro":22.00,"sug":0.00},
    {"id":95,"name":"Bovino adulto o vitellone, spalla, muscolo, girello e fesone, crudo","cat":"Carni fresche","h2o":75.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":108.00,"ENj":450.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.40,"pro":21.50,"sug":0.00},
    {"id":96,"name":"Bovino adulto o vitellone, tagli anteriori, crudo","cat":"Carni fresche","h2o":72.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":66.00,"ENc":145.00,"ENj":607.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.00,"pro":20.50,"sug":0.00},
    {"id":97,"name":"Bovino adulto o vitellone, tagli posteriori, crudo","cat":"Carni fresche","h2o":74.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":60.00,"ENc":117.00,"ENj":488.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.40,"pro":21.50,"sug":0.00},
    {"id":98,"name":"Brandy","cat":"Bevande alcoliche","h2o":68.90,"alc":32.90,"ami":0.00,"cho":0.00,"col":0.00,"ENc":230.00,"ENj":964.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":0.00},
    {"id":99,"name":"Brasato al Barolo","cat":"Ricette Italiane","h2o":77.00,"alc":0.00,"ami":0.00,"cho":0.30,"col":28.50,"ENc":108.00,"ENj":452.00,"fin":0.00,"fso":0.00,"fib":0.60,"fat":5.60,"pro":13.80,"sug":0.20},
    {"id":100,"name":"Bresaola della Valtellina IGP","cat":"Carni trasformate e conservate","h2o":59.30,"alc":0.00,"ami":0.00,"cho":0.40,"col":63.00,"ENc":152.00,"ENj":636.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.00,"pro":33.10,"sug":0.00},
    {"id":101,"name":"Brie","cat":"Formaggi e latticini","h2o":51.60,"alc":0.00,"ami":0.00,"cho":0.20,"col":98.00,"ENc":320.00,"ENj":1339.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":26.90,"pro":19.30,"sug":0.20},
    {"id":102,"name":"Broccoletti di rapa, cotti, bolliti","cat":"Verdure e ortaggi","h2o":90.90,"alc":0.00,"ami":0.00,"cho":2.10,"col":0.00,"ENc":29.00,"ENj":122.00,"fin":2.24,"fso":2.24,"fib":3.10,"fat":0.30,"pro":3.10,"sug":2.10},
    {"id":103,"name":"Broccoletti di rapa, crudi","cat":"Verdure e ortaggi","h2o":91.40,"alc":0.00,"ami":0.00,"cho":2.00,"col":0.00,"ENc":28.00,"ENj":115.00,"fin":0.00,"fso":0.00,"fib":2.90,"fat":0.30,"pro":2.90,"sug":2.00},
    {"id":104,"name":"Broccolo a testa, cotto, bollito","cat":"Verdure e ortaggi","h2o":91.70,"alc":0.00,"ami":0.00,"cho":3.20,"col":0.00,"ENc":34.00,"ENj":144.00,"fin":2.35,"fso":2.35,"fib":3.20,"fat":0.40,"pro":3.10,"sug":3.20},
    {"id":105,"name":"Broccolo a testa, crudo","cat":"Verdure e ortaggi","h2o":92.00,"alc":0.00,"ami":0.00,"cho":3.10,"col":0.00,"ENc":33.00,"ENj":140.00,"fin":0.00,"fso":0.00,"fib":3.10,"fat":0.40,"pro":3.00,"sug":3.10},
    {"id":106,"name":"Bufalo, solo tessuto muscolare, crudo","cat":"Carni fresche","h2o":74.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":62.00,"ENc":98.00,"ENj":410.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.10,"pro":22.00,"sug":0.00},
    {"id":107,"name":"Burrini","cat":"Formaggi e latticini","h2o":26.50,"alc":0.00,"ami":0.00,"cho":4.10,"col":0.00,"ENc":527.00,"ENj":2204.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":47.00,"pro":22.10,"sug":4.10},
    {"id":108,"name":"Burro","cat":"Oli e grassi","h2o":14.10,"alc":0.00,"ami":0.00,"cho":1.10,"col":250.00,"ENc":758.00,"ENj":3171.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":83.40,"pro":0.80,"sug":1.10},
    {"id":109,"name":"Burro d'arachidi","cat":"Oli e grassi","h2o":0.70,"alc":0.00,"ami":3.30,"cho":8.00,"col":0.00,"ENc":625.00,"ENj":2615.00,"fin":0.00,"fso":0.00,"fib":6.00,"fat":53.70,"pro":24.90,"sug":4.40},
    {"id":110,"name":"Butirro calabro","cat":"Formaggi e latticini","h2o":33.70,"alc":0.00,"ami":0.00,"cho":0.30,"col":0.00,"ENc":449.00,"ENj":1879.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":38.80,"pro":24.70,"sug":0.30},
    {"id":111,"name":"Cacao amaro, in polvere","cat":"Prodotti vari","h2o":2.50,"alc":0.00,"ami":10.50,"cho":11.50,"col":0.00,"ENc":355.00,"ENj":1486.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":25.60,"pro":20.40,"sug":0.00},
    {"id":112,"name":"Caciocavallo","cat":"Formaggi e latticini","h2o":30.00,"alc":0.00,"ami":0.00,"cho":2.30,"col":0.00,"ENc":431.00,"ENj":1805.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":31.10,"pro":35.70,"sug":2.30},
    {"id":113,"name":"Cacioricotta di capra","cat":"Formaggi e latticini","h2o":68.40,"alc":0.00,"ami":0.00,"cho":1.50,"col":0.00,"ENc":182.00,"ENj":761.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":14.30,"pro":11.90,"sug":1.50},
    {"id":114,"name":"Caciotta mista","cat":"Formaggi e latticini","h2o":39.50,"alc":0.00,"ami":0.00,"cho":1.80,"col":0.00,"ENc":384.00,"ENj":1606.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":31.00,"pro":24.50,"sug":1.80},
    {"id":115,"name":"Caciotta romana di pecora","cat":"Formaggi e latticini","h2o":40.50,"alc":0.00,"ami":0.00,"cho":2.10,"col":0.00,"ENc":364.00,"ENj":1525.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":27.30,"pro":27.70,"sug":2.10},
    {"id":116,"name":"Caciotta toscana","cat":"Formaggi e latticini","h2o":42.80,"alc":0.00,"ami":0.00,"cho":0.80,"col":0.00,"ENc":368.00,"ENj":1539.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":29.60,"pro":24.60,"sug":0.80},
    {"id":117,"name":"Caciottina fresca","cat":"Formaggi e latticini","h2o":54.80,"alc":0.00,"ami":0.00,"cho":0.50,"col":0.00,"ENc":263.00,"ENj":1099.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":21.30,"pro":17.30,"sug":0.50},
    {"id":118,"name":"Caciottina mista","cat":"Formaggi e latticini","h2o":41.30,"alc":0.00,"ami":0.00,"cho":1.50,"col":0.00,"ENc":370.00,"ENj":1547.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":28.50,"pro":26.90,"sug":1.50},
    {"id":119,"name":"Caciottina vaccina","cat":"Formaggi e latticini","h2o":48.20,"alc":0.00,"ami":0.00,"cho":3.00,"col":71.00,"ENc":324.00,"ENj":1357.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":25.40,"pro":21.10,"sug":3.00},
    {"id":120,"name":"Caffe' tostato","cat":"Prodotti vari","h2o":4.10,"alc":0.00,"ami":0.00,"cho":28.50,"col":0.00,"ENc":287.00,"ENj":1201.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":15.40,"pro":10.40,"sug":0.00},
    {"id":121,"name":"Calamaro","cat":"Prodotti della pesca","h2o":83.00,"alc":0.00,"ami":0.00,"cho":0.60,"col":177.00,"ENc":68.00,"ENj":284.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.70,"pro":12.60,"sug":0.60},
    {"id":122,"name":"Calamaro surgelato","cat":"Prodotti della pesca","h2o":84.20,"alc":0.00,"ami":0.00,"cho":0.60,"col":176.00,"ENc":68.00,"ENj":285.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.50,"pro":13.10,"sug":0.60},
    {"id":123,"name":"Camembert","cat":"Formaggi e latticini","h2o":51.70,"alc":0.00,"ami":0.00,"cho":0.20,"col":78.00,"ENc":307.00,"ENj":1283.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":24.70,"pro":20.90,"sug":0.20},
    {"id":124,"name":"Canditi","cat":"Dolci","h2o":17.00,"alc":0.00,"ami":0.00,"cho":75.00,"col":0.00,"ENc":292.00,"ENj":1223.00,"fin":0.00,"fso":0.00,"fib":4.50,"fat":0.00,"pro":0.50,"sug":75.00},
    {"id":125,"name":"Cannoli alla crema, industriale","cat":"Dolci","h2o":33.70,"alc":0.00,"ami":28.20,"cho":41.90,"col":0.00,"ENc":368.00,"ENj":1541.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":20.70,"pro":6.20,"sug":10.90},
    {"id":126,"name":"Cannoli siciliani","cat":"Ricette Italiane","h2o":32.70,"alc":0.00,"ami":15.10,"cho":42.70,"col":27.00,"ENc":325.00,"ENj":1358.00,"fin":0.00,"fso":0.00,"fib":2.10,"fat":14.60,"pro":7.20,"sug":26.10},
    {"id":127,"name":"Capitone","cat":"Prodotti della pesca","h2o":62.00,"alc":0.00,"ami":0.00,"cho":0.50,"col":0.00,"ENc":247.00,"ENj":1035.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":21.50,"pro":13.00,"sug":0.50},
    {"id":128,"name":"Capocollo","cat":"Carni trasformate e conservate","h2o":36.30,"alc":0.00,"ami":0.00,"cho":1.40,"col":0.00,"ENc":450.00,"ENj":1884.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":40.20,"pro":20.80,"sug":1.40},
    {"id":129,"name":"Capocollo di Calabria","cat":"Carni trasformate e conservate","h2o":38.50,"alc":0.00,"ami":0.00,"cho":0.90,"col":0.00,"ENc":344.00,"ENj":1439.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":24.90,"pro":29.10,"sug":0.90},
    {"id":130,"name":"Capra, solo tessuto muscolare, crudo","cat":"Carni fresche","h2o":76.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":55.00,"ENc":106.00,"ENj":441.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.30,"pro":21.20,"sug":0.00},
    {"id":131,"name":"Capretto, crudo","cat":"Carni fresche","h2o":74.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":61.00,"ENc":113.00,"ENj":472.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.00,"pro":19.20,"sug":0.00},
    {"id":132,"name":"Caramelle dure","cat":"Dolci","h2o":2.70,"alc":0.00,"ami":0.00,"cho":91.60,"col":0.00,"ENc":344.00,"ENj":1437.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":91.60},
    {"id":133,"name":"Caramelle tipo \"mou\"","cat":"Dolci","h2o":4.80,"alc":0.00,"ami":1.00,"cho":71.10,"col":0.00,"ENc":430.00,"ENj":1798.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":17.20,"pro":2.10,"sug":70.00},
    {"id":134,"name":"Carciofi alla romana","cat":"Ricette Italiane","h2o":82.40,"alc":0.00,"ami":1.10,"cho":1.80,"col":0.00,"ENc":85.00,"ENj":355.00,"fin":0.00,"fso":0.00,"fib":8.10,"fat":5.90,"pro":2.20,"sug":0.60},
    {"id":135,"name":"Carciofi, cotti, al microonde","cat":"Verdure e ortaggi","h2o":91.10,"alc":0.00,"ami":0.50,"cho":2.50,"col":0.00,"ENc":34.00,"ENj":140.00,"fin":0.00,"fso":0.00,"fib":5.60,"fat":0.20,"pro":2.80,"sug":1.90},
    {"id":136,"name":"Carciofi, cotti, bolliti","cat":"Verdure e ortaggi","h2o":88.20,"alc":0.00,"ami":0.70,"cho":3.30,"col":0.00,"ENc":44.00,"ENj":185.00,"fin":2.97,"fso":2.97,"fib":7.40,"fat":0.30,"pro":3.60,"sug":2.60},
    {"id":137,"name":"Carciofi, crudi","cat":"Verdure e ortaggi","h2o":91.30,"alc":0.00,"ami":0.50,"cho":2.50,"col":0.00,"ENc":33.00,"ENj":138.00,"fin":0.00,"fso":0.00,"fib":5.50,"fat":0.20,"pro":2.70,"sug":1.90},
    {"id":138,"name":"Carciofi, surgelati","cat":"Verdure e ortaggi","h2o":90.50,"alc":0.00,"ami":0.50,"cho":2.50,"col":0.00,"ENc":32.00,"ENj":134.00,"fin":1.93,"fso":1.93,"fib":5.00,"fat":0.20,"pro":2.70,"sug":1.90},
    {"id":139,"name":"Carciofo Romanesco IGP","cat":"Verdure e ortaggi","h2o":84.20,"alc":0.00,"ami":0.00,"cho":6.10,"col":0.00,"ENc":48.00,"ENj":199.00,"fin":0.00,"fso":0.00,"fib":5.50,"fat":0.20,"pro":3.00,"sug":0.00},
    {"id":140,"name":"Carciofo, Spinoso sardo","cat":"Verdure e ortaggi","h2o":87.10,"alc":0.00,"ami":0.00,"cho":8.90,"col":0.00,"ENc":45.00,"ENj":187.00,"fin":0.00,"fso":0.00,"fib":1.00,"fat":0.20,"pro":1.90,"sug":1.70},
    {"id":141,"name":"Carciofo, verde, precoce","cat":"Verdure e ortaggi","h2o":84.00,"alc":0.00,"ami":0.00,"cho":5.40,"col":0.00,"ENc":45.00,"ENj":187.00,"fin":0.00,"fso":0.00,"fib":5.50,"fat":0.20,"pro":2.90,"sug":0.00},
    {"id":142,"name":"Carciofo, violetto, precoce","cat":"Verdure e ortaggi","h2o":85.30,"alc":0.00,"ami":0.00,"cho":5.20,"col":0.00,"ENc":43.00,"ENj":182.00,"fin":0.00,"fso":0.00,"fib":5.50,"fat":0.20,"pro":2.80,"sug":0.00},
    {"id":143,"name":"Cardi, cotti, bolliti","cat":"Verdure e ortaggi","h2o":90.50,"alc":0.00,"ami":0.30,"cho":2.90,"col":0.00,"ENc":22.00,"ENj":91.00,"fin":2.08,"fso":2.08,"fib":2.50,"fat":0.20,"pro":1.00,"sug":2.50},
    {"id":144,"name":"Cardi, crudi","cat":"Verdure e ortaggi","h2o":94.30,"alc":0.00,"ami":0.20,"cho":1.70,"col":0.00,"ENc":13.00,"ENj":53.00,"fin":0.00,"fso":0.00,"fib":1.50,"fat":0.10,"pro":0.60,"sug":1.50},
    {"id":145,"name":"Carne bovina in gelatina, in scatola","cat":"Carni trasformate e conservate","h2o":83.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":29.00,"ENc":67.00,"ENj":279.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.80,"pro":12.60,"sug":0.00},
    {"id":146,"name":"Carne bovina pressata, in scatola","cat":"Carni trasformate e conservate","h2o":72.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":45.00,"ENc":141.00,"ENj":589.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.30,"pro":16.50,"sug":0.00},
    {"id":147,"name":"Carote, cotte, al microonde","cat":"Verdure e ortaggi","h2o":88.90,"alc":0.00,"ami":0.00,"cho":7.70,"col":0.00,"ENc":41.00,"ENj":173.00,"fin":0.00,"fso":0.00,"fib":3.10,"fat":0.20,"pro":1.10,"sug":7.70},
    {"id":148,"name":"Carote, cotte, bollite","cat":"Verdure e ortaggi","h2o":88.50,"alc":0.00,"ami":0.00,"cho":8.70,"col":0.00,"ENc":47.00,"ENj":196.00,"fin":1.90,"fso":1.90,"fib":3.60,"fat":0.20,"pro":1.30,"sug":8.70},
    {"id":149,"name":"Carote, crude","cat":"Verdure e ortaggi","h2o":90.00,"alc":0.00,"ami":0.00,"cho":7.60,"col":0.00,"ENc":41.00,"ENj":171.00,"fin":2.70,"fso":2.70,"fib":3.10,"fat":0.20,"pro":1.10,"sug":7.60},
    {"id":150,"name":"Carpa","cat":"Prodotti della pesca","h2o":72.40,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":139.00,"ENj":584.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.10,"pro":18.90,"sug":0.00},
    {"id":151,"name":"Carrube","cat":"Frutta secca a guscio e semi oleaginosi","h2o":10.00,"alc":0.00,"ami":0.90,"cho":49.90,"col":0.00,"ENc":253.00,"ENj":1058.00,"fin":19.07,"fso":19.07,"fib":23.10,"fat":0.70,"pro":3.30,"sug":48.90},
    {"id":152,"name":"Castagnaccio","cat":"Ricette Italiane","h2o":43.40,"alc":0.00,"ami":21.00,"cho":39.30,"col":2.00,"ENc":234.00,"ENj":978.00,"fin":0.00,"fso":0.00,"fib":5.80,"fat":6.80,"pro":3.40,"sug":16.20},
    {"id":153,"name":"Castagne","cat":"Frutta secca a guscio e semi oleaginosi","h2o":55.80,"alc":0.00,"ami":25.30,"cho":36.70,"col":0.00,"ENc":174.00,"ENj":728.00,"fin":4.33,"fso":4.33,"fib":4.70,"fat":1.70,"pro":2.90,"sug":8.90},
    {"id":154,"name":"Castagne, arrostite","cat":"Frutta secca a guscio e semi oleaginosi","h2o":42.40,"alc":0.00,"ami":28.30,"cho":41.80,"col":0.00,"ENc":210.00,"ENj":878.00,"fin":7.61,"fso":7.61,"fib":8.30,"fat":2.40,"pro":3.70,"sug":10.70},
    {"id":155,"name":"Castagne, bollite","cat":"Frutta secca a guscio e semi oleaginosi","h2o":63.30,"alc":0.00,"ami":16.90,"cho":26.10,"col":0.00,"ENc":130.00,"ENj":545.00,"fin":4.84,"fso":4.84,"fib":5.40,"fat":1.30,"pro":2.50,"sug":7.50},
    {"id":156,"name":"Castagne, secche","cat":"Frutta secca a guscio e semi oleaginosi","h2o":10.10,"alc":0.00,"ami":41.70,"cho":62.00,"col":0.00,"ENc":315.00,"ENj":1317.00,"fin":12.66,"fso":12.66,"fib":13.80,"fat":3.40,"pro":6.00,"sug":16.10},
    {"id":157,"name":"Cavallo, solo tessuto muscolare, crudo","cat":"Carni fresche","h2o":74.00,"alc":0.00,"ami":0.60,"cho":0.70,"col":50.00,"ENc":106.00,"ENj":442.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.00,"pro":23.50,"sug":0.00},
    {"id":158,"name":"Cavallo, tessuto muscolare e grasso, crudo","cat":"Carni fresche","h2o":72.90,"alc":0.00,"ami":0.50,"cho":0.60,"col":61.00,"ENc":143.00,"ENj":597.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.80,"pro":19.80,"sug":0.00},
    {"id":159,"name":"Caviale, storione","cat":"Prodotti della pesca","h2o":46.00,"alc":0.00,"ami":0.00,"cho":3.30,"col":300.00,"ENc":255.00,"ENj":1067.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":15.00,"pro":26.90,"sug":3.30},
    {"id":160,"name":"Cavolfiore, cotto, al microonde","cat":"Verdure e ortaggi","h2o":89.40,"alc":0.00,"ami":0.30,"cho":2.80,"col":0.00,"ENc":30.00,"ENj":125.00,"fin":0.00,"fso":0.00,"fib":2.40,"fat":0.20,"pro":3.20,"sug":2.40},
    {"id":161,"name":"Cavolfiore, cotto, bollito","cat":"Verdure e ortaggi","h2o":89.80,"alc":0.00,"ami":0.30,"cho":2.90,"col":0.00,"ENc":31.00,"ENj":132.00,"fin":1.82,"fso":1.82,"fib":2.60,"fat":0.20,"pro":3.40,"sug":2.60},
    {"id":162,"name":"Cavolfiore, crudo","cat":"Verdure e ortaggi","h2o":90.50,"alc":0.00,"ami":0.30,"cho":2.70,"col":0.00,"ENc":30.00,"ENj":124.00,"fin":0.00,"fso":0.00,"fib":2.40,"fat":0.20,"pro":3.20,"sug":2.40},
    {"id":163,"name":"Cavoli di Bruxelles, cotti, bolliti","cat":"Verdure e ortaggi","h2o":84.10,"alc":0.00,"ami":0.90,"cho":4.60,"col":0.00,"ENc":53.00,"ENj":220.00,"fin":4.72,"fso":4.72,"fib":5.60,"fat":0.60,"pro":4.70,"sug":3.70},
    {"id":164,"name":"Cavoli di Bruxelles, crudi","cat":"Verdure e ortaggi","h2o":85.70,"alc":0.00,"ami":0.80,"cho":4.20,"col":0.00,"ENc":47.00,"ENj":197.00,"fin":0.00,"fso":0.00,"fib":5.00,"fat":0.50,"pro":4.20,"sug":3.30},
    {"id":165,"name":"Cavolo broccolo verde ramoso, cotto, bollito","cat":"Verdure e ortaggi","h2o":80.70,"alc":0.00,"ami":0.00,"cho":3.50,"col":0.00,"ENc":52.00,"ENj":219.00,"fin":4.34,"fso":4.34,"fib":5.30,"fat":0.50,"pro":6.00,"sug":3.50},
    {"id":166,"name":"Cavolo broccolo verde ramoso, crudo","cat":"Verdure e ortaggi","h2o":89.00,"alc":0.00,"ami":0.00,"cho":2.00,"col":0.00,"ENc":30.00,"ENj":125.00,"fin":0.00,"fso":0.00,"fib":3.00,"fat":0.30,"pro":3.40,"sug":2.00},
    {"id":167,"name":"Cavolo cappuccio, rosso, crudo","cat":"Verdure e ortaggi","h2o":92.30,"alc":0.00,"ami":0.00,"cho":2.70,"col":0.00,"ENc":22.00,"ENj":90.00,"fin":0.00,"fso":0.00,"fib":1.00,"fat":0.20,"pro":1.90,"sug":2.70},
    {"id":168,"name":"Cavolo cappuccio, verde, cotto, bollito","cat":"Verdure e ortaggi","h2o":92.10,"alc":0.00,"ami":0.00,"cho":2.50,"col":0.00,"ENc":24.00,"ENj":100.00,"fin":1.93,"fso":1.93,"fib":2.60,"fat":0.10,"pro":2.10,"sug":2.50},
    {"id":169,"name":"Cavolo cappuccio, verde, crudo","cat":"Verdure e ortaggi","h2o":92.20,"alc":0.00,"ami":0.00,"cho":2.50,"col":0.00,"ENc":24.00,"ENj":100.00,"fin":2.26,"fso":2.26,"fib":2.60,"fat":0.10,"pro":2.10,"sug":2.50},
    {"id":170,"name":"Cavolo verza, cotto, bollito","cat":"Verdure e ortaggi","h2o":90.70,"alc":0.00,"ami":0.00,"cho":3.80,"col":0.00,"ENc":29.00,"ENj":121.00,"fin":2.02,"fso":2.02,"fib":2.90,"fat":0.10,"pro":2.00,"sug":3.80},
    {"id":171,"name":"Cavolo verza, cruda","cat":"Verdure e ortaggi","h2o":90.70,"alc":0.00,"ami":0.00,"cho":3.80,"col":0.00,"ENc":29.00,"ENj":121.00,"fin":2.53,"fso":2.53,"fib":2.90,"fat":0.10,"pro":2.00,"sug":3.80},
    {"id":172,"name":"Ceci, in scatola, scolati","cat":"Legumi","h2o":69.20,"alc":0.00,"ami":11.70,"cho":13.90,"col":0.00,"ENc":111.00,"ENj":465.00,"fin":5.30,"fso":5.30,"fib":5.70,"fat":2.30,"pro":6.70,"sug":1.00},
    {"id":173,"name":"Ceci, secchi","cat":"Legumi","h2o":10.30,"alc":0.00,"ami":39.30,"cho":46.90,"col":0.00,"ENc":343.00,"ENj":1437.00,"fin":12.45,"fso":12.45,"fib":13.60,"fat":6.30,"pro":20.90,"sug":3.70},
    {"id":174,"name":"Ceci, secchi, cotti, bolliti","cat":"Legumi","h2o":63.60,"alc":0.00,"ami":16.00,"cho":18.90,"col":0.00,"ENc":132.00,"ENj":553.00,"fin":5.29,"fso":5.29,"fib":5.80,"fat":2.40,"pro":7.00,"sug":1.30},
    {"id":175,"name":"Cefalo muggine","cat":"Prodotti della pesca","h2o":73.40,"alc":0.00,"ami":0.00,"cho":0.70,"col":53.00,"ENc":127.00,"ENj":531.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.80,"pro":15.80,"sug":0.70},
    {"id":176,"name":"Cernia surgelata","cat":"Prodotti della pesca","h2o":78.50,"alc":0.00,"ami":0.00,"cho":0.50,"col":0.00,"ENc":88.00,"ENj":368.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.00,"pro":17.00,"sug":0.50},
    {"id":177,"name":"Cervello di bovino","cat":"Frattaglie","h2o":76.60,"alc":0.00,"ami":0.00,"cho":0.80,"col":2000.00,"ENc":157.00,"ENj":658.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":12.70,"pro":10.00,"sug":0.80},
    {"id":178,"name":"Cervo, solo tessuto muscolare, crudo","cat":"Carni fresche","h2o":76.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":75.00,"ENc":91.00,"ENj":382.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.80,"pro":21.00,"sug":0.00},
    {"id":179,"name":"Cetrioli, freschi","cat":"Verdure e ortaggi","h2o":96.50,"alc":0.00,"ami":0.00,"cho":1.80,"col":0.00,"ENc":16.00,"ENj":65.00,"fin":0.54,"fso":0.54,"fib":0.80,"fat":0.50,"pro":0.70,"sug":1.80},
    {"id":180,"name":"Cheddar","cat":"Formaggi e latticini","h2o":38.50,"alc":0.00,"ami":0.00,"cho":0.50,"col":0.00,"ENc":388.00,"ENj":1622.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":31.00,"pro":26.70,"sug":0.50},
    {"id":181,"name":"Cheese burger, fast food","cat":"Fast-food a base di carne","h2o":38.40,"alc":0.00,"ami":0.00,"cho":30.20,"col":0.00,"ENc":300.00,"ENj":1257.00,"fin":0.00,"fso":0.00,"fib":2.00,"fat":13.10,"pro":16.30,"sug":0.00},
    {"id":182,"name":"Chicken Nuggets, fast food","cat":"Fast-food a base di carne","h2o":51.80,"alc":0.00,"ami":12.70,"cho":15.40,"col":0.00,"ENc":242.00,"ENj":1012.00,"fin":0.00,"fso":0.00,"fib":0.90,"fat":12.40,"pro":17.70,"sug":1.50},
    {"id":183,"name":"Ciccioli","cat":"Carni trasformate e conservate","h2o":2.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":90.00,"ENc":636.00,"ENj":2662.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":50.60,"pro":45.20,"sug":0.00},
    {"id":184,"name":"Cicoria, da taglio, coltivata, cruda","cat":"Verdure e ortaggi","h2o":95.00,"alc":0.00,"ami":0.00,"cho":1.70,"col":0.00,"ENc":14.00,"ENj":61.00,"fin":0.00,"fso":0.00,"fib":1.20,"fat":0.10,"pro":1.20,"sug":1.70},
    {"id":185,"name":"Cicoria, di campo, cotta, bollita","cat":"Verdure e ortaggi","h2o":93.30,"alc":0.00,"ami":0.00,"cho":0.70,"col":0.00,"ENc":17.00,"ENj":72.00,"fin":2.43,"fso":2.43,"fib":3.60,"fat":0.20,"pro":1.40,"sug":0.70},
    {"id":186,"name":"Cicoria, di campo, cruda","cat":"Verdure e ortaggi","h2o":93.40,"alc":0.00,"ami":0.00,"cho":0.70,"col":0.00,"ENc":17.00,"ENj":72.00,"fin":0.00,"fso":0.00,"fib":3.60,"fat":0.20,"pro":1.40,"sug":0.70},
    {"id":187,"name":"Cicoria, witloof o indivia belga, cruda","cat":"Verdure e ortaggi","h2o":94.30,"alc":0.00,"ami":0.00,"cho":3.20,"col":0.00,"ENc":20.00,"ENj":82.00,"fin":0.97,"fso":0.97,"fib":1.10,"fat":0.30,"pro":0.70,"sug":3.20},
    {"id":188,"name":"Ciliege, candite","cat":"Frutta","h2o":28.60,"alc":0.00,"ami":0.00,"cho":66.40,"col":0.00,"ENc":252.00,"ENj":1056.00,"fin":0.00,"fso":0.00,"fib":0.90,"fat":0.00,"pro":0.40,"sug":66.40},
    {"id":189,"name":"Ciliege, fresche","cat":"Frutta","h2o":86.20,"alc":0.00,"ami":0.00,"cho":11.00,"col":0.00,"ENc":48.00,"ENj":201.00,"fin":0.80,"fso":0.80,"fib":1.30,"fat":0.10,"pro":0.80,"sug":11.00},
    {"id":190,"name":"Cioccolato, al latte","cat":"Dolci","h2o":0.80,"alc":0.00,"ami":0.00,"cho":50.50,"col":10.00,"ENc":552.00,"ENj":2308.00,"fin":0.00,"fso":0.00,"fib":3.20,"fat":36.30,"pro":7.30,"sug":50.50},
    {"id":191,"name":"Cioccolato, al latte con nocciole","cat":"Dolci","h2o":1.10,"alc":0.00,"ami":0.00,"cho":48.60,"col":9.00,"ENc":553.00,"ENj":2313.00,"fin":0.00,"fso":0.00,"fib":4.80,"fat":36.90,"pro":7.20,"sug":48.60},
    {"id":192,"name":"Cioccolato, fondente","cat":"Dolci","h2o":0.50,"alc":0.00,"ami":0.00,"cho":49.70,"col":0.00,"ENc":531.00,"ENj":2222.00,"fin":0.00,"fso":0.00,"fib":8.00,"fat":33.60,"pro":6.60,"sug":49.70},
    {"id":193,"name":"Cipolle, cotte, bollite","cat":"Verdure e ortaggi","h2o":89.20,"alc":0.00,"ami":0.00,"cho":7.80,"col":0.00,"ENc":39.00,"ENj":161.00,"fin":0.82,"fso":0.82,"fib":1.40,"fat":0.10,"pro":1.40,"sug":7.80},
    {"id":194,"name":"Cipolle, crude","cat":"Verdure e ortaggi","h2o":92.10,"alc":0.00,"ami":0.00,"cho":5.70,"col":0.00,"ENc":28.00,"ENj":118.00,"fin":0.88,"fso":0.88,"fib":1.00,"fat":0.10,"pro":1.00,"sug":5.70},
    {"id":195,"name":"Cipolline, cotte, bollite","cat":"Verdure e ortaggi","h2o":84.90,"alc":0.00,"ami":0.00,"cho":10.90,"col":0.00,"ENc":53.00,"ENj":223.00,"fin":1.60,"fso":1.60,"fib":2.40,"fat":0.10,"pro":1.70,"sug":10.90},
    {"id":196,"name":"Cipolline, crude","cat":"Verdure e ortaggi","h2o":88.20,"alc":0.00,"ami":0.00,"cho":8.50,"col":0.00,"ENc":42.00,"ENj":175.00,"fin":1.48,"fso":1.48,"fib":1.90,"fat":0.10,"pro":1.30,"sug":8.50},
    {"id":197,"name":"Clementine, fresche","cat":"Frutta","h2o":87.50,"alc":0.00,"ami":0.00,"cho":8.70,"col":0.00,"ENc":40.00,"ENj":165.00,"fin":0.00,"fso":0.00,"fib":1.20,"fat":0.10,"pro":0.90,"sug":8.70},
    {"id":198,"name":"Cocco, essiccato","cat":"Frutta secca a guscio e semi oleaginosi","h2o":2.30,"alc":0.00,"ami":0.00,"cho":16.40,"col":0.00,"ENc":669.00,"ENj":2800.00,"fin":0.00,"fso":0.00,"fib":13.70,"fat":62.00,"pro":5.60,"sug":16.40},
    {"id":199,"name":"Cocco, fresco","cat":"Frutta secca a guscio e semi oleaginosi","h2o":50.90,"alc":0.00,"ami":0.00,"cho":4.40,"col":0.00,"ENc":358.00,"ENj":1497.00,"fin":0.00,"fso":0.00,"fib":6.10,"fat":35.00,"pro":3.50,"sug":4.40},
    {"id":200,"name":"Cocktail di frutta sciroppata","cat":"Frutta","h2o":81.80,"alc":0.00,"ami":0.00,"cho":14.80,"col":0.00,"ENc":59.00,"ENj":248.00,"fin":0.00,"fso":0.00,"fib":1.00,"fat":0.00,"pro":0.40,"sug":14.80},
    {"id":201,"name":"Cocomero, fresco","cat":"Frutta","h2o":95.30,"alc":0.00,"ami":0.00,"cho":3.70,"col":0.00,"ENc":16.00,"ENj":67.00,"fin":0.20,"fso":0.20,"fib":0.20,"fat":0.00,"pro":0.40,"sug":3.70},
    {"id":202,"name":"Coniglio intero, crudo","cat":"Carni fresche","h2o":74.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":52.00,"ENc":118.00,"ENj":495.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.30,"pro":19.90,"sug":0.00},
    {"id":203,"name":"Coniglio, coscio, crudo","cat":"Carni fresche","h2o":72.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":60.00,"ENc":137.00,"ENj":574.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.90,"pro":21.00,"sug":0.00},
    {"id":204,"name":"Coniglio, intero surgelato, crudo","cat":"Carni fresche","h2o":74.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":51.00,"ENc":116.00,"ENj":487.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.90,"pro":20.30,"sug":0.00},
    {"id":205,"name":"Coniglio, intero, cotto","cat":"Carni fresche","h2o":62.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":73.00,"ENc":166.00,"ENj":693.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.00,"pro":27.90,"sug":0.00},
    {"id":206,"name":"Coppa","cat":"Carni trasformate e conservate","h2o":34.70,"alc":0.00,"ami":0.00,"cho":0.00,"col":127.00,"ENc":400.00,"ENj":1674.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":31.60,"pro":28.90,"sug":0.00},
    {"id":207,"name":"Coratella di agnello","cat":"Frattaglie","h2o":77.20,"alc":0.00,"ami":0.00,"cho":1.00,"col":0.00,"ENc":109.00,"ENj":455.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.70,"pro":15.70,"sug":1.00},
    {"id":208,"name":"Coratella di vitello","cat":"Frattaglie","h2o":78.00,"alc":0.00,"ami":0.00,"cho":0.80,"col":0.00,"ENc":100.00,"ENj":419.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.20,"pro":17.10,"sug":0.80},
    {"id":209,"name":"Coregone","cat":"Prodotti della pesca","h2o":75.30,"alc":0.00,"ami":0.00,"cho":0.10,"col":0.00,"ENc":135.00,"ENj":564.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.50,"pro":19.00,"sug":0.10},
    {"id":210,"name":"Corn flakes","cat":"Cereali e derivati","h2o":5.00,"alc":0.00,"ami":70.00,"cho":87.40,"col":0.00,"ENc":367.00,"ENj":1535.00,"fin":0.00,"fso":0.00,"fib":2.80,"fat":0.80,"pro":6.60,"sug":10.40},
    {"id":211,"name":"Cornetti","cat":"Cereali e derivati","h2o":19.50,"alc":0.00,"ami":40.10,"cho":54.70,"col":0.00,"ENc":403.00,"ENj":1685.00,"fin":0.00,"fso":0.00,"fib":2.10,"fat":18.30,"pro":7.20,"sug":10.60},
    {"id":212,"name":"Corvina","cat":"Prodotti della pesca","h2o":78.00,"alc":0.00,"ami":0.00,"cho":0.20,"col":55.00,"ENc":88.00,"ENj":368.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.80,"pro":20.00,"sug":0.20},
    {"id":213,"name":"Cotechino Modena IGP, cotto","cat":"Carni trasformate e conservate","h2o":54.40,"alc":0.00,"ami":0.00,"cho":3.20,"col":86.00,"ENc":253.00,"ENj":1059.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":16.30,"pro":23.60,"sug":0.00},
    {"id":214,"name":"Cotechino, confezionato precotto","cat":"Carni trasformate e conservate","h2o":36.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":449.00,"ENj":1881.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":42.30,"pro":17.20,"sug":0.00},
    {"id":215,"name":"Couscous, cotto","cat":"Cereali e derivati","h2o":55.10,"alc":0.00,"ami":34.90,"cho":39.70,"col":0.00,"ENc":179.00,"ENj":747.00,"fin":0.00,"fso":0.00,"fib":2.70,"fat":1.10,"pro":3.60,"sug":1.30},
    {"id":216,"name":"Couscous, crudo","cat":"Cereali e derivati","h2o":9.10,"alc":0.00,"ami":67.10,"cho":76.50,"col":0.00,"ENc":361.00,"ENj":1509.00,"fin":0.00,"fso":0.00,"fib":4.50,"fat":1.10,"pro":13.70,"sug":2.70},
    {"id":217,"name":"Cozza o mitilo","cat":"Prodotti della pesca","h2o":82.10,"alc":0.00,"ami":2.80,"cho":3.40,"col":32.00,"ENc":84.00,"ENj":351.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.70,"pro":11.70,"sug":0.30},
    {"id":218,"name":"Crackers, al formaggio","cat":"Cereali e derivati","h2o":5.50,"alc":0.00,"ami":48.90,"cho":59.20,"col":0.00,"ENc":498.00,"ENj":2082.00,"fin":0.00,"fso":0.00,"fib":2.80,"fat":25.50,"pro":10.10,"sug":5.50},
    {"id":219,"name":"Crackers, alla soia","cat":"Cereali e derivati","h2o":2.90,"alc":0.00,"ami":55.80,"cho":67.70,"col":0.00,"ENc":421.00,"ENj":1763.00,"fin":0.00,"fso":0.00,"fib":4.90,"fat":11.70,"pro":13.10,"sug":6.30},
    {"id":220,"name":"Crackers, integrali","cat":"Cereali e derivati","h2o":5.00,"alc":0.00,"ami":54.20,"cho":61.70,"col":0.00,"ENc":402.00,"ENj":1682.00,"fin":8.80,"fso":8.80,"fib":10.00,"fat":11.40,"pro":12.00,"sug":2.10},
    {"id":221,"name":"Crackers, salati","cat":"Cereali e derivati","h2o":4.50,"alc":0.00,"ami":61.40,"cho":67.50,"col":0.00,"ENc":386.00,"ENj":1616.00,"fin":0.00,"fso":0.00,"fib":2.80,"fat":10.00,"pro":9.40,"sug":0.00},
    {"id":222,"name":"Crema di latte, 12% di lipidi","cat":"Latte e yogurt","h2o":78.50,"alc":0.00,"ami":0.00,"cho":4.40,"col":43.00,"ENc":140.00,"ENj":587.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":12.50,"pro":2.80,"sug":4.40},
    {"id":223,"name":"Crema di nocciole e cacao","cat":"Dolci","h2o":0.80,"alc":0.00,"ami":0.00,"cho":58.10,"col":2.00,"ENc":537.00,"ENj":2247.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":32.40,"pro":6.90,"sug":58.10},
    {"id":224,"name":"Crescenza","cat":"Formaggi e latticini","h2o":58.30,"alc":0.00,"ami":0.00,"cho":1.90,"col":53.00,"ENc":281.00,"ENj":1177.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":23.30,"pro":16.10,"sug":1.90},
    {"id":225,"name":"Croissants","cat":"Cereali e derivati","h2o":31.10,"alc":0.00,"ami":33.50,"cho":38.00,"col":75.00,"ENc":362.00,"ENj":1513.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":20.30,"pro":8.30,"sug":1.10},
    {"id":226,"name":"Crostata, con crema al cacao, industriale","cat":"Dolci","h2o":6.10,"alc":0.00,"ami":28.10,"cho":67.00,"col":0.00,"ENc":492.00,"ENj":2058.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":24.70,"pro":4.60,"sug":36.10},
    {"id":227,"name":"Crostata, con marmellata di albicocche, industriale","cat":"Dolci","h2o":9.90,"alc":0.00,"ami":39.40,"cho":71.70,"col":0.00,"ENc":421.00,"ENj":1761.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":16.50,"pro":0.90,"sug":28.40},
    {"id":228,"name":"Crostata, con marmellata, industriale","cat":"Dolci","h2o":20.90,"alc":0.00,"ami":0.00,"cho":65.50,"col":0.00,"ENc":339.00,"ENj":1418.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.20,"pro":4.90,"sug":0.00},
    {"id":229,"name":"Crusca di frumento","cat":"Cereali e derivati","h2o":8.20,"alc":0.00,"ami":20.70,"cho":26.60,"col":0.00,"ENc":290.00,"ENj":1215.00,"fin":41.13,"fso":41.13,"fib":42.40,"fat":5.50,"pro":14.10,"sug":3.80},
    {"id":230,"name":"Cuore di bovino","cat":"Frattaglie","h2o":75.50,"alc":0.00,"ami":0.00,"cho":0.50,"col":150.00,"ENc":123.00,"ENj":515.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.00,"pro":16.80,"sug":0.50},
    {"id":231,"name":"Cuore di bovino, cotto","cat":"Frattaglie","h2o":62.30,"alc":0.00,"ami":0.00,"cho":0.80,"col":231.00,"ENc":189.00,"ENj":791.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":9.20,"pro":25.80,"sug":0.80},
    {"id":232,"name":"Cuore di equino","cat":"Frattaglie","h2o":73.00,"alc":0.00,"ami":0.00,"cho":0.60,"col":0.00,"ENc":146.00,"ENj":612.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.70,"pro":18.70,"sug":0.60},
    {"id":233,"name":"Cuore di ovino","cat":"Frattaglie","h2o":75.00,"alc":0.00,"ami":0.00,"cho":0.40,"col":0.00,"ENc":134.00,"ENj":560.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.20,"pro":16.90,"sug":0.40},
    {"id":234,"name":"Cuore di pollo","cat":"Frattaglie","h2o":77.90,"alc":0.00,"ami":0.00,"cho":0.20,"col":170.00,"ENc":103.00,"ENj":431.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.10,"pro":14.10,"sug":0.20},
    {"id":235,"name":"Cuore di pollo, cotto","cat":"Frattaglie","h2o":69.90,"alc":0.00,"ami":0.00,"cho":0.30,"col":231.00,"ENc":147.00,"ENj":616.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.30,"pro":20.10,"sug":0.30},
    {"id":236,"name":"Cuore di suino","cat":"Frattaglie","h2o":72.00,"alc":0.00,"ami":0.00,"cho":0.40,"col":0.00,"ENc":159.00,"ENj":667.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":9.40,"pro":18.30,"sug":0.40},
    {"id":237,"name":"Cuore di tacchino","cat":"Frattaglie","h2o":77.90,"alc":0.00,"ami":0.00,"cho":0.20,"col":150.00,"ENc":103.00,"ENj":431.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.10,"pro":14.10,"sug":0.20},
    {"id":238,"name":"Cuore di tacchino, cotto","cat":"Frattaglie","h2o":69.90,"alc":0.00,"ami":0.00,"cho":0.30,"col":238.00,"ENc":147.00,"ENj":616.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.30,"pro":20.10,"sug":0.30},
    {"id":239,"name":"Dadi da brodo","cat":"Prodotti vari","h2o":4.60,"alc":0.00,"ami":0.00,"cho":5.00,"col":0.00,"ENc":250.00,"ENj":1045.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":18.70,"pro":15.70,"sug":0.00},
    {"id":240,"name":"Daino, solo tessuto muscolare, crudo","cat":"Carni fresche","h2o":75.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":70.00,"ENc":95.00,"ENj":397.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.20,"pro":21.00,"sug":0.00},
    {"id":241,"name":"Datteri, secchi","cat":"Frutta","h2o":22.30,"alc":0.00,"ami":0.00,"cho":63.10,"col":0.00,"ENc":270.00,"ENj":1131.00,"fin":7.49,"fso":7.49,"fib":8.70,"fat":0.60,"pro":2.70,"sug":63.10},
    {"id":242,"name":"Dentice","cat":"Prodotti della pesca","h2o":76.10,"alc":0.00,"ami":0.00,"cho":0.70,"col":0.00,"ENc":101.00,"ENj":422.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.50,"pro":16.70,"sug":0.70},
    {"id":243,"name":"Dentice, surgelato","cat":"Prodotti della pesca","h2o":80.10,"alc":0.00,"ami":0.00,"cho":0.70,"col":0.00,"ENc":103.00,"ENj":431.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.50,"pro":17.20,"sug":0.70},
    {"id":244,"name":"Dolce verde","cat":"Formaggi e latticini","h2o":52.50,"alc":0.00,"ami":0.00,"cho":2.50,"col":0.00,"ENc":276.00,"ENj":1153.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":21.10,"pro":19.10,"sug":2.50},
    {"id":245,"name":"Edam","cat":"Formaggi e latticini","h2o":41.80,"alc":0.00,"ami":0.00,"cho":1.40,"col":79.00,"ENc":326.00,"ENj":1364.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":24.30,"pro":25.50,"sug":1.40},
    {"id":246,"name":"Emmenthal","cat":"Formaggi e latticini","h2o":34.60,"alc":0.00,"ami":0.00,"cho":3.60,"col":0.00,"ENc":403.00,"ENj":1686.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":30.60,"pro":28.50,"sug":3.60},
    {"id":247,"name":"Fagiano, crudo","cat":"Carni fresche","h2o":69.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":70.00,"ENc":144.00,"ENj":602.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.20,"pro":24.30,"sug":0.00},
    {"id":248,"name":"Fagioli","cat":"Legumi","h2o":10.50,"alc":0.00,"ami":40.00,"cho":47.50,"col":0.00,"ENc":326.00,"ENj":1362.00,"fin":15.14,"fso":15.14,"fib":17.50,"fat":2.00,"pro":23.60,"sug":3.50},
    {"id":249,"name":"Fagioli dall'occhio, secchi","cat":"Legumi","h2o":10.30,"alc":0.00,"ami":42.60,"cho":50.90,"col":0.00,"ENc":318.00,"ENj":1332.00,"fin":11.70,"fso":11.70,"fib":12.70,"fat":1.40,"pro":22.40,"sug":4.00},
    {"id":250,"name":"Fagioli, Borlotti, freschi","cat":"Legumi","h2o":60.80,"alc":0.00,"ami":19.50,"cho":22.70,"col":0.00,"ENc":143.00,"ENj":597.00,"fin":3.93,"fso":3.93,"fib":4.80,"fat":0.80,"pro":10.20,"sug":1.20},
    {"id":251,"name":"Fagioli, Borlotti, freschi, cotti, bolliti","cat":"Legumi","h2o":78.90,"alc":0.00,"ami":9.70,"cho":11.20,"col":0.00,"ENc":78.00,"ENj":325.00,"fin":0.00,"fso":0.00,"fib":4.20,"fat":0.50,"pro":5.70,"sug":0.50},
    {"id":252,"name":"Fagioli, Borlotti, in scatola, scolati","cat":"Legumi","h2o":71.90,"alc":0.00,"ami":13.50,"cho":15.90,"col":0.00,"ENc":102.00,"ENj":426.00,"fin":4.58,"fso":4.58,"fib":5.50,"fat":0.50,"pro":6.70,"sug":1.00},
    {"id":253,"name":"Fagioli, Borlotti, secchi","cat":"Legumi","h2o":10.30,"alc":0.00,"ami":40.20,"cho":47.70,"col":0.00,"ENc":312.00,"ENj":1307.00,"fin":15.71,"fso":15.71,"fib":17.30,"fat":2.00,"pro":20.20,"sug":3.50},
    {"id":254,"name":"Fagioli, Borlotti, secchi, cotti, bolliti","cat":"Legumi","h2o":70.20,"alc":0.00,"ami":14.00,"cho":16.40,"col":0.00,"ENc":106.00,"ENj":446.00,"fin":6.28,"fso":6.28,"fib":6.90,"fat":0.40,"pro":6.90,"sug":1.00},
    {"id":255,"name":"Fagioli, Cannellini in scatola, scolati","cat":"Legumi","h2o":76.70,"alc":0.00,"ami":11.10,"cho":12.50,"col":0.00,"ENc":86.00,"ENj":359.00,"fin":3.93,"fso":3.93,"fib":4.80,"fat":0.60,"pro":6.00,"sug":0.30},
    {"id":256,"name":"Fagioli, Cannellini, secchi","cat":"Legumi","h2o":10.00,"alc":0.00,"ami":38.70,"cho":45.50,"col":0.00,"ENc":314.00,"ENj":1313.00,"fin":15.25,"fso":15.25,"fib":17.60,"fat":1.60,"pro":23.40,"sug":2.90},
    {"id":257,"name":"Fagioli, Cannellini, secchi, cotti, bolliti","cat":"Legumi","h2o":69.30,"alc":0.00,"ami":13.00,"cho":14.90,"col":0.00,"ENc":107.00,"ENj":448.00,"fin":6.78,"fso":6.78,"fib":7.80,"fat":0.40,"pro":8.00,"sug":0.60},
    {"id":258,"name":"Fagioli, cotti, bolliti","cat":"Legumi","h2o":70.00,"alc":0.00,"ami":14.40,"cho":17.00,"col":0.00,"ENc":117.00,"ENj":491.00,"fin":6.78,"fso":6.78,"fib":7.80,"fat":0.70,"pro":7.90,"sug":1.20},
    {"id":259,"name":"Fagiolini a corallo, cotti, bolliti","cat":"Legumi","h2o":90.00,"alc":0.00,"ami":2.30,"cho":4.60,"col":0.00,"ENc":31.00,"ENj":129.00,"fin":2.14,"fso":2.14,"fib":2.90,"fat":0.10,"pro":1.70,"sug":2.10},
    {"id":260,"name":"Fagiolini, freschi","cat":"Legumi","h2o":90.50,"alc":0.00,"ami":0.00,"cho":2.40,"col":0.00,"ENc":24.00,"ENj":101.00,"fin":2.14,"fso":2.14,"fib":2.90,"fat":0.10,"pro":2.10,"sug":2.40},
    {"id":261,"name":"Fagiolini, surgelati, cotti, bolliti","cat":"Legumi","h2o":90.00,"alc":0.00,"ami":2.30,"cho":4.60,"col":0.00,"ENc":31.00,"ENj":129.00,"fin":2.07,"fso":2.07,"fib":2.90,"fat":0.10,"pro":1.70,"sug":2.10},
    {"id":262,"name":"Falafel","cat":"Alimenti Etnici","h2o":61.70,"alc":0.00,"ami":13.50,"cho":19.80,"col":0.00,"ENc":198.00,"ENj":828.00,"fin":0.00,"fso":0.00,"fib":4.90,"fat":9.50,"pro":7.10,"sug":0.90},
    {"id":263,"name":"Faraona, coscio, con pelle, crudo","cat":"Carni fresche","h2o":71.60,"alc":0.00,"ami":0.30,"cho":0.30,"col":71.00,"ENc":133.00,"ENj":554.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.80,"pro":24.30,"sug":0.00},
    {"id":264,"name":"Faraona, coscio, senza pelle, cotto, allo spiedo","cat":"Carni fresche","h2o":59.10,"alc":0.00,"ami":0.30,"cho":0.30,"col":99.00,"ENc":194.00,"ENj":810.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.10,"pro":34.40,"sug":0.00},
    {"id":265,"name":"Faraona, coscio, senza pelle, crudo","cat":"Carni fresche","h2o":72.40,"alc":0.00,"ami":0.30,"cho":0.30,"col":51.00,"ENc":127.00,"ENj":531.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.30,"pro":24.00,"sug":0.00},
    {"id":266,"name":"Faraona, petto, senza pelle, cotto, allo spiedo","cat":"Carni fresche","h2o":66.90,"alc":0.00,"ami":0.20,"cho":0.20,"col":39.00,"ENc":146.00,"ENj":611.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.50,"pro":30.70,"sug":0.00},
    {"id":267,"name":"Faraona, petto, senza pelle, crudo","cat":"Carni fresche","h2o":74.20,"alc":0.00,"ami":0.20,"cho":0.20,"col":32.00,"ENc":121.00,"ENj":506.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.90,"pro":25.80,"sug":0.00},
    {"id":268,"name":"Farina d'avena","cat":"Cereali e derivati","h2o":9.00,"alc":0.00,"ami":59.20,"cho":66.30,"col":0.00,"ENc":378.00,"ENj":1582.00,"fin":0.00,"fso":0.00,"fib":7.60,"fat":7.10,"pro":12.60,"sug":1.20},
    {"id":269,"name":"Farina di castagne","cat":"Frutta secca a guscio e semi oleaginosi","h2o":11.40,"alc":0.00,"ami":41.70,"cho":62.00,"col":0.00,"ENc":318.00,"ENj":1330.00,"fin":0.00,"fso":0.00,"fib":13.80,"fat":3.70,"pro":6.10,"sug":16.10},
    {"id":270,"name":"Farina di frumento, duro","cat":"Cereali e derivati","h2o":12.50,"alc":0.00,"ami":54.50,"cho":63.20,"col":0.00,"ENc":330.00,"ENj":1380.00,"fin":0.00,"fso":0.00,"fib":8.00,"fat":2.80,"pro":12.90,"sug":3.20},
    {"id":271,"name":"Farina di frumento, integrale","cat":"Cereali e derivati","h2o":13.40,"alc":0.00,"ami":54.30,"cho":61.80,"col":0.00,"ENc":313.00,"ENj":1311.00,"fin":6.51,"fso":6.51,"fib":8.40,"fat":1.90,"pro":11.90,"sug":2.10},
    {"id":272,"name":"Farina di frumento, tipo 0","cat":"Cereali e derivati","h2o":14.20,"alc":0.00,"ami":61.50,"cho":69.50,"col":0.00,"ENc":321.00,"ENj":1345.00,"fin":1.86,"fso":1.86,"fib":2.90,"fat":1.00,"pro":11.50,"sug":1.80},
    {"id":273,"name":"Farina di frumento, tipo 00","cat":"Cereali e derivati","h2o":14.20,"alc":0.00,"ami":63.50,"cho":71.60,"col":0.00,"ENc":323.00,"ENj":1352.00,"fin":1.41,"fso":1.41,"fib":2.20,"fat":0.70,"pro":11.00,"sug":1.70},
    {"id":274,"name":"Farina di mais","cat":"Cereali e derivati","h2o":12.50,"alc":0.00,"ami":65.40,"cho":73.50,"col":0.00,"ENc":341.00,"ENj":1426.00,"fin":2.76,"fso":2.76,"fib":3.10,"fat":2.70,"pro":8.70,"sug":1.50},
    {"id":275,"name":"Farina di manioca","cat":"Cereali e derivati","h2o":12.60,"alc":0.00,"ami":79.30,"cho":87.20,"col":0.00,"ENc":332.00,"ENj":1389.00,"fin":0.00,"fso":0.00,"fib":0.40,"fat":0.20,"pro":0.60,"sug":0.00},
    {"id":276,"name":"Farina di riso","cat":"Cereali e derivati","h2o":12.30,"alc":0.00,"ami":71.90,"cho":79.10,"col":0.00,"ENc":332.00,"ENj":1390.00,"fin":0.90,"fso":0.90,"fib":1.00,"fat":0.50,"pro":7.30,"sug":0.00},
    {"id":277,"name":"Farina di segale","cat":"Cereali e derivati","h2o":9.20,"alc":0.00,"ami":61.50,"cho":67.80,"col":0.00,"ENc":342.00,"ENj":1429.00,"fin":10.69,"fso":10.69,"fib":11.30,"fat":2.00,"pro":11.70,"sug":0.10},
    {"id":278,"name":"Farina di soia","cat":"Legumi","h2o":7.00,"alc":0.00,"ami":11.10,"cho":23.40,"col":0.00,"ENc":469.00,"ENj":1962.00,"fin":0.00,"fso":0.00,"fib":11.20,"fat":23.50,"pro":36.80,"sug":11.20},
    {"id":279,"name":"Farina d'orzo","cat":"Cereali e derivati","h2o":12.80,"alc":0.00,"ami":61.50,"cho":68.40,"col":0.00,"ENc":333.00,"ENj":1393.00,"fin":0.00,"fso":0.00,"fib":8.50,"fat":1.90,"pro":10.60,"sug":0.80},
    {"id":280,"name":"Farro perlato, cotto, bollito","cat":"Cereali e derivati","h2o":57.60,"alc":0.00,"ami":33.10,"cho":37.30,"col":0.00,"ENc":178.00,"ENj":744.00,"fin":0.00,"fso":0.00,"fib":2.10,"fat":1.00,"pro":6.20,"sug":0.90},
    {"id":281,"name":"Farro perlato, crudo","cat":"Cereali e derivati","h2o":11.30,"alc":0.00,"ami":60.90,"cho":69.30,"col":0.00,"ENc":353.00,"ENj":1476.00,"fin":5.50,"fso":5.50,"fib":6.50,"fat":2.40,"pro":14.60,"sug":2.40},
    {"id":282,"name":"Fave, fresche","cat":"Legumi","h2o":83.90,"alc":0.00,"ami":2.10,"cho":4.50,"col":0.00,"ENc":51.00,"ENj":215.00,"fin":4.45,"fso":4.45,"fib":5.00,"fat":0.40,"pro":5.20,"sug":2.20},
    {"id":283,"name":"Fave, fresche, cotte, in padella","cat":"Legumi","h2o":81.00,"alc":0.00,"ami":2.50,"cho":5.30,"col":0.00,"ENc":61.00,"ENj":253.00,"fin":4.86,"fso":4.86,"fib":5.90,"fat":0.50,"pro":6.10,"sug":2.60},
    {"id":284,"name":"Fave, secche","cat":"Legumi","h2o":10.20,"alc":0.00,"ami":43.90,"cho":52.80,"col":0.00,"ENc":331.00,"ENj":1387.00,"fin":13.93,"fso":13.93,"fib":14.70,"fat":2.10,"pro":21.30,"sug":4.50},
    {"id":285,"name":"Fave, secche, sgusciate, cotte, bollite","cat":"Legumi","h2o":61.40,"alc":0.00,"ami":18.40,"cho":22.40,"col":0.00,"ENc":146.00,"ENj":610.00,"fin":2.89,"fso":2.89,"fib":3.00,"fat":1.30,"pro":11.00,"sug":2.10},
    {"id":286,"name":"Fave, secche, sgusciate, crude","cat":"Legumi","h2o":11.30,"alc":0.00,"ami":44.30,"cho":53.60,"col":0.00,"ENc":343.00,"ENj":1434.00,"fin":6.64,"fso":6.64,"fib":7.00,"fat":3.00,"pro":25.20,"sug":4.90},
    {"id":287,"name":"Fecola di patate","cat":"Prodotti vari","h2o":16.10,"alc":0.00,"ami":74.20,"cho":90.70,"col":0.00,"ENc":346.00,"ENj":1447.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":1.40,"sug":9.10},
    {"id":288,"name":"Fegato di bovino","cat":"Frattaglie","h2o":70.00,"alc":0.00,"ami":0.00,"cho":5.90,"col":191.00,"ENc":142.00,"ENj":593.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.40,"pro":20.00,"sug":5.90},
    {"id":289,"name":"Fegato di bovino, cotto","cat":"Frattaglie","h2o":60.00,"alc":0.00,"ami":0.00,"cho":7.90,"col":255.00,"ENc":190.00,"ENj":793.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.90,"pro":26.70,"sug":7.90},
    {"id":290,"name":"Fegato di equino","cat":"Frattaglie","h2o":67.80,"alc":0.00,"ami":0.00,"cho":5.30,"col":0.00,"ENc":145.00,"ENj":609.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.00,"pro":22.40,"sug":5.30},
    {"id":291,"name":"Fegato di ovino","cat":"Frattaglie","h2o":73.00,"alc":0.00,"ami":0.00,"cho":2.00,"col":0.00,"ENc":135.00,"ENj":565.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.50,"pro":19.50,"sug":2.00},
    {"id":292,"name":"Fegato di pollo","cat":"Frattaglie","h2o":70.00,"alc":0.00,"ami":0.00,"cho":1.50,"col":555.00,"ENc":154.00,"ENj":642.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.30,"pro":22.80,"sug":1.50},
    {"id":293,"name":"Fegato di pollo, cotto","cat":"Frattaglie","h2o":60.00,"alc":0.00,"ami":0.00,"cho":2.00,"col":746.00,"ENc":192.00,"ENj":802.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.40,"pro":29.40,"sug":2.00},
    {"id":294,"name":"Fegato di suino","cat":"Frattaglie","h2o":70.00,"alc":0.00,"ami":0.00,"cho":1.50,"col":260.00,"ENc":140.00,"ENj":586.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.80,"pro":22.80,"sug":1.50},
    {"id":295,"name":"Fegato di suino, cotto","cat":"Frattaglie","h2o":60.00,"alc":0.00,"ami":0.00,"cho":2.00,"col":290.00,"ENc":183.00,"ENj":764.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.40,"pro":29.40,"sug":2.00},
    {"id":296,"name":"Fegato di tacchino","cat":"Frattaglie","h2o":75.20,"alc":0.00,"ami":0.00,"cho":0.50,"col":435.00,"ENc":127.00,"ENj":532.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.00,"pro":17.80,"sug":0.50},
    {"id":297,"name":"Fegato di tacchino, cotto","cat":"Frattaglie","h2o":61.80,"alc":0.00,"ami":0.00,"cho":0.80,"col":599.00,"ENc":195.00,"ENj":818.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":9.20,"pro":27.40,"sug":0.80},
    {"id":298,"name":"Feijoa, fresca","cat":"Frutta","h2o":81.90,"alc":0.00,"ami":0.00,"cho":9.60,"col":0.00,"ENc":59.00,"ENj":247.00,"fin":5.39,"fso":5.39,"fib":6.50,"fat":0.80,"pro":0.70,"sug":9.60},
    {"id":299,"name":"Feta","cat":"Formaggi e latticini","h2o":57.10,"alc":0.00,"ami":0.00,"cho":1.50,"col":68.00,"ENc":250.00,"ENj":1045.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":20.20,"pro":15.60,"sug":1.50},
    {"id":300,"name":"Fette biscottate","cat":"Cereali e derivati","h2o":4.00,"alc":0.00,"ami":66.20,"cho":75.00,"col":0.00,"ENc":387.00,"ENj":1621.00,"fin":0.00,"fso":0.00,"fib":3.50,"fat":6.00,"pro":11.30,"sug":2.20},
    {"id":301,"name":"Fette biscottate, integrali","cat":"Cereali e derivati","h2o":6.50,"alc":0.00,"ami":56.30,"cho":63.00,"col":0.00,"ENc":394.00,"ENj":1648.00,"fin":0.00,"fso":0.00,"fib":5.40,"fat":10.00,"pro":14.20,"sug":1.10},
    {"id":302,"name":"Fichi d'india, freschi","cat":"Frutta","h2o":83.20,"alc":0.00,"ami":0.00,"cho":13.00,"col":0.00,"ENc":63.00,"ENj":263.00,"fin":4.87,"fso":4.87,"fib":5.00,"fat":0.10,"pro":0.80,"sug":13.00},
    {"id":303,"name":"Fichi, canditi","cat":"Frutta","h2o":21.00,"alc":0.00,"ami":0.00,"cho":73.70,"col":0.00,"ENc":294.00,"ENj":1232.00,"fin":0.00,"fso":0.00,"fib":1.10,"fat":0.20,"pro":3.50,"sug":73.70},
    {"id":304,"name":"Fichi, freschi","cat":"Frutta","h2o":81.90,"alc":0.00,"ami":0.00,"cho":14.20,"col":0.00,"ENc":63.00,"ENj":262.00,"fin":1.38,"fso":1.38,"fib":2.00,"fat":0.20,"pro":0.90,"sug":14.20},
    {"id":305,"name":"Fichi, seccati al forno e mandorlati","cat":"Frutta","h2o":18.30,"alc":0.00,"ami":0.00,"cho":58.20,"col":0.00,"ENc":305.00,"ENj":1275.00,"fin":0.00,"fso":0.00,"fib":14.00,"fat":4.20,"pro":5.20,"sug":58.20},
    {"id":306,"name":"Fichi, secchi","cat":"Frutta","h2o":19.40,"alc":0.00,"ami":0.00,"cho":58.00,"col":0.00,"ENc":282.00,"ENj":1179.00,"fin":11.01,"fso":11.01,"fib":13.00,"fat":2.70,"pro":3.50,"sug":58.00},
    {"id":307,"name":"Finocchi, cotti, al microonde","cat":"Verdure e ortaggi","h2o":93.10,"alc":0.00,"ami":0.00,"cho":1.50,"col":0.00,"ENc":15.00,"ENj":62.00,"fin":0.00,"fso":0.00,"fib":2.20,"fat":0.00,"pro":1.20,"sug":1.50},
    {"id":308,"name":"Finocchi, cotti, bolliti","cat":"Verdure e ortaggi","h2o":92.10,"alc":0.00,"ami":0.00,"cho":1.70,"col":0.00,"ENc":17.00,"ENj":72.00,"fin":1.89,"fso":1.89,"fib":2.60,"fat":0.00,"pro":1.40,"sug":1.70},
    {"id":309,"name":"Finocchi, crudi","cat":"Verdure e ortaggi","h2o":93.20,"alc":0.00,"ami":0.00,"cho":1.50,"col":0.00,"ENc":15.00,"ENj":62.00,"fin":1.97,"fso":1.97,"fib":2.20,"fat":0.00,"pro":1.20,"sug":1.50},
    {"id":310,"name":"Fiocchi d'avena","cat":"Cereali e derivati","h2o":10.30,"alc":0.00,"ami":60.20,"cho":66.80,"col":0.00,"ENc":367.00,"ENj":1534.00,"fin":4.99,"fso":4.99,"fib":8.30,"fat":7.50,"pro":8.00,"sug":0.60},
    {"id":311,"name":"Fiocchi di formaggio magro","cat":"Formaggi e latticini","h2o":78.80,"alc":0.00,"ami":0.00,"cho":3.20,"col":0.00,"ENc":115.00,"ENj":480.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.10,"pro":9.70,"sug":3.20},
    {"id":312,"name":"Fior di latte","cat":"Formaggi e latticini","h2o":54.80,"alc":0.00,"ami":0.00,"cho":0.70,"col":0.00,"ENc":268.00,"ENj":1120.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":20.30,"pro":20.60,"sug":0.70},
    {"id":313,"name":"Fiori di zucca, freschi","cat":"Verdure e ortaggi","h2o":94.30,"alc":0.00,"ami":0.00,"cho":0.50,"col":0.00,"ENc":18.00,"ENj":75.00,"fin":0.00,"fso":0.00,"fib":2.80,"fat":0.40,"pro":1.70,"sug":0.50},
    {"id":314,"name":"Foglie di rapa, crude","cat":"Verdure e ortaggi","h2o":92.50,"alc":0.00,"ami":0.00,"cho":2.80,"col":0.00,"ENc":27.00,"ENj":112.00,"fin":0.00,"fso":0.00,"fib":2.50,"fat":0.10,"pro":2.60,"sug":2.80},
    {"id":315,"name":"Fontina","cat":"Formaggi e latticini","h2o":45.10,"alc":0.00,"ami":0.00,"cho":0.80,"col":82.00,"ENc":361.00,"ENj":1511.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":28.90,"pro":24.50,"sug":0.80},
    {"id":316,"name":"Formaggino","cat":"Formaggi e latticini","h2o":52.60,"alc":0.00,"ami":0.00,"cho":6.00,"col":0.00,"ENc":309.00,"ENj":1295.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":26.90,"pro":11.20,"sug":6.00},
    {"id":317,"name":"Formaggino con ridotto contenuto di grasso","cat":"Formaggi e latticini","h2o":58.40,"alc":0.00,"ami":0.00,"cho":6.50,"col":0.00,"ENc":239.00,"ENj":999.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":17.30,"pro":14.70,"sug":6.50},
    {"id":318,"name":"Formaggio cremoso spalmabile","cat":"Formaggi e latticini","h2o":59.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":90.00,"ENc":313.00,"ENj":1311.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":31.00,"pro":8.60,"sug":0.00},
    {"id":319,"name":"Formaggio cremoso spalmabile, light","cat":"Formaggi e latticini","h2o":70.00,"alc":0.00,"ami":0.00,"cho":3.10,"col":42.00,"ENc":179.00,"ENj":749.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":14.50,"pro":9.20,"sug":3.10},
    {"id":320,"name":"Formaggio molle da tavola","cat":"Formaggi e latticini","h2o":38.90,"alc":0.00,"ami":0.00,"cho":1.00,"col":0.00,"ENc":377.00,"ENj":1578.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":30.20,"pro":25.40,"sug":1.00},
    {"id":321,"name":"Fragole, fresche","cat":"Frutta","h2o":90.50,"alc":0.00,"ami":0.00,"cho":5.30,"col":0.00,"ENc":30.00,"ENj":127.00,"fin":1.13,"fso":1.13,"fib":1.60,"fat":0.40,"pro":0.90,"sug":5.30},
    {"id":322,"name":"Frittata","cat":"Ricette Italiane","h2o":68.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":393.00,"ENc":185.00,"ENj":774.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":13.40,"pro":16.10,"sug":0.00},
    {"id":323,"name":"Frittata prosciutto e formaggio","cat":"Ricette Italiane","h2o":64.20,"alc":0.00,"ami":0.00,"cho":0.30,"col":262.00,"ENc":205.00,"ENj":856.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":15.40,"pro":16.20,"sug":0.30},
    {"id":324,"name":"Frumento duro","cat":"Cereali e derivati","h2o":11.50,"alc":0.00,"ami":53.90,"cho":62.50,"col":0.00,"ENc":332.00,"ENj":1389.00,"fin":0.00,"fso":0.00,"fib":9.80,"fat":2.90,"pro":13.00,"sug":3.20},
    {"id":325,"name":"Frumento tenero","cat":"Cereali e derivati","h2o":12.00,"alc":0.00,"ami":56.30,"cho":65.20,"col":0.00,"ENc":336.00,"ENj":1408.00,"fin":0.00,"fso":0.00,"fib":9.70,"fat":2.60,"pro":12.30,"sug":3.30},
    {"id":326,"name":"Frutta mista caramellata","cat":"Alimenti Etnici","h2o":53.20,"alc":0.00,"ami":10.00,"cho":36.00,"col":0.00,"ENc":217.00,"ENj":909.00,"fin":0.00,"fso":0.00,"fib":1.30,"fat":8.00,"pro":1.90,"sug":25.00},
    {"id":327,"name":"Funghi coltivati, pleurotes, cotti, in padella","cat":"Verdure e ortaggi","h2o":88.70,"alc":0.00,"ami":0.00,"cho":5.30,"col":0.00,"ENc":35.00,"ENj":148.00,"fin":0.00,"fso":0.00,"fib":0.80,"fat":0.40,"pro":2.60,"sug":5.30},
    {"id":328,"name":"Funghi coltivati, pleurotes, crudi","cat":"Verdure e ortaggi","h2o":90.40,"alc":0.00,"ami":0.00,"cho":5.60,"col":0.00,"ENc":37.00,"ENj":156.00,"fin":2.15,"fso":2.15,"fib":2.40,"fat":0.30,"pro":2.20,"sug":5.60},
    {"id":329,"name":"Funghi coltivati, prataioli, cotti, in padella","cat":"Verdure e ortaggi","h2o":81.90,"alc":0.00,"ami":0.00,"cho":1.50,"col":0.00,"ENc":46.00,"ENj":192.00,"fin":4.03,"fso":4.03,"fib":4.30,"fat":0.40,"pro":7.00,"sug":1.50},
    {"id":330,"name":"Funghi coltivati, prataioli, crudi","cat":"Verdure e ortaggi","h2o":90.40,"alc":0.00,"ami":0.00,"cho":0.80,"col":0.00,"ENc":24.00,"ENj":101.00,"fin":2.14,"fso":2.14,"fib":2.30,"fat":0.20,"pro":3.70,"sug":0.80},
    {"id":331,"name":"Funghi ovuli, crudi","cat":"Verdure e ortaggi","h2o":92.90,"alc":0.00,"ami":0.00,"cho":1.60,"col":0.00,"ENc":23.00,"ENj":95.00,"fin":0.00,"fso":0.00,"fib":3.00,"fat":0.30,"pro":2.00,"sug":1.60},
    {"id":332,"name":"Funghi porcini, crudi","cat":"Verdure e ortaggi","h2o":92.00,"alc":0.00,"ami":0.00,"cho":1.00,"col":0.00,"ENc":31.00,"ENj":128.00,"fin":0.00,"fso":0.00,"fib":2.50,"fat":0.70,"pro":3.90,"sug":1.00},
    {"id":333,"name":"Gallina, crudo","cat":"Carni fresche","h2o":66.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":96.00,"ENc":194.00,"ENj":813.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":12.30,"pro":20.90,"sug":0.00},
    {"id":334,"name":"Gamberetti con funghi e bambù","cat":"Alimenti Etnici","h2o":79.00,"alc":0.00,"ami":1.10,"cho":1.90,"col":0.00,"ENc":110.00,"ENj":460.00,"fin":0.00,"fso":0.00,"fib":1.30,"fat":7.30,"pro":8.60,"sug":0.70},
    {"id":335,"name":"Gamberi","cat":"Prodotti della pesca","h2o":80.10,"alc":0.00,"ami":0.00,"cho":2.90,"col":150.00,"ENc":71.00,"ENj":296.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.60,"pro":13.60,"sug":2.90},
    {"id":336,"name":"Gamberi sgusciati, surgelati","cat":"Prodotti della pesca","h2o":81.20,"alc":0.00,"ami":0.00,"cho":2.90,"col":150.00,"ENc":85.00,"ENj":356.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.90,"pro":16.50,"sug":2.90},
    {"id":337,"name":"Gateau","cat":"Ricette Italiane","h2o":68.10,"alc":0.00,"ami":9.40,"cho":11.60,"col":31.00,"ENc":156.00,"ENj":653.00,"fin":0.00,"fso":0.00,"fib":3.40,"fat":7.70,"pro":9.10,"sug":1.30},
    {"id":338,"name":"Gelato confezionato, biscotto con crema, zabaione e cioccolato","cat":"Dolci","h2o":36.30,"alc":0.00,"ami":0.00,"cho":49.10,"col":0.00,"ENc":271.00,"ENj":1134.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.30,"pro":5.30,"sug":0.00},
    {"id":339,"name":"Gelato confezionato, cacao, in vaschetta","cat":"Dolci","h2o":63.00,"alc":0.00,"ami":0.00,"cho":25.30,"col":0.00,"ENc":173.00,"ENj":725.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.10,"pro":3.60,"sug":25.30},
    {"id":340,"name":"Gelato confezionato, caffè, in vaschetta","cat":"Dolci","h2o":54.90,"alc":0.00,"ami":0.00,"cho":23.90,"col":0.00,"ENc":245.00,"ENj":1025.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":15.00,"pro":5.10,"sug":23.90},
    {"id":341,"name":"Gelato confezionato, cono con panna e cioccolato","cat":"Dolci","h2o":43.20,"alc":0.00,"ami":0.00,"cho":34.90,"col":0.00,"ENc":291.00,"ENj":1217.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":15.50,"pro":5.10,"sug":0.00},
    {"id":342,"name":"Gelato confezionato, cono con panna, scaglie di cioccolato e noccioline","cat":"Dolci","h2o":41.10,"alc":0.00,"ami":0.00,"cho":33.30,"col":0.00,"ENc":326.00,"ENj":1365.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":20.50,"pro":4.20,"sug":0.00},
    {"id":343,"name":"Gelato confezionato, fior di latte, in vaschetta","cat":"Dolci","h2o":60.60,"alc":0.00,"ami":0.00,"cho":20.70,"col":0.00,"ENc":218.00,"ENj":911.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":13.70,"pro":4.20,"sug":20.70},
    {"id":344,"name":"Gelato confezionato, fior di latte, ricoperto di sorbetto alla fragola","cat":"Dolci","h2o":75.20,"alc":0.00,"ami":0.00,"cho":21.30,"col":0.00,"ENc":101.00,"ENj":422.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.70,"pro":1.40,"sug":21.30},
    {"id":345,"name":"Gelato confezionato, ghiacciolo all'arancio","cat":"Dolci","h2o":64.00,"alc":0.00,"ami":0.00,"cho":36.50,"col":0.00,"ENc":137.00,"ENj":573.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":36.50},
    {"id":346,"name":"Gelato confezionato, nocciola, in vaschetta","cat":"Dolci","h2o":62.40,"alc":0.00,"ami":0.00,"cho":25.20,"col":0.00,"ENc":183.00,"ENj":767.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.40,"pro":3.30,"sug":25.20},
    {"id":347,"name":"Gelato confezionato, panna ricoperta di cioccolato fondente","cat":"Dolci","h2o":44.80,"alc":0.00,"ami":0.00,"cho":28.20,"col":0.00,"ENc":316.00,"ENj":1324.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":21.40,"pro":4.50,"sug":28.20},
    {"id":348,"name":"Gelato confezionato, panna, in vaschetta","cat":"Dolci","h2o":60.60,"alc":0.00,"ami":0.00,"cho":27.20,"col":0.00,"ENc":189.00,"ENj":789.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.20,"pro":3.20,"sug":27.20},
    {"id":349,"name":"Gelato confezionato, sorbetto al limone, in vaschetta","cat":"Dolci","h2o":64.90,"alc":0.00,"ami":0.00,"cho":34.20,"col":0.00,"ENc":132.00,"ENj":552.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.90,"sug":34.20},
    {"id":350,"name":"Gelato confezionato, stracciatella, in vaschetta","cat":"Dolci","h2o":57.80,"alc":0.00,"ami":0.00,"cho":31.50,"col":0.00,"ENc":193.00,"ENj":806.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.00,"pro":2.90,"sug":31.50},
    {"id":351,"name":"Gelato confezionato, vaniglia, in vaschetta","cat":"Dolci","h2o":63.50,"alc":0.00,"ami":0.00,"cho":25.70,"col":0.00,"ENc":167.00,"ENj":701.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.70,"pro":2.70,"sug":25.70},
    {"id":352,"name":"Gelato misto fritto","cat":"Alimenti Etnici","h2o":50.00,"alc":0.00,"ami":14.90,"cho":34.70,"col":0.00,"ENc":252.00,"ENj":1055.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":11.70,"pro":4.20,"sug":18.30},
    {"id":353,"name":"Germe di frumento","cat":"Cereali e derivati","h2o":10.00,"alc":0.00,"ami":0.00,"cho":43.50,"col":0.00,"ENc":378.00,"ENj":1582.00,"fin":0.00,"fso":0.00,"fib":10.50,"fat":10.00,"pro":26.00,"sug":0.00},
    {"id":354,"name":"Germogli di soia","cat":"Verdure e ortaggi","h2o":86.30,"alc":0.00,"ami":0.00,"cho":3.00,"col":0.00,"ENc":54.00,"ENj":227.00,"fin":0.00,"fso":0.00,"fib":2.80,"fat":1.40,"pro":6.20,"sug":3.00},
    {"id":355,"name":"Gnocchi al pesto alla genovese","cat":"Ricette Italiane","h2o":61.00,"alc":0.00,"ami":18.60,"cho":21.70,"col":8.00,"ENc":186.00,"ENj":777.00,"fin":0.36,"fso":0.36,"fib":1.60,"fat":8.40,"pro":6.40,"sug":1.30},
    {"id":356,"name":"Gnocchi al ragù alla bolognese","cat":"Ricette Italiane","h2o":64.20,"alc":0.00,"ami":12.60,"cho":15.40,"col":26.00,"ENc":175.00,"ENj":732.00,"fin":0.40,"fso":0.40,"fib":1.30,"fat":9.00,"pro":8.40,"sug":1.50},
    {"id":357,"name":"Gnocchi al sugo di pomodoro","cat":"Ricette Italiane","h2o":72.80,"alc":0.00,"ami":15.60,"cho":19.40,"col":0.00,"ENc":112.00,"ENj":467.00,"fin":0.55,"fso":0.55,"fib":1.80,"fat":2.80,"pro":2.50,"sug":2.20},
    {"id":358,"name":"Gnocchi di patate, cotti","cat":"Cereali e derivati","h2o":60.70,"alc":0.00,"ami":30.10,"cho":34.00,"col":2.00,"ENc":147.00,"ENj":615.00,"fin":0.00,"fso":0.00,"fib":1.80,"fat":0.20,"pro":3.50,"sug":1.00},
    {"id":359,"name":"Gnocchi di patate, crudi","cat":"Cereali e derivati","h2o":56.30,"alc":0.00,"ami":33.20,"cho":37.90,"col":7.00,"ENc":164.00,"ENj":687.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":0.30,"pro":3.90,"sug":1.40},
    {"id":360,"name":"Gomme da masticare, lastrine e confetti","cat":"Dolci","h2o":3.50,"alc":0.00,"ami":0.00,"cho":70.00,"col":0.00,"ENc":262.00,"ENj":1098.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":70.00},
    {"id":361,"name":"Gorgonzola","cat":"Formaggi e latticini","h2o":50.80,"alc":0.00,"ami":0.00,"cho":1.00,"col":70.00,"ENc":324.00,"ENj":1356.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":27.10,"pro":19.10,"sug":1.00},
    {"id":362,"name":"Grana Padano, DOP","cat":"Formaggi e latticini","h2o":32.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":98.00,"ENc":392.00,"ENj":1641.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":28.50,"pro":33.90,"sug":0.00},
    {"id":363,"name":"Granchio, in scatola","cat":"Prodotti della pesca","h2o":79.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":101.00,"ENc":81.00,"ENj":337.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.90,"pro":18.10,"sug":0.00},
    {"id":364,"name":"Grano saraceno","cat":"Cereali e derivati","h2o":13.10,"alc":0.00,"ami":0.00,"cho":61.20,"col":0.00,"ENc":329.00,"ENj":1376.00,"fin":8.35,"fso":8.35,"fib":10.00,"fat":3.30,"pro":12.40,"sug":0.00},
    {"id":365,"name":"Grappa","cat":"Bevande alcoliche","h2o":66.30,"alc":34.60,"ami":0.00,"cho":0.00,"col":0.00,"ENc":242.00,"ENj":1014.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":0.00},
    {"id":366,"name":"Grissini","cat":"Cereali e derivati","h2o":7.50,"alc":0.00,"ami":57.20,"cho":65.10,"col":0.00,"ENc":421.00,"ENj":1763.00,"fin":0.00,"fso":0.00,"fib":3.50,"fat":13.90,"pro":11.30,"sug":2.20},
    {"id":367,"name":"Groviera","cat":"Formaggi e latticini","h2o":34.10,"alc":0.00,"ami":0.00,"cho":1.50,"col":100.00,"ENc":417.00,"ENj":1744.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":32.10,"pro":30.60,"sug":1.50},
    {"id":368,"name":"Guava, fresca","cat":"Frutta","h2o":87.20,"alc":0.00,"ami":0.00,"cho":3.50,"col":0.00,"ENc":31.00,"ENj":131.00,"fin":0.00,"fso":0.00,"fib":5.40,"fat":0.50,"pro":0.70,"sug":3.50},
    {"id":369,"name":"Halibut","cat":"Prodotti della pesca","h2o":74.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":50.00,"ENc":114.00,"ENj":477.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.50,"pro":20.60,"sug":0.00},
    {"id":370,"name":"Hamburger piccolo, solo carne, fast food","cat":"Fast-food a base di carne","h2o":53.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":264.00,"ENj":1103.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":18.40,"pro":24.50,"sug":0.00},
    {"id":371,"name":"Hamburger, fast food","cat":"Fast-food a base di carne","h2o":45.70,"alc":0.00,"ami":0.00,"cho":30.10,"col":0.00,"ENc":251.00,"ENj":1051.00,"fin":0.00,"fso":0.00,"fib":2.00,"fat":9.10,"pro":13.10,"sug":0.00},
    {"id":372,"name":"Hummus","cat":"Alimenti Etnici","h2o":63.00,"alc":0.00,"ami":7.50,"cho":9.40,"col":0.00,"ENc":168.00,"ENj":704.00,"fin":3.37,"fso":3.37,"fib":4.60,"fat":11.00,"pro":6.20,"sug":1.10},
    {"id":373,"name":"Indivia, fresca","cat":"Verdure e ortaggi","h2o":93.00,"alc":0.00,"ami":0.00,"cho":2.70,"col":0.00,"ENc":20.00,"ENj":82.00,"fin":1.40,"fso":1.40,"fib":1.60,"fat":0.30,"pro":0.90,"sug":2.70},
    {"id":374,"name":"Involtini Primavera","cat":"Alimenti Etnici","h2o":56.90,"alc":0.00,"ami":15.90,"cho":21.60,"col":0.00,"ENc":238.00,"ENj":997.00,"fin":0.00,"fso":0.00,"fib":2.40,"fat":15.40,"pro":3.50,"sug":4.10},
    {"id":375,"name":"Italico","cat":"Formaggi e latticini","h2o":49.20,"alc":0.00,"ami":0.00,"cho":1.20,"col":0.00,"ENc":316.00,"ENj":1323.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":25.20,"pro":21.20,"sug":1.20},
    {"id":376,"name":"Kebab solo carne","cat":"Alimenti Etnici","h2o":49.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":102.00,"ENc":269.00,"ENj":1126.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":16.70,"pro":29.70,"sug":0.00},
    {"id":377,"name":"Ketchup, salsa","cat":"Prodotti vari","h2o":64.80,"alc":0.00,"ami":1.00,"cho":24.00,"col":0.00,"ENc":100.00,"ENj":420.00,"fin":0.00,"fso":0.00,"fib":0.90,"fat":0.00,"pro":2.10,"sug":22.90},
    {"id":378,"name":"Kiwi, freschi","cat":"Frutta","h2o":84.60,"alc":0.00,"ami":0.00,"cho":9.00,"col":0.00,"ENc":48.00,"ENj":202.00,"fin":1.43,"fso":1.43,"fib":2.20,"fat":0.60,"pro":1.20,"sug":9.00},
    {"id":379,"name":"Lamponi, freschi","cat":"Frutta","h2o":84.60,"alc":0.00,"ami":0.00,"cho":6.50,"col":0.00,"ENc":49.00,"ENj":203.00,"fin":0.00,"fso":0.00,"fib":7.40,"fat":0.60,"pro":1.00,"sug":6.50},
    {"id":380,"name":"Lardo","cat":"Oli e grassi","h2o":1.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":95.00,"ENc":891.00,"ENj":3728.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.00,"pro":0.00,"sug":0.00},
    {"id":381,"name":"Lasagna","cat":"Ricette Italiane","h2o":63.90,"alc":0.00,"ami":11.10,"cho":13.40,"col":0.00,"ENc":196.00,"ENj":819.00,"fin":0.00,"fso":0.00,"fib":0.80,"fat":10.80,"pro":11.70,"sug":1.20},
    {"id":382,"name":"Lattarini","cat":"Prodotti della pesca","h2o":73.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":48.50,"ENc":145.00,"ENj":606.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":9.60,"pro":14.60,"sug":0.00},
    {"id":383,"name":"Latte di bufala","cat":"Latte e yogurt","h2o":81.20,"alc":0.00,"ami":0.00,"cho":5.10,"col":0.00,"ENc":114.00,"ENj":475.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.50,"pro":4.50,"sug":5.10},
    {"id":384,"name":"Latte di capra","cat":"Latte e yogurt","h2o":86.30,"alc":0.00,"ami":0.00,"cho":4.70,"col":10.00,"ENc":76.00,"ENj":320.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.80,"pro":3.90,"sug":4.70},
    {"id":385,"name":"Latte di pecora","cat":"Latte e yogurt","h2o":82.70,"alc":0.00,"ami":0.00,"cho":5.20,"col":11.00,"ENc":103.00,"ENj":430.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.90,"pro":5.30,"sug":5.20},
    {"id":386,"name":"Latte di vacca, condensato, zuccherato","cat":"Latte e yogurt","h2o":26.50,"alc":0.00,"ami":0.00,"cho":56.50,"col":34.00,"ENc":328.00,"ENj":1371.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":9.00,"pro":8.70,"sug":56.50},
    {"id":387,"name":"Latte di vacca, evaporato, non zuccherato","cat":"Latte e yogurt","h2o":74.00,"alc":0.00,"ami":0.00,"cho":8.60,"col":31.00,"ENc":134.00,"ENj":561.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.20,"pro":7.00,"sug":8.60},
    {"id":388,"name":"Latte di vacca, in polvere, intero","cat":"Latte e yogurt","h2o":3.20,"alc":0.00,"ami":0.00,"cho":42.00,"col":109.00,"ENc":484.00,"ENj":2027.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":24.90,"pro":25.70,"sug":42.00},
    {"id":389,"name":"Latte di vacca, in polvere, parzialmente scremato","cat":"Latte e yogurt","h2o":4.20,"alc":0.00,"ami":0.00,"cho":50.20,"col":58.00,"ENc":418.00,"ENj":1748.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":12.70,"pro":28.80,"sug":50.20},
    {"id":390,"name":"Latte di vacca, in polvere, scremato","cat":"Latte e yogurt","h2o":5.00,"alc":0.00,"ami":0.00,"cho":56.20,"col":22.00,"ENc":351.00,"ENj":1470.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.90,"pro":33.10,"sug":56.20},
    {"id":391,"name":"Latte di vacca, pastorizzato, intero","cat":"Latte e yogurt","h2o":87.00,"alc":0.00,"ami":0.00,"cho":4.90,"col":11.00,"ENc":64.00,"ENj":268.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.60,"pro":3.30,"sug":4.90},
    {"id":392,"name":"Latte di vacca, pastorizzato, parzialmente scremato","cat":"Latte e yogurt","h2o":88.50,"alc":0.00,"ami":0.00,"cho":5.00,"col":7.00,"ENc":46.00,"ENj":194.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.50,"pro":3.50,"sug":5.00},
    {"id":393,"name":"Latte di vacca, pastorizzato, scremato","cat":"Latte e yogurt","h2o":90.50,"alc":0.00,"ami":0.00,"cho":5.30,"col":2.00,"ENc":36.00,"ENj":151.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.20,"pro":3.60,"sug":5.30},
    {"id":394,"name":"Latte di vacca, UHT, intero","cat":"Latte e yogurt","h2o":87.60,"alc":0.00,"ami":0.00,"cho":4.70,"col":11.00,"ENc":63.00,"ENj":265.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.60,"pro":3.30,"sug":4.70},
    {"id":395,"name":"Latte di vacca, UHT, parzialmente scremato","cat":"Latte e yogurt","h2o":89.50,"alc":0.00,"ami":0.00,"cho":5.10,"col":7.00,"ENc":46.00,"ENj":194.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.60,"pro":3.20,"sug":5.10},
    {"id":396,"name":"Latteria","cat":"Formaggi e latticini","h2o":28.40,"alc":0.00,"ami":0.00,"cho":1.40,"col":0.00,"ENc":456.00,"ENj":1906.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":36.40,"pro":30.70,"sug":1.40},
    {"id":397,"name":"Lattuga, a cappuccio, fresca","cat":"Verdure e ortaggi","h2o":92.20,"alc":0.00,"ami":0.00,"cho":3.00,"col":0.00,"ENc":22.00,"ENj":91.00,"fin":1.11,"fso":1.11,"fib":1.30,"fat":0.20,"pro":1.50,"sug":3.00},
    {"id":398,"name":"Lattuga, da taglio, fresca","cat":"Verdure e ortaggi","h2o":95.60,"alc":0.00,"ami":0.00,"cho":2.20,"col":0.00,"ENc":17.00,"ENj":69.00,"fin":0.00,"fso":0.00,"fib":1.50,"fat":0.10,"pro":1.10,"sug":2.20},
    {"id":399,"name":"Lattuga, fresca","cat":"Verdure e ortaggi","h2o":94.30,"alc":0.00,"ami":0.00,"cho":2.20,"col":0.00,"ENc":22.00,"ENj":92.00,"fin":1.33,"fso":1.33,"fib":1.50,"fat":0.40,"pro":1.80,"sug":2.20},
    {"id":400,"name":"Lattughino IV gamma, fresco","cat":"Verdure e ortaggi","h2o":94.70,"alc":0.00,"ami":0.00,"cho":1.40,"col":0.00,"ENc":16.00,"ENj":66.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":0.20,"pro":1.40,"sug":1.40},
    {"id":401,"name":"Lattughino, fresco","cat":"Verdure e ortaggi","h2o":94.60,"alc":0.00,"ami":0.00,"cho":1.40,"col":0.00,"ENc":16.00,"ENj":68.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":0.20,"pro":1.50,"sug":1.40},
    {"id":402,"name":"Lenti colorate ripiene di cioccolato","cat":"Dolci","h2o":1.50,"alc":0.00,"ami":2.80,"cho":73.90,"col":0.00,"ENc":456.00,"ENj":1909.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":17.50,"pro":5.40,"sug":70.80},
    {"id":403,"name":"Lenticchie, in scatola, scolate","cat":"Legumi","h2o":75.10,"alc":0.00,"ami":13.40,"cho":15.40,"col":0.00,"ENc":91.00,"ENj":379.00,"fin":3.79,"fso":3.79,"fib":4.20,"fat":0.50,"pro":5.00,"sug":0.70},
    {"id":404,"name":"Lenticchie, secche","cat":"Legumi","h2o":11.20,"alc":0.00,"ami":44.80,"cho":51.10,"col":0.00,"ENc":319.00,"ENj":1335.00,"fin":12.91,"fso":12.91,"fib":13.80,"fat":1.00,"pro":22.70,"sug":1.80},
    {"id":405,"name":"Lenticchie, secche, cotte, bollite","cat":"Legumi","h2o":69.70,"alc":0.00,"ami":13.50,"cho":16.30,"col":0.00,"ENc":109.00,"ENj":456.00,"fin":7.74,"fso":7.74,"fib":8.30,"fat":0.40,"pro":6.90,"sug":0.70},
    {"id":406,"name":"Lievito di birra, compresso","cat":"Prodotti vari","h2o":71.00,"alc":0.00,"ami":0.00,"cho":1.10,"col":0.00,"ENc":70.00,"ENj":293.00,"fin":0.00,"fso":0.00,"fib":6.90,"fat":0.40,"pro":12.10,"sug":1.10},
    {"id":407,"name":"Limoni, freschi","cat":"Frutta","h2o":91.50,"alc":0.00,"ami":0.00,"cho":2.50,"col":0.00,"ENc":16.00,"ENj":65.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":0.00,"pro":0.60,"sug":2.50},
    {"id":408,"name":"Limoni, succo","cat":"Frutta","h2o":93.10,"alc":0.00,"ami":0.00,"cho":2.50,"col":0.00,"ENc":10.00,"ENj":43.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.20,"sug":2.50},
    {"id":409,"name":"Lingua di bovino","cat":"Frattaglie","h2o":64.00,"alc":0.00,"ami":0.00,"cho":0.40,"col":119.00,"ENc":232.00,"ENj":970.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":18.00,"pro":17.10,"sug":0.40},
    {"id":410,"name":"Lingua di bovino, cotta, fritta","cat":"Frattaglie","h2o":52.00,"alc":0.00,"ami":0.00,"cho":0.50,"col":211.00,"ENc":309.00,"ENj":1293.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":24.00,"pro":22.80,"sug":0.50},
    {"id":411,"name":"Liquori da dessert","cat":"Bevande alcoliche","h2o":40.80,"alc":28.20,"ami":0.00,"cho":31.10,"col":0.00,"ENc":314.00,"ENj":1314.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":31.10},
    {"id":412,"name":"Litchi, freschi","cat":"Frutta","h2o":80.60,"alc":0.00,"ami":0.00,"cho":17.20,"col":0.00,"ENc":72.00,"ENj":303.00,"fin":0.00,"fso":0.00,"fib":1.30,"fat":0.10,"pro":1.10,"sug":17.20},
    {"id":413,"name":"Loti o kaki, freschi","cat":"Frutta","h2o":82.00,"alc":0.00,"ami":0.00,"cho":16.00,"col":0.00,"ENc":70.00,"ENj":293.00,"fin":2.43,"fso":2.43,"fib":2.50,"fat":0.30,"pro":0.60,"sug":16.00},
    {"id":414,"name":"Luccio","cat":"Prodotti della pesca","h2o":79.00,"alc":0.00,"ami":0.00,"cho":0.10,"col":0.00,"ENc":81.00,"ENj":337.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.60,"pro":18.70,"sug":0.10},
    {"id":415,"name":"Lumaca, cruda","cat":"Carni fresche","h2o":82.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":50.00,"ENc":67.00,"ENj":280.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.70,"pro":12.90,"sug":0.00},
    {"id":416,"name":"Lupini, ammollati","cat":"Legumi","h2o":73.00,"alc":0.00,"ami":6.00,"cho":7.10,"col":0.00,"ENc":119.00,"ENj":500.00,"fin":0.00,"fso":0.00,"fib":2.80,"fat":2.40,"pro":16.40,"sug":0.50},
    {"id":417,"name":"Macadamia","cat":"Frutta secca a guscio e semi oleaginosi","h2o":3.00,"alc":0.00,"ami":1.00,"cho":5.10,"col":0.00,"ENc":751.00,"ENj":3143.00,"fin":0.00,"fso":0.00,"fib":8.00,"fat":76.00,"pro":8.00,"sug":4.00},
    {"id":418,"name":"Maiale in agrodolce","cat":"Alimenti Etnici","h2o":58.50,"alc":0.00,"ami":6.70,"cho":17.70,"col":0.00,"ENc":233.00,"ENj":973.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":14.60,"pro":8.70,"sug":10.30},
    {"id":419,"name":"Maiale, bistecca, cotto, in padella","cat":"Carni fresche","h2o":62.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":74.00,"ENc":187.00,"ENj":783.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":9.50,"pro":25.40,"sug":0.00},
    {"id":420,"name":"Maiale, bistecca, crudo","cat":"Carni fresche","h2o":69.60,"alc":0.00,"ami":0.00,"cho":0.00,"col":62.00,"ENc":157.00,"ENj":658.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.00,"pro":21.30,"sug":0.00},
    {"id":421,"name":"Maiale, coscio, crudo","cat":"Carni fresche","h2o":75.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":64.00,"ENc":110.00,"ENj":459.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.20,"pro":20.20,"sug":0.00},
    {"id":422,"name":"Maiale, lombo, crudo","cat":"Carni fresche","h2o":70.70,"alc":0.00,"ami":0.00,"cho":0.00,"col":61.00,"ENc":146.00,"ENj":610.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.00,"pro":20.70,"sug":0.00},
    {"id":423,"name":"Maiale, pesante, coscio, crudo","cat":"Carni fresche","h2o":72.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":89.00,"ENc":127.00,"ENj":533.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.10,"pro":20.40,"sug":0.00},
    {"id":424,"name":"Maiale, pesante, lombo, crudo","cat":"Carni fresche","h2o":68.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":88.00,"ENc":172.00,"ENj":721.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":9.90,"pro":20.80,"sug":0.00},
    {"id":425,"name":"Maiale, pesante, spalla, crudo","cat":"Carni fresche","h2o":70.60,"alc":0.00,"ami":0.00,"cho":0.00,"col":83.00,"ENc":156.00,"ENj":653.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.90,"pro":19.00,"sug":0.00},
    {"id":426,"name":"Maiale, spalla, crudo","cat":"Carni fresche","h2o":73.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":67.00,"ENc":133.00,"ENj":555.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.30,"pro":19.00,"sug":0.00},
    {"id":427,"name":"Maionese, artigianale","cat":"Ricette Italiane","h2o":24.10,"alc":0.00,"ami":0.00,"cho":0.10,"col":92.00,"ENc":660.00,"ENj":2761.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":71.90,"pro":3.20,"sug":0.10},
    {"id":428,"name":"Maionese, industriale","cat":"Prodotti vari","h2o":15.00,"alc":0.00,"ami":0.00,"cho":2.10,"col":70.00,"ENc":655.00,"ENj":2741.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":70.00,"pro":4.30,"sug":2.10},
    {"id":429,"name":"Mais","cat":"Cereali e derivati","h2o":12.50,"alc":0.00,"ami":66.00,"cho":75.10,"col":0.00,"ENc":357.00,"ENj":1492.00,"fin":0.00,"fso":0.00,"fib":2.00,"fat":3.80,"pro":9.20,"sug":2.50},
    {"id":430,"name":"Mais, amido","cat":"Cereali e derivati","h2o":12.00,"alc":0.00,"ami":77.70,"cho":87.00,"col":0.00,"ENc":328.00,"ENj":1370.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.30,"sug":1.50},
    {"id":431,"name":"Mais, dolce, in scatola, sgocciolato","cat":"Cereali e derivati","h2o":70.30,"alc":0.00,"ami":13.80,"cho":20.50,"col":0.00,"ENc":105.00,"ENj":439.00,"fin":0.00,"fso":0.00,"fib":1.40,"fat":1.30,"pro":3.40,"sug":5.30},
    {"id":432,"name":"Mandaranci, freschi","cat":"Frutta","h2o":85.30,"alc":0.00,"ami":0.00,"cho":12.80,"col":0.00,"ENc":57.00,"ENj":240.00,"fin":0.00,"fso":0.00,"fib":2.20,"fat":0.20,"pro":0.80,"sug":12.80},
    {"id":433,"name":"Mandarini, freschi","cat":"Frutta","h2o":81.40,"alc":0.00,"ami":0.00,"cho":17.60,"col":0.00,"ENc":76.00,"ENj":317.00,"fin":1.03,"fso":1.03,"fib":1.70,"fat":0.30,"pro":0.90,"sug":17.60},
    {"id":434,"name":"Mandorle dolci, secche","cat":"Frutta secca a guscio e semi oleaginosi","h2o":5.10,"alc":0.00,"ami":0.80,"cho":4.60,"col":0.00,"ENc":628.00,"ENj":2629.00,"fin":12.04,"fso":12.04,"fib":12.70,"fat":55.30,"pro":22.00,"sug":3.70},
    {"id":435,"name":"Mango, fresco","cat":"Frutta","h2o":82.80,"alc":0.00,"ami":0.00,"cho":12.60,"col":0.00,"ENc":56.00,"ENj":235.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":0.20,"pro":1.00,"sug":12.60},
    {"id":436,"name":"Margarina, 100% vegetale","cat":"Oli e grassi","h2o":13.00,"alc":0.00,"ami":0.00,"cho":0.40,"col":0.00,"ENc":760.00,"ENj":3179.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":84.00,"pro":0.60,"sug":0.40},
    {"id":437,"name":"Margarina, 2\/3 di grassi animali, 1\/3 di grassi vegetali","cat":"Oli e grassi","h2o":12.00,"alc":0.00,"ami":0.00,"cho":0.40,"col":50.00,"ENc":760.00,"ENj":3181.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":84.00,"pro":0.70,"sug":0.40},
    {"id":438,"name":"Marmellata","cat":"Dolci","h2o":36.00,"alc":0.00,"ami":0.00,"cho":58.70,"col":0.00,"ENc":227.00,"ENj":948.00,"fin":0.00,"fso":0.00,"fib":2.20,"fat":0.00,"pro":0.50,"sug":58.70},
    {"id":439,"name":"Marsala all'uovo","cat":"Bevande alcoliche","h2o":72.80,"alc":14.80,"ami":0.00,"cho":12.40,"col":0.00,"ENc":150.00,"ENj":629.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":12.40},
    {"id":440,"name":"Marsala tipico","cat":"Bevande alcoliche","h2o":57.80,"alc":14.00,"ami":0.00,"cho":28.00,"col":0.00,"ENc":203.00,"ENj":850.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":28.00},
    {"id":441,"name":"Mascarpone","cat":"Formaggi e latticini","h2o":44.40,"alc":0.00,"ami":0.00,"cho":0.30,"col":0.00,"ENc":455.00,"ENj":1902.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":47.00,"pro":7.60,"sug":0.30},
    {"id":442,"name":"Melagrane, fresche","cat":"Frutta","h2o":80.50,"alc":0.00,"ami":0.00,"cho":15.90,"col":0.00,"ENc":68.00,"ENj":284.00,"fin":1.98,"fso":1.98,"fib":2.20,"fat":0.20,"pro":0.50,"sug":15.90},
    {"id":443,"name":"Melanzane, cotte, al microonde","cat":"Verdure e ortaggi","h2o":93.20,"alc":0.00,"ami":0.00,"cho":3.00,"col":0.00,"ENc":23.00,"ENj":96.00,"fin":0.00,"fso":0.00,"fib":2.40,"fat":0.40,"pro":0.80,"sug":3.00},
    {"id":444,"name":"Melanzane, cotte, in padella","cat":"Verdure e ortaggi","h2o":89.00,"alc":0.00,"ami":0.00,"cho":5.40,"col":0.00,"ENc":39.00,"ENj":164.00,"fin":2.44,"fso":2.44,"fib":3.70,"fat":0.70,"pro":1.30,"sug":5.40},
    {"id":445,"name":"Melanzane, crude","cat":"Verdure e ortaggi","h2o":93.30,"alc":0.00,"ami":0.00,"cho":2.60,"col":0.00,"ENc":23.00,"ENj":94.00,"fin":0.00,"fso":0.00,"fib":2.60,"fat":0.40,"pro":1.00,"sug":2.60},
    {"id":446,"name":"Mele cotogne, fresche","cat":"Frutta","h2o":84.30,"alc":0.00,"ami":0.00,"cho":6.30,"col":0.00,"ENc":38.00,"ENj":157.00,"fin":4.51,"fso":4.51,"fib":5.90,"fat":0.10,"pro":0.30,"sug":6.30},
    {"id":447,"name":"Mele, disidratate","cat":"Frutta","h2o":2.70,"alc":0.00,"ami":0.00,"cho":82.20,"col":0.00,"ENc":356.00,"ENj":1491.00,"fin":9.66,"fso":9.66,"fib":12.50,"fat":2.00,"pro":1.30,"sug":82.20},
    {"id":448,"name":"Mele, fresche, annurche","cat":"Frutta","h2o":84.30,"alc":0.00,"ami":0.00,"cho":10.20,"col":0.00,"ENc":45.00,"ENj":187.00,"fin":0.00,"fso":0.00,"fib":2.20,"fat":0.10,"pro":0.30,"sug":10.20},
    {"id":449,"name":"Mele, fresche, con buccia","cat":"Frutta","h2o":85.20,"alc":0.00,"ami":0.00,"cho":10.00,"col":0.00,"ENc":44.00,"ENj":182.00,"fin":1.84,"fso":1.84,"fib":2.60,"fat":0.00,"pro":0.20,"sug":10.00},
    {"id":450,"name":"Mele, fresche, deliziose","cat":"Frutta","h2o":86.80,"alc":0.00,"ami":0.00,"cho":11.10,"col":0.00,"ENc":46.00,"ENj":194.00,"fin":0.00,"fso":0.00,"fib":1.50,"fat":0.10,"pro":0.20,"sug":11.10},
    {"id":451,"name":"Mele, fresche, golden","cat":"Frutta","h2o":86.90,"alc":0.00,"ami":0.00,"cho":10.70,"col":0.00,"ENc":46.00,"ENj":193.00,"fin":0.00,"fso":0.00,"fib":1.70,"fat":0.10,"pro":0.40,"sug":10.70},
    {"id":452,"name":"Mele, fresche, granny smith","cat":"Frutta","h2o":87.80,"alc":0.00,"ami":0.00,"cho":9.40,"col":0.00,"ENc":42.00,"ENj":176.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":0.10,"pro":0.50,"sug":9.40},
    {"id":453,"name":"Mele, fresche, imperatore","cat":"Frutta","h2o":86.20,"alc":0.00,"ami":0.00,"cho":11.30,"col":0.00,"ENc":48.00,"ENj":200.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":0.10,"pro":0.20,"sug":11.30},
    {"id":454,"name":"Mele, fresche, renette","cat":"Frutta","h2o":87.60,"alc":0.00,"ami":0.00,"cho":10.00,"col":0.00,"ENc":43.00,"ENj":180.00,"fin":0.00,"fso":0.00,"fib":1.70,"fat":0.10,"pro":0.30,"sug":10.00},
    {"id":455,"name":"Mele, fresche, senza buccia","cat":"Frutta","h2o":82.50,"alc":0.00,"ami":0.00,"cho":13.70,"col":0.00,"ENc":57.00,"ENj":240.00,"fin":1.44,"fso":1.44,"fib":2.00,"fat":0.10,"pro":0.30,"sug":13.70},
    {"id":456,"name":"Melone, d'estate, fresco","cat":"Frutta","h2o":90.10,"alc":0.00,"ami":0.00,"cho":7.40,"col":0.00,"ENc":34.00,"ENj":143.00,"fin":0.55,"fso":0.55,"fib":0.70,"fat":0.20,"pro":0.80,"sug":7.40},
    {"id":457,"name":"Melone, d'inverno, fresco","cat":"Frutta","h2o":94.10,"alc":0.00,"ami":0.00,"cho":4.90,"col":0.00,"ENc":24.00,"ENj":99.00,"fin":0.00,"fso":0.00,"fib":0.70,"fat":0.20,"pro":0.50,"sug":4.90},
    {"id":458,"name":"Melù o pesce molo","cat":"Prodotti della pesca","h2o":80.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":52.00,"ENc":72.00,"ENj":303.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.30,"pro":17.40,"sug":0.00},
    {"id":459,"name":"Melù o pesce molo, stoccafisso, ammollato","cat":"Prodotti della pesca","h2o":76.10,"alc":0.00,"ami":0.00,"cho":0.30,"col":0.00,"ENc":92.00,"ENj":385.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.90,"pro":20.70,"sug":0.30},
    {"id":460,"name":"Melù o pesce molo, stoccafisso, secco","cat":"Prodotti della pesca","h2o":12.00,"alc":0.00,"ami":0.00,"cho":1.10,"col":0.00,"ENc":356.00,"ENj":1490.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.50,"pro":80.10,"sug":1.10},
    {"id":461,"name":"Menta, fresca","cat":"Verdure e ortaggi","h2o":86.40,"alc":0.00,"ami":0.00,"cho":5.30,"col":0.00,"ENc":49.00,"ENj":205.00,"fin":0.00,"fso":0.00,"fib":3.80,"fat":0.70,"pro":3.80,"sug":5.30},
    {"id":462,"name":"Merendine, con marmellata, industriale","cat":"Dolci","h2o":19.70,"alc":0.00,"ami":21.00,"cho":67.90,"col":0.00,"ENc":364.00,"ENj":1524.00,"fin":0.00,"fso":0.00,"fib":1.10,"fat":9.50,"pro":5.50,"sug":44.80},
    {"id":463,"name":"Merendine, farcite con cacao, industriale","cat":"Dolci","h2o":14.20,"alc":0.00,"ami":19.70,"cho":65.50,"col":0.00,"ENc":410.00,"ENj":1716.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":15.10,"pro":6.20,"sug":43.80},
    {"id":464,"name":"Merendine, tipo briosche, industriale","cat":"Dolci","h2o":11.30,"alc":0.00,"ami":39.30,"cho":65.70,"col":47.00,"ENc":440.00,"ENj":1839.00,"fin":0.00,"fso":0.00,"fib":1.00,"fat":17.60,"pro":8.20,"sug":22.50},
    {"id":465,"name":"Merendine, tipo pan di Spagna, industriale","cat":"Dolci","h2o":17.90,"alc":0.00,"ami":25.30,"cho":62.70,"col":58.00,"ENc":386.00,"ENj":1613.00,"fin":0.00,"fso":0.00,"fib":1.50,"fat":13.10,"pro":7.40,"sug":34.90},
    {"id":466,"name":"Merendine, tipo pasta frolla, industriale","cat":"Dolci","h2o":8.10,"alc":0.00,"ami":33.20,"cho":71.70,"col":47.00,"ENc":447.00,"ENj":1872.00,"fin":0.00,"fso":0.00,"fib":1.70,"fat":17.10,"pro":5.30,"sug":35.20},
    {"id":467,"name":"Merluzzo in umido","cat":"Ricette Italiane","h2o":80.80,"alc":0.00,"ami":0.00,"cho":1.70,"col":28.00,"ENc":86.00,"ENj":358.00,"fin":0.00,"fso":0.00,"fib":0.90,"fat":4.30,"pro":9.70,"sug":1.70},
    {"id":468,"name":"Merluzzo o nasello","cat":"Prodotti della pesca","h2o":81.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":50.00,"ENc":71.00,"ENj":296.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.30,"pro":17.00,"sug":0.00},
    {"id":469,"name":"Merluzzo o nasello, baccalà, ammollato","cat":"Prodotti della pesca","h2o":75.60,"alc":0.00,"ami":0.00,"cho":0.00,"col":50.00,"ENc":95.00,"ENj":399.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.00,"pro":21.60,"sug":0.00},
    {"id":470,"name":"Merluzzo o nasello, baccalà, secco","cat":"Prodotti della pesca","h2o":52.40,"alc":0.00,"ami":0.00,"cho":0.00,"col":82.00,"ENc":131.00,"ENj":549.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.70,"pro":29.00,"sug":0.00},
    {"id":471,"name":"Merluzzo o nasello, bastoncini di pesce, surgelati","cat":"Prodotti della pesca","h2o":61.50,"alc":0.00,"ami":13.00,"cho":15.00,"col":0.00,"ENc":191.00,"ENj":800.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":10.10,"pro":11.00,"sug":0.60},
    {"id":472,"name":"Merluzzo o nasello, surgelato","cat":"Prodotti della pesca","h2o":80.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":50.00,"ENc":75.00,"ENj":312.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.60,"pro":17.30,"sug":0.00},
    {"id":473,"name":"Merluzzo o nasello, surgelato, cotto, al microonde","cat":"Prodotti della pesca","h2o":72.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":62.00,"ENc":107.00,"ENj":449.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.90,"pro":24.80,"sug":0.00},
    {"id":474,"name":"Merluzzo o nasello, surgelato, cotto, in forno","cat":"Prodotti della pesca","h2o":79.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":60.00,"ENc":78.00,"ENj":327.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.90,"pro":17.50,"sug":0.00},
    {"id":475,"name":"Merluzzo o nasello, surgelato, filetti","cat":"Prodotti della pesca","h2o":81.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":49.00,"ENc":68.00,"ENj":284.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.60,"pro":15.60,"sug":0.00},
    {"id":476,"name":"Miele","cat":"Dolci","h2o":18.00,"alc":0.00,"ami":0.00,"cho":80.30,"col":0.00,"ENc":304.00,"ENj":1270.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.60,"sug":80.30},
    {"id":477,"name":"Miglio","cat":"Cereali e derivati","h2o":10.80,"alc":0.00,"ami":0.00,"cho":64.90,"col":0.00,"ENc":343.00,"ENj":1434.00,"fin":0.00,"fso":0.00,"fib":8.50,"fat":3.90,"pro":11.80,"sug":0.00},
    {"id":478,"name":"Miglio, decorticato","cat":"Cereali e derivati","h2o":12.60,"alc":0.00,"ami":57.20,"cho":67.80,"col":0.00,"ENc":342.00,"ENj":1432.00,"fin":0.00,"fso":0.00,"fib":3.10,"fat":4.20,"pro":11.00,"sug":4.90},
    {"id":479,"name":"Milza di bovino","cat":"Frattaglie","h2o":75.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":107.00,"ENj":449.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.70,"pro":18.50,"sug":0.00},
    {"id":480,"name":"Minestre in scatola, crema di asparagi","cat":"Prodotti vari","h2o":92.90,"alc":0.00,"ami":0.00,"cho":4.20,"col":0.00,"ENc":26.00,"ENj":109.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.70,"pro":1.00,"sug":0.00},
    {"id":481,"name":"Minestre in scatola, crema di cipolle","cat":"Prodotti vari","h2o":93.40,"alc":0.00,"ami":0.00,"cho":2.20,"col":0.00,"ENc":26.00,"ENj":109.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.00,"pro":2.20,"sug":0.00},
    {"id":482,"name":"Minestre in scatola, crema di funghi","cat":"Prodotti vari","h2o":89.60,"alc":0.00,"ami":0.00,"cho":4.20,"col":0.00,"ENc":56.00,"ENj":233.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.00,"pro":1.00,"sug":0.00},
    {"id":483,"name":"Minestre in scatola, crema di pollo","cat":"Prodotti vari","h2o":87.90,"alc":0.00,"ami":3.10,"cho":4.50,"col":0.00,"ENc":58.00,"ENj":242.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.80,"pro":1.70,"sug":1.10},
    {"id":484,"name":"Minestre in scatola, crema di pomodori","cat":"Prodotti vari","h2o":90.50,"alc":0.00,"ami":0.00,"cho":6.40,"col":0.00,"ENc":36.00,"ENj":151.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.00,"pro":0.80,"sug":0.00},
    {"id":485,"name":"Minestrone liofilizzato","cat":"Prodotti vari","h2o":3.90,"alc":0.00,"ami":29.40,"cho":47.60,"col":0.00,"ENc":298.00,"ENj":1247.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.80,"pro":10.10,"sug":15.00},
    {"id":486,"name":"Minestrone, cotto","cat":"Verdure e ortaggi","h2o":88.10,"alc":0.00,"ami":0.00,"cho":7.80,"col":0.00,"ENc":45.00,"ENj":188.00,"fin":1.50,"fso":1.50,"fib":2.10,"fat":0.40,"pro":2.00,"sug":0.00},
    {"id":487,"name":"Mirtilli, freschi","cat":"Frutta","h2o":85.00,"alc":0.00,"ami":0.00,"cho":10.10,"col":0.00,"ENc":49.00,"ENj":207.00,"fin":0.00,"fso":0.00,"fib":3.10,"fat":0.20,"pro":0.90,"sug":10.10},
    {"id":488,"name":"Mora di rovo","cat":"Frutta","h2o":85.00,"alc":0.00,"ami":0.00,"cho":8.10,"col":0.00,"ENc":42.00,"ENj":176.00,"fin":0.00,"fso":0.00,"fib":3.20,"fat":0.00,"pro":1.30,"sug":8.10},
    {"id":489,"name":"Mormora","cat":"Prodotti della pesca","h2o":78.00,"alc":0.00,"ami":0.00,"cho":2.00,"col":45.00,"ENc":97.00,"ENj":404.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.90,"pro":18.00,"sug":2.00},
    {"id":490,"name":"Mortadella Bologna IGP","cat":"Carni trasformate e conservate","h2o":56.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":72.00,"ENc":288.00,"ENj":1204.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":25.00,"pro":15.70,"sug":0.00},
    {"id":491,"name":"Mortadella di bovino e suino","cat":"Carni trasformate e conservate","h2o":44.20,"alc":0.00,"ami":0.00,"cho":0.50,"col":0.00,"ENc":388.00,"ENj":1624.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":37.00,"pro":13.30,"sug":0.50},
    {"id":492,"name":"Mozzarella di bufala","cat":"Formaggi e latticini","h2o":55.50,"alc":0.00,"ami":0.00,"cho":0.40,"col":0.00,"ENc":288.00,"ENj":1205.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":24.40,"pro":16.70,"sug":0.40},
    {"id":493,"name":"Mozzarella di bufala campana DOP","cat":"Formaggi e latticini","h2o":61.30,"alc":0.00,"ami":0.00,"cho":1.40,"col":52.00,"ENc":257.00,"ENj":1075.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":21.70,"pro":14.10,"sug":0.00},
    {"id":494,"name":"Mozzarella di vacca","cat":"Formaggi e latticini","h2o":58.80,"alc":0.00,"ami":0.00,"cho":0.70,"col":46.00,"ENc":253.00,"ENj":1058.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":19.50,"pro":18.70,"sug":0.70},
    {"id":495,"name":"Muesli","cat":"Cereali e derivati","h2o":7.40,"alc":0.00,"ami":41.40,"cho":71.70,"col":0.00,"ENc":374.00,"ENj":1567.00,"fin":0.00,"fso":0.00,"fib":6.40,"fat":6.00,"pro":9.70,"sug":26.20},
    {"id":496,"name":"Nachos","cat":"Alimenti Etnici","h2o":2.30,"alc":0.00,"ami":57.80,"cho":63.80,"col":0.00,"ENc":512.00,"ENj":2140.00,"fin":0.00,"fso":0.00,"fib":5.70,"fat":26.10,"pro":6.50,"sug":0.00},
    {"id":497,"name":"Nespole, fresche","cat":"Frutta","h2o":88.30,"alc":0.00,"ami":0.00,"cho":6.10,"col":0.00,"ENc":32.00,"ENj":135.00,"fin":1.57,"fso":1.57,"fib":2.10,"fat":0.40,"pro":0.40,"sug":6.10},
    {"id":498,"name":"Nocciole, secche","cat":"Frutta secca a guscio e semi oleaginosi","h2o":4.50,"alc":0.00,"ami":1.80,"cho":6.10,"col":0.00,"ENc":671.00,"ENj":2808.00,"fin":0.00,"fso":0.00,"fib":8.10,"fat":64.10,"pro":13.80,"sug":4.10},
    {"id":499,"name":"Noci","cat":"Frutta secca a guscio e semi oleaginosi","h2o":19.20,"alc":0.00,"ami":1.90,"cho":5.50,"col":0.00,"ENc":589.00,"ENj":2464.00,"fin":0.00,"fso":0.00,"fib":3.50,"fat":57.70,"pro":10.50,"sug":3.40},
    {"id":500,"name":"Noci pecan","cat":"Frutta secca a guscio e semi oleaginosi","h2o":5.00,"alc":0.00,"ami":3.30,"cho":7.90,"col":0.00,"ENc":723.00,"ENj":3027.00,"fin":9.07,"fso":9.07,"fib":9.40,"fat":71.80,"pro":7.20,"sug":4.30},
    {"id":501,"name":"Noci, secche","cat":"Frutta secca a guscio e semi oleaginosi","h2o":3.50,"alc":0.00,"ami":1.80,"cho":5.10,"col":0.00,"ENc":702.00,"ENj":2936.00,"fin":5.37,"fso":5.37,"fib":6.20,"fat":68.10,"pro":14.30,"sug":3.10},
    {"id":502,"name":"Nuvole di gamberi","cat":"Alimenti Etnici","h2o":7.20,"alc":0.00,"ami":48.00,"cho":57.00,"col":0.00,"ENc":559.00,"ENj":2339.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":38.00,"pro":0.80,"sug":4.20},
    {"id":503,"name":"Oca, crudo","cat":"Carni fresche","h2o":49.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":68.00,"ENc":373.00,"ENj":1560.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":34.40,"pro":15.80,"sug":0.00},
    {"id":504,"name":"Occhiata","cat":"Prodotti della pesca","h2o":77.00,"alc":0.00,"ami":0.00,"cho":2.00,"col":48.00,"ENc":117.00,"ENj":489.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.70,"pro":19.00,"sug":2.00},
    {"id":505,"name":"Olii vegetali","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":506,"name":"Olio di arachide","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":507,"name":"Olio di cocco","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":508,"name":"Olio di colza","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":509,"name":"Olio di fegato di merluzzo","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":570.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":510,"name":"Olio di germe di grano","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":511,"name":"Olio di girasole","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":512,"name":"Olio di mais","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":513,"name":"Olio di mandorle dolci","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":514,"name":"Olio di oliva","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":515,"name":"Olio di oliva extra vergine","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":516,"name":"Olio di palma","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":517,"name":"Olio di sesamo","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":518,"name":"Olio di soia","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":519,"name":"Olio di vinacciolo","cat":"Oli e grassi","h2o":0.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":899.00,"ENj":3762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.90,"pro":0.00,"sug":0.00},
    {"id":520,"name":"Oliva Giarraffa","cat":"Frutta","h2o":73.10,"alc":0.00,"ami":0.00,"cho":0.19,"col":0.00,"ENc":192.00,"ENj":801.00,"fin":0.00,"fso":0.00,"fib":2.60,"fat":20.00,"pro":1.40,"sug":0.19},
    {"id":521,"name":"Oliva Nocellara del Belice","cat":"Frutta","h2o":68.90,"alc":0.00,"ami":0.00,"cho":0.06,"col":0.00,"ENc":211.00,"ENj":884.00,"fin":0.00,"fso":0.00,"fib":3.30,"fat":22.10,"pro":1.40,"sug":0.06},
    {"id":522,"name":"Olive da tavola conservate","cat":"Frutta","h2o":58.00,"alc":0.00,"ami":0.00,"cho":5.00,"col":0.00,"ENc":277.00,"ENj":1157.00,"fin":0.00,"fso":0.00,"fib":4.40,"fat":27.00,"pro":1.50,"sug":5.00},
    {"id":523,"name":"Olive, nere","cat":"Frutta","h2o":68.60,"alc":0.00,"ami":0.00,"cho":0.80,"col":0.00,"ENc":243.00,"ENj":1017.00,"fin":0.00,"fso":0.00,"fib":3.90,"fat":25.10,"pro":1.60,"sug":0.80},
    {"id":524,"name":"Olive, verdi","cat":"Frutta","h2o":76.80,"alc":0.00,"ami":0.00,"cho":1.00,"col":0.00,"ENc":148.00,"ENj":619.00,"fin":0.00,"fso":0.00,"fib":3.00,"fat":15.00,"pro":0.80,"sug":1.00},
    {"id":525,"name":"Olive, verdi, in salamoia","cat":"Frutta","h2o":78.00,"alc":0.00,"ami":0.00,"cho":1.00,"col":0.00,"ENc":113.00,"ENj":473.00,"fin":0.00,"fso":0.00,"fib":2.80,"fat":11.00,"pro":1.20,"sug":1.00},
    {"id":526,"name":"Orata fresca d'allevamento, filetti","cat":"Prodotti della pesca","h2o":69.10,"alc":0.00,"ami":0.00,"cho":1.20,"col":68.00,"ENc":159.00,"ENj":665.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.40,"pro":19.70,"sug":1.20},
    {"id":527,"name":"Orata fresca, filetti","cat":"Prodotti della pesca","h2o":73.20,"alc":0.00,"ami":0.00,"cho":1.00,"col":64.00,"ENc":121.00,"ENj":505.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.80,"pro":20.70,"sug":1.00},
    {"id":528,"name":"Orata, surgelata","cat":"Prodotti della pesca","h2o":78.40,"alc":0.00,"ami":0.00,"cho":1.00,"col":63.00,"ENc":94.00,"ENj":392.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.20,"pro":19.80,"sug":1.00},
    {"id":529,"name":"Orzo perlato","cat":"Cereali e derivati","h2o":12.00,"alc":0.00,"ami":65.60,"cho":73.70,"col":0.00,"ENc":346.00,"ENj":1447.00,"fin":4.83,"fso":4.83,"fib":9.20,"fat":1.50,"pro":9.40,"sug":1.50},
    {"id":530,"name":"Orzo perlato, cotto, bollito","cat":"Cereali e derivati","h2o":72.70,"alc":0.00,"ami":22.90,"cho":26.40,"col":0.00,"ENc":118.00,"ENj":495.00,"fin":0.00,"fso":0.00,"fib":1.80,"fat":0.60,"pro":2.60,"sug":1.20},
    {"id":531,"name":"Ostrica","cat":"Prodotti della pesca","h2o":85.70,"alc":0.00,"ami":4.50,"cho":5.40,"col":30.00,"ENc":69.00,"ENj":289.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.90,"pro":10.20,"sug":0.50},
    {"id":532,"name":"Pagello","cat":"Prodotti della pesca","h2o":79.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":35.00,"ENc":101.00,"ENj":423.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.90,"pro":21.00,"sug":0.00},
    {"id":533,"name":"Pagello bocca d'oro","cat":"Prodotti della pesca","h2o":73.00,"alc":0.00,"ami":0.00,"cho":3.00,"col":23.00,"ENc":118.00,"ENj":495.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.00,"pro":20.00,"sug":3.00},
    {"id":534,"name":"Palombo","cat":"Prodotti della pesca","h2o":79.20,"alc":0.00,"ami":0.00,"cho":1.30,"col":0.00,"ENc":80.00,"ENj":333.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.20,"pro":16.00,"sug":1.30},
    {"id":535,"name":"Pan di Spagna","cat":"Ricette Italiane","h2o":34.30,"alc":0.00,"ami":16.80,"cho":49.70,"col":144.00,"ENc":275.00,"ENj":1153.00,"fin":0.00,"fso":0.00,"fib":1.40,"fat":5.10,"pro":10.10,"sug":31.20},
    {"id":536,"name":"Pancetta arrotolata","cat":"Carni trasformate e conservate","h2o":30.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":68.00,"ENc":530.00,"ENj":2219.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":52.20,"pro":15.10,"sug":0.00},
    {"id":537,"name":"Pancetta magretta","cat":"Carni trasformate e conservate","h2o":47.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":65.00,"ENc":314.00,"ENj":1316.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":25.30,"pro":21.70,"sug":0.00},
    {"id":538,"name":"Pancetta tesa","cat":"Carni trasformate e conservate","h2o":45.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":80.00,"ENc":337.00,"ENj":1408.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":28.10,"pro":20.90,"sug":0.00},
    {"id":539,"name":"Pane al malto","cat":"Cereali e derivati","h2o":26.00,"alc":0.00,"ami":27.70,"cho":56.60,"col":0.00,"ENc":278.00,"ENj":1163.00,"fin":0.00,"fso":0.00,"fib":5.50,"fat":2.40,"pro":8.30,"sug":26.10},
    {"id":540,"name":"Pane azzimo","cat":"Cereali e derivati","h2o":4.50,"alc":0.00,"ami":72.50,"cho":81.70,"col":0.00,"ENc":365.00,"ENj":1528.00,"fin":1.89,"fso":1.89,"fib":3.50,"fat":1.00,"pro":10.70,"sug":1.90},
    {"id":541,"name":"Pane bianco","cat":"Cereali e derivati","h2o":31.00,"alc":0.00,"ami":52.30,"cho":59.50,"col":0.00,"ENc":268.00,"ENj":1120.00,"fin":0.00,"fso":0.00,"fib":3.80,"fat":0.50,"pro":8.10,"sug":2.00},
    {"id":542,"name":"Pane di segale","cat":"Cereali e derivati","h2o":37.00,"alc":0.00,"ami":39.50,"cho":45.40,"col":0.00,"ENc":228.00,"ENj":954.00,"fin":0.00,"fso":0.00,"fib":4.60,"fat":1.70,"pro":8.30,"sug":1.80},
    {"id":543,"name":"Pane di tipo integrale","cat":"Cereali e derivati","h2o":38.60,"alc":0.00,"ami":38.20,"cho":44.10,"col":0.00,"ENc":224.00,"ENj":938.00,"fin":5.36,"fso":5.36,"fib":6.50,"fat":1.30,"pro":8.50,"sug":2.00},
    {"id":544,"name":"Pane di tipo semintegrale","cat":"Cereali e derivati","h2o":34.00,"alc":0.00,"ami":45.30,"cho":52.00,"col":0.00,"ENc":239.00,"ENj":1001.00,"fin":0.00,"fso":0.00,"fib":4.20,"fat":0.30,"pro":8.30,"sug":2.20},
    {"id":545,"name":"Pane formato rosetta","cat":"Cereali e derivati","h2o":30.70,"alc":0.00,"ami":45.50,"cho":57.60,"col":0.00,"ENc":275.00,"ENj":1152.00,"fin":1.63,"fso":1.63,"fib":3.10,"fat":1.90,"pro":9.00,"sug":7.50},
    {"id":546,"name":"Panettone","cat":"Dolci","h2o":26.90,"alc":0.00,"ami":30.30,"cho":56.20,"col":0.00,"ENc":337.00,"ENj":1409.00,"fin":0.00,"fso":0.00,"fib":2.10,"fat":10.70,"pro":6.40,"sug":22.90},
    {"id":547,"name":"Pangrattato","cat":"Cereali e derivati","h2o":6.80,"alc":0.00,"ami":66.20,"cho":77.80,"col":0.00,"ENc":357.00,"ENj":1496.00,"fin":0.00,"fso":0.00,"fib":3.20,"fat":2.10,"pro":10.10,"sug":5.00},
    {"id":548,"name":"Panini al latte","cat":"Cereali e derivati","h2o":33.50,"alc":0.00,"ami":41.30,"cho":48.10,"col":0.00,"ENc":298.00,"ENj":1249.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":8.70,"pro":9.00,"sug":2.70},
    {"id":549,"name":"Panini all'olio","cat":"Cereali e derivati","h2o":30.80,"alc":0.00,"ami":44.20,"cho":52.60,"col":0.00,"ENc":288.00,"ENj":1204.00,"fin":0.00,"fso":0.00,"fib":3.70,"fat":5.80,"pro":7.70,"sug":4.00},
    {"id":550,"name":"Panino con hamburger piccolo, fast food","cat":"Fast-food a base di carne","h2o":40.30,"alc":0.00,"ami":22.10,"cho":29.00,"col":0.00,"ENc":254.00,"ENj":1061.00,"fin":0.00,"fso":0.00,"fib":1.20,"fat":8.90,"pro":15.60,"sug":4.70},
    {"id":551,"name":"Panino, fast food","cat":"Fast-food a base di carne","h2o":32.40,"alc":0.00,"ami":35.90,"cho":47.00,"col":0.00,"ENc":248.00,"ENj":1036.00,"fin":0.00,"fso":0.00,"fib":2.00,"fat":3.00,"pro":10.10,"sug":7.50},
    {"id":552,"name":"Panna da cucina, sterilizzata, 23% di lipidi","cat":"Latte e yogurt","h2o":68.80,"alc":0.00,"ami":0.00,"cho":4.00,"col":79.00,"ENc":233.00,"ENj":974.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":23.00,"pro":2.70,"sug":4.00},
    {"id":553,"name":"Panna da montare, 35% di lipidi","cat":"Latte e yogurt","h2o":58.50,"alc":0.00,"ami":0.00,"cho":3.40,"col":120.00,"ENc":337.00,"ENj":1410.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":35.00,"pro":2.30,"sug":3.40},
    {"id":554,"name":"Papaia, fresca","cat":"Frutta","h2o":89.30,"alc":0.00,"ami":0.00,"cho":6.90,"col":0.00,"ENc":33.00,"ENj":138.00,"fin":0.00,"fso":0.00,"fib":2.30,"fat":0.10,"pro":0.40,"sug":6.90},
    {"id":555,"name":"Pappa reale","cat":"Prodotti vari","h2o":66.00,"alc":0.00,"ami":0.00,"cho":14.00,"col":0.00,"ENc":146.00,"ENj":613.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.00,"pro":10.00,"sug":14.00},
    {"id":556,"name":"Parmigiana di melanzane","cat":"Ricette Italiane","h2o":65.00,"alc":0.00,"ami":0.00,"cho":4.00,"col":141.00,"ENc":242.00,"ENj":1011.00,"fin":0.00,"fso":0.00,"fib":4.80,"fat":21.00,"pro":7.00,"sug":4.00},
    {"id":557,"name":"Parmigiano Reggiano DOP","cat":"Formaggi e latticini","h2o":31.40,"alc":0.00,"ami":0.00,"cho":0.00,"col":83.00,"ENc":397.00,"ENj":1661.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":29.70,"pro":32.40,"sug":0.00},
    {"id":558,"name":"Passiflora, fresca","cat":"Frutta","h2o":74.40,"alc":0.00,"ami":0.00,"cho":5.70,"col":0.00,"ENc":64.00,"ENj":267.00,"fin":0.00,"fso":0.00,"fib":13.90,"fat":0.30,"pro":3.00,"sug":5.70},
    {"id":559,"name":"Pasta aglio, olio e peperoncino","cat":"Ricette Italiane","h2o":56.80,"alc":0.00,"ami":23.10,"cho":26.50,"col":0.00,"ENc":190.00,"ENj":795.00,"fin":0.87,"fso":0.87,"fib":1.40,"fat":7.90,"pro":4.20,"sug":1.20},
    {"id":560,"name":"Pasta al pesto alla genovese","cat":"Ricette Italiane","h2o":61.60,"alc":0.00,"ami":18.20,"cho":21.40,"col":7.00,"ENc":175.00,"ENj":732.00,"fin":0.99,"fso":0.99,"fib":1.50,"fat":7.20,"pro":6.70,"sug":1.50},
    {"id":561,"name":"Pasta al ragù alla bolognese","cat":"Ricette Italiane","h2o":64.20,"alc":0.00,"ami":13.10,"cho":16.00,"col":22.00,"ENc":168.00,"ENj":703.00,"fin":0.84,"fso":0.84,"fib":1.20,"fat":8.00,"pro":8.40,"sug":1.60},
    {"id":562,"name":"Pasta al sugo di pomodoro","cat":"Ricette Italiane","h2o":71.50,"alc":0.00,"ami":15.70,"cho":19.50,"col":0.00,"ENc":113.00,"ENj":472.00,"fin":1.06,"fso":1.06,"fib":1.60,"fat":2.60,"pro":3.30,"sug":2.20},
    {"id":563,"name":"Pasta alla amatriciana","cat":"Ricette Italiane","h2o":55.50,"alc":0.00,"ami":24.40,"cho":28.70,"col":14.00,"ENc":241.00,"ENj":1007.00,"fin":0.00,"fso":0.00,"fib":2.00,"fat":11.00,"pro":7.50,"sug":1.80},
    {"id":564,"name":"Pasta alla carbonara","cat":"Ricette Italiane","h2o":51.40,"alc":0.00,"ami":24.90,"cho":28.40,"col":95.00,"ENc":245.00,"ENj":1024.00,"fin":0.00,"fso":0.00,"fib":1.20,"fat":10.30,"pro":10.80,"sug":1.00},
    {"id":565,"name":"Pasta alle vongole","cat":"Ricette Italiane","h2o":62.60,"alc":0.00,"ami":25.00,"cho":28.30,"col":34.00,"ENc":157.00,"ENj":657.00,"fin":0.00,"fso":0.00,"fib":1.30,"fat":2.40,"pro":6.70,"sug":0.80},
    {"id":566,"name":"Pasta all'uovo, secca","cat":"Cereali e derivati","h2o":12.50,"alc":0.00,"ami":62.70,"cho":71.00,"col":94.00,"ENc":346.00,"ENj":1449.00,"fin":0.00,"fso":0.00,"fib":3.20,"fat":2.40,"pro":13.00,"sug":2.00},
    {"id":567,"name":"Pasta all'uovo, secca, cotta, bollita","cat":"Cereali e derivati","h2o":68.50,"alc":0.00,"ami":23.00,"cho":25.50,"col":31.00,"ENc":123.00,"ENj":516.00,"fin":0.00,"fso":0.00,"fib":1.10,"fat":0.80,"pro":4.60,"sug":0.17},
    {"id":568,"name":"Pasta burro e parmigiano","cat":"Ricette Italiane","h2o":57.70,"alc":0.00,"ami":23.70,"cho":27.30,"col":17.00,"ENc":184.00,"ENj":769.00,"fin":0.90,"fso":0.90,"fib":1.30,"fat":6.10,"pro":6.00,"sug":1.20},
    {"id":569,"name":"Pasta cacio e pepe","cat":"Ricette Italiane","h2o":58.70,"alc":0.00,"ami":23.70,"cho":27.40,"col":9.00,"ENc":167.00,"ENj":699.00,"fin":0.90,"fso":0.90,"fib":1.30,"fat":3.80,"pro":6.90,"sug":1.40},
    {"id":570,"name":"Pasta di mandorle","cat":"Frutta secca a guscio e semi oleaginosi","h2o":10.80,"alc":0.00,"ami":0.00,"cho":54.60,"col":0.00,"ENc":455.00,"ENj":1904.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":23.40,"pro":9.90,"sug":54.60},
    {"id":571,"name":"Pasta di semola","cat":"Cereali e derivati","h2o":10.80,"alc":0.00,"ami":64.10,"cho":72.70,"col":0.00,"ENc":341.00,"ENj":1426.00,"fin":0.98,"fso":0.98,"fib":1.70,"fat":1.20,"pro":13.50,"sug":2.20},
    {"id":572,"name":"Pasta di semola, cotta, bollita","cat":"Cereali e derivati","h2o":54.30,"alc":0.00,"ami":32.90,"cho":37.30,"col":0.00,"ENc":175.00,"ENj":731.00,"fin":0.62,"fso":0.62,"fib":0.90,"fat":0.60,"pro":6.90,"sug":1.10},
    {"id":573,"name":"Pasta di semola, integrale","cat":"Cereali e derivati","h2o":10.70,"alc":0.00,"ami":56.20,"cho":64.80,"col":0.00,"ENc":330.00,"ENj":1382.00,"fin":5.57,"fso":5.57,"fib":7.10,"fat":2.20,"pro":13.30,"sug":3.00},
    {"id":574,"name":"Pasta di semola, integrale, cotta, bollita","cat":"Cereali e derivati","h2o":50.40,"alc":0.00,"ami":31.20,"cho":36.00,"col":0.00,"ENc":182.00,"ENj":763.00,"fin":2.84,"fso":2.84,"fib":3.50,"fat":1.20,"pro":7.40,"sug":1.70},
    {"id":575,"name":"Pasta e fagioli","cat":"Ricette Italiane","h2o":75.00,"alc":0.00,"ami":9.50,"cho":12.20,"col":34.00,"ENc":116.00,"ENj":485.00,"fin":1.79,"fso":1.79,"fib":2.30,"fat":5.10,"pro":4.90,"sug":1.80},
    {"id":576,"name":"Pasta integrale al sugo di pomodoro","cat":"Ricette Italiane","h2o":66.50,"alc":0.00,"ami":18.70,"cho":21.30,"col":0.00,"ENc":132.00,"ENj":554.00,"fin":2.10,"fso":2.10,"fib":2.70,"fat":3.20,"pro":4.60,"sug":0.90},
    {"id":577,"name":"Pastiera","cat":"Ricette Italiane","h2o":37.00,"alc":0.00,"ami":15.30,"cho":43.60,"col":88.00,"ENc":295.00,"ENj":1235.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":11.00,"pro":7.20,"sug":26.70},
    {"id":578,"name":"Patate novelle, cotte, al microonde","cat":"Verdure e ortaggi","h2o":79.90,"alc":0.00,"ami":14.10,"cho":15.70,"col":0.00,"ENc":72.00,"ENj":303.00,"fin":0.00,"fso":0.00,"fib":1.40,"fat":0.30,"pro":2.00,"sug":0.20},
    {"id":579,"name":"Patate novelle, cotte, bollte","cat":"Verdure e ortaggi","h2o":82.90,"alc":0.00,"ami":13.40,"cho":14.90,"col":0.00,"ENc":68.00,"ENj":285.00,"fin":1.05,"fso":1.05,"fib":1.40,"fat":0.20,"pro":1.90,"sug":0.20},
    {"id":580,"name":"Patate novelle, crude","cat":"Verdure e ortaggi","h2o":81.90,"alc":0.00,"ami":14.10,"cho":15.70,"col":0.00,"ENc":72.00,"ENj":303.00,"fin":0.00,"fso":0.00,"fib":1.40,"fat":0.30,"pro":2.00,"sug":0.20},
    {"id":581,"name":"Patate, con buccia, cotte, bollite","cat":"Verdure e ortaggi","h2o":78.50,"alc":0.00,"ami":15.90,"cho":17.90,"col":0.00,"ENc":88.00,"ENj":367.00,"fin":0.85,"fso":0.85,"fib":1.60,"fat":1.00,"pro":2.10,"sug":0.40},
    {"id":582,"name":"Patate, cotte, al microonde","cat":"Verdure e ortaggi","h2o":78.50,"alc":0.00,"ami":15.90,"cho":17.90,"col":0.00,"ENc":88.00,"ENj":367.00,"fin":0.85,"fso":0.85,"fib":1.60,"fat":1.00,"pro":2.10,"sug":0.40},
    {"id":583,"name":"Patate, cotte, arrosto","cat":"Verdure e ortaggi","h2o":65.00,"alc":0.00,"ami":22.80,"cho":25.70,"col":0.00,"ENc":152.00,"ENj":636.00,"fin":0.00,"fso":0.00,"fib":1.80,"fat":4.50,"pro":2.90,"sug":0.60},
    {"id":584,"name":"Patate, crude","cat":"Verdure e ortaggi","h2o":80.60,"alc":0.00,"ami":13.50,"cho":16.00,"col":0.00,"ENc":72.00,"ENj":303.00,"fin":0.96,"fso":0.96,"fib":1.80,"fat":0.10,"pro":2.00,"sug":1.00},
    {"id":585,"name":"Patate, fritte","cat":"Verdure e ortaggi","h2o":55.50,"alc":0.00,"ami":26.60,"cho":29.90,"col":0.00,"ENc":192.00,"ENj":805.00,"fin":0.00,"fso":0.00,"fib":2.20,"fat":6.70,"pro":3.90,"sug":0.60},
    {"id":586,"name":"Patate, senza buccia, cotte, bollite","cat":"Verdure e ortaggi","h2o":80.10,"alc":0.00,"ami":15.00,"cho":16.90,"col":0.00,"ENc":74.00,"ENj":310.00,"fin":0.00,"fso":0.00,"fib":1.30,"fat":0.10,"pro":1.80,"sug":0.40},
    {"id":587,"name":"Patatine, fritte, confezionate in busta","cat":"Verdure e ortaggi","h2o":12.10,"alc":0.00,"ami":51.80,"cho":58.00,"col":0.00,"ENc":512.00,"ENj":2142.00,"fin":0.00,"fso":0.00,"fib":3.20,"fat":29.60,"pro":5.40,"sug":1.00},
    {"id":588,"name":"Patatine, fritte, fast food","cat":"Fast-food a base di carne","h2o":38.70,"alc":0.00,"ami":36.30,"cho":37.60,"col":0.00,"ENc":283.00,"ENj":1184.00,"fin":0.00,"fso":0.00,"fib":2.70,"fat":13.70,"pro":3.30,"sug":1.30},
    {"id":589,"name":"Patè di coniglio","cat":"Carni trasformate e conservate","h2o":51.70,"alc":0.00,"ami":0.00,"cho":1.10,"col":0.00,"ENc":346.00,"ENj":1447.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":32.20,"pro":13.00,"sug":0.00},
    {"id":590,"name":"Patè di fegato","cat":"Carni trasformate e conservate","h2o":51.50,"alc":0.00,"ami":0.00,"cho":1.30,"col":169.00,"ENc":341.00,"ENj":1427.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":31.70,"pro":12.70,"sug":0.00},
    {"id":591,"name":"Patè di pollo","cat":"Carni trasformate e conservate","h2o":55.40,"alc":0.00,"ami":0.00,"cho":2.30,"col":0.00,"ENc":289.00,"ENj":1208.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":26.20,"pro":11.10,"sug":0.00},
    {"id":592,"name":"Patè di prosciutto","cat":"Carni trasformate e conservate","h2o":49.80,"alc":0.00,"ami":0.00,"cho":1.10,"col":0.00,"ENc":358.00,"ENj":1498.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":34.30,"pro":11.30,"sug":0.00},
    {"id":593,"name":"Pecora, solo tessuto muscolare, crudo","cat":"Carni fresche","h2o":74.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":67.00,"ENc":121.00,"ENj":506.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.10,"pro":21.00,"sug":0.00},
    {"id":594,"name":"Pecorino","cat":"Formaggi e latticini","h2o":34.00,"alc":0.00,"ami":0.00,"cho":0.20,"col":88.00,"ENc":404.00,"ENj":1690.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":32.00,"pro":28.80,"sug":0.20},
    {"id":595,"name":"Pecorino romano","cat":"Formaggi e latticini","h2o":31.90,"alc":0.00,"ami":0.00,"cho":1.80,"col":90.00,"ENc":409.00,"ENj":1710.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":33.10,"pro":26.00,"sug":1.80},
    {"id":596,"name":"Pecorino siciliano","cat":"Formaggi e latticini","h2o":30.30,"alc":0.00,"ami":0.00,"cho":2.40,"col":90.00,"ENc":427.00,"ENj":1787.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":33.60,"pro":28.90,"sug":2.40},
    {"id":597,"name":"Pepe nero","cat":"Verdure e ortaggi","h2o":11.50,"alc":0.00,"ami":44.50,"cho":49.00,"col":0.00,"ENc":311.00,"ENj":1301.00,"fin":0.00,"fso":0.00,"fib":25.90,"fat":3.30,"pro":11.40,"sug":0.00},
    {"id":598,"name":"Peperoncini piccanti, freschi","cat":"Verdure e ortaggi","h2o":91.80,"alc":0.00,"ami":2.10,"cho":3.80,"col":0.00,"ENc":30.00,"ENj":125.00,"fin":0.00,"fso":0.00,"fib":2.00,"fat":0.50,"pro":1.80,"sug":1.50},
    {"id":599,"name":"Peperoni, cotti, in padella","cat":"Verdure e ortaggi","h2o":55.40,"alc":0.00,"ami":0.00,"cho":24.30,"col":0.00,"ENc":143.00,"ENj":598.00,"fin":4.80,"fso":4.80,"fib":8.70,"fat":1.50,"pro":5.20,"sug":24.30},
    {"id":600,"name":"Peperoni, crudi","cat":"Verdure e ortaggi","h2o":92.30,"alc":0.00,"ami":0.00,"cho":4.20,"col":0.00,"ENc":26.00,"ENj":108.00,"fin":1.47,"fso":1.47,"fib":1.90,"fat":0.30,"pro":0.90,"sug":4.20},
    {"id":601,"name":"Peperoni, gialli, cotti, al microonde","cat":"Verdure e ortaggi","h2o":89.60,"alc":0.00,"ami":0.00,"cho":8.10,"col":0.00,"ENc":41.00,"ENj":172.00,"fin":0.00,"fso":0.00,"fib":2.30,"fat":0.20,"pro":1.10,"sug":8.10},
    {"id":602,"name":"Peperoni, gialli, crudi","cat":"Verdure e ortaggi","h2o":91.30,"alc":0.00,"ami":0.00,"cho":6.80,"col":0.00,"ENc":35.00,"ENj":145.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":0.20,"pro":0.90,"sug":6.80},
    {"id":603,"name":"Peperoni, rossi e gialli, crudi","cat":"Verdure e ortaggi","h2o":91.50,"alc":0.00,"ami":0.00,"cho":6.70,"col":0.00,"ENc":35.00,"ENj":147.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":0.30,"pro":0.90,"sug":6.70},
    {"id":604,"name":"Peperoni, rossi, cotti, al microonde","cat":"Verdure e ortaggi","h2o":89.80,"alc":0.00,"ami":0.00,"cho":7.90,"col":0.00,"ENc":42.00,"ENj":177.00,"fin":0.00,"fso":0.00,"fib":2.30,"fat":0.40,"pro":1.10,"sug":7.90},
    {"id":605,"name":"Peperoni, rossi, crudi","cat":"Verdure e ortaggi","h2o":91.60,"alc":0.00,"ami":0.00,"cho":6.50,"col":0.00,"ENc":34.00,"ENj":144.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":0.30,"pro":0.90,"sug":6.50},
    {"id":606,"name":"Peperoni, verdi, crudi","cat":"Verdure e ortaggi","h2o":92.00,"alc":0.00,"ami":0.00,"cho":6.00,"col":0.00,"ENc":31.00,"ENj":129.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":0.20,"pro":0.70,"sug":6.00},
    {"id":607,"name":"Pere, candite","cat":"Frutta","h2o":21.00,"alc":0.00,"ami":0.00,"cho":73.90,"col":0.00,"ENc":294.00,"ENj":1229.00,"fin":0.00,"fso":0.00,"fib":4.50,"fat":0.40,"pro":1.00,"sug":73.90},
    {"id":608,"name":"Pere, fresche, Abate Fetel","cat":"Frutta","h2o":82.70,"alc":0.00,"ami":0.00,"cho":12.70,"col":0.00,"ENc":59.00,"ENj":246.00,"fin":0.00,"fso":0.00,"fib":3.80,"fat":0.30,"pro":0.20,"sug":12.70},
    {"id":609,"name":"Pere, fresche, Coscia","cat":"Frutta","h2o":87.00,"alc":0.00,"ami":0.00,"cho":8.60,"col":0.00,"ENc":42.00,"ENj":176.00,"fin":0.00,"fso":0.00,"fib":3.80,"fat":0.10,"pro":0.30,"sug":8.60},
    {"id":610,"name":"Pere, fresche, Kaiser","cat":"Frutta","h2o":86.00,"alc":0.00,"ami":0.00,"cho":9.20,"col":0.00,"ENc":48.00,"ENj":200.00,"fin":0.00,"fso":0.00,"fib":3.80,"fat":0.50,"pro":0.30,"sug":9.20},
    {"id":611,"name":"Pere, fresche, Max-Red Barlett","cat":"Frutta","h2o":85.20,"alc":0.00,"ami":0.00,"cho":10.00,"col":0.00,"ENc":50.00,"ENj":209.00,"fin":0.00,"fso":0.00,"fib":3.80,"fat":0.40,"pro":0.30,"sug":10.00},
    {"id":612,"name":"Pere, fresche, senza buccia","cat":"Frutta","h2o":87.40,"alc":0.00,"ami":0.00,"cho":8.80,"col":0.00,"ENc":43.00,"ENj":179.00,"fin":2.56,"fso":2.56,"fib":3.80,"fat":0.10,"pro":0.30,"sug":8.80},
    {"id":613,"name":"Pere, fresche, William","cat":"Frutta","h2o":85.00,"alc":0.00,"ami":0.00,"cho":10.20,"col":0.00,"ENc":52.00,"ENj":216.00,"fin":0.00,"fso":0.00,"fib":3.80,"fat":0.50,"pro":0.30,"sug":10.20},
    {"id":614,"name":"Pesce gatto","cat":"Prodotti della pesca","h2o":79.80,"alc":0.00,"ami":0.00,"cho":0.20,"col":0.00,"ENc":92.00,"ENj":387.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.30,"pro":15.50,"sug":0.20},
    {"id":615,"name":"Pesche, disidratate","cat":"Frutta","h2o":3.00,"alc":0.00,"ami":0.00,"cho":88.00,"col":0.00,"ENc":364.00,"ENj":1524.00,"fin":0.00,"fso":0.00,"fib":3.50,"fat":0.90,"pro":4.80,"sug":88.00},
    {"id":616,"name":"Pesche, fresche, con buccia","cat":"Frutta","h2o":88.20,"alc":0.00,"ami":0.00,"cho":5.80,"col":0.00,"ENc":28.00,"ENj":119.00,"fin":1.14,"fso":1.14,"fib":1.90,"fat":0.00,"pro":0.70,"sug":5.80},
    {"id":617,"name":"Pesche, fresche, senza buccia","cat":"Frutta","h2o":90.70,"alc":0.00,"ami":0.00,"cho":6.10,"col":0.00,"ENc":30.00,"ENj":126.00,"fin":0.71,"fso":0.71,"fib":1.60,"fat":0.10,"pro":0.80,"sug":6.10},
    {"id":618,"name":"Pesche, sciroppate","cat":"Frutta","h2o":81.10,"alc":0.00,"ami":0.00,"cho":14.00,"col":0.00,"ENc":56.00,"ENj":236.00,"fin":0.00,"fso":0.00,"fib":0.90,"fat":0.00,"pro":0.50,"sug":14.00},
    {"id":619,"name":"Pesche, secche","cat":"Frutta","h2o":25.00,"alc":0.00,"ami":0.00,"cho":68.30,"col":0.00,"ENc":280.00,"ENj":1173.00,"fin":0.00,"fso":0.00,"fib":2.80,"fat":0.70,"pro":3.10,"sug":68.30},
    {"id":620,"name":"Peschenoci, fresche","cat":"Frutta","h2o":88.40,"alc":0.00,"ami":0.00,"cho":7.00,"col":0.00,"ENc":36.00,"ENj":150.00,"fin":0.65,"fso":0.65,"fib":1.50,"fat":0.10,"pro":1.40,"sug":7.00},
    {"id":621,"name":"Pesto alla genovese","cat":"Ricette Italiane","h2o":61.30,"alc":0.00,"ami":0.00,"cho":1.80,"col":21.00,"ENc":250.00,"ENj":1044.00,"fin":0.96,"fso":0.96,"fib":1.30,"fat":21.80,"pro":11.00,"sug":1.80},
    {"id":622,"name":"Piccione giovane, crudo","cat":"Carni fresche","h2o":71.70,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":138.00,"ENj":577.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.50,"pro":22.10,"sug":0.00},
    {"id":623,"name":"Pinoli","cat":"Frutta secca a guscio e semi oleaginosi","h2o":4.30,"alc":0.00,"ami":0.10,"cho":4.00,"col":0.00,"ENc":604.00,"ENj":2528.00,"fin":3.77,"fso":3.77,"fib":4.50,"fat":50.30,"pro":31.90,"sug":3.90},
    {"id":624,"name":"Piselli, freschi","cat":"Legumi","h2o":79.40,"alc":0.00,"ami":2.30,"cho":6.50,"col":0.00,"ENc":64.00,"ENj":269.00,"fin":5.80,"fso":5.80,"fib":6.30,"fat":0.60,"pro":5.50,"sug":4.00},
    {"id":625,"name":"Piselli, freschi, cotti, in padella","cat":"Legumi","h2o":68.50,"alc":0.00,"ami":4.10,"cho":11.50,"col":0.00,"ENc":104.00,"ENj":434.00,"fin":5.73,"fso":5.73,"fib":6.40,"fat":1.00,"pro":9.70,"sug":7.00},
    {"id":626,"name":"Piselli, in scatola, scolati","cat":"Legumi","h2o":77.90,"alc":0.00,"ami":6.50,"cho":11.10,"col":0.00,"ENc":78.00,"ENj":328.00,"fin":0.52,"fso":0.52,"fib":5.10,"fat":0.60,"pro":5.30,"sug":3.90},
    {"id":627,"name":"Piselli, secchi","cat":"Legumi","h2o":13.00,"alc":0.00,"ami":41.20,"cho":48.20,"col":0.00,"ENc":317.00,"ENj":1326.00,"fin":0.00,"fso":0.00,"fib":15.70,"fat":2.00,"pro":21.70,"sug":2.90},
    {"id":628,"name":"Piselli, surgelati","cat":"Legumi","h2o":80.70,"alc":0.00,"ami":2.30,"cho":6.50,"col":0.00,"ENc":61.00,"ENj":256.00,"fin":0.00,"fso":0.00,"fib":6.30,"fat":0.30,"pro":5.40,"sug":4.00},
    {"id":629,"name":"Pistacchi","cat":"Frutta secca a guscio e semi oleaginosi","h2o":3.90,"alc":0.00,"ami":3.30,"cho":8.10,"col":0.00,"ENc":629.00,"ENj":2631.00,"fin":0.00,"fso":0.00,"fib":10.60,"fat":56.10,"pro":18.10,"sug":4.50},
    {"id":630,"name":"Pistacchio di Bronte DOP","cat":"Frutta secca a guscio e semi oleaginosi","h2o":5.30,"alc":0.00,"ami":0.00,"cho":7.30,"col":0.00,"ENc":595.00,"ENj":2491.00,"fin":0.00,"fso":0.00,"fib":15.30,"fat":48.90,"pro":24.30,"sug":7.30},
    {"id":631,"name":"Pizza bianca","cat":"Cereali e derivati","h2o":27.80,"alc":0.00,"ami":50.70,"cho":57.90,"col":0.00,"ENc":306.00,"ENj":1282.00,"fin":1.11,"fso":1.11,"fib":2.30,"fat":5.90,"pro":7.90,"sug":2.10},
    {"id":632,"name":"Pizza con pomodoro","cat":"Cereali e derivati","h2o":41.30,"alc":0.00,"ami":34.40,"cho":40.70,"col":0.00,"ENc":248.00,"ENj":1037.00,"fin":1.93,"fso":1.93,"fib":2.70,"fat":6.60,"pro":7.60,"sug":2.90},
    {"id":633,"name":"Pizza con pomodoro e mozzarella","cat":"Cereali e derivati","h2o":41.00,"alc":0.00,"ami":30.00,"cho":35.50,"col":0.00,"ENc":255.00,"ENj":1067.00,"fin":0.00,"fso":0.00,"fib":2.50,"fat":7.60,"pro":12.10,"sug":2.50},
    {"id":634,"name":"Pizza Napoletana Margherita STG","cat":"Ricette Italiane","h2o":48.50,"alc":0.00,"ami":24.10,"cho":27.40,"col":17.00,"ENc":235.00,"ENj":982.00,"fin":0.00,"fso":0.00,"fib":2.70,"fat":9.30,"pro":10.70,"sug":0.90},
    {"id":635,"name":"Polenta al ragù alla bolognese","cat":"Ricette Italiane","h2o":75.70,"alc":0.00,"ami":10.60,"cho":12.40,"col":15.00,"ENc":114.00,"ENj":479.00,"fin":0.23,"fso":0.23,"fib":0.90,"fat":5.30,"pro":4.60,"sug":0.80},
    {"id":636,"name":"Polenta al sugo di pomodoro","cat":"Ricette Italiane","h2o":81.80,"alc":0.00,"ami":11.90,"cho":14.20,"col":0.00,"ENc":73.00,"ENj":305.00,"fin":0.29,"fso":0.29,"fib":1.10,"fat":1.50,"pro":1.00,"sug":1.10},
    {"id":637,"name":"Polenta, cotta","cat":"Cereali e derivati","h2o":80.40,"alc":0.00,"ami":16.00,"cho":17.80,"col":0.00,"ENc":73.00,"ENj":303.00,"fin":0.00,"fso":0.00,"fib":0.90,"fat":0.04,"pro":0.90,"sug":0.20},
    {"id":638,"name":"Polenta, cruda","cat":"Cereali e derivati","h2o":10.40,"alc":0.00,"ami":62.50,"cho":72.70,"col":0.00,"ENc":323.00,"ENj":1353.00,"fin":0.00,"fso":0.00,"fib":6.20,"fat":0.70,"pro":8.00,"sug":0.60},
    {"id":639,"name":"Pollo all'ananas","cat":"Alimenti Etnici","h2o":74.70,"alc":0.00,"ami":0.00,"cho":5.50,"col":0.00,"ENc":126.00,"ENj":527.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.60,"pro":11.50,"sug":5.50},
    {"id":640,"name":"Pollo alle mandorle","cat":"Alimenti Etnici","h2o":67.20,"alc":0.00,"ami":1.00,"cho":2.40,"col":0.00,"ENc":182.00,"ENj":762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":11.90,"pro":16.50,"sug":1.30},
    {"id":641,"name":"Pollo, ala, con pelle, cotta, al forno","cat":"Carni fresche","h2o":49.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":283.00,"ENj":1186.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":17.40,"pro":31.70,"sug":0.00},
    {"id":642,"name":"Pollo, ala, con pelle, crudo","cat":"Carni fresche","h2o":68.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":196.00,"ENj":818.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":14.30,"pro":16.70,"sug":0.00},
    {"id":643,"name":"Pollo, fuso, con pelle, cotto, al forno","cat":"Carni fresche","h2o":58.70,"alc":0.00,"ami":0.00,"cho":0.00,"col":91.00,"ENc":201.00,"ENj":842.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.50,"pro":31.20,"sug":0.00},
    {"id":644,"name":"Pollo, fuso, con pelle, crudo","cat":"Carni fresche","h2o":74.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":94.00,"ENc":125.00,"ENj":523.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.70,"pro":18.40,"sug":0.00},
    {"id":645,"name":"Pollo, fuso, senza pelle, cotto, al forno","cat":"Carni fresche","h2o":62.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":86.00,"ENc":175.00,"ENj":734.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.20,"pro":29.90,"sug":0.00},
    {"id":646,"name":"Pollo, fuso, senza pelle, crudo","cat":"Carni fresche","h2o":77.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":88.00,"ENc":107.00,"ENj":449.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.70,"pro":18.50,"sug":0.00},
    {"id":647,"name":"Pollo, intero, con pelle, cotto, al forno","cat":"Carni fresche","h2o":61.60,"alc":0.00,"ami":0.00,"cho":0.00,"col":119.00,"ENc":200.00,"ENj":838.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":10.20,"pro":27.10,"sug":0.00},
    {"id":648,"name":"Pollo, intero, con pelle, cotto, arrosto","cat":"Carni fresche","h2o":55.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":119.00,"ENc":245.00,"ENj":1027.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":14.70,"pro":28.30,"sug":0.00},
    {"id":649,"name":"Pollo, intero, con pelle, crudo","cat":"Carni fresche","h2o":69.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":93.00,"ENc":171.00,"ENj":717.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":10.60,"pro":19.00,"sug":0.00},
    {"id":650,"name":"Pollo, intero, senza pelle, cotto, al forno","cat":"Carni fresche","h2o":65.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":109.00,"ENc":160.00,"ENj":670.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.40,"pro":27.90,"sug":0.00},
    {"id":651,"name":"Pollo, intero, senza pelle, cotto, arrosto","cat":"Carni fresche","h2o":59.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":109.00,"ENc":206.00,"ENj":860.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":10.00,"pro":28.90,"sug":0.00},
    {"id":652,"name":"Pollo, intero, senza pelle, crudo","cat":"Carni fresche","h2o":76.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":75.00,"ENc":110.00,"ENj":460.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.60,"pro":19.40,"sug":0.00},
    {"id":653,"name":"Pollo, petto, cotto, in padella","cat":"Carni fresche","h2o":67.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":75.00,"ENc":129.00,"ENj":539.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.90,"pro":30.20,"sug":0.00},
    {"id":654,"name":"Pollo, petto, crudo","cat":"Carni fresche","h2o":74.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":60.00,"ENc":100.00,"ENj":420.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.80,"pro":23.30,"sug":0.00},
    {"id":655,"name":"Pollo, sovracoscio, con pelle, cotto, al forno","cat":"Carni fresche","h2o":59.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":80.00,"ENc":219.00,"ENj":918.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":12.50,"pro":26.70,"sug":0.00},
    {"id":656,"name":"Pollo, sovracoscio, con pelle, crudo","cat":"Carni fresche","h2o":67.40,"alc":0.00,"ami":0.00,"cho":0.00,"col":82.00,"ENc":196.00,"ENj":818.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":14.00,"pro":17.40,"sug":0.00},
    {"id":657,"name":"Pollo, sovracoscio, senza pelle, cotto, al forno","cat":"Carni fresche","h2o":61.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":70.00,"ENc":188.00,"ENj":786.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.30,"pro":28.30,"sug":0.00},
    {"id":658,"name":"Pollo, sovracoscio, senza pelle, crudo","cat":"Carni fresche","h2o":74.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":73.00,"ENc":132.00,"ENj":550.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.30,"pro":18.70,"sug":0.00},
    {"id":659,"name":"Polmone di bovino","cat":"Frattaglie","h2o":83.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":75.00,"ENj":313.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.10,"pro":14.00,"sug":0.00},
    {"id":660,"name":"Polpo","cat":"Prodotti della pesca","h2o":82.00,"alc":0.00,"ami":0.00,"cho":1.40,"col":72.00,"ENc":57.00,"ENj":237.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.00,"pro":10.60,"sug":1.40},
    {"id":661,"name":"Pomodori al riso","cat":"Ricette Italiane","h2o":78.80,"alc":0.00,"ami":12.40,"cho":14.50,"col":0.00,"ENc":93.00,"ENj":390.00,"fin":0.00,"fso":0.00,"fib":2.80,"fat":2.80,"pro":2.00,"sug":0.90},
    {"id":662,"name":"Pomodori, conserva","cat":"Verdure e ortaggi","h2o":70.00,"alc":0.00,"ami":0.00,"cho":20.40,"col":0.00,"ENc":100.00,"ENj":417.00,"fin":0.00,"fso":0.00,"fib":2.00,"fat":0.40,"pro":3.90,"sug":20.40},
    {"id":663,"name":"Pomodori, da insalata, freschi","cat":"Verdure e ortaggi","h2o":94.20,"alc":0.00,"ami":0.00,"cho":2.80,"col":0.00,"ENc":19.00,"ENj":80.00,"fin":0.77,"fso":0.77,"fib":1.00,"fat":0.20,"pro":1.20,"sug":2.80},
    {"id":664,"name":"Pomodori, maturi, freschi","cat":"Verdure e ortaggi","h2o":94.00,"alc":0.00,"ami":0.00,"cho":3.50,"col":0.00,"ENc":23.00,"ENj":96.00,"fin":1.67,"fso":1.67,"fib":2.00,"fat":0.20,"pro":1.00,"sug":3.50},
    {"id":665,"name":"Pomodori, Nerina, rossi, freschi","cat":"Verdure e ortaggi","h2o":93.30,"alc":0.00,"ami":0.00,"cho":2.80,"col":0.00,"ENc":21.00,"ENj":89.00,"fin":0.00,"fso":0.00,"fib":1.40,"fat":0.40,"pro":1.10,"sug":2.80},
    {"id":666,"name":"Pomodori, Nerina, verdi, freschi","cat":"Verdure e ortaggi","h2o":93.60,"alc":0.00,"ami":0.00,"cho":2.80,"col":0.00,"ENc":21.00,"ENj":87.00,"fin":0.00,"fso":0.00,"fib":1.50,"fat":0.40,"pro":0.90,"sug":2.80},
    {"id":667,"name":"Pomodori, passata","cat":"Verdure e ortaggi","h2o":90.80,"alc":0.00,"ami":0.00,"cho":3.00,"col":0.00,"ENc":21.00,"ENj":89.00,"fin":0.00,"fso":0.00,"fib":1.50,"fat":0.20,"pro":1.30,"sug":3.00},
    {"id":668,"name":"Pomodori, pelati in scatola","cat":"Verdure e ortaggi","h2o":94.70,"alc":0.00,"ami":0.00,"cho":3.00,"col":0.00,"ENc":22.00,"ENj":94.00,"fin":0.00,"fso":0.00,"fib":0.90,"fat":0.50,"pro":1.20,"sug":3.00},
    {"id":669,"name":"Pomodori, San Marzano, freschi","cat":"Verdure e ortaggi","h2o":94.10,"alc":0.00,"ami":0.00,"cho":3.00,"col":0.00,"ENc":21.00,"ENj":90.00,"fin":0.00,"fso":0.00,"fib":2.00,"fat":0.20,"pro":1.10,"sug":3.00},
    {"id":670,"name":"Pomodori, succo","cat":"Verdure e ortaggi","h2o":93.80,"alc":0.00,"ami":0.00,"cho":3.00,"col":0.00,"ENc":16.00,"ENj":66.00,"fin":0.00,"fso":0.00,"fib":0.60,"fat":0.00,"pro":0.80,"sug":3.00},
    {"id":671,"name":"Pomodorini ciliegino, freschi","cat":"Verdure e ortaggi","h2o":91.00,"alc":0.00,"ami":0.00,"cho":7.20,"col":0.00,"ENc":37.00,"ENj":155.00,"fin":0.00,"fso":0.00,"fib":1.00,"fat":0.40,"pro":1.10,"sug":9.00},
    {"id":672,"name":"Pompelmo, fresco","cat":"Frutta","h2o":91.20,"alc":0.00,"ami":0.00,"cho":6.20,"col":0.00,"ENc":29.00,"ENj":121.00,"fin":1.06,"fso":1.06,"fib":1.60,"fat":0.00,"pro":0.60,"sug":6.20},
    {"id":673,"name":"Pop corn","cat":"Prodotti vari","h2o":4.30,"alc":0.00,"ami":0.00,"cho":77.90,"col":0.00,"ENc":408.00,"ENj":1708.00,"fin":0.00,"fso":0.00,"fib":15.10,"fat":4.20,"pro":12.00,"sug":0.00},
    {"id":674,"name":"Porri, cotti, bolliti","cat":"Verdure e ortaggi","h2o":87.60,"alc":0.00,"ami":0.00,"cho":5.30,"col":0.00,"ENc":35.00,"ENj":147.00,"fin":2.07,"fso":2.07,"fib":3.00,"fat":0.10,"pro":2.10,"sug":5.30},
    {"id":675,"name":"Porri, crudi","cat":"Verdure e ortaggi","h2o":87.80,"alc":0.00,"ami":0.00,"cho":5.20,"col":0.00,"ENc":35.00,"ENj":145.00,"fin":0.00,"fso":0.00,"fib":2.90,"fat":0.10,"pro":2.10,"sug":5.20},
    {"id":676,"name":"Prezzemolo, fresco","cat":"Verdure e ortaggi","h2o":87.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":30.00,"ENj":127.00,"fin":0.00,"fso":0.00,"fib":5.00,"fat":0.60,"pro":3.70,"sug":0.00},
    {"id":677,"name":"Prosciutto cotto","cat":"Carni trasformate e conservate","h2o":72.20,"alc":0.00,"ami":0.00,"cho":1.70,"col":49.00,"ENc":138.00,"ENj":576.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.60,"pro":15.70,"sug":0.00},
    {"id":678,"name":"Prosciutto cotto, alta qualità","cat":"Carni trasformate e conservate","h2o":66.80,"alc":0.00,"ami":0.00,"cho":0.80,"col":50.00,"ENc":182.00,"ENj":762.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":11.90,"pro":18.00,"sug":0.00},
    {"id":679,"name":"Prosciutto cotto, alta qualità, sgrassato","cat":"Carni trasformate e conservate","h2o":71.80,"alc":0.00,"ami":0.00,"cho":0.90,"col":59.00,"ENc":126.00,"ENj":529.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.00,"pro":19.50,"sug":0.00},
    {"id":680,"name":"Prosciutto cotto, scelto","cat":"Carni trasformate e conservate","h2o":70.00,"alc":0.00,"ami":0.00,"cho":0.60,"col":57.00,"ENc":155.00,"ENj":649.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":9.20,"pro":17.50,"sug":0.00},
    {"id":681,"name":"Prosciutto cotto, scelto, sgrassato","cat":"Carni trasformate e conservate","h2o":73.30,"alc":0.00,"ami":0.00,"cho":0.50,"col":49.00,"ENc":115.00,"ENj":483.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.00,"pro":19.40,"sug":0.00},
    {"id":682,"name":"Prosciutto cotto, sgrassato","cat":"Carni trasformate e conservate","h2o":74.70,"alc":0.00,"ami":0.00,"cho":1.90,"col":42.00,"ENc":107.00,"ENj":446.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.50,"pro":17.00,"sug":0.00},
    {"id":683,"name":"Prosciutto crudo di montagna","cat":"Carni trasformate e conservate","h2o":44.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":303.00,"ENj":1267.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":22.00,"pro":26.20,"sug":0.00},
    {"id":684,"name":"Prosciutto crudo DOP, di Parma","cat":"Carni trasformate e conservate","h2o":50.30,"alc":0.00,"ami":0.00,"cho":0.30,"col":81.00,"ENc":269.00,"ENj":1127.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":18.30,"pro":25.90,"sug":0.00},
    {"id":685,"name":"Prosciutto crudo, di Modena DOP","cat":"Carni trasformate e conservate","h2o":45.60,"alc":0.00,"ami":0.00,"cho":0.10,"col":62.00,"ENc":309.00,"ENj":1292.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":22.90,"pro":25.60,"sug":0.00},
    {"id":686,"name":"Prosciutto crudo, di Modena DOP, sgrassato","cat":"Carni trasformate e conservate","h2o":53.80,"alc":0.00,"ami":0.00,"cho":0.10,"col":75.00,"ENc":201.00,"ENj":842.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.90,"pro":30.20,"sug":0.00},
    {"id":687,"name":"Prosciutto crudo, di Parma DOP, sgrassato","cat":"Carni trasformate e conservate","h2o":58.10,"alc":0.00,"ami":0.00,"cho":0.40,"col":89.00,"ENc":176.00,"ENj":734.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.40,"pro":29.10,"sug":0.00},
    {"id":688,"name":"Prosciutto crudo, di Pietraroja","cat":"Carni trasformate e conservate","h2o":40.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":146.00,"ENc":358.00,"ENj":1497.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":28.10,"pro":26.20,"sug":0.00},
    {"id":689,"name":"Prosciutto crudo, di San Daniele DOP","cat":"Carni trasformate e conservate","h2o":50.20,"alc":0.00,"ami":0.00,"cho":0.20,"col":83.00,"ENc":271.00,"ENj":1134.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":18.60,"pro":25.70,"sug":0.00},
    {"id":690,"name":"Prosciutto crudo, di San Daniele DOP, sgrassato","cat":"Carni trasformate e conservate","h2o":58.00,"alc":0.00,"ami":0.00,"cho":0.40,"col":91.00,"ENc":176.00,"ENj":736.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.50,"pro":29.00,"sug":0.00},
    {"id":691,"name":"Prosciutto crudo, disossato","cat":"Carni trasformate e conservate","h2o":53.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":66.00,"ENc":224.00,"ENj":936.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":12.90,"pro":26.90,"sug":0.00},
    {"id":692,"name":"Prosciutto crudo, disossato, sgrassato","cat":"Carni trasformate e conservate","h2o":57.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":159.00,"ENj":664.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.60,"pro":29.30,"sug":0.00},
    {"id":693,"name":"Prosciutto crudo, gambuccio","cat":"Carni trasformate e conservate","h2o":43.60,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":306.00,"ENj":1282.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":22.40,"pro":26.20,"sug":0.00},
    {"id":694,"name":"Prosciutto crudo, Nazionale","cat":"Carni trasformate e conservate","h2o":50.50,"alc":0.00,"ami":0.00,"cho":0.10,"col":75.00,"ENc":235.00,"ENj":983.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":13.70,"pro":27.80,"sug":0.00},
    {"id":695,"name":"Prosciutto crudo, Nazionale, sgrassato","cat":"Carni trasformate e conservate","h2o":56.10,"alc":0.00,"ami":0.00,"cho":0.30,"col":87.00,"ENc":169.00,"ENj":707.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.10,"pro":30.50,"sug":0.00},
    {"id":696,"name":"Provolone","cat":"Formaggi e latticini","h2o":39.00,"alc":0.00,"ami":0.00,"cho":2.00,"col":73.00,"ENc":374.00,"ENj":1564.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":28.20,"pro":28.10,"sug":2.00},
    {"id":697,"name":"Prugne, fresche","cat":"Frutta","h2o":87.50,"alc":0.00,"ami":0.00,"cho":10.50,"col":0.00,"ENc":45.00,"ENj":189.00,"fin":0.87,"fso":0.87,"fib":1.50,"fat":0.10,"pro":0.50,"sug":10.50},
    {"id":698,"name":"Prugne, gialle","cat":"Frutta","h2o":88.10,"alc":0.00,"ami":0.00,"cho":6.60,"col":0.00,"ENc":30.00,"ENj":127.00,"fin":0.83,"fso":0.83,"fib":1.40,"fat":0.10,"pro":0.50,"sug":6.60},
    {"id":699,"name":"Prugne, rosse","cat":"Frutta","h2o":87.50,"alc":0.00,"ami":0.00,"cho":10.50,"col":0.00,"ENc":45.00,"ENj":190.00,"fin":0.91,"fso":0.91,"fib":1.60,"fat":0.10,"pro":0.50,"sug":10.50},
    {"id":700,"name":"Prugne, secche","cat":"Frutta","h2o":29.30,"alc":0.00,"ami":0.00,"cho":55.00,"col":0.00,"ENc":236.00,"ENj":989.00,"fin":4.84,"fso":4.84,"fib":8.40,"fat":0.50,"pro":2.20,"sug":55.00},
    {"id":701,"name":"Quaglia, crudo","cat":"Carni fresche","h2o":65.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":58.00,"ENc":161.00,"ENj":674.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.80,"pro":25.00,"sug":0.00},
    {"id":702,"name":"Quinoa, cotta, bollita","cat":"Cereali e derivati","h2o":71.20,"alc":0.00,"ami":19.30,"cho":24.30,"col":0.00,"ENc":140.00,"ENj":586.00,"fin":0.00,"fso":0.00,"fib":3.60,"fat":2.50,"pro":4.80,"sug":3.10},
    {"id":703,"name":"Quinoa, cruda","cat":"Cereali e derivati","h2o":10.80,"alc":0.00,"ami":47.70,"cho":57.80,"col":0.00,"ENc":376.00,"ENj":1572.00,"fin":0.00,"fso":0.00,"fib":12.20,"fat":8.10,"pro":15.40,"sug":5.30},
    {"id":704,"name":"Radicchio rosso di Treviso IGP, precoce","cat":"Verdure e ortaggi","h2o":92.10,"alc":0.00,"ami":0.00,"cho":1.00,"col":0.00,"ENc":16.00,"ENj":67.00,"fin":0.00,"fso":0.00,"fib":2.50,"fat":0.10,"pro":1.60,"sug":1.00},
    {"id":705,"name":"Radicchio rosso di Treviso IGP, tardivo","cat":"Verdure e ortaggi","h2o":92.50,"alc":0.00,"ami":0.00,"cho":1.40,"col":0.00,"ENc":16.00,"ENj":68.00,"fin":0.00,"fso":0.00,"fib":2.10,"fat":0.10,"pro":1.50,"sug":1.40},
    {"id":706,"name":"Radicchio variegato di Castelfranco IGP","cat":"Verdure e ortaggi","h2o":93.10,"alc":0.00,"ami":0.00,"cho":1.40,"col":0.00,"ENc":16.00,"ENj":65.00,"fin":0.00,"fso":0.00,"fib":2.10,"fat":0.10,"pro":1.30,"sug":1.40},
    {"id":707,"name":"Radicchio, rosso, fresco","cat":"Verdure e ortaggi","h2o":94.00,"alc":0.00,"ami":0.00,"cho":1.60,"col":0.00,"ENc":19.00,"ENj":77.00,"fin":2.37,"fso":2.37,"fib":3.00,"fat":0.10,"pro":1.40,"sug":1.60},
    {"id":708,"name":"Radicchio, verde, fresco","cat":"Verdure e ortaggi","h2o":88.10,"alc":0.00,"ami":0.00,"cho":0.50,"col":0.00,"ENc":14.00,"ENj":59.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.50,"pro":1.90,"sug":0.50},
    {"id":709,"name":"Ragù alla bolognese","cat":"Ricette Italiane","h2o":66.70,"alc":0.00,"ami":0.00,"cho":1.90,"col":44.00,"ENc":196.00,"ENj":818.00,"fin":0.69,"fso":0.69,"fib":0.90,"fat":15.40,"pro":12.00,"sug":1.90},
    {"id":710,"name":"Rana, crudo","cat":"Carni fresche","h2o":81.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":50.00,"ENc":64.00,"ENj":267.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.20,"pro":15.50,"sug":0.00},
    {"id":711,"name":"Rape, cotte, bollite","cat":"Verdure e ortaggi","h2o":92.80,"alc":0.00,"ami":0.20,"cho":4.30,"col":0.00,"ENc":26.00,"ENj":109.00,"fin":2.10,"fso":2.10,"fib":2.80,"fat":0.00,"pro":1.10,"sug":4.10},
    {"id":712,"name":"Rape, crude","cat":"Verdure e ortaggi","h2o":93.30,"alc":0.00,"ami":0.20,"cho":4.00,"col":0.00,"ENc":24.00,"ENj":101.00,"fin":2.32,"fso":2.32,"fib":2.60,"fat":0.00,"pro":1.00,"sug":3.80},
    {"id":713,"name":"Ravanelli, freschi","cat":"Verdure e ortaggi","h2o":95.60,"alc":0.00,"ami":0.00,"cho":1.80,"col":0.00,"ENc":13.00,"ENj":56.00,"fin":1.23,"fso":1.23,"fib":1.30,"fat":0.10,"pro":0.80,"sug":1.80},
    {"id":714,"name":"Ravioli al burro e salvia","cat":"Ricette Italiane","h2o":54.40,"alc":0.00,"ami":21.20,"cho":24.70,"col":49.00,"ENc":238.00,"ENj":995.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":12.90,"pro":6.50,"sug":1.40},
    {"id":715,"name":"Ravioli al sugo di pomodoro","cat":"Ricette Italiane","h2o":70.20,"alc":0.00,"ami":13.90,"cho":17.60,"col":15.00,"ENc":135.00,"ENj":564.00,"fin":0.00,"fso":0.00,"fib":1.80,"fat":5.10,"pro":4.80,"sug":2.30},
    {"id":716,"name":"Ravioli al vapore","cat":"Alimenti Etnici","h2o":53.00,"alc":0.00,"ami":17.60,"cho":21.40,"col":0.00,"ENc":270.00,"ENj":1128.00,"fin":0.00,"fso":0.00,"fib":1.50,"fat":17.50,"pro":7.20,"sug":2.00},
    {"id":717,"name":"Ravioli, cotti","cat":"Cereali e derivati","h2o":59.10,"alc":0.00,"ami":23.70,"cho":27.50,"col":25.00,"ENc":177.00,"ENj":739.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":4.60,"pro":7.20,"sug":1.40},
    {"id":718,"name":"Ravioli, crudi, freschi","cat":"Cereali e derivati","h2o":39.30,"alc":0.00,"ami":35.40,"cho":40.70,"col":76.00,"ENc":265.00,"ENj":1109.00,"fin":0.00,"fso":0.00,"fib":2.20,"fat":7.30,"pro":10.60,"sug":1.80},
    {"id":719,"name":"Razza","cat":"Prodotti della pesca","h2o":82.20,"alc":0.00,"ami":0.00,"cho":0.70,"col":0.00,"ENc":68.00,"ENj":283.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.90,"pro":14.20,"sug":0.70},
    {"id":720,"name":"Rene di bovino","cat":"Frattaglie","h2o":76.50,"alc":0.00,"ami":0.00,"cho":0.80,"col":375.00,"ENc":118.00,"ENj":494.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.60,"pro":18.40,"sug":0.80},
    {"id":721,"name":"Ribes, freschi","cat":"Frutta","h2o":82.40,"alc":0.00,"ami":0.00,"cho":9.70,"col":0.00,"ENc":52.00,"ENj":216.00,"fin":0.00,"fso":0.00,"fib":5.80,"fat":0.00,"pro":0.90,"sug":9.70},
    {"id":722,"name":"Ricotta di bufala","cat":"Formaggi e latticini","h2o":67.30,"alc":0.00,"ami":0.00,"cho":3.70,"col":0.00,"ENc":212.00,"ENj":885.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":17.30,"pro":10.50,"sug":3.70},
    {"id":723,"name":"Ricotta di pecora","cat":"Formaggi e latticini","h2o":74.10,"alc":0.00,"ami":0.00,"cho":4.20,"col":42.00,"ENc":157.00,"ENj":658.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":11.50,"pro":9.50,"sug":4.20},
    {"id":724,"name":"Ricotta di vacca","cat":"Formaggi e latticini","h2o":75.70,"alc":0.00,"ami":0.00,"cho":3.50,"col":57.00,"ENc":146.00,"ENj":613.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":10.90,"pro":8.80,"sug":3.50},
    {"id":725,"name":"Riso al ragù alla bolognese","cat":"Ricette Italiane","h2o":69.80,"alc":0.00,"ami":11.50,"cho":13.60,"col":21.00,"ENc":145.00,"ENj":608.00,"fin":0.38,"fso":0.38,"fib":0.50,"fat":7.40,"pro":6.70,"sug":1.00},
    {"id":726,"name":"Riso al sugo di pomodoro","cat":"Ricette Italiane","h2o":77.60,"alc":0.00,"ami":13.70,"cho":16.50,"col":0.00,"ENc":90.00,"ENj":377.00,"fin":0.50,"fso":0.50,"fib":0.80,"fat":2.20,"pro":1.70,"sug":1.40},
    {"id":727,"name":"Riso Basmati, cotto, bollito","cat":"Cereali e derivati","h2o":70.60,"alc":0.00,"ami":22.30,"cho":25.20,"col":0.00,"ENc":113.00,"ENj":474.00,"fin":0.00,"fso":0.00,"fib":0.70,"fat":0.60,"pro":3.00,"sug":0.70},
    {"id":728,"name":"Riso Basmati, crudo","cat":"Cereali e derivati","h2o":12.20,"alc":0.00,"ami":74.40,"cho":82.90,"col":0.00,"ENc":367.00,"ENj":1534.00,"fin":0.00,"fso":0.00,"fib":1.30,"fat":1.90,"pro":9.00,"sug":1.10},
    {"id":729,"name":"Riso burro e Parmigiano reggiano","cat":"Ricette Italiane","h2o":68.00,"alc":0.00,"ami":19.90,"cho":22.10,"col":16.00,"ENc":144.00,"ENj":601.00,"fin":0.10,"fso":0.10,"fib":0.20,"fat":5.20,"pro":3.40,"sug":0.10},
    {"id":730,"name":"Riso Cantonese","cat":"Alimenti Etnici","h2o":58.20,"alc":0.00,"ami":24.70,"cho":29.80,"col":29.00,"ENc":207.00,"ENj":867.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":7.80,"pro":5.50,"sug":2.60},
    {"id":731,"name":"Riso Venere, cotto, bollito","cat":"Cereali e derivati","h2o":59.30,"alc":0.00,"ami":32.10,"cho":36.90,"col":0.00,"ENc":160.00,"ENj":669.00,"fin":0.00,"fso":0.00,"fib":2.40,"fat":0.30,"pro":3.50,"sug":1.60},
    {"id":732,"name":"Riso Venere, crudo","cat":"Cereali e derivati","h2o":12.50,"alc":0.00,"ami":71.10,"cho":80.40,"col":0.00,"ENc":355.00,"ENj":1484.00,"fin":0.00,"fso":0.00,"fib":5.10,"fat":1.30,"pro":7.80,"sug":2.20},
    {"id":733,"name":"Riso, brillato","cat":"Cereali e derivati","h2o":12.00,"alc":0.00,"ami":72.90,"cho":80.40,"col":0.00,"ENc":334.00,"ENj":1397.00,"fin":0.89,"fso":0.89,"fib":1.00,"fat":0.40,"pro":6.70,"sug":0.20},
    {"id":734,"name":"Riso, brillato, cotto, bollito","cat":"Cereali e derivati","h2o":72.60,"alc":0.00,"ami":21.90,"cho":24.20,"col":0.00,"ENc":100.00,"ENj":419.00,"fin":0.11,"fso":0.11,"fib":0.20,"fat":0.10,"pro":2.00,"sug":0.10},
    {"id":735,"name":"Riso, integrale","cat":"Cereali e derivati","h2o":12.00,"alc":0.00,"ami":69.20,"cho":77.40,"col":0.00,"ENc":341.00,"ENj":1427.00,"fin":1.80,"fso":1.80,"fib":1.90,"fat":1.90,"pro":7.50,"sug":1.20},
    {"id":736,"name":"Riso, integrale, cotto, bollito","cat":"Cereali e derivati","h2o":70.30,"alc":0.00,"ami":22.80,"cho":25.50,"col":0.00,"ENc":113.00,"ENj":471.00,"fin":0.00,"fso":0.00,"fib":0.80,"fat":0.60,"pro":2.50,"sug":0.40},
    {"id":737,"name":"Riso, parboiled","cat":"Cereali e derivati","h2o":10.30,"alc":0.00,"ami":73.60,"cho":81.30,"col":0.00,"ENc":338.00,"ENj":1415.00,"fin":0.00,"fso":0.00,"fib":0.50,"fat":0.30,"pro":7.40,"sug":0.30},
    {"id":738,"name":"Riso, parboiled, cotto, bollito","cat":"Cereali e derivati","h2o":73.40,"alc":0.00,"ami":21.00,"cho":23.30,"col":0.00,"ENc":97.00,"ENj":407.00,"fin":0.00,"fso":0.00,"fib":0.30,"fat":0.10,"pro":2.10,"sug":0.20},
    {"id":739,"name":"Riso, soffiato, da prima colazione","cat":"Cereali e derivati","h2o":5.60,"alc":0.00,"ami":69.20,"cho":85.10,"col":0.00,"ENc":354.00,"ENj":1482.00,"fin":0.00,"fso":0.00,"fib":1.00,"fat":1.00,"pro":6.00,"sug":9.00},
    {"id":740,"name":"Risotto alla parmigiana","cat":"Ricette Italiane","h2o":62.20,"alc":0.00,"ami":22.80,"cho":25.40,"col":26.00,"ENc":181.00,"ENj":755.00,"fin":0.44,"fso":0.44,"fib":0.70,"fat":7.50,"pro":4.10,"sug":0.30},
    {"id":741,"name":"Robiola","cat":"Formaggi e latticini","h2o":46.00,"alc":0.00,"ami":0.00,"cho":2.30,"col":0.00,"ENc":338.00,"ENj":1414.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":27.70,"pro":20.00,"sug":2.30},
    {"id":742,"name":"Rombo","cat":"Prodotti della pesca","h2o":79.50,"alc":0.00,"ami":0.00,"cho":1.20,"col":0.00,"ENc":81.00,"ENj":341.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.30,"pro":16.30,"sug":1.20},
    {"id":743,"name":"Rosmarino, fresco","cat":"Verdure e ortaggi","h2o":72.50,"alc":0.00,"ami":0.00,"cho":13.50,"col":0.00,"ENc":110.00,"ENj":460.00,"fin":0.00,"fso":0.00,"fib":7.00,"fat":4.40,"pro":1.40,"sug":13.50},
    {"id":744,"name":"Rughetta o rucola, fresca","cat":"Verdure e ortaggi","h2o":91.00,"alc":0.00,"ami":0.00,"cho":3.90,"col":0.00,"ENc":30.00,"ENj":124.00,"fin":0.00,"fso":0.00,"fib":0.90,"fat":0.30,"pro":2.60,"sug":3.90},
    {"id":745,"name":"Salame Brianza","cat":"Carni trasformate e conservate","h2o":36.30,"alc":0.00,"ami":0.00,"cho":1.00,"col":84.00,"ENc":384.00,"ENj":1608.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":30.50,"pro":26.50,"sug":1.00},
    {"id":746,"name":"Salame Fabriano","cat":"Carni trasformate e conservate","h2o":32.00,"alc":0.00,"ami":0.00,"cho":1.50,"col":88.00,"ENc":420.00,"ENj":1759.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":34.30,"pro":26.50,"sug":1.50},
    {"id":747,"name":"Salame Felino","cat":"Carni trasformate e conservate","h2o":35.20,"alc":0.00,"ami":0.00,"cho":0.50,"col":95.00,"ENc":375.00,"ENj":1569.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":27.90,"pro":30.50,"sug":0.50},
    {"id":748,"name":"Salame Milano","cat":"Carni trasformate e conservate","h2o":37.70,"alc":0.00,"ami":0.00,"cho":1.10,"col":104.00,"ENc":385.00,"ENj":1610.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":31.00,"pro":25.40,"sug":0.00},
    {"id":749,"name":"Salame Napoli","cat":"Carni trasformate e conservate","h2o":37.30,"alc":0.00,"ami":0.00,"cho":0.20,"col":91.00,"ENc":378.00,"ENj":1580.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":29.70,"pro":27.40,"sug":0.00},
    {"id":750,"name":"Salame nostrano","cat":"Carni trasformate e conservate","h2o":37.30,"alc":0.00,"ami":0.00,"cho":1.10,"col":94.00,"ENc":370.00,"ENj":1547.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":28.50,"pro":27.30,"sug":1.10},
    {"id":751,"name":"Salame ungherese","cat":"Carni trasformate e conservate","h2o":34.60,"alc":0.00,"ami":0.00,"cho":1.10,"col":92.00,"ENc":420.00,"ENj":1757.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":35.70,"pro":23.60,"sug":0.00},
    {"id":752,"name":"Salami italiani alla cacciatora DOP","cat":"Carni trasformate e conservate","h2o":33.10,"alc":0.00,"ami":0.00,"cho":0.70,"col":94.00,"ENc":411.00,"ENj":1718.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":32.70,"pro":28.40,"sug":0.00},
    {"id":753,"name":"Salmone, affumicato","cat":"Prodotti della pesca","h2o":64.90,"alc":0.00,"ami":0.00,"cho":1.20,"col":50.00,"ENc":147.00,"ENj":613.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.50,"pro":25.40,"sug":1.20},
    {"id":754,"name":"Salmone, fresco","cat":"Prodotti della pesca","h2o":68.00,"alc":0.00,"ami":0.00,"cho":1.00,"col":35.00,"ENc":185.00,"ENj":776.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":12.00,"pro":18.40,"sug":1.00},
    {"id":755,"name":"Salmone, in salamoia","cat":"Prodotti della pesca","h2o":64.30,"alc":0.00,"ami":0.00,"cho":1.00,"col":35.00,"ENc":192.00,"ENj":802.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":11.50,"pro":21.10,"sug":1.00},
    {"id":756,"name":"Salpa","cat":"Prodotti della pesca","h2o":77.30,"alc":0.00,"ami":0.00,"cho":2.00,"col":62.00,"ENc":104.00,"ENj":434.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.70,"pro":18.00,"sug":2.00},
    {"id":757,"name":"Salsiccia di Calabria","cat":"Carni trasformate e conservate","h2o":34.20,"alc":0.00,"ami":0.00,"cho":1.30,"col":0.00,"ENc":366.00,"ENj":1532.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":25.20,"pro":33.60,"sug":1.30},
    {"id":758,"name":"Salsiccia di fegato","cat":"Carni trasformate e conservate","h2o":42.90,"alc":0.00,"ami":0.00,"cho":0.90,"col":184.00,"ENc":424.00,"ENj":1773.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":41.20,"pro":12.40,"sug":0.90},
    {"id":759,"name":"Salsiccia di suino, fresca","cat":"Carni trasformate e conservate","h2o":53.60,"alc":0.00,"ami":0.00,"cho":0.60,"col":0.00,"ENc":304.00,"ENj":1273.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":26.70,"pro":15.40,"sug":0.60},
    {"id":760,"name":"Salsiccia di suino, fresca, cotta, in padella","cat":"Carni trasformate e conservate","h2o":46.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":324.00,"ENj":1354.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":26.10,"pro":22.20,"sug":0.00},
    {"id":761,"name":"Salsiccia di suino, secca","cat":"Carni trasformate e conservate","h2o":28.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":514.00,"ENj":2149.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":47.30,"pro":22.00,"sug":0.00},
    {"id":762,"name":"Salvia, fresco","cat":"Verdure e ortaggi","h2o":66.40,"alc":0.00,"ami":0.00,"cho":15.60,"col":0.00,"ENc":132.00,"ENj":552.00,"fin":0.00,"fso":0.00,"fib":8.20,"fat":4.60,"pro":3.90,"sug":15.60},
    {"id":763,"name":"Sarago","cat":"Prodotti della pesca","h2o":75.20,"alc":0.00,"ami":0.00,"cho":1.00,"col":65.00,"ENc":97.00,"ENj":407.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.20,"pro":20.70,"sug":0.00},
    {"id":764,"name":"Sarda","cat":"Prodotti della pesca","h2o":73.00,"alc":0.00,"ami":0.00,"cho":1.50,"col":63.00,"ENc":129.00,"ENj":541.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.50,"pro":20.80,"sug":1.50},
    {"id":765,"name":"Sardine","cat":"Prodotti della pesca","h2o":62.70,"alc":0.00,"ami":0.00,"cho":1.50,"col":65.00,"ENc":225.00,"ENj":943.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":15.40,"pro":20.30,"sug":1.50},
    {"id":766,"name":"Sardine, fritte","cat":"Prodotti della pesca","h2o":43.10,"alc":0.00,"ami":0.00,"cho":1.00,"col":0.00,"ENc":324.00,"ENj":1355.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":21.20,"pro":32.30,"sug":1.00},
    {"id":767,"name":"Sarmale","cat":"Alimenti Etnici","h2o":78.00,"alc":0.00,"ami":5.70,"cho":7.90,"col":13.00,"ENc":103.00,"ENj":431.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":5.20,"pro":5.70,"sug":1.60},
    {"id":768,"name":"Savoiardi, industriali","cat":"Dolci","h2o":8.50,"alc":0.00,"ami":27.50,"cho":69.60,"col":0.00,"ENc":395.00,"ENj":1651.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":9.20,"pro":11.90,"sug":39.30},
    {"id":769,"name":"Scaloppina di tacchino al limone","cat":"Ricette Italiane","h2o":61.80,"alc":0.00,"ami":0.00,"cho":11.50,"col":55.00,"ENc":170.00,"ENj":711.00,"fin":0.00,"fso":0.00,"fib":0.30,"fat":4.90,"pro":20.50,"sug":0.60},
    {"id":770,"name":"Scamorza","cat":"Formaggi e latticini","h2o":44.90,"alc":0.00,"ami":0.00,"cho":1.00,"col":0.00,"ENc":334.00,"ENj":1398.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":25.60,"pro":25.00,"sug":1.00},
    {"id":771,"name":"Scarola","cat":"Verdure e ortaggi","h2o":94.00,"alc":0.00,"ami":0.00,"cho":2.30,"col":0.00,"ENc":19.00,"ENj":81.00,"fin":0.00,"fso":0.00,"fib":1.80,"fat":0.30,"pro":1.10,"sug":2.30},
    {"id":772,"name":"Scarola IV gamma, fresca","cat":"Verdure e ortaggi","h2o":94.50,"alc":0.00,"ami":0.00,"cho":2.20,"col":0.00,"ENc":17.00,"ENj":71.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":0.20,"pro":0.90,"sug":2.20},
    {"id":773,"name":"Scorfano","cat":"Prodotti della pesca","h2o":79.00,"alc":0.00,"ami":0.00,"cho":0.60,"col":67.00,"ENc":82.00,"ENj":342.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.40,"pro":19.00,"sug":0.60},
    {"id":774,"name":"Sedano rapa, crudo","cat":"Verdure e ortaggi","h2o":92.00,"alc":0.00,"ami":0.00,"cho":3.80,"col":0.00,"ENc":29.00,"ENj":121.00,"fin":0.00,"fso":0.00,"fib":3.10,"fat":0.10,"pro":1.90,"sug":3.80},
    {"id":775,"name":"Sedano, cotto, al microonde","cat":"Verdure e ortaggi","h2o":90.10,"alc":0.00,"ami":0.20,"cho":2.40,"col":0.00,"ENc":24.00,"ENj":100.00,"fin":0.00,"fso":0.00,"fib":1.70,"fat":0.20,"pro":2.40,"sug":2.20},
    {"id":776,"name":"Sedano, crudo","cat":"Verdure e ortaggi","h2o":88.30,"alc":0.00,"ami":0.20,"cho":2.40,"col":0.00,"ENc":23.00,"ENj":97.00,"fin":1.41,"fso":1.41,"fib":1.60,"fat":0.20,"pro":2.30,"sug":2.20},
    {"id":777,"name":"Sego di bue","cat":"Oli e grassi","h2o":2.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":872.00,"ENj":3647.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":96.50,"pro":0.80,"sug":0.00},
    {"id":778,"name":"Semola","cat":"Cereali e derivati","h2o":14.00,"alc":0.00,"ami":68.20,"cho":76.90,"col":0.00,"ENc":346.00,"ENj":1448.00,"fin":0.00,"fso":0.00,"fib":3.60,"fat":0.50,"pro":11.50,"sug":1.90},
    {"id":779,"name":"Seppia","cat":"Prodotti della pesca","h2o":81.50,"alc":0.00,"ami":0.00,"cho":0.70,"col":157.00,"ENc":72.00,"ENj":302.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.50,"pro":14.00,"sug":0.70},
    {"id":780,"name":"Sgombro o maccarello","cat":"Prodotti della pesca","h2o":69.80,"alc":0.00,"ami":0.00,"cho":0.50,"col":95.00,"ENc":170.00,"ENj":710.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":11.10,"pro":17.00,"sug":0.50},
    {"id":781,"name":"Sgombro o maccarello, in salamoia","cat":"Prodotti della pesca","h2o":66.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":94.00,"ENc":177.00,"ENj":741.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":11.10,"pro":19.30,"sug":0.00},
    {"id":782,"name":"Sogliola","cat":"Prodotti della pesca","h2o":79.50,"alc":0.00,"ami":0.00,"cho":0.80,"col":51.00,"ENc":83.00,"ENj":348.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.40,"pro":16.90,"sug":0.80},
    {"id":783,"name":"Sogliola al vino bianco","cat":"Ricette Italiane","h2o":59.80,"alc":0.00,"ami":4.80,"cho":5.60,"col":52.00,"ENc":188.00,"ENj":788.00,"fin":0.10,"fso":0.10,"fib":0.20,"fat":10.10,"pro":19.00,"sug":0.30},
    {"id":784,"name":"Sogliola, surgelata","cat":"Prodotti della pesca","h2o":79.40,"alc":0.00,"ami":0.00,"cho":0.10,"col":51.00,"ENc":81.00,"ENj":340.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.30,"pro":17.30,"sug":0.10},
    {"id":785,"name":"Soia, bevanda","cat":"Prodotti vari","h2o":89.70,"alc":0.00,"ami":0.00,"cho":0.80,"col":0.00,"ENc":32.00,"ENj":133.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.90,"pro":2.90,"sug":0.80},
    {"id":786,"name":"Soia, isolato proteico","cat":"Legumi","h2o":5.00,"alc":0.00,"ami":0.00,"cho":4.50,"col":0.00,"ENc":369.00,"ENj":1545.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.70,"pro":86.50,"sug":0.00},
    {"id":787,"name":"Soia, salsa","cat":"Prodotti vari","h2o":67.60,"alc":0.00,"ami":0.00,"cho":8.30,"col":0.00,"ENc":66.00,"ENj":276.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":8.70,"sug":8.30},
    {"id":788,"name":"Soia, secca","cat":"Legumi","h2o":9.50,"alc":0.00,"ami":11.10,"cho":23.20,"col":0.00,"ENc":430.00,"ENj":1800.00,"fin":0.00,"fso":0.00,"fib":11.90,"fat":19.10,"pro":36.90,"sug":11.00},
    {"id":789,"name":"Soia, yogurt","cat":"Prodotti vari","h2o":82.40,"alc":0.00,"ami":0.00,"cho":3.90,"col":0.00,"ENc":72.00,"ENj":303.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.20,"pro":5.00,"sug":3.90},
    {"id":790,"name":"Soppressata di Calabria","cat":"Carni trasformate e conservate","h2o":31.70,"alc":0.00,"ami":0.00,"cho":0.90,"col":0.00,"ENc":377.00,"ENj":1577.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":26.30,"pro":34.20,"sug":0.90},
    {"id":791,"name":"Spaghetti fritti","cat":"Alimenti Etnici","h2o":69.60,"alc":0.00,"ami":11.10,"cho":13.60,"col":0.00,"ENc":165.00,"ENj":692.00,"fin":0.00,"fso":0.00,"fib":1.20,"fat":10.40,"pro":4.60,"sug":1.40},
    {"id":792,"name":"Speck dell'Alto Adige IGP","cat":"Carni trasformate e conservate","h2o":43.60,"alc":0.00,"ami":0.00,"cho":1.20,"col":91.00,"ENc":299.00,"ENj":1252.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":19.10,"pro":30.70,"sug":0.00},
    {"id":793,"name":"Spezzatino di vitellone al vino rosso","cat":"Ricette Italiane","h2o":66.80,"alc":0.00,"ami":1.20,"cho":3.00,"col":59.00,"ENc":161.00,"ENj":676.00,"fin":0.00,"fso":0.00,"fib":0.20,"fat":8.60,"pro":18.10,"sug":1.50},
    {"id":794,"name":"Spigola","cat":"Prodotti della pesca","h2o":79.00,"alc":0.00,"ami":0.00,"cho":0.60,"col":48.00,"ENc":82.00,"ENj":342.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.50,"pro":16.50,"sug":0.60},
    {"id":795,"name":"Spigola al cartoccio","cat":"Ricette Italiane","h2o":73.00,"alc":0.00,"ami":0.00,"cho":0.90,"col":48.00,"ENc":126.00,"ENj":527.00,"fin":0.00,"fso":0.00,"fib":0.10,"fat":6.40,"pro":16.20,"sug":0.90},
    {"id":796,"name":"Spigola d'allevamento, filetti","cat":"Prodotti della pesca","h2o":69.90,"alc":0.00,"ami":0.00,"cho":0.80,"col":75.00,"ENc":149.00,"ENj":625.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.80,"pro":21.30,"sug":0.80},
    {"id":797,"name":"Spinaci, cotti, al microonde","cat":"Verdure e ortaggi","h2o":89.00,"alc":0.00,"ami":2.30,"cho":3.00,"col":0.00,"ENc":35.00,"ENj":146.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":0.70,"pro":3.40,"sug":0.40},
    {"id":798,"name":"Spinaci, cotti, bolliti","cat":"Verdure e ortaggi","h2o":88.20,"alc":0.00,"ami":2.70,"cho":3.50,"col":0.00,"ENc":41.00,"ENj":171.00,"fin":1.80,"fso":1.80,"fib":2.30,"fat":0.80,"pro":4.00,"sug":0.50},
    {"id":799,"name":"Spinaci, crudi","cat":"Verdure e ortaggi","h2o":90.10,"alc":0.00,"ami":2.30,"cho":2.90,"col":0.00,"ENc":35.00,"ENj":145.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":0.70,"pro":3.40,"sug":0.40},
    {"id":800,"name":"Spinaci, surgelati","cat":"Verdure e ortaggi","h2o":92.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":15.00,"ENj":62.00,"fin":0.00,"fso":0.00,"fib":1.80,"fat":0.00,"pro":2.80,"sug":0.00},
    {"id":801,"name":"Spumante","cat":"Bevande alcoliche","h2o":87.30,"alc":12.10,"ami":0.00,"cho":0.60,"col":0.00,"ENc":87.00,"ENj":364.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":0.60},
    {"id":802,"name":"Storione","cat":"Prodotti della pesca","h2o":72.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":66.00,"ENc":145.00,"ENj":608.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":7.60,"pro":19.20,"sug":0.00},
    {"id":803,"name":"Stracchino","cat":"Formaggi e latticini","h2o":53.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":300.00,"ENj":1255.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":25.10,"pro":18.50,"sug":0.00},
    {"id":804,"name":"Strutto o sugna","cat":"Oli e grassi","h2o":0.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":892.00,"ENj":3733.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":99.00,"pro":0.30,"sug":0.00},
    {"id":805,"name":"Struzzo, solo tessuto muscolare, cotto, al forno","cat":"Carni fresche","h2o":71.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":72.00,"ENc":101.00,"ENj":424.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.40,"pro":22.20,"sug":0.00},
    {"id":806,"name":"Struzzo, solo tessuto muscolare, crudo","cat":"Carni fresche","h2o":76.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":57.00,"ENc":92.00,"ENj":384.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.90,"pro":20.90,"sug":0.00},
    {"id":807,"name":"Succo di frutta","cat":"Frutta","h2o":84.50,"alc":0.00,"ami":0.00,"cho":14.50,"col":0.00,"ENc":56.00,"ENj":236.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.10,"pro":0.30,"sug":14.50},
    {"id":808,"name":"Sugo di pomodoro","cat":"Ricette Italiane","h2o":85.90,"alc":0.00,"ami":0.00,"cho":3.60,"col":0.00,"ENc":74.00,"ENj":308.00,"fin":1.15,"fso":1.15,"fib":1.80,"fat":5.70,"pro":1.30,"sug":3.60},
    {"id":809,"name":"Suro o sugarello","cat":"Prodotti della pesca","h2o":77.20,"alc":0.00,"ami":0.00,"cho":1.00,"col":65.00,"ENc":104.00,"ENj":435.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.00,"pro":18.30,"sug":1.00},
    {"id":810,"name":"Tabulè","cat":"Alimenti Etnici","h2o":75.20,"alc":0.00,"ami":12.40,"cho":14.00,"col":0.00,"ENc":95.00,"ENj":399.00,"fin":0.00,"fso":0.00,"fib":4.60,"fat":2.40,"pro":3.00,"sug":1.60},
    {"id":811,"name":"Tacchino intero, con pelle, crudo","cat":"Carni fresche","h2o":73.60,"alc":0.00,"ami":0.00,"cho":0.00,"col":195.00,"ENc":135.00,"ENj":564.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.90,"pro":18.20,"sug":0.00},
    {"id":812,"name":"Tacchino intero, senza pelle, cotto, al forno","cat":"Carni fresche","h2o":71.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":80.00,"ENc":117.00,"ENj":488.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.60,"pro":23.30,"sug":0.00},
    {"id":813,"name":"Tacchino intero, senza pelle, crudo","cat":"Carni fresche","h2o":74.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":63.00,"ENc":109.00,"ENj":457.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.40,"pro":21.90,"sug":0.00},
    {"id":814,"name":"Tacchino, coscio, con pelle, crudo","cat":"Carni fresche","h2o":74.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":92.00,"ENc":131.00,"ENj":548.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.50,"pro":18.10,"sug":0.00},
    {"id":815,"name":"Tacchino, fesa, cotta, al forno","cat":"Carni fresche","h2o":67.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":62.00,"ENc":131.00,"ENj":548.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.40,"pro":29.60,"sug":0.00},
    {"id":816,"name":"Tacchino, fesa, crudo","cat":"Carni fresche","h2o":73.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":50.00,"ENc":107.00,"ENj":447.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.20,"pro":24.00,"sug":0.00},
    {"id":817,"name":"Tacchino, fuso, con pelle, cotto, al forno","cat":"Carni fresche","h2o":62.60,"alc":0.00,"ami":0.00,"cho":0.00,"col":110.00,"ENc":191.00,"ENj":797.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":9.30,"pro":26.70,"sug":0.00},
    {"id":818,"name":"Tacchino, fuso, con pelle, crudo","cat":"Carni fresche","h2o":74.70,"alc":0.00,"ami":0.00,"cho":0.00,"col":73.00,"ENc":126.00,"ENj":526.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.00,"pro":17.90,"sug":0.00},
    {"id":819,"name":"Tacchino, fuso, senza pelle, cotto, al forno","cat":"Carni fresche","h2o":61.60,"alc":0.00,"ami":0.00,"cho":0.00,"col":107.00,"ENc":190.00,"ENj":796.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.70,"pro":28.00,"sug":0.00},
    {"id":820,"name":"Tacchino, fuso, senza pelle, crudo","cat":"Carni fresche","h2o":76.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":67.00,"ENc":113.00,"ENj":474.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.60,"pro":18.00,"sug":0.00},
    {"id":821,"name":"Tacchino, sovracoscio, senza pelle, cotto, al forno","cat":"Carni fresche","h2o":61.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":108.00,"ENc":182.00,"ENj":761.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.80,"pro":30.20,"sug":0.00},
    {"id":822,"name":"Tacchino, sovracoscio, senza pelle, crudo","cat":"Carni fresche","h2o":74.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":71.00,"ENc":120.00,"ENj":502.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.10,"pro":18.50,"sug":0.00},
    {"id":823,"name":"Taleggio","cat":"Formaggi e latticini","h2o":51.80,"alc":0.00,"ami":0.00,"cho":0.90,"col":0.00,"ENc":315.00,"ENj":1319.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":26.20,"pro":19.00,"sug":0.90},
    {"id":824,"name":"Tarassaco o dente di leone, fresco","cat":"Verdure e ortaggi","h2o":90.00,"alc":0.00,"ami":0.00,"cho":3.70,"col":0.00,"ENc":37.00,"ENj":155.00,"fin":0.00,"fso":0.00,"fib":0.40,"fat":1.10,"pro":3.10,"sug":3.70},
    {"id":825,"name":"Tartufo, nero","cat":"Verdure e ortaggi","h2o":81.80,"alc":0.00,"ami":0.00,"cho":0.70,"col":0.00,"ENc":48.00,"ENj":201.00,"fin":0.00,"fso":0.00,"fib":8.40,"fat":0.50,"pro":6.00,"sug":0.70},
    {"id":826,"name":"Tè, foglie","cat":"Prodotti vari","h2o":9.30,"alc":0.00,"ami":0.00,"cho":3.00,"col":0.00,"ENc":108.00,"ENj":450.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.00,"pro":19.60,"sug":3.00},
    {"id":827,"name":"Tinca","cat":"Prodotti della pesca","h2o":78.50,"alc":0.00,"ami":0.00,"cho":0.90,"col":0.00,"ENc":79.00,"ENj":333.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.50,"pro":17.90,"sug":0.90},
    {"id":828,"name":"Tonno","cat":"Prodotti della pesca","h2o":67.50,"alc":0.00,"ami":0.00,"cho":0.10,"col":70.00,"ENc":159.00,"ENj":666.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.10,"pro":21.50,"sug":0.10},
    {"id":829,"name":"Tonno in salamoia, sgocciolato","cat":"Prodotti della pesca","h2o":73.40,"alc":0.00,"ami":0.00,"cho":0.00,"col":63.00,"ENc":103.00,"ENj":431.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.30,"pro":25.10,"sug":0.00},
    {"id":830,"name":"Tonno sott'olio, sgocciolato","cat":"Prodotti della pesca","h2o":62.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":65.00,"ENc":192.00,"ENj":803.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":10.10,"pro":25.20,"sug":0.00},
    {"id":831,"name":"Topinambur, cotto, bollito","cat":"Verdure e ortaggi","h2o":79.50,"alc":0.00,"ami":0.00,"cho":10.60,"col":0.00,"ENc":52.00,"ENj":219.00,"fin":2.01,"fso":2.01,"fib":2.70,"fat":0.10,"pro":1.60,"sug":0.00},
    {"id":832,"name":"Torrone alla mandorla","cat":"Dolci","h2o":6.90,"alc":0.00,"ami":0.00,"cho":52.00,"col":0.00,"ENc":483.00,"ENj":2022.00,"fin":0.00,"fso":0.00,"fib":1.90,"fat":26.80,"pro":10.80,"sug":52.00},
    {"id":833,"name":"Torta di mele","cat":"Ricette Italiane","h2o":41.80,"alc":0.00,"ami":19.20,"cho":46.30,"col":96.00,"ENc":300.00,"ENj":1257.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":11.50,"pro":5.00,"sug":25.10},
    {"id":834,"name":"Torta margherita","cat":"Dolci","h2o":19.40,"alc":0.00,"ami":26.60,"cho":63.40,"col":0.00,"ENc":370.00,"ENj":1547.00,"fin":0.00,"fso":0.00,"fib":1.40,"fat":10.40,"pro":8.90,"sug":34.10},
    {"id":835,"name":"Tortellini, freschi","cat":"Cereali e derivati","h2o":28.20,"alc":0.00,"ami":44.20,"cho":49.90,"col":0.00,"ENc":302.00,"ENj":1265.00,"fin":0.00,"fso":0.00,"fib":1.20,"fat":7.10,"pro":12.20,"sug":1.30},
    {"id":836,"name":"Tortellini, freschi, cotti","cat":"Cereali e derivati","h2o":62.60,"alc":0.00,"ami":23.00,"cho":26.00,"col":0.00,"ENc":158.00,"ENj":659.00,"fin":0.00,"fso":0.00,"fib":0.60,"fat":3.70,"pro":6.40,"sug":0.70},
    {"id":837,"name":"Tortellini, secchi","cat":"Cereali e derivati","h2o":12.80,"alc":0.00,"ami":51.00,"cho":57.60,"col":0.00,"ENc":379.00,"ENj":1587.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":10.80,"pro":15.70,"sug":1.50},
    {"id":838,"name":"Triglia","cat":"Prodotti della pesca","h2o":75.30,"alc":0.00,"ami":0.00,"cho":1.10,"col":78.00,"ENc":123.00,"ENj":515.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":6.20,"pro":15.80,"sug":1.10},
    {"id":839,"name":"Trippa di bovino","cat":"Frattaglie","h2o":72.00,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":108.00,"ENj":453.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":5.00,"pro":15.80,"sug":0.00},
    {"id":840,"name":"Trota","cat":"Prodotti della pesca","h2o":80.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":55.00,"ENc":86.00,"ENj":359.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.00,"pro":14.70,"sug":0.00},
    {"id":841,"name":"Trota al forno","cat":"Ricette Italiane","h2o":61.60,"alc":0.00,"ami":4.90,"cho":5.80,"col":57.00,"ENc":186.00,"ENj":777.00,"fin":0.00,"fso":0.00,"fib":0.30,"fat":8.90,"pro":20.80,"sug":0.40},
    {"id":842,"name":"Trota iridea d'allevamento, filetti","cat":"Prodotti della pesca","h2o":74.30,"alc":0.00,"ami":0.00,"cho":0.00,"col":50.00,"ENc":118.00,"ENj":494.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":4.10,"pro":20.30,"sug":0.00},
    {"id":843,"name":"Trota surgelata","cat":"Prodotti della pesca","h2o":77.20,"alc":0.00,"ami":0.00,"cho":0.00,"col":56.00,"ENc":92.00,"ENj":385.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.30,"pro":17.80,"sug":0.00},
    {"id":844,"name":"Trota surgelata, cotta, al microonde","cat":"Prodotti della pesca","h2o":74.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":60.00,"ENc":105.00,"ENj":439.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.60,"pro":20.40,"sug":0.00},
    {"id":845,"name":"Trota surgelata, cotta, in forno","cat":"Prodotti della pesca","h2o":68.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":62.00,"ENc":136.00,"ENj":567.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.20,"pro":26.70,"sug":0.00},
    {"id":846,"name":"Uova di anatra, intero","cat":"Uova","h2o":70.90,"alc":0.00,"ami":0.00,"cho":0.70,"col":0.00,"ENc":190.00,"ENj":795.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":15.40,"pro":12.20,"sug":0.70},
    {"id":847,"name":"Uova di gallina, albume","cat":"Uova","h2o":87.70,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":43.00,"ENj":180.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":10.70,"sug":0.00},
    {"id":848,"name":"Uova di gallina, intero","cat":"Uova","h2o":77.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":358.00,"ENc":128.00,"ENj":535.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.70,"pro":12.40,"sug":0.00},
    {"id":849,"name":"Uova di gallina, intero , cotto, a frittata o strapazzato","cat":"Uova","h2o":73.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":436.00,"ENc":150.00,"ENj":629.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":10.20,"pro":14.60,"sug":0.00},
    {"id":850,"name":"Uova di gallina, intero , cotto, alla coque o sodo","cat":"Uova","h2o":77.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":358.00,"ENc":128.00,"ENj":535.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.70,"pro":12.40,"sug":0.00},
    {"id":851,"name":"Uova di gallina, intero, congelato","cat":"Uova","h2o":77.10,"alc":0.00,"ami":0.00,"cho":0.00,"col":358.00,"ENc":128.00,"ENj":535.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":8.70,"pro":12.40,"sug":0.00},
    {"id":852,"name":"Uova di gallina, intero, in polvere","cat":"Uova","h2o":4.10,"alc":0.00,"ami":0.00,"cho":0.40,"col":1600.00,"ENc":537.00,"ENj":2246.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":36.40,"pro":51.90,"sug":0.40},
    {"id":853,"name":"Uova di gallina, tuorlo","cat":"Uova","h2o":53.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":1232.00,"ENc":325.00,"ENj":1360.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":29.10,"pro":15.80,"sug":0.00},
    {"id":854,"name":"Uova di gallina, tuorlo , cotto, in camicia","cat":"Uova","h2o":53.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":1337.00,"ENc":325.00,"ENj":1360.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":29.10,"pro":15.80,"sug":0.00},
    {"id":855,"name":"Uova di gallina, tuorlo, congelato","cat":"Uova","h2o":53.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":1270.00,"ENc":325.00,"ENj":1360.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":29.10,"pro":15.80,"sug":0.00},
    {"id":856,"name":"Uova di gallina, tuorlo, in polvere","cat":"Uova","h2o":4.00,"alc":0.00,"ami":0.00,"cho":0.02,"col":2800.00,"ENc":642.00,"ENj":2688.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":57.50,"pro":31.20,"sug":0.02},
    {"id":857,"name":"Uova di oca, intero","cat":"Uova","h2o":69.70,"alc":0.00,"ami":0.00,"cho":1.00,"col":0.00,"ENc":189.00,"ENj":789.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":14.40,"pro":13.80,"sug":1.00},
    {"id":858,"name":"Uova di tacchina, intero","cat":"Uova","h2o":72.60,"alc":0.00,"ami":0.00,"cho":1.00,"col":0.00,"ENc":147.00,"ENj":614.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":10.20,"pro":12.80,"sug":1.00},
    {"id":859,"name":"Uva, bianca, fresca","cat":"Frutta","h2o":77.20,"alc":0.00,"ami":0.00,"cho":21.50,"col":0.00,"ENc":86.00,"ENj":360.00,"fin":1.20,"fso":1.20,"fib":1.40,"fat":0.10,"pro":0.40,"sug":21.50},
    {"id":860,"name":"Uva, fresca","cat":"Frutta","h2o":81.30,"alc":0.00,"ami":0.00,"cho":15.60,"col":0.00,"ENc":64.00,"ENj":269.00,"fin":1.27,"fso":1.27,"fib":1.50,"fat":0.10,"pro":0.50,"sug":15.60},
    {"id":861,"name":"Uva, nera, fresca","cat":"Frutta","h2o":86.00,"alc":0.00,"ami":0.00,"cho":12.60,"col":0.00,"ENc":53.00,"ENj":223.00,"fin":1.33,"fso":1.33,"fib":1.60,"fat":0.10,"pro":0.50,"sug":12.60},
    {"id":862,"name":"Uva, secca","cat":"Frutta","h2o":17.10,"alc":0.00,"ami":0.00,"cho":72.00,"col":0.00,"ENc":293.00,"ENj":1228.00,"fin":4.04,"fso":4.04,"fib":5.20,"fat":0.60,"pro":1.90,"sug":72.00},
    {"id":863,"name":"Uva, succo, in cartone","cat":"Frutta","h2o":81.40,"alc":0.00,"ami":0.00,"cho":17.30,"col":0.00,"ENc":66.00,"ENj":277.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.30,"sug":17.30},
    {"id":864,"name":"Vegetali misti, surgelati","cat":"Verdure e ortaggi","h2o":82.10,"alc":0.00,"ami":0.00,"cho":13.70,"col":0.00,"ENc":67.00,"ENj":281.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.30,"pro":3.30,"sug":0.00},
    {"id":865,"name":"Vermouth dolce","cat":"Bevande alcoliche","h2o":72.40,"alc":12.40,"ami":0.00,"cho":13.90,"col":0.00,"ENc":139.00,"ENj":581.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":13.90},
    {"id":866,"name":"Vermouth secco","cat":"Bevande alcoliche","h2o":80.80,"alc":15.10,"ami":0.00,"cho":4.00,"col":0.00,"ENc":121.00,"ENj":505.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":4.00},
    {"id":867,"name":"Vino da pasto bianco","cat":"Bevande alcoliche","h2o":89.80,"alc":10.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":70.00,"ENj":293.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":0.00},
    {"id":868,"name":"Vino da pasto rosso","cat":"Bevande alcoliche","h2o":89.20,"alc":10.70,"ami":0.00,"cho":0.00,"col":0.00,"ENc":75.00,"ENj":314.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":0.00},
    {"id":869,"name":"Vitello, filetto, cotto in padella","cat":"Carni fresche","h2o":72.80,"alc":0.00,"ami":0.00,"cho":0.00,"col":84.00,"ENc":126.00,"ENj":529.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.20,"pro":24.40,"sug":0.00},
    {"id":870,"name":"Vitello, filetto, crudo","cat":"Carni fresche","h2o":76.90,"alc":0.00,"ami":0.00,"cho":0.00,"col":71.00,"ENc":107.00,"ENj":448.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.70,"pro":20.70,"sug":0.00},
    {"id":871,"name":"Vongola","cat":"Prodotti della pesca","h2o":80.60,"alc":0.00,"ami":3.60,"cho":3.90,"col":32.00,"ENc":65.00,"ENj":270.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.20,"pro":9.80,"sug":0.00},
    {"id":872,"name":"Wafer ricoperto di cioccolato","cat":"Dolci","h2o":1.30,"alc":0.00,"ami":12.30,"cho":60.30,"col":0.00,"ENc":498.00,"ENj":2085.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":26.60,"pro":8.20,"sug":46.80},
    {"id":873,"name":"Whisky","cat":"Bevande alcoliche","h2o":65.90,"alc":35.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":245.00,"ENj":1025.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":0.00},
    {"id":874,"name":"Wurstel di puro suino, cotto","cat":"Carni trasformate e conservate","h2o":60.30,"alc":0.00,"ami":0.00,"cho":0.30,"col":84.00,"ENc":259.00,"ENj":1085.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":22.20,"pro":14.60,"sug":0.00},
    {"id":875,"name":"Wurstel di puro suino, crudo","cat":"Carni trasformate e conservate","h2o":61.70,"alc":0.00,"ami":0.00,"cho":1.90,"col":81.00,"ENc":250.00,"ENj":1045.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":21.10,"pro":13.20,"sug":0.00},
    {"id":876,"name":"Yogurt caprino","cat":"Latte e yogurt","h2o":87.50,"alc":0.00,"ami":0.00,"cho":5.10,"col":9.00,"ENc":60.00,"ENj":249.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":2.90,"pro":3.60,"sug":5.10},
    {"id":877,"name":"Yogurt da latte intero, alla frutta","cat":"Latte e yogurt","h2o":81.80,"alc":0.00,"ami":0.00,"cho":10.50,"col":7.00,"ENc":80.00,"ENj":335.00,"fin":0.00,"fso":0.00,"fib":1.00,"fat":2.80,"pro":3.40,"sug":10.50},
    {"id":878,"name":"Yogurt da latte, parzialmente scremato","cat":"Latte e yogurt","h2o":89.00,"alc":0.00,"ami":0.00,"cho":3.80,"col":8.00,"ENc":43.00,"ENj":181.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":1.70,"pro":3.40,"sug":3.80},
    {"id":879,"name":"Yogurt da latte, scremato","cat":"Latte e yogurt","h2o":89.00,"alc":0.00,"ami":0.00,"cho":4.00,"col":2.00,"ENc":36.00,"ENj":152.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.90,"pro":3.30,"sug":4.00},
    {"id":880,"name":"Yogurt greco, 0% lipidi","cat":"Latte e yogurt","h2o":86.00,"alc":0.00,"ami":0.00,"cho":4.00,"col":1.00,"ENc":51.00,"ENj":213.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":9.00,"sug":4.00},
    {"id":881,"name":"Yogurt greco, 0% lipidi, aromatizzato","cat":"Latte e yogurt","h2o":76.50,"alc":0.00,"ami":0.90,"cho":15.00,"col":1.00,"ENc":86.00,"ENj":361.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":7.50,"sug":14.00},
    {"id":882,"name":"Yogurt greco, da latte intero","cat":"Latte e yogurt","h2o":78.50,"alc":0.00,"ami":0.00,"cho":2.00,"col":0.00,"ENc":115.00,"ENj":481.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":9.10,"pro":6.40,"sug":2.00},
    {"id":883,"name":"Yogurt greco, da latte magro, alla frutta","cat":"Latte e yogurt","h2o":80.10,"alc":0.00,"ami":0.00,"cho":11.00,"col":2.00,"ENc":74.00,"ENj":311.00,"fin":0.00,"fso":0.00,"fib":0.50,"fat":0.90,"pro":6.00,"sug":11.00},
    {"id":884,"name":"Yogurt, 0% lipidi","cat":"Latte e yogurt","h2o":88.50,"alc":0.00,"ami":0.00,"cho":6.00,"col":1.00,"ENc":42.00,"ENj":177.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.10,"pro":4.70,"sug":6.00},
    {"id":885,"name":"Yogurt, da latte intero","cat":"Latte e yogurt","h2o":87.00,"alc":0.00,"ami":0.00,"cho":4.30,"col":11.00,"ENc":66.00,"ENj":278.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":3.90,"pro":3.80,"sug":4.30},
    {"id":886,"name":"Zampone confezionato, precotto","cat":"Carni trasformate e conservate","h2o":45.50,"alc":0.00,"ami":0.00,"cho":0.00,"col":0.00,"ENc":361.00,"ENj":1510.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":31.60,"pro":19.10,"sug":0.00},
    {"id":887,"name":"Zampone Modena IGP, cotto","cat":"Carni trasformate e conservate","h2o":54.10,"alc":0.00,"ami":0.00,"cho":2.60,"col":106.00,"ENc":262.00,"ENj":1096.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":17.50,"pro":23.70,"sug":0.00},
    {"id":888,"name":"Zucca gialla, fresca","cat":"Verdure e ortaggi","h2o":94.60,"alc":0.00,"ami":0.90,"cho":3.50,"col":0.00,"ENc":29.00,"ENj":123.00,"fin":0.00,"fso":0.00,"fib":5.50,"fat":0.10,"pro":1.10,"sug":2.50},
    {"id":889,"name":"Zucchero, saccarosio","cat":"Dolci","h2o":0.50,"alc":0.00,"ami":0.00,"cho":104.50,"col":0.00,"ENc":392.00,"ENj":1640.00,"fin":0.00,"fso":0.00,"fib":0.00,"fat":0.00,"pro":0.00,"sug":104.50},
    {"id":890,"name":"Zucchine chiare, cotte, al vapore","cat":"Verdure e ortaggi","h2o":93.00,"alc":0.00,"ami":0.00,"cho":2.50,"col":0.00,"ENc":20.00,"ENj":84.00,"fin":0.00,"fso":0.00,"fib":1.30,"fat":0.00,"pro":2.00,"sug":2.50},
    {"id":891,"name":"Zucchine chiare, cotte, in padella grigliate","cat":"Verdure e ortaggi","h2o":85.00,"alc":0.00,"ami":0.00,"cho":5.70,"col":0.00,"ENc":29.00,"ENj":120.00,"fin":0.00,"fso":0.00,"fib":2.80,"fat":0.20,"pro":0.00,"sug":5.70},
    {"id":892,"name":"Zucchine grigliate","cat":"Ricette Italiane","h2o":81.70,"alc":0.00,"ami":0.00,"cho":5.50,"col":0.00,"ENc":75.00,"ENj":315.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":3.80,"pro":4.30,"sug":5.50},
    {"id":893,"name":"Zucchine, chiare","cat":"Verdure e ortaggi","h2o":93.40,"alc":0.00,"ami":0.10,"cho":1.70,"col":0.00,"ENc":17.00,"ENj":72.00,"fin":0.00,"fso":0.00,"fib":1.20,"fat":0.10,"pro":1.90,"sug":1.60},
    {"id":894,"name":"Zucchine, chiare, cotte, al microonde","cat":"Verdure e ortaggi","h2o":92.10,"alc":0.00,"ami":0.00,"cho":3.00,"col":0.00,"ENc":23.00,"ENj":98.00,"fin":0.00,"fso":0.00,"fib":1.60,"fat":0.10,"pro":2.00,"sug":3.00},
    {"id":895,"name":"Zucchine, cotte, bollite","cat":"Verdure e ortaggi","h2o":84.20,"alc":0.00,"ami":0.20,"cho":3.40,"col":0.00,"ENc":30.00,"ENj":125.00,"fin":0.98,"fso":0.98,"fib":1.30,"fat":0.20,"pro":3.20,"sug":3.20},
    {"id":896,"name":"Zucchine, crude","cat":"Verdure e ortaggi","h2o":93.60,"alc":0.00,"ami":0.10,"cho":1.70,"col":0.00,"ENc":16.00,"ENj":66.00,"fin":0.00,"fso":0.00,"fib":1.20,"fat":0.10,"pro":1.50,"sug":1.80},
    {"id":897,"name":"Zucchine, scure, cotte, al microonde","cat":"Verdure e ortaggi","h2o":92.20,"alc":0.00,"ami":0.00,"cho":3.50,"col":0.00,"ENc":23.00,"ENj":98.00,"fin":0.00,"fso":0.00,"fib":1.50,"fat":0.00,"pro":1.80,"sug":3.50},
    {"id":898,"name":"Zucchine, scure, cotte, al vapore","cat":"Verdure e ortaggi","h2o":93.20,"alc":0.00,"ami":0.00,"cho":2.70,"col":0.00,"ENc":20.00,"ENj":85.00,"fin":0.00,"fso":0.00,"fib":1.50,"fat":0.10,"pro":1.60,"sug":2.70},
    {"id":899,"name":"Zucchine, scure, cotte, in padella grigliate","cat":"Verdure e ortaggi","h2o":88.40,"alc":0.00,"ami":0.10,"cho":5.60,"col":0.00,"ENc":37.00,"ENj":154.00,"fin":0.00,"fso":0.00,"fib":2.00,"fat":0.10,"pro":2.70,"sug":5.50},
    {"id":900,"name":"Zucchine, scure, crude","cat":"Verdure e ortaggi","h2o":93.90,"alc":0.00,"ami":0.10,"cho":2.10,"col":0.00,"ENc":17.00,"ENj":70.00,"fin":0.00,"fso":0.00,"fib":1.20,"fat":0.10,"pro":1.40,"sug":2.00}
    ]
const jsonFoodList_=
[
{'id':"S1", 'name':'Acciuga o alice', 'category': 'PESCE', 'cho100':1.5, 'part':'fette', 'wpart':10, 'step':5, 'min':0, 'max':20},
{'id':"S2", 'name':'Acciuga o alice, sotto sale', 'category': 'PESCE', 'cho100':2.3},
{'id':"S3", 'name':'Acciuga o alice, sott\'olio', 'category': 'PESCE', 'cho100':0.2},


{'id':4, 'name':'Aglio', 'category': 'VERDURA', 'cho100':1},
{'id':5, 'name':'Aglio Bianco Piacentino', 'category': 'VERDURA', 'cho100':0.9},
{'id':6, 'name':'Aglio Rosso di Castelliri', 'category': 'VERDURA', 'cho100':1.02},
{'id':7, 'name':'Aglio Rosso di Procerno', 'category': 'VERDURA', 'cho100':1},
{'id':8, 'name':'Aglio Rosso di Sulmona', 'category': 'VERDURA', 'cho100':1},
{'id':9, 'name':'Agretti, cotti, bolliti', 'category': 'VERDURA', 'cho100':2.6},
{'id':10, 'name':'Agretti, crudi', 'category': 'VERDURA', 'cho100':2.2},
{'id':11, 'name':'Albicocche, disidratate', 'category': 'FRUTTA', 'cho100':80.6},
{'id':12, 'name':'Albicocche, fresche', 'category': 'FRUTTA', 'cho100':9.8},
{'id':13, 'name':'Albicocche, sciroppate', 'category': 'FRUTTA', 'cho100':16.1},
{'id':14, 'name':'Albicocche, secche', 'category': 'FRUTTA', 'cho100':66.5},
{'id':15, 'name':'Alici al forno', 'category': 'PESCE', 'cho100':7.1},
{'id':16, 'name':'Alici marinate', 'category': 'PESCE', 'cho100':1.4},
{'id':17, 'name':'Amarene, fresche', 'category': 'FRUTTA', 'cho100':10.2},
{'id':18, 'name':'Anacardi', 'category': 'FRUTTA', 'cho100':33},
{'id':19, 'name':'Ananas, fresco', 'category': 'FRUTTA', 'cho100':10},
{'id':20, 'name':'Ananas, sciroppato', 'category': 'FRUTTA', 'cho100':16.6},
{'id':21, 'name':'Anguilla d\'allevamento, filetti', 'category': 'PESCE', 'cho100':0.5},
{'id':22, 'name':'Anguilla di fiume', 'category': 'PESCE', 'cho100':0.1},
{'id':23, 'name':'Anguilla di mare', 'category': 'PESCE', 'cho100':0.7},
{'id':24, 'name':'Anguilla, affumicata', 'category': 'PESCE', 'cho100':0.2},
{'id':25, 'name':'Anguilla, marinata', 'category': 'PESCE', 'cho100':0.6},
{'id':26, 'name':'Animelle di bovino', 'category': 'CARNE', 'cho100':0.1},
{'id':27, 'name':'Animelle di bovino, cotte', 'category': 'CARNE', 'cho100':0.2},
{'id':28, 'name':'Anona, fresca', 'category': 'PESCE', 'cho100':15.8},
{'id':29, 'name':'Aperitivi a base di vino', 'category': 'BEVANDE', 'cho100':17},
{'id':30, 'name':'Arachidi, tostate', 'category': 'FRUTTA SECCA', 'cho100':8.5},
{'id':31, 'name':'Aragosta', 'category': 'PESCE', 'cho100':1},
{'id':32, 'name':'Aragosta, bollita', 'category': 'PESCE', 'cho100':1},
{'id':33, 'name':'Arance bionde succo, fresco', 'category': 'FRUTTA', 'cho100':9.6},
{'id':34, 'name':'Arance bionde, fresche', 'category': 'FRUTTA', 'cho100':9.9},
{'id':35, 'name':'Arance Moro, fresche', 'category': 'FRUTTA', 'cho100':9},
{'id':36, 'name':'Arance rosse succo, fresco', 'category': 'FRUTTA', 'cho100':9.8},
{'id':37, 'name':'Arance rosse, fresche', 'category': 'FRUTTA', 'cho100':9.9},
{'id':38, 'name':'Arance Sanguinello, fresche', 'category': 'FRUTTA', 'cho100':10.6},
{'id':39, 'name':'Arance succo, fresco', 'category': 'FRUTTA', 'cho100':8.2},
{'id':40, 'name':'Arance Tarocco, fresche', 'category': 'FRUTTA', 'cho100':10},
{'id':41, 'name':'Arance Valencia, fresche', 'category': 'FRUTTA', 'cho100':8},
{'id':42, 'name':'Arance Washington Navel, fresche', 'category': 'FRUTTA', 'cho100':9.4},
{'id':43, 'name':'Arance, fresche', 'category': 'FRUTTA', 'cho100':7.8},
{'id':44, 'name':'Aranciata', 'category': 'BEVANDE', 'cho100':10},
{'id':45, 'name':'Asparagi di bosco, crudi', 'category': 'VERDURA', 'cho100':4},
{'id':46, 'name':'Asparagi di campo, cotti, bolliti', 'category': 'VERDURA', 'cho100':3.4},
{'id':47, 'name':'Asparagi di campo, crudi', 'category': 'VERDURA', 'cho100':3.3},
{'id':48, 'name':'Asparagi di serra, crudi', 'category': 'VERDURA', 'cho100':3},
{'id':49, 'name':'Avocado, fresco', 'category': 'FRUTTA', 'cho100':1.8},
{'id':50, 'name':'Baba\' al rhum', 'category': 'DOLCI', 'cho100':41.2},
{'id':51, 'name':'Babaco, fresco', 'category': 'VERDURA', 'cho100':4.5},
{'id':52, 'name':'Baccalà alla vicentina', 'category': 'PESCE', 'cho100':0.3},
{'id':53, 'name':'Banane, fresche', 'category': 'FRUTTA', 'cho100':17.4},
{'id':54, 'name':'Barbabietole rosse, cotte, bollite', 'category': 'VERDURA', 'cho100':7.8},
{'id':55, 'name':'Barbabietole rosse, crude', 'category': 'VERDURA', 'cho100':4},
{'id':56, 'name':'Barretta al cocco ricoperta di cioccolato', 'category': 'MERENDA', 'cho100':58.2},
{'id':57, 'name':'Basilico, fresco', 'category': 'VERDURA', 'cho100':5.2},
{'id':168, 'name':'Coppa', 'category': '', 'cho100':0},
{'id':59, 'name':'Besciamella', 'category': 'LATTICINI', 'cho100':9.5},
{'id':277, 'name':'Formaggio cremoso spalmabile', 'category': 'LATTICINI', 'cho100':0},
{'id':61, 'name':'Bieta, cotta, al microonde', 'category': 'VERDURA', 'cho100':1.8},
{'id':62, 'name':'Bieta, cotta, bollita', 'category': 'VERDURA', 'cho100':2.1},
{'id':63, 'name':'Bieta, cruda', 'category': 'VERDURA', 'cho100':1.8},
{'id':64, 'name':'Birra chiara', 'category': 'BEVANDE', 'cho100':3.5},
{'id':65, 'name':'Biscotti, alla soia', 'category': 'BEVANDE', 'cho100':70.8},
{'id':66, 'name':'Biscotti, frollini', 'category': 'DOLCI', 'cho100':71.7},
{'id':67, 'name':'Biscotti, integrali', 'category': 'DOLCI', 'cho100':70.8},
{'id':68, 'name':'Biscotti, integrali, con soia', 'category': 'DOLCI', 'cho100':60.4},
{'id':69, 'name':'Biscotti, secchi', 'category': 'DOLCI', 'cho100':80.4},
{'id':70, 'name':'Biscotti, wafers', 'category': 'DOLCI', 'cho100':76.2},
{'id':320, 'name':'Grappa', 'category': '', 'cho100':0},
{'id':72, 'name':'Brandy', 'category': 'BEVANDE', 'cho100':0},
{'id':73, 'name':'Brasato al Barolo', 'category': 'CARNE', 'cho100':0.3},
{'id':74, 'name':'Bresaola della Valtellina IGP', 'category': 'CARNE', 'cho100':0.4},
{'id':75, 'name':'Brie', 'category': 'FORMAGGIO', 'cho100':0.2},
{'id':76, 'name':'Broccoletti di rapa, cotti, bolliti', 'category': 'VERDURA', 'cho100':2.1},
{'id':77, 'name':'Broccoletti di rapa, crudi', 'category': 'VERDURA', 'cho100':2},
{'id':78, 'name':'Broccolo a testa, cotto, bollito', 'category': 'VERDURA', 'cho100':3.2},
{'id':79, 'name':'Broccolo a testa, crudo', 'category': 'VERDURA', 'cho100':3.1},
{'id':399, 'name':'Melù o pesce molo', 'category': '', 'cho100':0},
{'id':81, 'name':'Burro', 'category': 'LATTICINI', 'cho100':1.1},
{'id':82, 'name':'Burro d\'arachidi', 'category': '', 'cho100':8},
{'id':423, 'name':'Mortadella Bologna IGP', 'category': '', 'cho100':0},
{'id':452, 'name':'Pancetta arrotolata', 'category': '', 'cho100':0},
{'id':471, 'name':'Parmigiano Reggiano DOP', 'category': 'LATTICINI', 'cho100':0},
{'id':86, 'name':'Cacioricotta di capra', 'category': 'LATTICINI', 'cho100':1.5},
{'id':87, 'name':'Caciotta mista', 'category': 'LATTICINI', 'cho100':1.8},
{'id':88, 'name':'Caciotta romana di pecora', 'category': 'LATTICINI', 'cho100':2.1},
{'id':89, 'name':'Caciotta toscana', 'category': 'LATTICINI', 'cho100':0.8},
{'id':90, 'name':'Caciottina fresca', 'category': 'LATTICINI', 'cho100':0.5},
{'id':91, 'name':'Caciottina mista', 'category': 'LATTICINI', 'cho100':1.5},
{'id':92, 'name':'Caciottina vaccina', 'category': 'LATTICINI', 'cho100':3},
{'id':683, 'name':'Stracchino', 'category': 'LATTICINI', 'cho100':0},
{'id':704, 'name':'Uova di gallina, albume', 'category': '', 'cho100':0},
{'id':705, 'name':'Uova di gallina, intero', 'category': '', 'cho100':0},
{'id':706, 'name':'Uova di gallina, intero , cotto, a frittata o strapazzato', 'category': '', 'cho100':0},
{'id':707, 'name':'Uova di gallina, intero , cotto, alla coque o sodo', 'category': '', 'cho100':0},
{'id':708, 'name':'Uova di gallina, intero, congelato', 'category': '', 'cho100':0},
{'id':710, 'name':'Uova di gallina, tuorlo', 'category': '', 'cho100':0},
{'id':711, 'name':'Uova di gallina, tuorlo , cotto, in camicia', 'category': '', 'cho100':0},
{'id':712, 'name':'Uova di gallina, tuorlo, congelato', 'category': '', 'cho100':0},
{'id':724, 'name':'Vino da pasto bianco', 'category': '', 'cho100':0},
{'id':725, 'name':'Vino da pasto rosso', 'category': '', 'cho100':0},
{'id':728, 'name':'Whisky', 'category': '', 'cho100':0},
{'id':105, 'name':'Carciofi alla romana', 'category': 'VERDURA', 'cho100':1.8},
{'id':106, 'name':'Carciofi, cotti, al microonde', 'category': 'VERDURA', 'cho100':2.5},
{'id':107, 'name':'Carciofi, cotti, bolliti', 'category': 'VERDURA', 'cho100':3.3},
{'id':108, 'name':'Carciofi, crudi', 'category': 'VERDURA', 'cho100':2.5},
{'id':109, 'name':'Carciofi, surgelati', 'category': 'VERDURA', 'cho100':2.5},
{'id':110, 'name':'Carciofo Romanesco IGP', 'category': 'VERDURA', 'cho100':6.1},
{'id':111, 'name':'Carciofo, Spinoso sardo', 'category': 'VERDURA', 'cho100':8.9},
{'id':112, 'name':'Carciofo, verde, precoce', 'category': 'VERDURA', 'cho100':5.4},
{'id':113, 'name':'Carciofo, violetto, precoce', 'category': 'VERDURA', 'cho100':5.2},
{'id':103, 'name':'Caramelle dure', 'category': 'DOLCI', 'cho100':91.6},
{'id':246, 'name':'Fecola di patate', 'category': '', 'cho100':90.7},
{'id':116, 'name':'Carote, cotte, al microonde', 'category': 'VERDURA', 'cho100':7.7},
{'id':117, 'name':'Carote, cotte, bollite', 'category': 'VERDURA', 'cho100':8.7},
{'id':118, 'name':'Carote, crude', 'category': 'VERDURA', 'cho100':7.6},
{'id':119, 'name':'Carrube', 'category': 'VERDURA', 'cho100':49.9},
{'id':172, 'name':'Corn flakes', 'category': '', 'cho100':87.4},
{'id':454, 'name':'Pane azzimo', 'category': '', 'cho100':81.7},
{'id':410, 'name':'Miele', 'category': '', 'cho100':80.3},
{'id':566, 'name':'Pop corn', 'category': '', 'cho100':77.9},
{'id':461, 'name':'Pangrattato', 'category': '', 'cho100':77.8},
{'id':661, 'name':'Semola', 'category': '', 'cho100':76.9},
{'id':177, 'name':'Couscous, crudo', 'category': '', 'cho100':76.5},
{'id':97, 'name':'Canditi', 'category': '', 'cho100':75},
{'id':128, 'name':'Cavolfiore, cotto, al microonde', 'category': 'VERDURA', 'cho100':2.8},
{'id':129, 'name':'Cavolfiore, cotto, bollito', 'category': 'VERDURA', 'cho100':2.9},
{'id':130, 'name':'Cavolfiore, crudo', 'category': 'VERDURA', 'cho100':2.7},
{'id':131, 'name':'Cavoli di Bruxelles, cotti, bolliti', 'category': 'VERDURA', 'cho100':4.6},
{'id':132, 'name':'Cavoli di Bruxelles, crudi', 'category': 'VERDURA', 'cho100':4.2},
{'id':133, 'name':'Cavolo broccolo verde ramoso, cotto, bollito', 'category': 'VERDURA', 'cho100':3.5},
{'id':134, 'name':'Cavolo broccolo verde ramoso, crudo', 'category': 'VERDURA', 'cho100':2},
{'id':135, 'name':'Cavolo cappuccio, rosso, crudo', 'category': 'VERDURA', 'cho100':2.7},
{'id':136, 'name':'Cavolo cappuccio, verde, cotto, bollito', 'category': 'VERDURA', 'cho100':2.5},
{'id':137, 'name':'Cavolo cappuccio, verde, crudo', 'category': 'VERDURA', 'cho100':2.5},
{'id':138, 'name':'Cavolo verza, cotto, bollito', 'category': 'VERDURA', 'cho100':3.8},
{'id':139, 'name':'Cavolo verza, cruda', 'category': 'VERDURA', 'cho100':3.8},
{'id':140, 'name':'Ceci, in scatola, scolati', 'category': 'LATTICINI', 'cho100':13.9},
{'id':141, 'name':'Ceci, secchi', 'category': 'LATTICINI', 'cho100':46.9},
{'id':142, 'name':'Ceci, secchi, cotti, bolliti', 'category': 'LATTICINI', 'cho100':18.9},
{'id':259, 'name':'Fette biscottate', 'category': '', 'cho100':75},
{'id':352, 'name':'Lenti colorate ripiene di cioccolato', 'category': '', 'cho100':73.9},
{'id':446, 'name':'Orzo perlato', 'category': '', 'cho100':73.7},
{'id':146, 'name':'Cetrioli, freschi', 'category': 'FRUTTA', 'cho100':1.8},
{'id':147, 'name':'Cheddar', 'category': 'FRUTTA', 'cho100':0.5},
{'id':485, 'name':'Pasta di semola', 'category': '', 'cho100':72.7},
{'id':550, 'name':'Polenta, cruda', 'category': '', 'cho100':72.7},
{'id':150, 'name':'Cicoria, da taglio, coltivata, cruda', 'category': 'VERDURA', 'cho100':1.7},
{'id':151, 'name':'Cicoria, di campo, cotta, bollita', 'category': 'VERDURA', 'cho100':0.7},
{'id':152, 'name':'Cicoria, di campo, cruda', 'category': 'VERDURA', 'cho100':0.7},
{'id':153, 'name':'Cicoria, witloof o indivia belga, cruda', 'category': 'VERDURA', 'cho100':3.2},
{'id':154, 'name':'Ciliege, candite', 'category': 'FRUTTA', 'cho100':66.4},
{'id':155, 'name':'Ciliege, fresche', 'category': 'FRUTTA', 'cho100':11},
{'id':188, 'name':'Crostata, con marmellata di albicocche, industriale', 'category': '', 'cho100':71.7},
{'id':407, 'name':'Merendine, tipo pasta frolla, industriale', 'category': '', 'cho100':71.7},
{'id':428, 'name':'Muesli', 'category': '', 'cho100':71.7},
{'id':104, 'name':'Caramelle tipo "mou"', 'category': '', 'cho100':71.1},
{'id':480, 'name':'Pasta all\'uovo, secca', 'category': '', 'cho100':71},
{'id':317, 'name':'Gomme da masticare, lastrine e confetti', 'category': '', 'cho100':70},
{'id':652, 'name':'Savoiardi, industriali', 'category': '', 'cho100':69.6},
{'id':403, 'name':'Merendine, con marmellata, industriale', 'category': '', 'cho100':67.9},
{'id':412, 'name':'Miglio, decorticato', 'category': '', 'cho100':67.8},
{'id':180, 'name':'Crackers, alla soia', 'category': '', 'cho100':67.7},
{'id':182, 'name':'Crackers, salati', 'category': '', 'cho100':67.5},
{'id':187, 'name':'Crostata, con crema al cacao, industriale', 'category': '', 'cho100':67},
{'id':269, 'name':'Fiocchi d\'avena', 'category': '', 'cho100':66.8},
{'id':405, 'name':'Merendine, tipo briosche, industriale', 'category': '', 'cho100':65.7},
{'id':189, 'name':'Crostata, con marmellata, industriale', 'category': '', 'cho100':65.5},
{'id':404, 'name':'Merendine, farcite con cacao, industriale', 'category': '', 'cho100':65.5},
{'id':283, 'name':'Frumento tenero', 'category': '', 'cho100':65.2},
{'id':321, 'name':'Grissini', 'category': '', 'cho100':65.1},
{'id':411, 'name':'Miglio', 'category': '', 'cho100':64.9},
{'id':487, 'name':'Pasta di semola, integrale', 'category': '', 'cho100':64.8},
{'id':429, 'name':'Nachos', 'category': '', 'cho100':63.8},
{'id':201, 'name':'Datteri, secchi', 'category': '', 'cho100':63.1},
{'id':260, 'name':'Fette biscottate, integrali', 'category': '', 'cho100':63},
{'id':406, 'name':'Merendine, tipo pan di Spagna, industriale', 'category': '', 'cho100':62.7},
{'id':282, 'name':'Frumento duro', 'category': '', 'cho100':62.5},
{'id':124, 'name':'Castagne, secche', 'category': '', 'cho100':62},
{'id':181, 'name':'Crackers, integrali', 'category': '', 'cho100':61.7},
{'id':319, 'name':'Grano saraceno', 'category': '', 'cho100':61.2},
{'id':727, 'name':'Wafer ricoperto di cioccolato', 'category': '', 'cho100':60.3},
{'id':455, 'name':'Pane bianco', 'category': '', 'cho100':59.5},
{'id':179, 'name':'Crackers, al formaggio', 'category': '', 'cho100':59.2},
{'id':379, 'name':'Marmellata', 'category': '', 'cho100':58.7},
{'id':184, 'name':'Crema di nocciole e cacao', 'category': '', 'cho100':58.1},
{'id':501, 'name':'Patatine, fritte, confezionate in busta', 'category': '', 'cho100':58},
{'id':543, 'name':'Pizza bianca', 'category': '', 'cho100':57.9},
{'id':590, 'name':'Quinoa, cruda', 'category': '', 'cho100':57.8},
{'id':459, 'name':'Pane formato rosetta', 'category': '', 'cho100':57.6},
{'id':435, 'name':'Nuvole di gamberi', 'category': '', 'cho100':57},
{'id':453, 'name':'Pane al malto', 'category': '', 'cho100':56.6},
{'id':460, 'name':'Panettone', 'category': '', 'cho100':56.2},
{'id':173, 'name':'Cornetti', 'category': '', 'cho100':54.7},
{'id':484, 'name':'Pasta di mandorle', 'category': '', 'cho100':54.6},
{'id':245, 'name':'Fave, secche, sgusciate, crude', 'category': '', 'cho100':53.6},
{'id':243, 'name':'Fave, secche', 'category': '', 'cho100':52.8},
{'id':463, 'name':'Panini all\'olio', 'category': '', 'cho100':52.6},
{'id':458, 'name':'Pane di tipo semintegrale', 'category': '', 'cho100':52},
{'id':695, 'name':'Torrone alla mandorla', 'category': '', 'cho100':52},
{'id':354, 'name':'Lenticchie, secche', 'category': '', 'cho100':51.1},
{'id':156, 'name':'Cioccolato, al latte', 'category': '', 'cho100':50.5},
{'id':158, 'name':'Cioccolato, fondente', 'category': '', 'cho100':49.7},
{'id':451, 'name':'Pan di Spagna', 'category': '', 'cho100':49.7},
{'id':207, 'name':'Fagioli', 'category': 'VERDURA', 'cho100':47.5},
{'id':208, 'name':'Fagioli dall\'occhio, secchi', 'category': 'VERDURA', 'cho100':50.9},
{'id':209, 'name':'Fagioli, Borlotti, freschi', 'category': 'VERDURA', 'cho100':22.7},
{'id':210, 'name':'Fagioli, Borlotti, freschi, cotti, bolliti', 'category': 'VERDURA', 'cho100':11.2},
{'id':211, 'name':'Fagioli, Borlotti, in scatola, scolati', 'category': 'VERDURA', 'cho100':15.9},
{'id':212, 'name':'Fagioli, Borlotti, secchi', 'category': 'VERDURA', 'cho100':47.7},
{'id':213, 'name':'Fagioli, Borlotti, secchi, cotti, bolliti', 'category': 'VERDURA', 'cho100':16.4},
{'id':214, 'name':'Fagioli, Cannellini in scatola, scolati', 'category': 'VERDURA', 'cho100':12.5},
{'id':215, 'name':'Fagioli, Cannellini, secchi', 'category': 'VERDURA', 'cho100':45.5},
{'id':216, 'name':'Fagioli, Cannellini, secchi, cotti, bolliti', 'category': 'VERDURA', 'cho100':14.9},
{'id':217, 'name':'Fagioli, cotti, bolliti', 'category': 'VERDURA', 'cho100':17},
{'id':218, 'name':'Fagiolini a corallo, cotti, bolliti', 'category': 'VERDURA', 'cho100':4.6},
{'id':219, 'name':'Fagiolini, freschi', 'category': 'VERDURA', 'cho100':2.4},
{'id':220, 'name':'Fagiolini, surgelati, cotti, bolliti', 'category': 'VERDURA', 'cho100':4.6},
{'id':510, 'name':'Pepe nero', 'category': '', 'cho100':49},
{'id':222, 'name':'Faraona, coscio, con pelle, crudo', 'category': 'CARNE', 'cho100':0.3},
{'id':223, 'name':'Faraona, coscio, senza pelle, cotto, allo spiedo', 'category': 'CARNE', 'cho100':0.3},
{'id':224, 'name':'Faraona, coscio, senza pelle, crudo', 'category': 'CARNE', 'cho100':0.3},
{'id':225, 'name':'Faraona, petto, senza pelle, cotto, allo spiedo', 'category': 'CARNE', 'cho100':0.2},
{'id':226, 'name':'Faraona, petto, senza pelle, crudo', 'category': 'CARNE', 'cho100':0.2},
{'id':227, 'name':'Farina d\'avena', 'category': '', 'cho100':66.3},
{'id':228, 'name':'Farina di castagne', 'category': '', 'cho100':62},
{'id':229, 'name':'Farina di frumento, duro', 'category': '', 'cho100':63.2},
{'id':230, 'name':'Farina di frumento, integrale', 'category': '', 'cho100':61.8},
{'id':231, 'name':'Farina di frumento, tipo 0', 'category': '', 'cho100':69.5},
{'id':232, 'name':'Farina di frumento, tipo 00', 'category': '', 'cho100':71.6},
{'id':233, 'name':'Farina di mais', 'category': '', 'cho100':73.5},
{'id':234, 'name':'Farina di manioca', 'category': '', 'cho100':87.2},
{'id':235, 'name':'Farina di riso', 'category': '', 'cho100':79.1},
{'id':236, 'name':'Farina di segale', 'category': '', 'cho100':67.8},
{'id':237, 'name':'Farina di soia', 'category': '', 'cho100':23.4},
{'id':238, 'name':'Farina d\'orzo', 'category': '', 'cho100':68.4},
{'id':239, 'name':'Farro perlato, cotto, bollito', 'category': '', 'cho100':37.3},
{'id':240, 'name':'Farro perlato, crudo', 'category': '', 'cho100':69.3},
{'id':157, 'name':'Cioccolato, al latte con nocciole', 'category': '', 'cho100':48.6},
{'id':539, 'name':'Piselli, secchi', 'category': '', 'cho100':48.2},
{'id':462, 'name':'Panini al latte', 'category': '', 'cho100':48.1},
{'id':418, 'name':'Minestrone liofilizzato', 'category': '', 'cho100':47.6},
{'id':465, 'name':'Panino, fast food', 'category': '', 'cho100':47},
{'id':456, 'name':'Pane di segale', 'category': '', 'cho100':45.4},
{'id':247, 'name':'Fegato di bovino', 'category': 'CARNE', 'cho100':5.9},
{'id':248, 'name':'Fegato di bovino, cotto', 'category': 'CARNE', 'cho100':7.9},
{'id':249, 'name':'Fegato di equino', 'category': 'CARNE', 'cho100':5.3},
{'id':250, 'name':'Fegato di ovino', 'category': 'CARNE', 'cho100':2},
{'id':251, 'name':'Fegato di pollo', 'category': 'CARNE', 'cho100':1.5},
{'id':252, 'name':'Fegato di pollo, cotto', 'category': 'CARNE', 'cho100':2},
{'id':253, 'name':'Fegato di suino', 'category': 'CARNE', 'cho100':1.5},
{'id':254, 'name':'Fegato di suino, cotto', 'category': 'CARNE', 'cho100':2},
{'id':255, 'name':'Fegato di tacchino', 'category': 'CARNE', 'cho100':0.5},
{'id':256, 'name':'Fegato di tacchino, cotto', 'category': 'CARNE', 'cho100':0.8},
{'id':457, 'name':'Pane di tipo integrale', 'category': '', 'cho100':44.1},
{'id':491, 'name':'Pastiera', 'category': '', 'cho100':43.6},
{'id':99, 'name':'Cannoli siciliani', 'category': '', 'cho100':42.7},
{'id':98, 'name':'Cannoli alla crema, industriale', 'category': '', 'cho100':41.9},
{'id':261, 'name':'Fichi d\'india, freschi', 'category': 'FRUTTA', 'cho100':13},
{'id':262, 'name':'Fichi, canditi', 'category': 'FRUTTA', 'cho100':73.7},
{'id':263, 'name':'Fichi, freschi', 'category': 'FRUTTA', 'cho100':14.2},
{'id':264, 'name':'Fichi, seccati al forno e mandorlati', 'category': 'FRUTTA', 'cho100':58.2},
{'id':265, 'name':'Fichi, secchi', 'category': 'FRUTTA', 'cho100':58},
{'id':266, 'name':'Finocchi, cotti, al microonde', 'category': 'VERDURA', 'cho100':1.5},
{'id':267, 'name':'Finocchi, cotti, bolliti', 'category': 'VERDURA', 'cho100':1.7},
{'id':268, 'name':'Finocchi, crudi', 'category': 'VERDURA', 'cho100':1.5},
{'id':122, 'name':'Castagne, arrostite', 'category': '', 'cho100':41.8},
{'id':544, 'name':'Pizza con pomodoro', 'category': '', 'cho100':40.7},
{'id':176, 'name':'Couscous, cotto', 'category': '', 'cho100':39.7},
{'id':272, 'name':'Fiori di zucca, freschi', 'category': 'VERDURA', 'cho100':0.5},
{'id':273, 'name':'Foglie di rapa, crude', 'category': 'VERDURA', 'cho100':2.8},
{'id':120, 'name':'Castagnaccio', 'category': '', 'cho100':39.3},
{'id':58, 'name':'Bastoncini di crusca di frumento', 'category': '', 'cho100':38.4},
{'id':186, 'name':'Croissants', 'category': '', 'cho100':38},
{'id':502, 'name':'Patatine, fritte, fast food', 'category': '', 'cho100':37.6},
{'id':486, 'name':'Pasta di semola, cotta, bollita', 'category': '', 'cho100':37.3},
{'id':121, 'name':'Castagne', 'category': '', 'cho100':36.7},
{'id':280, 'name':'Fragole, fresche', 'category': 'FRUTTA', 'cho100':5.3},
{'id':488, 'name':'Pasta di semola, integrale, cotta, bollita', 'category': '', 'cho100':36},
{'id':545, 'name':'Pizza con pomodoro e mozzarella', 'category': '', 'cho100':35.5},
{'id':148, 'name':'Cheese burger, fast food', 'category': '', 'cho100':30.2},
{'id':284, 'name':'Frutta mista caramellata', 'category': '', 'cho100':36},
{'id':285, 'name':'Funghi coltivati, pleurotes, cotti, in padella', 'category': 'VERDURA', 'cho100':5.3},
{'id':286, 'name':'Funghi coltivati, pleurotes, crudi', 'category': 'VERDURA', 'cho100':5.6},
{'id':287, 'name':'Funghi coltivati, prataioli, cotti, in padella', 'category': 'VERDURA', 'cho100':1.5},
{'id':288, 'name':'Funghi coltivati, prataioli, crudi', 'category': 'VERDURA', 'cho100':0.8},
{'id':289, 'name':'Funghi ovuli, crudi', 'category': 'VERDURA', 'cho100':1.6},
{'id':290, 'name':'Funghi porcini, crudi', 'category': 'VERDURA', 'cho100':1},
{'id':291, 'name':'Gamberetti con funghi e bambù', 'category': '', 'cho100':1.9},
{'id':292, 'name':'Gamberi', 'category': '', 'cho100':2.9},
{'id':293, 'name':'Gamberi sgusciati, surgelati', 'category': '', 'cho100':2.9},
{'id':324, 'name':'Hamburger, fast food', 'category': '', 'cho100':30.1},
{'id':295, 'name':'Gelato confezionato, biscotto con crema, zabaione e cioccolato', 'category': 'DOLCI', 'cho100':49.1},
{'id':296, 'name':'Gelato confezionato, cacao, in vaschetta', 'category': 'DOLCI', 'cho100':25.3},
{'id':297, 'name':'Gelato confezionato, caffè, in vaschetta', 'category': 'DOLCI', 'cho100':23.9},
{'id':298, 'name':'Gelato confezionato, cono con panna e cioccolato', 'category': 'DOLCI', 'cho100':34.9},
{'id':299, 'name':'Gelato confezionato, cono con panna, scaglie di cioccolato e noccioline', 'category': 'DOLCI', 'cho100':33.3},
{'id':300, 'name':'Gelato confezionato, fior di latte, in vaschetta', 'category': 'DOLCI', 'cho100':20.7},
{'id':301, 'name':'Gelato confezionato, fior di latte, ricoperto di sorbetto alla fragola', 'category': 'DOLCI', 'cho100':21.3},
{'id':302, 'name':'Gelato confezionato, ghiacciolo all\'arancio', 'category': 'DOLCI', 'cho100':36.5},
{'id':303, 'name':'Gelato confezionato, nocciola, in vaschetta', 'category': 'DOLCI', 'cho100':25.2},
{'id':304, 'name':'Gelato confezionato, panna ricoperta di cioccolato fondente', 'category': 'DOLCI', 'cho100':28.2},
{'id':305, 'name':'Gelato confezionato, panna, in vaschetta', 'category': 'DOLCI', 'cho100':27.2},
{'id':306, 'name':'Gelato confezionato, sorbetto al limone, in vaschetta', 'category': 'DOLCI', 'cho100':34.2},
{'id':307, 'name':'Gelato confezionato, stracciatella, in vaschetta', 'category': 'DOLCI', 'cho100':31.5},
{'id':308, 'name':'Gelato confezionato, vaniglia, in vaschetta', 'category': 'DOLCI', 'cho100':25.7},
{'id':309, 'name':'Gelato misto fritto', 'category': 'DOLCI', 'cho100':34.7},
{'id':310, 'name':'Germe di frumento', 'category': 'VERDURA', 'cho100':43.5},
{'id':311, 'name':'Germogli di soia', 'category': 'VERDURA', 'cho100':3},
{'id':312, 'name':'Gnocchi al pesto alla genovese', 'category': 'PASTA', 'cho100':21.7},
{'id':313, 'name':'Gnocchi al ragù alla bolognese', 'category': 'PASTA', 'cho100':15.4},
{'id':314, 'name':'Gnocchi al sugo di pomodoro', 'category': 'PASTA', 'cho100':19.4},
{'id':315, 'name':'Gnocchi di patate, cotti', 'category': 'PASTA', 'cho100':34},
{'id':316, 'name':'Gnocchi di patate, crudi', 'category': 'PASTA', 'cho100':37.9},
{'id':499, 'name':'Patate, fritte', 'category': '', 'cho100':29.9},
{'id':318, 'name':'Gorgonzola', 'category': 'FORMAGGIO', 'cho100':1},
{'id':464, 'name':'Panino con hamburger piccolo, fast food', 'category': '', 'cho100':29},
{'id':477, 'name':'Pasta alla amatriciana', 'category': '', 'cho100':28.7},
{'id':93, 'name':'Caffe\' tostato', 'category': '', 'cho100':28.5},
{'id':478, 'name':'Pasta alla carbonara', 'category': '', 'cho100':28.4},
{'id':479, 'name':'Pasta alle vongole', 'category': '', 'cho100':28.3},
{'id':381, 'name':'Marsala tipico', 'category': '', 'cho100':28},
{'id':483, 'name':'Pasta cacio e pepe', 'category': '', 'cho100':27.4},
{'id':326, 'name':'Indivia, fresca', 'category': 'VERDURA', 'cho100':2.7},
{'id':546, 'name':'Pizza Napoletana Margherita STG', 'category': '', 'cho100':27.4},
{'id':482, 'name':'Pasta burro e parmigiano', 'category': '', 'cho100':27.3},
{'id':190, 'name':'Crusca di frumento', 'category': '', 'cho100':26.6},
{'id':330, 'name':'Kiwi, freschi', 'category': 'FRUTTA', 'cho100':9},
{'id':331, 'name':'Lamponi, freschi', 'category': 'FRUTTA', 'cho100':6.5},
{'id':473, 'name':'Pasta aglio, olio e peperoncino', 'category': '', 'cho100':26.5},
{'id':333, 'name':'Latte di bufala', 'category': 'LATTICINI', 'cho100':5.1},
{'id':334, 'name':'Latte di capra', 'category': 'LATTICINI', 'cho100':4.7},
{'id':335, 'name':'Latte di pecora', 'category': 'LATTICINI', 'cho100':5.2},
{'id':336, 'name':'Latte di vacca, condensato, zuccherato', 'category': 'LATTICINI', 'cho100':56.5},
{'id':337, 'name':'Latte di vacca, evaporato, non zuccherato', 'category': 'LATTICINI', 'cho100':8.6},
{'id':338, 'name':'Latte di vacca, in polvere, intero', 'category': 'LATTICINI', 'cho100':42},
{'id':339, 'name':'Latte di vacca, in polvere, parzialmente scremato', 'category': 'LATTICINI', 'cho100':50.2},
{'id':340, 'name':'Latte di vacca, in polvere, scremato', 'category': 'LATTICINI', 'cho100':56.2},
{'id':341, 'name':'Latte di vacca, pastorizzato, intero', 'category': 'LATTICINI', 'cho100':4.9},
{'id':342, 'name':'Latte di vacca, pastorizzato, parzialmente scremato', 'category': 'LATTICINI', 'cho100':5},
{'id':343, 'name':'Latte di vacca, pastorizzato, scremato', 'category': 'LATTICINI', 'cho100':5.3},
{'id':344, 'name':'Latte di vacca, UHT, intero', 'category': 'LATTICINI', 'cho100':4.7},
{'id':345, 'name':'Latte di vacca, UHT, parzialmente scremato', 'category': 'LATTICINI', 'cho100':5.1},
{'id':346, 'name':'Latteria', 'category': 'LATTICINI', 'cho100':1.4},
{'id':347, 'name':'Lattuga, a cappuccio, fresca', 'category': 'VERDURA', 'cho100':3},
{'id':348, 'name':'Lattuga, da taglio, fresca', 'category': 'VERDURA', 'cho100':2.2},
{'id':349, 'name':'Lattuga, fresca', 'category': 'VERDURA', 'cho100':2.2},
{'id':350, 'name':'Lattughino IV gamma, fresco', 'category': 'VERDURA', 'cho100':1.4},
{'id':351, 'name':'Lattughino, fresco', 'category': 'VERDURA', 'cho100':1.4},
{'id':447, 'name':'Orzo perlato, cotto, bollito', 'category': '', 'cho100':26.4},
{'id':123, 'name':'Castagne, bollite', 'category': '', 'cho100':26.1},
{'id':497, 'name':'Patate, cotte, arrosto', 'category': '', 'cho100':25.7},
{'id':481, 'name':'Pasta all\'uovo, secca, cotta, bollita', 'category': '', 'cho100':25.5},
{'id':589, 'name':'Quinoa, cotta, bollita', 'category': '', 'cho100':24.3},
{'id':357, 'name':'Limoni, freschi', 'category': 'FRUTTA', 'cho100':2.5},
{'id':358, 'name':'Limoni, succo', 'category': 'FRUTTA', 'cho100':2.5},
{'id':359, 'name':'Lingua di bovino', 'category': 'CARNE', 'cho100':0.4},
{'id':360, 'name':'Lingua di bovino, cotta, fritta', 'category': 'CARNE', 'cho100':0.5},
{'id':361, 'name':'Liquori da dessert', 'category': '', 'cho100':31.1},
{'id':329, 'name':'Ketchup, salsa', 'category': '', 'cho100':24},
{'id':670, 'name':'Soia, secca', 'category': '', 'cho100':23.2},
{'id':244, 'name':'Fave, secche, sgusciate, cotte, bollite', 'category': '', 'cho100':22.4},
{'id':327, 'name':'Involtini Primavera', 'category': '', 'cho100':21.6},
{'id':474, 'name':'Pasta al pesto alla genovese', 'category': '', 'cho100':21.4},
{'id':490, 'name':'Pasta integrale al sugo di pomodoro', 'category': '', 'cho100':21.3},
{'id':221, 'name':'Falafel', 'category': '', 'cho100':19.8},
{'id':476, 'name':'Pasta al sugo di pomodoro', 'category': '', 'cho100':19.5},
{'id':370, 'name':'Mais', 'category': 'CARNE', 'cho100':75.1},
{'id':371, 'name':'Mais, amido', 'category': 'CARNE', 'cho100':87},
{'id':372, 'name':'Mais, dolce, in scatola, sgocciolato', 'category': 'CARNE', 'cho100':20.5},
{'id':373, 'name':'Mandaranci, freschi', 'category': 'FRUTTA', 'cho100':12.8},
{'id':374, 'name':'Mandarini, freschi', 'category': 'FRUTTA', 'cho100':17.6},
{'id':375, 'name':'Mandorle dolci, secche', 'category': 'FRUTTA', 'cho100':4.6},
{'id':376, 'name':'Mango, fresco', 'category': 'FRUTTA', 'cho100':12.6},
{'id':495, 'name':'Patate, con buccia, cotte, bollite', 'category': '', 'cho100':17.9},
{'id':496, 'name':'Patate, cotte, al microonde', 'category': '', 'cho100':17.9},
{'id':549, 'name':'Polenta, cotta', 'category': '', 'cho100':17.8},
{'id':367, 'name':'Maiale in agrodolce', 'category': '', 'cho100':17.7},
{'id':362, 'name':'Litchi, freschi', 'category': '', 'cho100':17.2},
{'id':500, 'name':'Patate, senza buccia, cotte, bollite', 'category': '', 'cho100':16.9},
{'id':383, 'name':'Melagrane, fresche', 'category': 'FRUTTA', 'cho100':15.9},
{'id':384, 'name':'Melanzane, cotte, al microonde', 'category': 'VERDURA', 'cho100':3},
{'id':385, 'name':'Melanzane, cotte, in padella', 'category': 'VERDURA', 'cho100':5.4},
{'id':386, 'name':'Melanzane, crude', 'category': 'VERDURA', 'cho100':2.6},
{'id':387, 'name':'Mele cotogne, fresche', 'category': 'FRUTTA', 'cho100':6.3},
{'id':388, 'name':'Mele, disidratate', 'category': 'FRUTTA', 'cho100':82.2},
{'id':389, 'name':'Mele, fresche, annurche', 'category': 'FRUTTA', 'cho100':10.2},
{'id':390, 'name':'Mele, fresche, con buccia', 'category': 'FRUTTA', 'cho100':10},
{'id':391, 'name':'Mele, fresche, deliziose', 'category': 'FRUTTA', 'cho100':11.1},
{'id':392, 'name':'Mele, fresche, golden', 'category': 'FRUTTA', 'cho100':10.7},
{'id':393, 'name':'Mele, fresche, granny smith', 'category': 'FRUTTA', 'cho100':9.4},
{'id':394, 'name':'Mele, fresche, imperatore', 'category': 'FRUTTA', 'cho100':11.3},
{'id':395, 'name':'Mele, fresche, renette', 'category': 'FRUTTA', 'cho100':10},
{'id':396, 'name':'Mele, fresche, senza buccia', 'category': 'FRUTTA', 'cho100':13.7},
{'id':397, 'name':'Melone, d\'estate, fresco', 'category': 'FRUTTA', 'cho100':7.4},
{'id':398, 'name':'Melone, d\'inverno, fresco', 'category': 'FRUTTA', 'cho100':4.9},
{'id':164, 'name':'Cocco, essiccato', 'category': '', 'cho100':16.4},
{'id':355, 'name':'Lenticchie, secche, cotte, bollite', 'category': '', 'cho100':16.3},
{'id':363, 'name':'Loti o kaki, freschi', 'category': '', 'cho100':16},
{'id':402, 'name':'Menta, fresca', 'category': 'VERDURA', 'cho100':5.3},
{'id':475, 'name':'Pasta al ragù alla bolognese', 'category': '', 'cho100':16},
{'id':498, 'name':'Patate, crude', 'category': '', 'cho100':16},
{'id':492, 'name':'Patate novelle, cotte, al microonde', 'category': '', 'cho100':15.7},
{'id':494, 'name':'Patate novelle, crude', 'category': '', 'cho100':15.7},
{'id':646, 'name':'Salvia, fresco', 'category': '', 'cho100':15.6},
{'id':408, 'name':'Merluzzo in umido', 'category': '', 'cho100':1.7},
{'id':409, 'name':'Merluzzo o nasello, bastoncini di pesce, surgelati', 'category': '', 'cho100':15},
{'id':149, 'name':'Chicken Nuggets, fast food', 'category': '', 'cho100':15.4},
{'id':353, 'name':'Lenticchie, in scatola, scolate', 'category': '', 'cho100':15.4},
{'id':493, 'name':'Patate novelle, cotte, bollte', 'category': '', 'cho100':14.9},
{'id':166, 'name':'Cocktail di frutta sciroppata', 'category': '', 'cho100':14.8},
{'id':684, 'name':'Succo di frutta', 'category': '', 'cho100':14.5},
{'id':548, 'name':'Polenta al sugo di pomodoro', 'category': '', 'cho100':14.2},
{'id':469, 'name':'Pappa reale', 'category': '', 'cho100':14},
{'id':687, 'name':'Tabulè', 'category': '', 'cho100':14},
{'id':722, 'name':'Vermouth dolce', 'category': '', 'cho100':13.9},
{'id':673, 'name':'Spaghetti fritti', 'category': '', 'cho100':13.6},
{'id':420, 'name':'Mirtilli, freschi', 'category': 'FRUTTA', 'cho100':10.1},
{'id':629, 'name':'Rosmarino, fresco', 'category': '', 'cho100':13.5},
{'id':332, 'name':'Lasagna', 'category': '', 'cho100':13.4},
{'id':380, 'name':'Marsala all\'uovo', 'category': '', 'cho100':12.4},
{'id':547, 'name':'Polenta al ragù alla bolognese', 'category': '', 'cho100':12.4},
{'id':489, 'name':'Pasta e fagioli', 'category': '', 'cho100':12.2},
{'id':294, 'name':'Gateau', 'category': '', 'cho100':11.6},
{'id':84, 'name':'Cacao amaro, in polvere', 'category': '', 'cho100':11.5},
{'id':537, 'name':'Piselli, freschi, cotti, in padella', 'category': '', 'cho100':11.5},
{'id':538, 'name':'Piselli, in scatola, scolati', 'category': '', 'cho100':11.1},
{'id':430, 'name':'Nespole, fresche', 'category': 'FRUTTA', 'cho100':6.1},
{'id':161, 'name':'Cipolline, cotte, bollite', 'category': '', 'cho100':10.9},
{'id':694, 'name':'Topinambur, cotto, bollito', 'category': '', 'cho100':10.6},
{'id':60, 'name':'Bevanda tipo cola', 'category': '', 'cho100':10.5},
{'id':607, 'name':'Ribes, freschi', 'category': '', 'cho100':9.7},
{'id':257, 'name':'Feijoa, fresca', 'category': '', 'cho100':9.6},
{'id':325, 'name':'Hummus', 'category': '', 'cho100':9.4},
{'id':437, 'name':'Oliva Giarraffa', 'category': 'VERDURA', 'cho100':0.19},
{'id':438, 'name':'Oliva Nocellara del Belice', 'category': 'VERDURA', 'cho100':0.06},
{'id':439, 'name':'Olive da tavola conservate', 'category': 'VERDURA', 'cho100':5},
{'id':440, 'name':'Olive, nere', 'category': 'VERDURA', 'cho100':0.8},
{'id':441, 'name':'Olive, verdi', 'category': 'VERDURA', 'cho100':1},
{'id':442, 'name':'Olive, verdi, in salamoia', 'category': 'VERDURA', 'cho100':1},
{'id':163, 'name':'Clementine, fresche', 'category': '', 'cho100':8.7},
{'id':162, 'name':'Cipolline, crude', 'category': '', 'cho100':8.5},
{'id':669, 'name':'Soia, salsa', 'category': '', 'cho100':8.3},
{'id':421, 'name':'Mora di rovo', 'category': '', 'cho100':8.1},
{'id':541, 'name':'Pistacchi', 'category': '', 'cho100':8.1},
{'id':433, 'name':'Noci pecan', 'category': '', 'cho100':7.9},
{'id':651, 'name':'Sarmale', 'category': '', 'cho100':7.9},
{'id':159, 'name':'Cipolle, cotte, bollite', 'category': '', 'cho100':7.8},
{'id':419, 'name':'Minestrone, cotto', 'category': '', 'cho100':7.8},
{'id':542, 'name':'Pistacchio di Bronte DOP', 'category': '', 'cho100':7.3},
{'id':365, 'name':'Lupini, ammollati', 'category': '', 'cho100':7.1},
{'id':468, 'name':'Papaia, fresca', 'category': '', 'cho100':6.9},
{'id':276, 'name':'Formaggino con ridotto contenuto di grasso', 'category': '', 'cho100':6.5},
{'id':536, 'name':'Piselli, freschi', 'category': '', 'cho100':6.5},
{'id':540, 'name':'Piselli, surgelati', 'category': '', 'cho100':6.5},
{'id':417, 'name':'Minestre in scatola, crema di pomodori', 'category': '', 'cho100':6.4},
{'id':431, 'name':'Nocciole, secche', 'category': '', 'cho100':6.1},
{'id':275, 'name':'Formaggino', 'category': '', 'cho100':6},
{'id':160, 'name':'Cipolle, crude', 'category': '', 'cho100':5.7},
{'id':472, 'name':'Passiflora, fresca', 'category': '', 'cho100':5.7},
{'id':432, 'name':'Noci', 'category': '', 'cho100':5.5},
{'id':551, 'name':'Pollo all\'ananas', 'category': '', 'cho100':5.5},
{'id':448, 'name':'Ostrica', 'category': '', 'cho100':5.4},
{'id':242, 'name':'Fave, fresche, cotte, in padella', 'category': '', 'cho100':5.3},
{'id':366, 'name':'Macadamia', 'category': '', 'cho100':5.1},
{'id':434, 'name':'Noci, secche', 'category': '', 'cho100':5.1},
{'id':200, 'name':'Dadi da brodo', 'category': '', 'cho100':5},
{'id':241, 'name':'Fave, fresche', 'category': '', 'cho100':4.5},
{'id':416, 'name':'Minestre in scatola, crema di pollo', 'category': '', 'cho100':4.5},
{'id':668, 'name':'Soia, isolato proteico', 'category': '', 'cho100':4.5},
{'id':165, 'name':'Cocco, fresco', 'category': '', 'cho100':4.4},
{'id':183, 'name':'Crema di latte, 12% di lipidi', 'category': '', 'cho100':4.4},
{'id':597, 'name':'Rape, cotte, bollite', 'category': '', 'cho100':4.3},
{'id':413, 'name':'Minestre in scatola, crema di asparagi', 'category': '', 'cho100':4.2},
{'id':415, 'name':'Minestre in scatola, crema di funghi', 'category': '', 'cho100':4.2},
{'id':80, 'name':'Burrini', 'category': '', 'cho100':4.1},
{'id':466, 'name':'Panna da cucina, sterilizzata, 23% di lipidi', 'category': '', 'cho100':4},
{'id':470, 'name':'Parmigiana di melanzane', 'category': '', 'cho100':4},
{'id':535, 'name':'Pinoli', 'category': '', 'cho100':4},
{'id':723, 'name':'Vermouth secco', 'category': '', 'cho100':4},
{'id':630, 'name':'Rughetta o rucola, fresca', 'category': '', 'cho100':3.9},
{'id':726, 'name':'Vongola', 'category': '', 'cho100':3.9},
{'id':511, 'name':'Peperoncini piccanti, freschi', 'category': '', 'cho100':3.8},
{'id':167, 'name':'Cocomero, fresco', 'category': '', 'cho100':3.7},
{'id':689, 'name':'Tarassaco o dente di leone, fresco', 'category': '', 'cho100':3.7},
{'id':206, 'name':'Emmenthal', 'category': '', 'cho100':3.6},
{'id':685, 'name':'Sugo di pomodoro', 'category': '', 'cho100':3.6},
{'id':323, 'name':'Guava, fresca', 'category': '', 'cho100':3.5},
{'id':178, 'name':'Cozza o mitilo', 'category': '', 'cho100':3.4},
{'id':467, 'name':'Panna da montare, 35% di lipidi', 'category': '', 'cho100':3.4},
{'id':127, 'name':'Caviale, storione', 'category': '', 'cho100':3.3},
{'id':175, 'name':'Cotechino Modena IGP, cotto', 'category': '', 'cho100':3.2},
{'id':270, 'name':'Fiocchi di formaggio magro', 'category': '', 'cho100':3.2},
{'id':278, 'name':'Formaggio cremoso spalmabile, light', 'category': '', 'cho100':3.1},
{'id':449, 'name':'Pagello bocca d\'oro', 'category': '', 'cho100':3},
{'id':675, 'name':'Spezzatino di vitellone al vino rosso', 'category': '', 'cho100':3},
{'id':691, 'name':'Tè, foglie', 'category': '', 'cho100':3},
{'id':114, 'name':'Cardi, cotti, bolliti', 'category': '', 'cho100':2.9},
{'id':204, 'name':'Dolce verde', 'category': '', 'cho100':2.5},
{'id':509, 'name':'Pecorino siciliano', 'category': '', 'cho100':2.4},
{'id':552, 'name':'Pollo alle mandorle', 'category': '', 'cho100':2.4},
{'id':85, 'name':'Caciocavallo', 'category': '', 'cho100':2.3},
{'id':505, 'name':'Patè di pollo', 'category': '', 'cho100':2.3},
{'id':627, 'name':'Robiola', 'category': '', 'cho100':2.3},
{'id':655, 'name':'Scarola', 'category': '', 'cho100':2.3},
{'id':414, 'name':'Minestre in scatola, crema di cipolle', 'category': '', 'cho100':2.2},
{'id':656, 'name':'Scarola IV gamma, fresca', 'category': '', 'cho100':2.2},
{'id':369, 'name':'Maionese, industriale', 'category': '', 'cho100':2.1},
{'id':422, 'name':'Mormora', 'category': '', 'cho100':2},
{'id':512, 'name':'Peperoni, cotti, in padella', 'category': 'VERDURA', 'cho100':24.3},
{'id':513, 'name':'Peperoni, crudi', 'category': 'VERDURA', 'cho100':4.2},
{'id':514, 'name':'Peperoni, gialli, cotti, al microonde', 'category': 'VERDURA', 'cho100':8.1},
{'id':515, 'name':'Peperoni, gialli, crudi', 'category': 'VERDURA', 'cho100':6.8},
{'id':516, 'name':'Peperoni, rossi e gialli, crudi', 'category': 'VERDURA', 'cho100':6.7},
{'id':517, 'name':'Peperoni, rossi, cotti, al microonde', 'category': 'VERDURA', 'cho100':7.9},
{'id':518, 'name':'Peperoni, rossi, crudi', 'category': 'VERDURA', 'cho100':6.5},
{'id':519, 'name':'Peperoni, verdi, crudi', 'category': 'VERDURA', 'cho100':6},
{'id':520, 'name':'Pere, candite', 'category': 'FRUTTA', 'cho100':73.9},
{'id':521, 'name':'Pere, fresche, Abate Fetel', 'category': 'FRUTTA', 'cho100':12.7},
{'id':522, 'name':'Pere, fresche, Coscia', 'category': 'FRUTTA', 'cho100':8.6},
{'id':523, 'name':'Pere, fresche, Kaiser', 'category': 'FRUTTA', 'cho100':9.2},
{'id':524, 'name':'Pere, fresche, Max-Red Barlett', 'category': 'FRUTTA', 'cho100':10},
{'id':525, 'name':'Pere, fresche, senza buccia', 'category': 'FRUTTA', 'cho100':8.8},
{'id':526, 'name':'Pere, fresche, William', 'category': 'FRUTTA', 'cho100':10.2},
{'id':527, 'name':'Pesce gatto', 'category': 'FRUTTA', 'cho100':0.2},
{'id':528, 'name':'Pesche, disidratate', 'category': 'FRUTTA', 'cho100':88},
{'id':529, 'name':'Pesche, fresche, con buccia', 'category': 'FRUTTA', 'cho100':5.8},
{'id':530, 'name':'Pesche, fresche, senza buccia', 'category': 'FRUTTA', 'cho100':6.1},
{'id':531, 'name':'Pesche, sciroppate', 'category': 'FRUTTA', 'cho100':14},
{'id':532, 'name':'Pesche, secche', 'category': 'FRUTTA', 'cho100':68.3},
{'id':533, 'name':'Peschenoci, fresche', 'category': 'FRUTTA', 'cho100':7},
{'id':436, 'name':'Occhiata', 'category': '', 'cho100':2},
{'id':642, 'name':'Salpa', 'category': '', 'cho100':2},
{'id':185, 'name':'Crescenza', 'category': '', 'cho100':1.9},
{'id':596, 'name':'Ragù alla bolognese', 'category': '', 'cho100':1.9},
{'id':730, 'name':'Wurstel di puro suino, crudo', 'category': '', 'cho100':1.9},
{'id':508, 'name':'Pecorino romano', 'category': '', 'cho100':1.8},
{'id':534, 'name':'Pesto alla genovese', 'category': '', 'cho100':1.8},
{'id':115, 'name':'Cardi, crudi', 'category': '', 'cho100':1.7},
{'id':258, 'name':'Feta', 'category': '', 'cho100':1.5},
{'id':322, 'name':'Groviera', 'category': '', 'cho100':1.5},
{'id':632, 'name':'Salame Fabriano', 'category': '', 'cho100':1.5},
{'id':648, 'name':'Sarda', 'category': '', 'cho100':1.5},
{'id':649, 'name':'Sardine', 'category': '', 'cho100':1.5},
{'id':101, 'name':'Capocollo', 'category': '', 'cho100':1.4},
{'id':205, 'name':'Edam', 'category': '', 'cho100':1.4},
{'id':426, 'name':'Mozzarella di bufala campana DOP', 'category': '', 'cho100':1.4},
{'id':450, 'name':'Palombo', 'category': '', 'cho100':1.3},
{'id':504, 'name':'Patè di fegato', 'category': '', 'cho100':1.3},
{'id':643, 'name':'Salsiccia di Calabria', 'category': '', 'cho100':1.3},
{'id':553, 'name':'Polpo', 'category': '', 'cho100':1.4},
{'id':554, 'name':'Pomodori al riso', 'category': 'VERDURA', 'cho100':14.5},
{'id':555, 'name':'Pomodori, conserva', 'category': 'VERDURA', 'cho100':20.4},
{'id':556, 'name':'Pomodori, da insalata, freschi', 'category': 'VERDURA', 'cho100':2.8},
{'id':557, 'name':'Pomodori, maturi, freschi', 'category': 'VERDURA', 'cho100':3.5},
{'id':558, 'name':'Pomodori, Nerina, rossi, freschi', 'category': 'VERDURA', 'cho100':2.8},
{'id':559, 'name':'Pomodori, Nerina, verdi, freschi', 'category': 'VERDURA', 'cho100':2.8},
{'id':560, 'name':'Pomodori, passata', 'category': 'VERDURA', 'cho100':3},
{'id':561, 'name':'Pomodori, pelati in scatola', 'category': 'VERDURA', 'cho100':3},
{'id':562, 'name':'Pomodori, San Marzano, freschi', 'category': 'VERDURA', 'cho100':3},
{'id':563, 'name':'Pomodori, succo', 'category': 'VERDURA', 'cho100':3},
{'id':564, 'name':'Pomodorini ciliegino, freschi', 'category': 'VERDURA', 'cho100':7.2},
{'id':565, 'name':'Pompelmo, fresco', 'category': 'FRUTTA', 'cho100':6.2},
{'id':328, 'name':'Italico', 'category': '', 'cho100':1.2},
{'id':567, 'name':'Porri, cotti, bolliti', 'category': 'VERDURA', 'cho100':5.3},
{'id':568, 'name':'Porri, crudi', 'category': 'VERDURA', 'cho100':5.2},
{'id':569, 'name':'Prezzemolo, fresco', 'category': 'VERDURA', 'cho100':0.0},
{'id':570, 'name':'Prosciutto cotto', 'category': 'CARNE', 'cho100':1.7},
{'id':571, 'name':'Prosciutto cotto, alta qualità', 'category': 'CARNE', 'cho100':0.8},
{'id':572, 'name':'Prosciutto cotto, alta qualità, sgrassato', 'category': 'CARNE', 'cho100':0.9},
{'id':573, 'name':'Prosciutto cotto, scelto', 'category': 'CARNE', 'cho100':0.6},
{'id':574, 'name':'Prosciutto cotto, scelto, sgrassato', 'category': 'CARNE', 'cho100':0.5},
{'id':575, 'name':'Prosciutto cotto, sgrassato', 'category': 'CARNE', 'cho100':1.9},
{'id':576, 'name':'Prosciutto crudo DOP, di Parma', 'category': 'CARNE', 'cho100':0.3},
{'id':577, 'name':'Prosciutto crudo, di Modena DOP', 'category': 'CARNE', 'cho100':0.1},
{'id':578, 'name':'Prosciutto crudo, di Modena DOP, sgrassato', 'category': 'CARNE', 'cho100':0.1},
{'id':579, 'name':'Prosciutto crudo, di Parma DOP, sgrassato', 'category': 'CARNE', 'cho100':0.4},
{'id':580, 'name':'Prosciutto crudo, di San Daniele DOP', 'category': 'CARNE', 'cho100':0.2},
{'id':581, 'name':'Prosciutto crudo, di San Daniele DOP, sgrassato', 'category': 'CARNE', 'cho100':0.4},
{'id':582, 'name':'Prosciutto crudo, Nazionale', 'category': 'CARNE', 'cho100':0.1},
{'id':583, 'name':'Prosciutto crudo, Nazionale, sgrassato', 'category': 'CARNE', 'cho100':0.3},
{'id':584, 'name':'Provolone', 'category': 'LATTICINI', 'cho100':2},
{'id':585, 'name':'Prugne, fresche', 'category': 'FRUTTA', 'cho100':10.5},
{'id':586, 'name':'Prugne, gialle', 'category': 'FRUTTA', 'cho100':6.6},
{'id':587, 'name':'Prugne, rosse', 'category': 'FRUTTA', 'cho100':10.5},
{'id':588, 'name':'Prugne, secche', 'category': 'FRUTTA', 'cho100':55},
{'id':443, 'name':'Orata fresca d\'allevamento, filetti', 'category': '', 'cho100':1.2},
{'id':628, 'name':'Rombo', 'category': '', 'cho100':1.2},
{'id':591, 'name':'Radicchio rosso di Treviso IGP, precoce', 'category': 'VERDURA', 'cho100':1},
{'id':592, 'name':'Radicchio rosso di Treviso IGP, tardivo', 'category': 'VERDURA', 'cho100':1.4},
{'id':593, 'name':'Radicchio variegato di Castelfranco IGP', 'category': 'VERDURA', 'cho100':1.4},
{'id':594, 'name':'Radicchio, rosso, fresco', 'category': 'VERDURA', 'cho100':1.6},
{'id':595, 'name':'Radicchio, verde, fresco', 'category': 'VERDURA', 'cho100':0.5},
{'id':639, 'name':'Salmone, affumicato', 'category': '', 'cho100':1.2},
{'id':674, 'name':'Speck dell\'Alto Adige IGP', 'category': '', 'cho100':1.2},
{'id':598, 'name':'Rape, crude', 'category': 'VERDURA', 'cho100':4},
{'id':599, 'name':'Ravanelli, freschi', 'category': 'VERDURA', 'cho100':1.8},
{'id':600, 'name':'Ravioli al burro e salvia', 'category': 'VERDURA', 'cho100':24.7},
{'id':601, 'name':'Ravioli al sugo di pomodoro', 'category': 'VERDURA', 'cho100':17.6},
{'id':602, 'name':'Ravioli al vapore', 'category': 'VERDURA', 'cho100':21.4},
{'id':603, 'name':'Ravioli, cotti', 'category': 'VERDURA', 'cho100':27.5},
{'id':604, 'name':'Ravioli, crudi, freschi', 'category': 'VERDURA', 'cho100':40.7},
{'id':356, 'name':'Lievito di birra, compresso', 'category': '', 'cho100':1.1},
{'id':401, 'name':'Melù o pesce molo, stoccafisso, secco', 'category': '', 'cho100':1.1},
{'id':503, 'name':'Patè di coniglio', 'category': '', 'cho100':1.1},
{'id':608, 'name':'Ricotta di bufala', 'category': 'LATTICINI', 'cho100':3.7},
{'id':609, 'name':'Ricotta di pecora', 'category': 'LATTICINI', 'cho100':4.2},
{'id':610, 'name':'Ricotta di vacca', 'category': 'LATTICINI', 'cho100':3.5},
{'id':611, 'name':'Riso al ragù alla bolognese', 'category': 'PASTA', 'cho100':13.6},
{'id':612, 'name':'Riso al sugo di pomodoro', 'category': 'PASTA', 'cho100':16.5},
{'id':613, 'name':'Riso Basmati, cotto, bollito', 'category': 'PASTA', 'cho100':25.2},
{'id':614, 'name':'Riso Basmati, crudo', 'category': 'PASTA', 'cho100':82.9},
{'id':615, 'name':'Riso burro e Parmigiano reggiano', 'category': 'PASTA', 'cho100':22.1},
{'id':616, 'name':'Riso Cantonese', 'category': 'PASTA', 'cho100':29.8},
{'id':617, 'name':'Riso Venere, cotto, bollito', 'category': 'PASTA', 'cho100':36.9},
{'id':618, 'name':'Riso Venere, crudo', 'category': 'PASTA', 'cho100':80.4},
{'id':619, 'name':'Riso, brillato', 'category': 'PASTA', 'cho100':80.4},
{'id':620, 'name':'Riso, brillato, cotto, bollito', 'category': 'PASTA', 'cho100':24.2},
{'id':621, 'name':'Riso, integrale', 'category': 'PASTA', 'cho100':77.4},
{'id':622, 'name':'Riso, integrale, cotto, bollito', 'category': 'PASTA', 'cho100':25.5},
{'id':623, 'name':'Riso, parboiled', 'category': 'PASTA', 'cho100':81.3},
{'id':624, 'name':'Riso, parboiled, cotto, bollito', 'category': 'PASTA', 'cho100':23.3},
{'id':625, 'name':'Riso, soffiato, da prima colazione', 'category': 'PASTA', 'cho100':85.1},
{'id':626, 'name':'Risotto alla parmigiana', 'category': 'PASTA', 'cho100':25.4},
{'id':506, 'name':'Patè di prosciutto', 'category': '', 'cho100':1.1},
{'id':634, 'name':'Salame Milano', 'category': '', 'cho100':1.1},
{'id':636, 'name':'Salame nostrano', 'category': '', 'cho100':1.1},
{'id':637, 'name':'Salame ungherese', 'category': '', 'cho100':1.1},
{'id':169, 'name':'Coratella di agnello', 'category': '', 'cho100':1},
{'id':279, 'name':'Formaggio molle da tavola', 'category': '', 'cho100':1},
{'id':444, 'name':'Orata fresca, filetti', 'category': '', 'cho100':1},
{'id':445, 'name':'Orata, surgelata', 'category': '', 'cho100':1},
{'id':631, 'name':'Salame Brianza', 'category': '', 'cho100':1},
{'id':640, 'name':'Salmone, fresco', 'category': '', 'cho100':1},
{'id':641, 'name':'Salmone, in salamoia', 'category': '', 'cho100':1},
{'id':647, 'name':'Sarago', 'category': '', 'cho100':1},
{'id':686, 'name':'Suro o sugarello', 'category': '', 'cho100':1},
{'id':714, 'name':'Uova di oca, intero', 'category': '', 'cho100':1},
{'id':715, 'name':'Uova di tacchina, intero', 'category': '', 'cho100':1},
{'id':102, 'name':'Capocollo di Calabria', 'category': '', 'cho100':0.9},
{'id':644, 'name':'Salsiccia di fegato', 'category': '', 'cho100':0.9},
{'id':672, 'name':'Soppressata di Calabria', 'category': '', 'cho100':0.9},
{'id':677, 'name':'Spigola al cartoccio', 'category': '', 'cho100':0.9},
{'id':688, 'name':'Taleggio', 'category': '', 'cho100':0.9},
{'id':692, 'name':'Tinca', 'category': '', 'cho100':0.9},
{'id':71, 'name':'Boga', 'category': '', 'cho100':0.8},
{'id':145, 'name':'Cervello di bovino', 'category': '', 'cho100':0.8},
{'id':650, 'name':'Sardine, fritte', 'category': 'PESCE', 'cho100':1},
{'id':170, 'name':'Coratella di vitello', 'category': '', 'cho100':0.8},
{'id':192, 'name':'Cuore di bovino, cotto', 'category': '', 'cho100':0.8},
{'id':653, 'name':'Scaloppina di tacchino al limone', 'category': 'CARNE', 'cho100':11.5},
{'id':654, 'name':'Scamorza', 'category': 'FORMAGGIO', 'cho100':1},
{'id':274, 'name':'Fontina', 'category': '', 'cho100':0.8},
{'id':606, 'name':'Rene di bovino', 'category': '', 'cho100':0.8},
{'id':667, 'name':'Soia, bevanda', 'category': '', 'cho100':0.8},
{'id':658, 'name':'Sedano rapa, crudo', 'category': 'VERDURA', 'cho100':3.8},
{'id':659, 'name':'Sedano, cotto, al microonde', 'category': 'VERDURA', 'cho100':2.4},
{'id':660, 'name':'Sedano, crudo', 'category': 'VERDURA', 'cho100':2.4},
{'id':678, 'name':'Spigola d\'allevamento, filetti', 'category': '', 'cho100':0.8},
{'id':662, 'name':'Seppia', 'category': 'PESCE', 'cho100':0.7},
{'id':663, 'name':'Sgombro o maccarello', 'category': 'PESCE', 'cho100':0.5},
{'id':664, 'name':'Sogliola', 'category': 'PESCE', 'cho100':0.8},
{'id':665, 'name':'Sogliola al vino bianco', 'category': 'PESCE', 'cho100':5.6},
{'id':666, 'name':'Sogliola, surgelata', 'category': 'PESCE', 'cho100':0.1},
{'id':125, 'name':'Cavallo, solo tessuto muscolare, crudo', 'category': '', 'cho100':0.7},
{'id':143, 'name':'Cefalo muggine', 'category': '', 'cho100':0.7},
{'id':202, 'name':'Dentice', 'category': '', 'cho100':0.7},
{'id':203, 'name':'Dentice, surgelato', 'category': '', 'cho100':0.7},
{'id':671, 'name':'Soia, yogurt', 'category': 'LATTICINI', 'cho100':3.9},
{'id':271, 'name':'Fior di latte', 'category': '', 'cho100':0.7},
{'id':427, 'name':'Mozzarella di vacca', 'category': '', 'cho100':0.7},
{'id':605, 'name':'Razza', 'category': '', 'cho100':0.7},
{'id':638, 'name':'Salami italiani alla cacciatora DOP', 'category': '', 'cho100':0.7},
{'id':703, 'name':'Uova di anatra, intero', 'category': '', 'cho100':0.7},
{'id':94, 'name':'Calamaro', 'category': '', 'cho100':0.6},
{'id':95, 'name':'Calamaro surgelato', 'category': '', 'cho100':0.6},
{'id':679, 'name':'Spinaci, cotti, al microonde', 'category': 'VERDURA', 'cho100':3},
{'id':680, 'name':'Spinaci, cotti, bolliti', 'category': 'VERDURA', 'cho100':3.5},
{'id':681, 'name':'Spinaci, crudi', 'category': 'VERDURA', 'cho100':2.9},
{'id':126, 'name':'Cavallo, tessuto muscolare e grasso, crudo', 'category': '', 'cho100':0.6},
{'id':193, 'name':'Cuore di equino', 'category': '', 'cho100':0.6},
{'id':645, 'name':'Salsiccia di suino, fresca', 'category': '', 'cho100':0.6},
{'id':657, 'name':'Scorfano', 'category': '', 'cho100':0.6},
{'id':676, 'name':'Spigola', 'category': '', 'cho100':0.6},
{'id':682, 'name':'Spumante', 'category': '', 'cho100':0.6},
{'id':100, 'name':'Capitone', 'category': '', 'cho100':0.5},
{'id':144, 'name':'Cernia surgelata', 'category': '', 'cho100':0.5},
{'id':690, 'name':'Tartufo, nero', 'category': 'VERDURA', 'cho100':0.7},
{'id':191, 'name':'Cuore di bovino', 'category': '', 'cho100':0.5},
{'id':424, 'name':'Mortadella di bovino e suino', 'category': '', 'cho100':0.5},
{'id':693, 'name':'Tonno', 'category': '', 'cho100':0.1},
{'id':633, 'name':'Salame Felino', 'category': '', 'cho100':0.5},
{'id':194, 'name':'Cuore di ovino', 'category': '', 'cho100':0.4},
{'id':696, 'name':'Torta di mele', 'category': '', 'cho100':46.3},
{'id':697, 'name':'Torta margherita', 'category': '', 'cho100':63.4},
{'id':698, 'name':'Tortellini, freschi', 'category': 'PASTA', 'cho100':49.9},
{'id':699, 'name':'Tortellini, freschi, cotti', 'category': 'PASTA', 'cho100':26},
{'id':700, 'name':'Tortellini, secchi', 'category': 'PASTA', 'cho100':57.6},
{'id':701, 'name':'Triglia', 'category': 'PESCE', 'cho100':1.1},
{'id':702, 'name':'Trota al forno', 'category': 'PESCE', 'cho100':5.8},
{'id':197, 'name':'Cuore di suino', 'category': '', 'cho100':0.4},
{'id':377, 'name':'Margarina, 100% vegetale', 'category': '', 'cho100':0.4},
{'id':378, 'name':'Margarina, 2/3 di grassi animali, 1/3 di grassi vegetali', 'category': '', 'cho100':0.4},
{'id':425, 'name':'Mozzarella di bufala', 'category': '', 'cho100':0.4},
{'id':709, 'name':'Uova di gallina, intero, in polvere', 'category': '', 'cho100':0.4},
{'id':83, 'name':'Butirro calabro', 'category': '', 'cho100':0.3},
{'id':196, 'name':'Cuore di pollo, cotto', 'category': '', 'cho100':0.3},
{'id':199, 'name':'Cuore di tacchino, cotto', 'category': '', 'cho100':0.3},
{'id':281, 'name':'Frittata prosciutto e formaggio', 'category': '', 'cho100':0.3},
{'id':382, 'name':'Mascarpone', 'category': '', 'cho100':0.3},
{'id':400, 'name':'Melù o pesce molo, stoccafisso, ammollato', 'category': '', 'cho100':0.3},
{'id':729, 'name':'Wurstel di puro suino, cotto', 'category': '', 'cho100':0.3},
{'id':96, 'name':'Camembert', 'category': '', 'cho100':0.2},
{'id':716, 'name':'Uva, bianca, fresca', 'category': 'FRUTTA', 'cho100':21.5},
{'id':717, 'name':'Uva, fresca', 'category': 'FRUTTA', 'cho100':15.6},
{'id':718, 'name':'Uva, nera, fresca', 'category': 'FRUTTA', 'cho100':12.6},
{'id':719, 'name':'Uva, secca', 'category': 'FRUTTA', 'cho100':72},
{'id':720, 'name':'Uva, succo, in cartone', 'category': 'FRUTTA', 'cho100':17.3},
{'id':721, 'name':'Vegetali misti, surgelati', 'category': 'VERDURA', 'cho100':13.7},
{'id':174, 'name':'Corvina', 'category': '', 'cho100':0.2},
{'id':195, 'name':'Cuore di pollo', 'category': '', 'cho100':0.2},
{'id':198, 'name':'Cuore di tacchino', 'category': '', 'cho100':0.2},
{'id':507, 'name':'Pecorino', 'category': '', 'cho100':0.2},
{'id':635, 'name':'Salame Napoli', 'category': '', 'cho100':0.2},
{'id':171, 'name':'Coregone', 'category': '', 'cho100':0.1},
{'id':364, 'name':'Luccio', 'category': '', 'cho100':0.1},
{'id':368, 'name':'Maionese, artigianale', 'category': '', 'cho100':0.1},
{'id':713, 'name':'Uova di gallina, tuorlo, in polvere', 'category': '', 'cho100':0.02},
{'id':731, 'name':'Yogurt caprino', 'category': 'LATTICINI', 'cho100':5.1},
{'id':732, 'name':'Yogurt da latte intero, alla frutta', 'category': 'LATTICINI', 'cho100':10.5},
{'id':733, 'name':'Yogurt da latte, parzialmente scremato', 'category': 'LATTICINI', 'cho100':3.8},
{'id':734, 'name':'Yogurt da latte, scremato', 'category': 'LATTICINI', 'cho100':4},
{'id':735, 'name':'Yogurt greco, 0% lipidi', 'category': 'LATTICINI', 'cho100':4},
{'id':736, 'name':'Yogurt greco, 0% lipidi, aromatizzato', 'category': 'LATTICINI', 'cho100':15},
{'id':737, 'name':'Yogurt greco, da latte intero', 'category': 'LATTICINI', 'cho100':2},
{'id':738, 'name':'Yogurt greco, da latte magro, alla frutta', 'category': 'LATTICINI', 'cho100':11},
{'id':739, 'name':'Yogurt, 0% lipidi', 'category': 'LATTICINI', 'cho100':6},
{'id':740, 'name':'Yogurt, da latte intero', 'category': 'LATTICINI', 'cho100':4.3},
{'id':741, 'name':'Zampone Modena IGP, cotto', 'category': 'LATTICINI', 'cho100':2.6},
{'id':742, 'name':'Zucca gialla, fresca', 'category': 'VERDURA', 'cho100':3.5},
{'id':743, 'name':'Zucchero, saccarosio', 'category': 'LATTICINI', 'cho100':104.5},
{'id':744, 'name':'Zucchine chiare, cotte, al vapore', 'category': 'VERDURA', 'cho100':2.5},
{'id':745, 'name':'Zucchine chiare, cotte, in padella grigliate', 'category': 'VERDURA', 'cho100':5.7},
{'id':746, 'name':'Zucchine grigliate', 'category': 'VERDURA', 'cho100':5.5},
{'id':747, 'name':'Zucchine, chiare', 'category': 'VERDURA', 'cho100':1.7},
{'id':748, 'name':'Zucchine, chiare, cotte, al microonde', 'category': 'VERDURA', 'cho100':3},
{'id':749, 'name':'Zucchine, cotte, bollite', 'category': 'VERDURA', 'cho100':3.4},
{'id':750, 'name':'Zucchine, crude', 'category': 'VERDURA', 'cho100':1.7},
{'id':751, 'name':'Zucchine, scure, cotte, al microonde', 'category': 'VERDURA', 'cho100':3.5},
{'id':752, 'name':'Zucchine, scure, cotte, al vapore', 'category': 'VERDURA', 'cho100':2.7},
{'id':753, 'name':'Zucchine, scure, cotte, in padella grigliate', 'category': 'VERDURA', 'cho100':5.6},
{'id':754, 'name':'Zucchine, scure, crude', 'category': 'VERDURA', 'cho100':2.1},
    
]






export  {jsonMenuType, jsonMenu, jsonNewMenu, jsonFoodList, jsonFoodList2};
