import * as React from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import {AuthenticationDetails, CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useNavigate } from "react-router-dom";
import { useJwt } from "react-jwt";
import userpool from './userpool.js';
import User from '../classes/UserDataClass.js'
import { useState, useContext } from "react"
import { AuthContext } from "./AuthContext"
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom"


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignOut({ setUser }) {
  const navigate = useNavigate();
  const { user, signIn, signOut, getCurrentUser } = useContext(AuthContext)
 
    signOut();
   
    navigate("/")
  
}