import React from "react";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';



export default class UserData{
    MEMORY_TAG = "cdcuser"
    id = 0;
    name = "";
    atoken = "";
    idtoken = "";
    rtoken = "";
    nick = "";
    weight = 0;
    ratioList = [];
    
    constructor(userData){
      this.name = "";
      this.id = 0;
      this.token = "";


      if (userData){
        this.setData(userData)
      }

    }
  
    test(){
      return "USER CLASS TEST"
    }


    getFromJson(json){
      
      return Object.assign(new UserData(), json);
    }
    
    setData(data){
      this.id = data.id;
      this.nick = data.nick;
      this.name = data.name;
      this.weight = data.weight;
      this.ratioList = data.ratioList;
      this.cognitoUserSession = data.cognitoUserSession;
      this.cognitoUser = data.cognitoUser;
    }

    getData(){
      return {
        'id': this.id,
        'nick': this.nick,
        'name': this.name,
        'weight' : this.weight,
        'ratioList' : this.ratioList
      }
    }

    addRatio(time, choratio){
      let len = 0;
      if (this.ratioList && this.ratioList.length) len = this.ratioList.length
      let ratio = {"id": len + 1, "t":time, "r":choratio}
      if (len == 0)  this.ratioList = [];
      this.ratioList = Object.assign([], this.ratioList);
      this.ratioList.push(ratio);
      console.log(this.ratioList)

    }

    modRatio(time, choratio, id){
      const objWithIdIndex = this.ratioList.findIndex((ratio) => ratio.id == id)
      this.ratioList[objWithIdIndex].t = time;
      this.ratioList[objWithIdIndex].r = choratio;


    }

    getRatio(id){
      const objWithIdIndex = this.ratioList.findIndex((ratio) => ratio.id == id)
      return this.ratioList[objWithIdIndex];

    }

    delRatio(id){
      const objWithIdIndex = this.ratioList.findIndex((ratio) => ratio.id == id)
      if (objWithIdIndex > -1) {
        this.ratioList = Object.assign([], this.ratioList);
        this.ratioList.splice(objWithIdIndex, 1);
      }
    }

    getRatioList(){
      if (!this.ratioList) this.ratioList = []
      return this.ratioList;
    }


    static getFormGrid(dimension, id, label, value, disabled, numeric, multiline){
      let type = "text";
      let rows = 1;
      if (numeric) type="number";
      if (multiline) rows = 2;
      return (
        <Grid item xs={dimension}>
          <TextField 
            id={id}  
            label={label} 
            variant="outlined" fullWidth sx={{ m: 1 }} 
            defaultValue={value}  
            disabled={disabled}
            type={type}
            inputProps={{step: 0.1 }}
            multiline={multiline}
            rows={rows}
            size="small"
          />
        </Grid>  
      )
    }

    static getForm(ratio){
      const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });

      let id_label = "";
      if (ratio && ratio.id>=0){ 
        id_label = "[" + ratio.id  + "]";
        let f = {}

      }
      return(
        <div>
          <FormControl>
          <Grid container spacing={0}  direction="col"  justifyContent="flex-end"  alignItems="center">
            <Grid item xs={10}><h2>RATIO</h2></Grid>

            {UserData.getFormGrid(2, "id"      , "ID"    , ratio.id, true)}
            {UserData.getFormGrid(6, "time"    , "Time", ratio.t)}
            {UserData.getFormGrid(6,"ratio" ,"Ratio", ratio.r)}



      </Grid>  
      </FormControl>

        </div>

      );
    }

    getTest(){
      return "User TEST";
    }

    getFirstLetter(){
      if (this.name) return this.name[0];
      else return "";
    }
  
    toString(){
      return JSON.stringify(this);
    }

    
    loadFromMemory(){
      console.log("******* LOAD FROM MEMORY ****")
      let json = localStorage.getItem(this.MEMORY_TAG);
      console.log(json);
      
      if (json){
        return this.getFromJson(json);
      }
      
      

  }
  
  saveToMemory() {
      
      localStorage.setItem(this.MEMORY_TAG, this.toString());
      
  }

  static resetMemory(){
      localStorage.removeItem(this.MEMORY_TAG);
      
      localStorage.clear();
  }
   

  }

