import * as React from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';


import  {Event, Events}  from './classes/EventsClass.js';


import { AuthContext } from "./Auth/AuthContext"
//import { useZxing } from "react-zxing";


import EventCalendar from "./components/EventCalendar.js";

import Toolbar from 'react-big-calendar/lib/Toolbar';
import moment from "moment";


import IconButton from '@mui/material/IconButton';
//*** ICONS
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';





const defaultTheme = createTheme();



  




export default function MyCalendar(props) {
  const { user, signOut, getValue, getSession } = React.useContext(AuthContext)
  let ismobile = false;
  if (props  && props.ismobile){
    ismobile = props.ismobile;
  }
  moment.locale("en-GB");
  let events = new Events();
  events.loadFromMemory();

  //███████ ████████  █████  ████████ ███████ 
  //██         ██    ██   ██    ██    ██      
  //███████    ██    ███████    ██    █████   
  //     ██    ██    ██   ██    ██    ██      
  //███████    ██    ██   ██    ██    ███████ 
  //const [events, setEvents] = React.useState(myEventsList);
  const [state, setState] = React.useState(0);
  //console.log(user)

  let value = "value1"



//const localizer = momentLocalizer(moment);




  //██████  ███████ ████████ ██    ██ ██████  ███    ██ 
  //██   ██ ██         ██    ██    ██ ██   ██ ████   ██ 
  //██████  █████      ██    ██    ██ ██████  ██ ██  ██ 
  //██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██ 
  //██   ██ ███████    ██     ██████  ██   ██ ██   ████ 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
         
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
      
        <EventCalendar ismobile={ismobile} events={events}  />
          
        
    </Box>
      
      
      
     
      </Container>
    </ThemeProvider>
  );
}

