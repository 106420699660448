import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

//import { jsonMealList, jsonMealList2 } from './Data.js';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Switch from '@mui/material/Switch';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Cookies from 'universal-cookie';


import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Fab from '@mui/material/Fab';

import Tooltip from '@mui/material/Tooltip';

// TODO remove, this demo shouldn't need to reset the theme.

import AddIcon from '@mui/icons-material/Add';


import Meal from './classes/MealClass.js';
import Food from './classes/MealClass.js';
import MealList from './classes/MealListClass.js';



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { ExportExcel } from './lib/importexport.js';

import { useNavigate } from "react-router-dom";


let msglastID = "---";
var ismobile = false;
var init = 0;





function SimpleSnackbar(props) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      
      <Snackbar open={props.open} autoHideDuration={500} onClose={handleClose}>
        <Alert  severity="success" sx={{ width: '100%' }}> {msglastID} </Alert>
      </Snackbar>
    </div>
  );
}



const defaultTheme = createTheme();



let mealList;
/////////////////////////////////////////////////////////////////////////////
//███████ ████████  █████  ██████  ████████ 
//██         ██    ██   ██ ██   ██    ██    
//███████    ██    ███████ ██████     ██    
//     ██    ██    ██   ██ ██   ██    ██    
//███████    ██    ██   ██ ██   ██    ██    
export default function Meals(props) {
  
  const navigate = useNavigate()
  React.useEffect(() => {
    init = 1;
    
 },[])

//*** INIT 

  mealList = new MealList();
  //mealList.initMealList(jsonMealList);
  mealList.initMealList();



  //if (!rows) rows = localMealList;
  if (props  && props.ismobile){
    ismobile = props.ismobile;
  }

//*** PROCS
  const[state, setState] = React.useState(true);
  const[openMSG, setMSGOpen] = React.useState(false);
  const[opend, setOpend] = React.useState(false); //dialog
  const[opendWeight, setOpendWeight] = React.useState(false); //dialog weight
  const[currentMeal, setCurrentMeal] = React.useState(new Meal()); //dialog
  

  const AddMealToFavourite = (id) => {
    
    msglastID = "Favourite " +  id;
    setMSGOpen(true);
    mealList.setFavourite(id);
    
  }

  const LoadCurrentMeal = (id) => {
    
    msglastID = "Load " +  id;
    setMSGOpen(true);
    
    mealList.setCurrentMeal(id);
    
    navigate("/current")


  }


  const DeleteMeal = (id) => {
    
    msglastID = "Delete " +  id;
    setMSGOpen(true);
    mealList.deleteMeal(id);
    
  }


 

  const columns = [
    { field: 'id', headerName: 'Name', width: 120 , align:'right'},
    { field: 'note', headerName: 'Note',  width: 170,
    
    renderCell: (params) => {
      return (
        
        <div className='topalign'>{params.row.note}</div>
        
        
        
      );
    }
    },
    
    
    /*{field: 'cho100',    headerName: 'CHO',    type: 'number',   width: 10,  align:'right'},
    {field: 'category',    headerName: 'Category',      width: 100,  },*/
    
    { field: "favourite",
      
      width: 5,
      headerName: '*',
      valueGetter: (params) => `${params.row.id}`,
      renderHeader: () => { return (<IconButton><StarIcon style={{ color: 'gold' }}/> </IconButton> );  },
      renderCell: (params) => {
          let starColor = 'gray';

          if (params.row.fav) starColor = 'gold';
          return (
            <IconButton   onClick={()=>{AddMealToFavourite(params.row.id)}}> <StarIcon style={{ color: starColor }}/> </IconButton>
            
          );
        }
    },

    { field: "delete",
      
    width: 5,
    headerName: '*',
    valueGetter: (params) => `${params.row.id}`,
    renderHeader: () => { return (<IconButton><DeleteForeverIcon /></IconButton>  );  },    
    renderCell: (params) => {
      return (
          <IconButton   onClick={()=>{DeleteMeal(params.row.id)}}> <DeleteForeverIcon /> </IconButton>
      );
      }
  },
/*
    { field: "select",
      
      width: 10,
      headerName: '',
      valueGetter: (params) => `${params.row.id}`,
      renderCell: (params) => {
          let starColor = 'gray';

          if (params.row.fav) starColor = 'gold';
          return (
            <IconButton   onClick={()=>{LoadCurrentMeal(params.row.id)}}> <ArrowCircleRightIcon /> </IconButton>
            
          );
        }
      },
    */
    
  ];



  const datafilter = (e, data, ID) =>{
    
    if (!e || !e.target || !e.target.value === undefined) return;

    if (ID === "SEL"){
      if (e.target.value === "ALL") mealList.filterByCategory("");
      else mealList.filterByCategory(e.target.value);
    }

    if (ID === "TXT"){
      mealList.filterByText(e.target.value.toLowerCase());

    }

    if (ID === "SWT"){
      
      mealList.filterByFavourite(data);
      

    }
    

    setState(!state)
    
  }





 

  let mobileColumnsVisibility = {
    // Hide columns status and traderName, the other columns will remain visible
    portion : false, id:false
    
  }

  const field = [];

//*** MAIN  
  
//  if ((localMealList)  && localMealList.length) {
  if ((mealList)  && mealList.hasMeal()) {  
    const categoryList = mealList.getCategoryList();
     
    field.push(<MenuItem value={"ALL"}>{"*** TUTTO ***"}</MenuItem>);
    for (let i=0; i<categoryList.length; i++){
      let category =  categoryList[i];
      field.push(<MenuItem value={category}>{category}</MenuItem>);
    }
  }

  
  if (!ismobile)  mobileColumnsVisibility = {}


  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const styleAddIcon = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
};

//██████  ███████ ████████ ██    ██ ██████  ███    ██ 
//██   ██ ██         ██    ██    ██ ██   ██ ████   ██ 
//██████  █████      ██    ██    ██ ██████  ██ ██  ██ 
//██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██ 
//██   ██ ███████    ██     ██████  ██   ██ ██   ████ 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
         
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        {/*<Button onClick={()=>{const cookies = new Cookies(); cookies.set('CURRENT_MEAL_J2', "{}", { path: '/' });}}> TEST</Button> */}
        <Snackbar open={openMSG} autoHideDuration={500} onClose={() => setMSGOpen(false)}>
          <Alert  severity="success" sx={{ width: '100%' }}> {msglastID} </Alert>
        </Snackbar>
        
        <Grid container spacing={0}  direction="row"  justifyContent="flex-end"  alignItems="center">
        <Grid item xs={9}><TextField
          required
          id="outlined-required"
          label="Filter"
          defaultValue={mealList.getTextFilter()}
          sx={{ height: '4rem', width:'100%'}}
          //onChange={(e, d)=>datafilter(e, d, 'TXT')}
          onKeyUp={(e, d) => {
            if (e.key === 'Enter'){
              
              datafilter(e, d, 'TXT')}
          }}
        /></Grid>


        <Grid item xs={3}>
          <FormControlLabel 
            control={
              <Switch onChange={(e, d) => datafilter(e, d, 'SWT')} />} label="Favoriti" labelPlacement="top" checked={mealList.getFavouriteFilter()}/>
        </Grid>
        
        </Grid>  

        
        
        <FormControl
          variant="outlined"
          margin={"1"}
          style={{ width: "100%", marginBottom: 32 }}
        >

        <InputLabel id="test-select-label">Categoria</InputLabel> 
        <Select
          onChange={(e, d)=>datafilter(e, d, 'SEL')}
          id="demo-simple-select"
          value={mealList.getCategoryFilter()}
          label="Categoria"
          sx={{ height: '4rem', width:'100%'}}
          style={{ width: "100%" }}
          variant="outlined"
        >
        {field}
        </Select>
        </FormControl>

    
        




        <DataGrid
         
          rows={mealList.getMealList()}
          columns={columns}
          onRowDoubleClick={(p, e, d)=>{LoadCurrentMeal(p.id); console.log(p)}}
          columnVisibilityModel={mobileColumnsVisibility}
          rowHeight={60}
          align='left' 
          valign='top' 
          initialState={{ pagination: { paginationModel: { page: 0, pageSize: 100 },    },          }}
          pageSizeOptions={[50, 100, 200]}
          sx={{verticalalign:'top'}}

        /> 
        </Box>


        
      </Container>
    </ThemeProvider>

    
  );
}