import * as React from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsonFoodList } from './Data.js';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

//import InputDialog from './InputDialog.js';

import Food from './classes/FoodClass.js';
import FoodList from './classes/FoodListClass.js';
import XLSX from 'sheetjs-style';
import { base64 } from 'javascript-excel';

//glokoo
import JSZip from 'jszip';
import Glokoo from './classes/GlokooClass.js';

const ExportExcelMenu =  (props) => {
  const fileName = 'foodList';
  const fileExtension = '.xlsx';
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const exportToExcel = async () => {
    
    var workbook = XLSX.utils.book_new();
    let foodList = new FoodList();
    foodList.initFoodList(jsonFoodList);
    var excelData = foodList.foodList;
    const ws = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, ws, "Results");
    XLSX.writeFile(workbook, fileName + fileExtension, {type: 'file'});
  }
  return (
    <>
        
      <Tooltip title = "Excel export">
        <Button onClick={(e) => exportToExcel()} color='primary' variant="contained" startIcon={<CloudDownloadIcon />}> Esporta lista piatti in Excel </Button>
      </Tooltip>
        
    
    </>
  )
}



const ImportExcelMenu =  (props) => {
  const fileName = 'foodList';
  const fileExtension = '.xlsx';
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';



  const handleFile=(e)=> {
    var file = e.target.files[0]
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      var data = reader.result.split(',')[1];
      
      var workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const raw_data = XLSX.utils.sheet_to_json(worksheet);
      let foodList = new FoodList();
      foodList.setFromStandard(raw_data);
      foodList.saveToMemory();
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    }
    
  }

  

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <>
      <Tooltip title = "Excel export">
      <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
        Importa lista piatti da Excel
      <VisuallyHiddenInput type="file" onChange={ handleFile}/>
      </Button>
      </Tooltip>

    </>
  )
}


const defaultTheme = createTheme();

//██ ███    ██ ██ ████████ 
//██ ████   ██ ██    ██    
//██ ██ ██  ██ ██    ██    
//██ ██  ██ ██ ██    ██    
//██ ██   ████ ██    ██    
export default function Export(props) {

  const imgUpload = (e, d) => {
    
    //setINState(Math.random());

  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
    
  });


  const IOButton = (label, in_out, onClick, fileType) => {
    //console.log("IOButton");
    //console.log(label);
    let _fileType = "*.*";
    if (fileType) _fileType = fileType;
    let icon = <FileDownloadIcon />;
    if (in_out) {  
      icon = <CloudUploadIcon />;
    }
    return (
<>
      <Tooltip title = "Excel export">
      <Button component="label" variant="contained" startIcon={icon}>
        {label}
      <VisuallyHiddenInput type="file" onChange={ (e) => handleFile(e, onClick)} accept={_fileType} />
      </Button>
      </Tooltip>

    </>
    )
  }


  //██   ██  █████  ███    ██ ██████  ██      ███████     ███████ ██ ██      ███████ 
  //██   ██ ██   ██ ████   ██ ██   ██ ██      ██          ██      ██ ██      ██      
  //███████ ███████ ██ ██  ██ ██   ██ ██      █████       █████   ██ ██      █████   
  //██   ██ ██   ██ ██  ██ ██ ██   ██ ██      ██          ██      ██ ██      ██      
  //██   ██ ██   ██ ██   ████ ██████  ███████ ███████     ██      ██ ███████ ███████ 
  const handleFile=(e, proc) => { 
    var file = e.target.files[0]
    proc(e, file);
    
  }

  const handleGlooko = async(e, file)=>{
    const glokoo = new Glokoo();
    //glokoo.test();
    //return;
    glokoo.loadFile(file);
    console.log("GLOOKO");
    glokoo.saveToLocalMemory();


  }

 

//███    ███  █████  ██ ███    ██ 
//████  ████ ██   ██ ██ ████   ██ 
//██ ████ ██ ███████ ██ ██ ██  ██ 
//██  ██  ██ ██   ██ ██ ██  ██ ██ 
//██      ██ ██   ██ ██ ██   ████ 
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', flexGrow: 1, }} >
          
        <ExportExcelMenu />
        <br />
        <ImportExcelMenu />
        <br />
        
        {IOButton("IMPORT GLOOKO DATA", 1, handleGlooko, ".zip")}
        </Box>
          
     

      </Container>
    </ThemeProvider>
  );
}




